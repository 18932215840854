import React, { Component } from "react";
import Password from "../../assests/img/Login/password.svg";
import User from "../../assests/img/Login/user.svg"
import Email from "../../assests/img/Login/email.svg"
import { ReactComponent as ResetPassword } from "../../assests/img/Login/reset-password.svg";
import { ReactComponent as PasswordChanged } from "../../assests/img/Login/password-changed-successfully.svg";
import { ReactComponent as TokenExp } from "../../assests/img/Login/token-expired.svg";
import { ReactComponent as TokenInvalid } from "../../assests/img/Login/token-invalid.svg";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ForgetPassSuccess } from "Redux/ForgetPassword/ForgetPassAction";
import ModalWrapper from "components/shared/Modals/ModalWrapper";
import { ReactComponent as Cancel } from "../../assests/img/Login/cancel-btn.svg";
import { fetchAuthToken } from "Redux/auth/authActions";

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: this.props.firstname,
      lname: this.props.lastname,
      email: this.props.email,
      password: "",
      confirmPassword: "",
      mismatch: false,
      emailError:"",
      passwordError:"",
      usernameError:false,
      commonError:false
    };
  }

  checkEmailValidation = (value) => {
    const validEmail = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");    
    if(this.state.email === ""){
        this.setState({emailError:"empty"})
    }else if (!validEmail.test(value)) {
        this.setState({ emailError: "invalid" });
    } else {
        this.setState({ emailError: "valid" });
    }
};

  signUpAPI = () => {
    const {fname,lname,email,password,confirmPassword} = this.state
    let payload = {
      "signup": {
          "first_name": fname,
          "last_name": lname,
          "email": email,
          "password": password,
          "password_confirmation": confirmPassword
      }
    }

    this.props.fetchAuthToken(payload,this.props.closeModal)
  }

  HandleSignup = () => {
    const validPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
    const validEmail = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");    

    // this.checkEmailValidation(this.state.email)
    // if(this.state.password === "" || this.state.confirmPassword === ""){
    //   this.setState({passwordError:"empty"})
    // }else if(!validPassword.test(this.state.password)){
    //   this.setState({passwordError:"invalid"})
    // }else if (this.state.password !== this.state.confirmPassword) {
    //   this.setState({ mismatch: true, passwordError:"" });
    // }else{
    //   this.setState({ mismatch: false,passwordError:"" });
    // }
    // if(this.state.fname === "" || this.state.lname === ""){
    //   this.setState({usernameError:true})
    // }else{
    //   this.setState({usernameError:false})
    // }

    if(this.state.email === "" || this.state.fname === "" || this.state.lname === "" || this.state.password === "" || this.state.confirmPassword === ""){
      this.setState({commonError:true})
    }else if (!validEmail.test(this.state.email)) {
      this.setState({ emailError: "invalid",commonError:false});
    }else if(!validPassword.test(this.state.password)){
      this.setState({passwordError:"invalid",emailError: "",commonError:false})
    }else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ mismatch: true, passwordError:"",emailError: "",commonError:false});
    }else{
      this.setState({ mismatch: false, passwordError:"",emailError: "",commonError:false}); 
      this.signUpAPI()
    }

    // if(this.state.email && this.state.fname && this.state.lname && this.state.password && this.state.confirmPassword && this.state.passwordError==="" && !this.state.mismatch && this.state.emailError==="valid") {
    //   const queryParams = window.location.search;
    //   const token = queryParams.split('accesstoken=')[1];
    //   let emailURL = queryParams.split('email=')[1]?.split('&')[0];
    //   this.props.ForgetPassSuccess(this.state.password, token, emailURL);
    // }
  };

  render() {
    const { lang, t,isLogin,authErroMsg } = this.props;
    
    const { password, confirmPassword, mismatch, email, fname, lname,emailError,passwordError,usernameError,commonError} = this.state;

    const Loader = () => {
      return (
        <div className="d-flex justify-content-center pt-2 pb-2">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    };

    return (
      <>
        <div className="">
              {/* <div className="text-center">
              <ResetPassword />
              </div>
              <h1
                className="text-center btnTxt fw-bolder mb-1 mt-2"
                style={{ fontSize: "20px" }}
              >
                {t("Sign Up")}
              </h1>
              <p className="fs-7" style={{ color: "black" }}>
                {t(
                  "Enter to continue and explore within your graps"
                )}
              </p> */}
              <h1 className='btnTxt fw-bolder mb-1' style={{ fontSize: "20px" }}>{t("Sign Up")}</h1>
              <p className='fs-7' style={{ color: "black" }}>{t("Enter to continue and explore within your graps")}</p>
              <div className="d-flex flex-column LoginIcon">
                {
                  commonError && <p className="text-danger mb-0 start-text">{t("Please fill all the fields")}</p>
                }
                  <div className="d-flex justify-content-between">
                    <div className={`my-1 position-relative ${lang === "ar" ? "ml-2" :"mr-2"}`}>
                    <img
                        src={User}
                        alt="user"
                        className={`img-fluid position-absolute ${
                        lang === "ar" ? "img2" : ""
                        }`}
                    />
                    <input
                        className={`w-100 login-input outline-color-skyblue ${
                        lang === "ar" ? "input2" : ""
                        }`}
                        type="text"
                        name="fname"
                        value={fname}
                        placeholder={t("First Name")}
                        onChange={(e) =>
                        this.setState({ fname: e.target.value })
                        }
                    />
                    </div>
                    <div className="my-1 position-relative">
                  {/* <img
                    src={User}
                    alt="user"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  /> */}
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2 lname-pad2" : "lname-pad"
                    }`} 
                    type="text"
                    name="lname"
                    value={lname}
                    placeholder={t("Last Name")}
                    onChange={(e) =>
                      this.setState({ lname: e.target.value })
                    }
                  />
                    </div>
                  </div>
                  {
                    usernameError && <p className="text-danger mb-0">{t("Please enter First name and Last name")}</p>
                  }
                  <div className="my-1 position-relative">
                  <img
                    src={Email}
                    alt="user"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                    style={{top:"14px"}}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="text"
                    name="email"
                    value={email}
                    placeholder={t("Email")}
                    onChange={(e) =>
                      this.setState({ email : e.target.value })
                    }
                  />
                  <div className="text-start">
                    {
                        emailError === "empty" ? 
                            <p className="text-danger mb-0">{t("Please enter email")}</p> :
                        emailError === "invalid" ? <p className="text-danger start-text mb-0">{t("Please enter a valid email")}</p> : null
                    }
                  </div>
                    </div>
                    <div className="my-2 position-relative">
                  <img
                    src={Password}
                    alt="password"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="Confirmpassword"
                    value={password}
                    placeholder={t("Password")}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </div>
                <div className="my-2 position-relative">
                  <img
                    src={Password}
                    alt="password"
                    className={`img-fluid position-absolute ${
                      lang === "ar" ? "img2" : ""
                    }`}
                  />
                  <input
                    className={`w-100 login-input outline-color-skyblue ${
                      lang === "ar" ? "input2" : ""
                    }`}
                    type="password"
                    name="Confirmpassword"
                    value={confirmPassword}
                    placeholder={t("Confirm Password")}
                    onChange={(e) =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              {passwordError === "empty" ? <p className="text-danger text-start">{t("Please enter password")}</p> : 
              passwordError === "invalid" ? <p className="text-danger start-text">{t("Password must contain at least one (upper case and lower case letter, digit, special character) and min 8 characters")}</p> :
              mismatch ?
                <p className="text-danger start-text">{t("Password Mismatch")}</p> : null
              }
              <button
                className="btn btnTextDark text-light w-100 "
                disabled={this.props.loading}
                onClick={this.HandleSignup}
                >
                {this.props.loading ? <Loader /> : t("Sign up")}
              </button>
                {authErroMsg && <p className="text-danger start-text">{t(`Email exists. Please log in to continue`)}</p>}
              <br />
              {
                !this.props.Authloading &&
                <p>{t("Already have an account ?")} <span className="cursor-pointer login-color" onClick={()=>isLogin()}>{t("Log in")}</span></p>
              }
        </div>

        {/* <ModalWrapper show={this.props.passwordChanged}>
          <div className="bg-white mx-auto LoginModel">
            <div
              className={`position-relative d-md-block d-none ${
                lang === "ar" ? "cross3" : "cross1"
              }`}
            >
              <a href="/">
                <Cancel/>
              </a>
            </div>
            <div
              className={`position-relative d-md-none d-block ${
                lang === "ar" ? "cross4" : "cross2"
              } `}
            >
              <a href="/">
                <Cancel/>
              </a>
            </div>
            <div className="text-center">
              {this.props.passwordChanged === "Password has been changed successful" ? (
                <PasswordChanged />
              ) : this.props.passwordChanged === "Invalid token" ? (
                <TokenInvalid />
                ) : (
                <TokenExp />
              )}
              <h1
                className="btnTxt fw-bolder mb-1 mt-2"
                style={{ fontSize: "18px" }}
              >
                {this.props.passwordChanged === "Password has been changed successful"
                  ? t("User Created Successfully")
                  : this.props.passwordChanged === "Invalid token"
                  ? t("Invalid token")
                  : t("Token expired")}
              </h1>
            </div>
          </div>
        </ModalWrapper> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    Authloading: state.auth.loading,
    passwordChanged: state?.ForgetPassReducer?.message,
    authErroMsg: state.auth.authErroMsg
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ForgetPassSuccess: (pass, token, emailURL) =>dispatch(ForgetPassSuccess(pass, token, emailURL)),
    fetchAuthToken: (payload,cb) => dispatch(fetchAuthToken(payload,cb,true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SignUp));
