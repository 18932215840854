import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';


export class PageNotfound extends Component {
    render() {
        const { t, location } = this.props;
        return (
            <div className="container">
                <div className="my-5 d-flex flex-column justify-content-center text-center p-5" style={{ height: "80vh" }}>
                    <span className='text-darkest'><h1>{`${t("404")}`}</h1></span>
                    <h4 className='pb-3'>{`${t("Page not found")}`}</h4>
                </div>
            </div>
        )
    }
}

export default (withTranslation()(PageNotfound))