import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchBanners } from '../../Redux/banners/bannersAction';
import '../../assests/css/App.css';
import { Link } from 'react-router-dom';

class BannerSection3 extends Component{

constructor(props){
    super(props)
    this.state={
    }
}

componentDidMount(){
    // this.props.fetchBanners();
}

render(){
    let touchstartX = 0;
    let touchendX = 0;

    function handleGesture() {
        if (touchendX < touchstartX && document.getElementsByName('BannerCardNextSection3')?.length !== 0) {document.getElementsByName('BannerCardNextSection3')[0].click()} ;
        if (touchendX > touchstartX && document.getElementsByName('BannerCardPrevSection3')?.length !== 0) {document.getElementsByName('BannerCardPrevSection3')[0].click()} ;
        }
    const {banners} = this.props
    const desktopView = ( banners && banners?.length >0 &&  <div className="d-md-block d-none container-fluid pb-md-5 pb-0 mb-2 mt-1">
    <div className="row">
                <div className="col-12 p-0">
                    <div id="carousel-SectionalBanner2" data-interval='3000' className="carousel slide" data-ride="carousel">
                        <div>
                            <ol className="carousel-indicators">
                                {
                                    banners?.map((e,index) => <li data-target="#carousel-SectionalBanner2" data-slide-to={index} className={index===0 && 'active'}></li>)
                                }                             
                            </ol>
                        </div>
                        
                        <div role="listbox" className="carousel-inner px-2">
                        {this.props.banners &&   this.props.banners?.length > 0 && banners?.map((banner,index) =>
                            <div key={index} className={`${index===0 && `active`} carousel-item `}>
                                    {
                                     banner.banner_clickable_flag && banner.banner_clickable_flag ? 
                                        <Link to={banner.redirect_url && banner.redirect_url}>
                                            <img className="d-block w-100" src={`${this.props.lang === 'en' ? banner?.banner?.large_rectangle : banner?.banner_arabic?.large_rectangle}`} alt='' />
                                        </Link> : <img className="d-block w-100" src={`${this.props.lang === 'en' ? banner?.banner?.large_rectangle : banner?.banner_arabic?.large_rectangle}`} alt='' />
                                    }
                                
                            </div>) }
                             
                        </div>
                        <div className="d-md-block d-none">
                            {
                                 banners?.length > 1 && <>
                                <a className="carousel-control-prev" href="#carousel-SectionalBanner2" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carousel-SectionalBanner2" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                                </>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
</div>)


const mobileView = (  banners &&   banners?.length >0 &&
    <div className="d-block d-md-none container-fluid bg-light pb-md-5 pb-4 mb-2">
    <div  className="row">
                <div  className="col-12 p-0">
                    <div id="carouselMobSectionalBanner2" onTouchStart={(e)=>{touchstartX = e.changedTouches[0].screenX;}} onTouchEnd={(e)=>{touchendX = e.changedTouches[0].screenX;
  handleGesture();}} data-interval='3000' className="carousel slide" data-ride="carousel">
                        <div  className='d-sm-inline-block'>
                            <ol className="indicator-size carousel-indicators mb-5 mt-3">
                            {
                                    banners?.map((e,index) => <li data-target="#carouselMobSectionalBanner2" data-slide-to={index} className={index===0 && `active`}></li>)
                                } 
                            </ol>
                        </div>
                        
                        <div className="carousel-inner px-2">
                        {this.props.banners && this.props.banners?.length > 0 && banners?.map((banner,index) =>
                                <div key={index} className={`${index===0 && `active`} carousel-item `}>
                                {
                                   banner.banner_clickable_flag && banner.banner_clickable_flag ? 
                                    <Link to={banner.redirect_url && banner.redirect_url}>
                                        <img className="d-block w-100 mb-2"style={{height:"8rem"}}  src={`${this.props.lang === 'en' ? banner?.banner?.large_rectangle : banner?.banner_arabic?.large_rectangle}`} alt='' />
                                    </Link> : 
                                    <img className="d-block w-100 mb-2" style={{height:"8rem"}} src={`${this.props.lang === 'en' ? banner?.banner?.large_rectangle : banner?.banner_arabic?.large_rectangle}`} alt='' />
                                }
                            </div>) }
                             
                        </div>
                        <div  className="d-md-block d-none">
                            {
                                 banners?.length > 1 && <>
                                <a  className="carousel-control-prev" name='BannerCardPrevSection3' href="#carouselMobSectionalBanner2" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a  className="carousel-control-next" name='BannerCardNextSection3' href="#carouselMobSectionalBanner2" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                                </>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
</div>
)







    return(
        <>
            {this.props.loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section> : <>
                {desktopView}
                {mobileView}
            </>
        }
        </>
    )
}

}

const mapStateToProps = state => {
    return {
        banners:state.banners.banners?.filter(e=> e?.section_name ==='SECTION-3'),
        loading:state.giftCards.bannerloading
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchBanners: () => dispatch(fetchBanners())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSection3);