import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import Card from '../shared/Card';
import SearchBar from "./SearchBar";
import SearchIcon from "../../assests/img/Union.png";
import cardDetailsLink from "../../assests/img/Frame391.png";
import arrow from "../../assests/img/Arrow.png";
import arrowUp from "../../assests/img/ArrowUp.svg";
import arrowDown from "../../assests/img/ArrowDown.svg";
import getSymbolFromCurrency from "currency-symbol-map";

class DisplayCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: "",
      dropdownFilter: "ALL",
      filteredlist: [],
      searchInp: "",
      displayFiltered: false,
      filterOrders: [],
      sortOrder: {
        date: "desc",
        description: "",
      },
    };
  }
  setDropdownFilter = (val) => {
    this.setState({ dropdownFilter: val });
  };
  setFilter = (val) => {
    this.setState({ searchFilter: val });
  };
  searchFilter = (list, type) => {
    return (
      this.props.cards &&
      this.props.cards.filter(
        (em) =>
          (this.props.type === "hassadGifts"
            ? em.product_name
            : em.brand_name
          ).match(
            new RegExp(
              `${this.state.searchFilter.replace(/[^\w\s]/gi, "")}`,
              "ig"
            )
          ) &&
          (this.state.dropdownFilter === "ALL"
            ? true
            : this.state.dropdownFilter === em.order_status ||
              this.state.dropdownFilter === em.status)
      )
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      filterOrders: this.props?.giftCardOrders,
    });
    this.sortOrders("Date", "desc");
  }
  toPascalCase(str) {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => {
      return chr.toUpperCase();
    });
  }
  sortOrders(name, type) {
    if (name === "Description") {
      this.setState({
        sortOrder: {
          ...this.state.sortOrder,
          description: type,
          date: "",
        },
      });
      if (type === "asc") {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = item1.brand.name.toUpperCase();
          const name2 = item2.brand.name.toUpperCase();
          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });
      } else {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = item1.brand.name.toUpperCase();
          const name2 = item2.brand.name.toUpperCase();
          if (name1 > name2) {
            return -1;
          }
          if (name1 < name2) {
            return 1;
          }
          return 0;
        });
      }
    } else {
      this.setState({
        sortOrder: {
          ...this.state.sortOrder,
          date: type,
          description: "",
        },
      });
      if (type === "asc") {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = moment(item1.createdAt).valueOf();
          const name2 = moment(item2.createdAt).valueOf();
          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });
      } else {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = moment(item1.createdAt).valueOf();
          const name2 = moment(item2.createdAt).valueOf();
          if (name1 > name2) {
            return -1;
          }
          if (name1 < name2) {
            return 1;
          }
          return 0;
        });
      }
    }
  }
  render() {
    let lang = this.props.i18n.language;
    // Mobile view mywallet inner module
    const { cards, name } = this.props;
    const { dropdownFilter } = this.state;
    const { t } = this.props;
    const mapOrders = this.state?.filterOrders
      ?.filter((item) => {
        return this.state?.searchInp.toLowerCase() === ""
          ? item
          : (item?.brand?.name)
              .toLowerCase()
              .includes(this.state?.searchInp.toLowerCase());
      })
      ?.map((val, ind) => {
        return (
          <>
            <div className="card" key={val?.id}>
              <div
                className={`my-acc-headings ${
                  ind % 2 != 0 ? "my-wallet-card-bg" : "bg-white"
                }`}
                id="headingOne"
              >
                <h2 className="mb-0 d-flex align-items-center">
                  <button
                    className="btn btn-block btn-accordion text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapseOne${val?.id}`}
                    aria-expanded="false"
                    aria-controls={`collapseOne${val?.id}`}
                  >
                    {
                      <div className="row d-flex align-items-center">
                        <div className="col d-flex">
                          <span>
                            <img className="arrow-img" src={arrow} alt="" />
                          </span>
                          <span className="mx-2">
                            {moment(val?.createdAt * 1000).format("MM/DD/YYYY")}
                          </span>
                          <span className="">
                            {moment(val.createdAt * 1000).format("hh:mm:ss")}
                          </span>
                        </div>
                        <div className="col">{val?.brand?.name}</div>
                      </div>
                    }
                  </button>
                </h2>
              </div>

              <div
                id={`collapseOne${val?.id}`}
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div
                  className={`card-body border-top ${
                    ind % 2 != 0 ? "my-wallet-card-bg" : "bg-white"
                  }`}
                >
                  <div
                    className={`px-3 py-2 ${
                      ind % 2 === 0 ? "my-wallet-card-bg" : "bg-white"
                    }`}
                  >
                    <div className="d-flex align-items-start justify-content-between py-2">
                      <div className="d-flex flex-column">
                        <span className="text-muted">{t("Status")}</span>
                        <span className="fw-bold" id="status">
                        {t((val?.status_label).toLowerCase())}
                        </span>
                      </div>
                      <div>
                        <img
                          className="d-block img-fluid border rounded"
                          style={{
                            height: "4rem",
                          }}
                          src={val?.brand?.variety?.imageURL}
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column border-top py-2">
                      <span className="text-muted">{t("Order")} #</span>
                      <span className="fw-bold">
                        {val?.orderID}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-top py-2">
                      <span className="text-muted">{`${t(
                        `Total ${getSymbolFromCurrency(
                        val?.brand?.variety?.currency
                      )}`
                      )}  ${t("Redeemed")}`}</span>
                      <span className="fw-bold">
                        {val?.brand?.variety?.denomination * val?.quantity}
                      </span>
                    </div>
                    <div className="d-flex flex-column border-top py-2">
                      <span className="text-muted">{` ${val?.brand?.name?.toLowerCase().includes("paypal") ? t("Number of Credits") : (val?.brand?.name.includes("Special Olympics") ? t('Number of Donations') : t("Number of cards"))}:`}</span>
                      <span className="fw-bold">{val?.quantity}</span>
                    </div>
                    <div className="d-flex flex-column border-top py-2">
                      <span className="text-muted">
                        {val?.address === null
                          ? t("Email")
                          : t("Mailing Address")}
                      </span>
                      <span className="fw-bold">
                        {val?.address === null ? (
                          val?.recipient?.email
                        ) : (
                          <div className="d-flex flex-column">
                            <span>
                              {val?.address?.fullName}
                            </span>
                            <span>
                              {val?.address?.line1}{val?.address?.line2 && `, ${val?.address?.line2}`}
                            </span>
                            <span>
                              {val?.address?.city},&nbsp;{val?.address?.state}&ensp;{val?.address?.zip}
                            </span>
                          </div>
                        )}
                      </span>
                    </div>
                    {/* {val?.status === "FULFILLED" && (
                      <div className="d-flex flex-column border-top py-2">
                        <div className="d-flex py-2 flex-column">
                          <span className="fw-normal text-muted">
                            {`${t("Card Details")}:`}{" "}
                          </span>
                          <div className="my-account-brand d-flex flex-column">
                            {val?.giftCardTokens?.map((token, ind2) => {
                              return (
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/giftcard/${token}/details?lang=${lang}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="wallet-link text-nowrap mr-4"
                                >
                                  <div className="d-flex align-items-center my-account-brand mb-2">
                                    <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                                      <h6
                                        className="m-0 fw-bold "
                                        style={{ fontSize: "11px" }}
                                      >
                                        {t("View")} {ind2 + 1}
                                      </h6>
                                    </button>
                                  </div>
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )} */}
                    {/* {(val?.status === "DELIVERED" ||
                      val?.status === "SHIPPED") && (
                        <>
                        <div className="d-flex flex-column border-top py-2">
                          <div className="d-flex py-2 flex-column">
                            <span className="fw-normal text-muted">
                              {`${t("Track Order")}:`}{" "}
                            </span>
                            <div className="my-account-brand d-flex flex-column">
                              <Link
                                style={{ textDecoration: "none" }}
                                to={{pathname:val?.shipment_tracking_url}}
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="wallet-link text-nowrap mr-4"
                              >
                                <div className="d-flex align-items-center my-account-brand mb-2">
                                  <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                                    <h6
                                      className="m-0 fw-bold "
                                      style={{ fontSize: "11px" }}
                                    >
                                      {t("View")}
                                    </h6>
                                  </button>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column border-top py-2">
                        <span className="text-muted">{t("Tracking Id")}</span>
                        <span className="fw-bold">{val?.shipment_tracking_id}</span>
                      </div>
                        </>
                      )} */}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    const linkCards = (type, row) => {
      if (row.giftcard_token) {
        type = "receivedGiftcards";
      } else if (row.voucher_url) {
        type = "receivedOffers";
      }

      if (type === "giftCards" || type === "eMagazines") {
        return (
          <>
            {row.giftcard_tokens.map((token, index) => {
              return (
                <>
                  <Link
                    target="_blank"
                    to={{
                      pathname: `/giftcard/${token}/details?lang=${this.props.lang}`,
                      state: { ...row },
                    }}
                  >
                    <td className="px-1 py-2 align-bottom  ">
                      <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                        <p className="m-0" style={{ fontSize: "15px" }}>
                          0{index + 1}
                        </p>
                        <h6
                          className="m-0 fw-bold "
                          style={{ fontSize: "11px", color: "#000000" }}
                        >
                          {t("View")}
                        </h6>
                      </button>{" "}
                    </td>
                  </Link>
                </>
              );
            })}
          </>
        );
      } else if (type === "offers") {
        if (row.vouchers === null) {
          return;
        }
        return (
          <>
            {row.is_gift === false &&
              row.vouchers.map((element, index) => {
                return (
                  <a
                    style={{ textDecoration: "none" }}
                    href={element.offer_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <td className="px-1 py-4 align-bottom  ">
                      <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                        <p className="m-0" style={{ fontSize: "15px" }}>
                          0{index + 1}
                        </p>
                        <h6
                          className="m-0 fw-bold mb-1 "
                          style={{ fontSize: "11px", color: "#00008b" }}
                        >
                          {t("View")}
                        </h6>
                      </button>{" "}
                    </td>
                  </a>
                );
              })}
          </>
        );
      } else if (type === "hassadGifts") {
        return (
          <Link
            to={{ pathname: row.shipment_tracking_url }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <td className="px-1 py-2 align-bottom  ">
              <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                <p className="m-0" style={{ fontSize: "15px" }}>
                  01
                </p>
                <h6
                  className="m-0 fw-bold "
                  style={{ fontSize: "11px", color: "#00008b" }}
                >
                  {t("View")}
                </h6>
              </button>{" "}
            </td>
          </Link>
        );
      } else if (type === "experience") {
        return (
          row.experience_url && (
            <Link
              to={{ pathname: row.experience_url }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <td className="px-1 py-4 align-bottom  ">
                <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                  <p className="m-0" style={{ fontSize: "15px" }}>
                    01
                  </p>
                  <h6
                    className="m-0 fw-bold "
                    style={{ fontSize: "12px", color: "#00008b" }}
                  >
                    {t("View")}
                  </h6>
                </button>{" "}
              </td>
            </Link>
          )
        );
      } else if (type === "software" || type === "games") {
        return (
          <Link
            to={{
              pathname: `/softwareGames/${row.id}/details?lang=${this.props.lang}`,
              state: { data: row },
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <td className="px-1 py-4 align-bottom  ">
              <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                <p className="m-0" style={{ fontSize: "15px" }}>
                  01
                </p>
                <h6
                  className="m-0 fw-bold "
                  style={{ fontSize: "12px", color: "#00008b" }}
                >
                  {t("View")}
                </h6>
              </button>{" "}
            </td>
          </Link>
        );
      } else if (type === "receivedgifts") {
        return (
          <>
            {row.gift_message && row.gift_message !== null ? (
              <button
                className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize"
                style={{ fontSize: "12px" }}
              >
                {row.gift_message}
              </button>
            ) : (
              false
            )}
            <Link
              to={{
                pathname: `/giftcard/${row?.giftcard_token}/details?lang=${this.props.lang}`,
                state: { ...row },
              }}
            >
              <td className="px-1 py-2 align-bottom  ">
                <button className="bg-light-gray border-0 px-3 fs-regular  fw-bold view-button">
                  <p className="m-0" style={{ fontSize: "15px" }}>
                    01
                  </p>
                  <h6
                    className="m-0 fw-bold my-1"
                    style={{ fontSize: "12px", color: "#00008b" }}
                  >
                    {t("View")}
                  </h6>
                </button>{" "}
              </td>
            </Link>
          </>
        );
      } else if (type === "receivedGiftcards") {
        return (
          <>
            <div className="py-2 fs-7">
              <span>{t("Gift Card Number")}: </span>
              <span className="color-skyblue fw-bold">
                {row.giftcard_number}
              </span>
            </div>
            {row.gift_message && row.gift_message !== null ? (
              <button
                className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize"
                style={{ fontSize: "12px" }}
              >
                {row.gift_message}
              </button>
            ) : (
              false
            )}
            <Link
              target="_blank"
              to={{
                pathname: `/giftcard/${row.giftcard_token}/details?lang=${this.props.lang}`,
                state: { ...row },
              }}
            >
              <td className="px-1 py-2 align-bottom  ">
                <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                  <p className="m-0" style={{ fontSize: "15px" }}>
                    01
                  </p>
                  <h6
                    className="m-0 fw-bold my-1"
                    style={{ fontSize: "12px", color: "#00008b" }}
                  >
                    {t("View")}
                  </h6>
                </button>{" "}
              </td>
            </Link>
          </>
        );
      } else if (type === "receivedOffers") {
        return (
          <>
            <div className="py-2 fs-7">
              <span>{t("Voucher Number")}: </span>
              <span className="color-skyblue fw-bold">
                {row.voucher_number}
              </span>
            </div>
            {row.gift_message && row.gift_message !== null ? (
              <button
                className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize"
                style={{ fontSize: "12px" }}
              >
                {row.gift_message}
              </button>
            ) : (
              false
            )}
            <a
              style={{ textDecoration: "none" }}
              href={row.voucher_url}
              target="_blank"
              rel="noreferrer"
            >
              <td className="px-1 py-4 align-bottom  ">
                <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                  <p className="m-0" style={{ fontSize: "15px" }}>
                    01
                  </p>
                  <h6
                    className="m-0 fw-bold mb-1 "
                    style={{ fontSize: "11px", color: "#00008b" }}
                  >
                    {t("View")}
                  </h6>
                </button>{" "}
              </td>
            </a>
          </>
        );
      }
    };
    return (
      <section style={{ marginBottom: "8rem" }}>
        {/* <SearchBar 
                    sectionName={name} 
                    onChange={this.setFilter} 
                    dropdownFilter={dropdownFilter} 
                    setDropdownFilter={this.setDropdownFilter}
                    // dropdownItems = {
                    //     cards[0].experience_value ? ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED","PLACED"] :
                    //     ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]
                    // }
                    dropdownItems  = {
                       this.props?.type === 'hassadGifts'? 
                        ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED","SHIPPED"]
                     :    
                        ["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]
                    }
                    
                   
                /> */}
        <div className="user-details p-3 my-4">
          <div className="mb-3 user-details-title fs-5 fw-bolder">
            {t("User Details")}
          </div>
          <div className="d-flex flex-column mb-2">
            <span className="pr-5 pb-1 text-muted fw-normal">{t("Name")}</span>
            <span className="pr-5 fw-bold">
              {this.props.userDetail?.firstName || t("N/A")}
            </span>
          </div>
          <div className="d-flex flex-column mb-2">
            <span className="pr-5 pb-1 text-muted fw-normal">{t("Email")}</span>
            <span className="pr-5 fw-bold">{this.props.userDetail?.email}</span>
          </div>
          {/* <div className='d-flex flex-column mb-2'>
                        <span className='pr-5 pb-1 text-muted fw-normal'>{t("Phone")}</span>
                        <span className='pr-5 fw-bold'>{t("8775741789")}</span>
                    </div> */}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <span className="fs-3 fw-bolder user-details-title">
              {t("My Account")}
            </span>
            <span className="">{`${this.props?.giftCardOrders?.length} ${
              this.props?.giftCardOrders?.length > 1 ? t("Orders") : t("Order")
            }`}</span>
          </div>
          <div className="p-2 mt-2 rounded my-account-search-mb ">
            <input
              className="my-account-search-inp"
              onChange={(e) => this.setState({ searchInp: e.target.value })}
              type="text"
              placeholder={t("Search All")}
            />
            <img src={SearchIcon} alt="search icon" />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center py-4">
          <div className="my-acc-headings w-100">
            <h2 className="mb-0 d-flex align-items-center">
              <button
                className="btn btn-block text-left border-0"
                type="button"
              >
                <div className="row text-muted">
                  <div className="col d-flex align-items-center">
                    <img src={arrow} style={{ opacity: "0" }} alt="" />
                    <span className="mx-2">{t("Date&Time")}</span>
                    <div className="d-flex flex-column">
                      <img
                        className={`cursor-pointer ${
                          this.state.sortOrder?.date &&
                          this.state.sortOrder?.date != "desc"
                            ? ""
                            : "arrow-muted"
                        }`}
                        style={{ width: "1.2rem" }}
                        onClick={() => this.sortOrders("Date&Time", "asc")}
                        src={arrowUp}
                        alt=""
                      />
                      <img
                        className={`cursor-pointer ${
                          this.state.sortOrder?.date &&
                          this.state.sortOrder?.date === "desc"
                            ? ""
                            : "arrow-muted"
                        }`}
                        style={{ width: "1.2rem" }}
                        onClick={() => this.sortOrders("Date&Time", "desc")}
                        src={arrowDown}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col d-flex align-items-center">
                    <span className="mx-2">{t("Description")}</span>
                    <div className="d-flex flex-column">
                      <img
                        className={`cursor-pointer ${
                          this.state.sortOrder?.description &&
                          this.state.sortOrder?.description != "desc"
                            ? ""
                            : "arrow-muted"
                        }`}
                        style={{ width: "1.2rem" }}
                        onClick={() => this.sortOrders("Description", "asc")}
                        src={arrowUp}
                        alt=""
                      />
                      <img
                        className={`cursor-pointer ${
                          this.state.sortOrder?.description &&
                          this.state.sortOrder?.description === "desc"
                            ? ""
                            : "arrow-muted"
                        }`}
                        style={{ width: "1.2rem" }}
                        onClick={() => this.sortOrders("Description", "desc")}
                        src={arrowDown}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </button>
            </h2>
          </div>
          <div className="accordion w-100" id="accordionExample">
            {mapOrders?.length ? (
              mapOrders
            ) : (
              <div className=" text-center text-muted fs-6 mt-3">
                {t("No results found.")}
              </div>
            )}
          </div>
          {/* {this.props?.searchFilter(cards,this.props.type)?.map(card=>(
                        <>
                            <div key={card.id} className="flex-grow-0 w-100">
                            <div className=" d-flex align-items-center justify-content-start my-acc-top">
                            <img 
                                className="d-block img-fluid p-3"
                                style={{width:"150px"}}
                                src={card.images?.small_rectangle || card.image?.small_rectangle || card.card_images?.small_rectangle } alt="" />
                                    <div className='d-flex flex-column'>
                                        <div className="product_title m-0 fw-bold fs-5 overflow-hidden" style={{ maxHeight: '6rem' }}>{this.props.lang === 'en' ? card.product_name || card.brand_name : card.product_name_arabic || card.brand_name_arabic}</div>
                                        <div className="pt-1 pb-3 fs-6 my-account-brand">{t("by")} {this.props.lang === 'en' ? card.brand_name : card.brand_name_arabic}</div>
                                    </div>
                                </div>
                                <div className="p-3 overflow-hidden my-acc-bottom">
                                  <div className='d-flex align-items-center justify-content-between'>
                                        {this.props.type !== "receivedgifts" ?
                                            <div className='d-flex flex-column'>
                                                <span className='text-muted fs-6'>{t("Order ID")}: </span><span className="color-skyblue fw-bold">{card.orderid}</span>
                                            </div>
                                            : false

                                        }
                                        {
                                            this.props.type === "receivedgifts" ?
                                                <div className="bg-light-gray rounded-0 fw-bold text-muted text-capitalize my-2 fs-6 d-inline-block px-2 py-2">
                                                    {t("RECEIVED")}
                                                </div>
                                                :
                                                <div className="status-btn-mb text-center">
                                                    {t(card.order_status || card.status)}
                                                </div>
                                        }
                                  </div>
                            
                            <div className="">                                     {
                       this.props.type === "receivedgifts" ?
                                       
                                         <span className='my-3'><span>{t("Expiry Date")}: </span><span className="color-skyblue fw-bold"><b>{card?.expiration_date ||card.effective_to_date}</b></span></span>
                                       
                                         :
                                         <>
                                          <div className='my-3 d-flex flex-column'><span className='text-muted fs-6'>{t("Order Date")}: </span><span className="color-skyblue fw-bold fs-6"><b>{this.props?.slug?.slug === 'kantar-bav' ? moment(card.date).format("MM/DD/YYYY") : moment(card.date).format("DD/MM/YYYY") || new Date(card.expiration_date).toDateString() }</b></span></div>
                                          </>

                                    } 
                                    {  this.props.type !== "receivedgifts" ? 
                                        <>
                                            <div className='d-flex flex-column mb-3'><span className='text-muted fs-6'>{t("Quantity")}: </span><span className="color-skyblue fw-bold fs-6">2</span></div>
                                            <div className='d-flex flex-column mb-3'><span className='text-muted fs-6'>{t("Product Value")}: </span><span className="color-skyblue fw-bold fs-6">{card.order_total}{" "}{card.unit_name || card.currency_name}</span></div>
                                            <div className='d-flex flex-column mb-3'><span className='text-muted fs-6'>{t("Points Redeemed")}: </span><span className="color-skyblue fw-bold fs-6">20 Points</span></div>
                                            <div className='d-flex flex-column mb-3'><span className='text-muted fs-6'>{t("Shipping Address")}: </span><span className="color-skyblue fw-bold fs-6">Edison, Stephenville Pkwy</span></div>
                                        </>
                                        : false

                                    }
                       
                                    {
                                        this.props.type === 'shop' && card.order_status ==="SHIPPED" && <div><span>{`${t("Tracking ID")}:`} </span><span className="text-dark">{card.shipment_tracking_id}</span></div>
                                    }
                                    {
                           ( card?.isgift || card.is_gift) && (card.order_status=== 'FULFILLED' || card.order_status === 'CONFIRMED')  ?
                           <>
                           {card.recipient_email && <div className='d-flex flex-column my-2'><span className='text-muted fs-6'>{`${t("Recipient Email")}:`} </span><span className="color-skyblue fw-bold fs-6">{card.recipient_email}</span></div>}
                                       {card.recipient_phone && <div><span>{`${t("Recipient Mobile")}:`} </span><span className="color-skyblue">{card.recipient_phone}</span></div>}
                                        <div className='justify-content-start d-flex'>
                                        <button className="bg-light-gray border-0 px-3 py-2 mt-2  fw-bold text-muted text-capitalize"  style={{color:"#00008b"}}>
                                         {t("Gifted")}
                                        </button>
                                         </div>
                                         </>
                                         :
                                          false
                                    }
                                    {
                                        this.props.type === 'experience' && card.status ==="CONFIRMED" && <div>
                                            
                                            {
                                                card.slot_date && 
                                                <div>
                                                    <span>{`${t("Slot Date")}:`} </span><span className="text-dark">{card.slot_date}</span>
                                                </div>
                                            }

                                            {
                                                card.slot_time && 
                                                <div>
                                                    <span>{`${t("Slot Time")}:`} </span><span className="text-dark">{card.slot_time}</span>
                                                </div>
                                            }

                                            {
                                                card.experience_code_id && 
                                                <div>
                                                    <span>{`${t("Experience Code")}:`} </span><span className="text-dark">{card.experience_code_id}</span>
                                                </div>
                                            }
                                            
                                            {
                                                card.confirmation_message && 
                                                <div>
                                                    <span>{`${t("Confirmation Message")}:`} </span><span className="text-dark">{card.confirmation_message}</span>
                                                </div>
                                            }
                                            </div>
                                    }

                                </div>
                                <div className='d-flex flex-column mb-2'>
                                    <span className='text-muted fs-6'>{t("Card Details")}: </span>
                                    <div className='d-flex flex-column my-account-brand'>
                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                            <span>(1) $50 Mastercard Prepaid Card</span>
                                            <img src={cardDetailsLink} alt="Details" />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                            <span>(2) $50 Mastercard Prepaid Card</span>
                                            <img src={cardDetailsLink} alt="Details" />
                                        </div>
                                    </div>
                                </div>
                                {
                                   card.order_status ==="FULFILLED" || card.order_status ==="SHIPPED" || card.status === "CONFIRMED" ? 
                                   linkCards(this.props.type,card)             : false
                               }
                                 {   this.props.type === "receivedgifts" ? 
                                    linkCards(name,card)
                                 :
                                false
                            }
                            </div>
                        
                    </div>
                        </>
                    ))} */}
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    slug: state.tenetReducer?.slug,
    giftCardOrders: state.myWallet.eCardEMagOrders,
    userDetail: state?.countries?.countries,
  };
};
export default connect(mapStateToProps)(withTranslation()(DisplayCards));
