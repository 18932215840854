import axios from "axios"
import store from "Redux/store"
import { REACT_APP_baseUrl } from "utils/app-constants"
import {FETCH_ADDRESS_REQ,FETCH_ADDRESS_SUCCESS,FETCH_ADDRESS_FAILURE,ADD_ADDRESS,EDIT_ADDRESS,DELETE_ADDRESS, SELECTED_ADDRESS} from "./AddressTypes"

const FetchAdressReq = () =>{
    return{
        type : FETCH_ADDRESS_REQ,
    }
}

const FetchAdressSuccess = (data) =>{
    return{
        type : FETCH_ADDRESS_SUCCESS,
        payload : data
    }
}

const FetchAdressFailure = (data) =>{
    return{
        type : FETCH_ADDRESS_FAILURE,
        payload : data
    }
}

export const AddAdrress = (data) =>{
    return{
        type : ADD_ADDRESS,
        payload : data
    }
}

export const EditAdrress = (data) =>{
    return{
        type : EDIT_ADDRESS,
        payload :data
    }
}

export const DeleteAdrress = (data) =>{
    return{
        type : DELETE_ADDRESS,
        payload : data
    }
}

export const selectedAddress = (data) =>{
    return{
        type : SELECTED_ADDRESS,
        payload : data
    }
}

export const addAddress = (payload, isDefault) =>{
    return(dispatch)=>{
        dispatch(FetchAdressReq())
        axios.post(`${REACT_APP_baseUrl}/user/create_user_address`,payload,{
            headers:{
                Authorization : `Bearer ${localStorage.getItem("userToken")}`
            }
        }).then(res =>{
            if(res?.data?.code === 200){
                payload['id'] = res?.data?.data?.id
                if (isDefault){
                    const body = {
                        "id" : res?.data?.data?.id
                    }
                    dispatch(defaultAddress(body))
                }else{
                    dispatch(getAddress())
                }
                // dispatch(AddAdrress(payload))
                // dispatch(selectedAddress(payload))
            }
        }).catch(err =>{
            dispatch(FetchAdressFailure(err?.response?.message))
        })
    }
}

export const getAddress = () =>{
    return(dispatch)=>{
        dispatch(FetchAdressReq())
        axios.get(`${REACT_APP_baseUrl}/user/get_user_addresses`,{
            headers:{
                Authorization : `Bearer ${localStorage.getItem("userToken")}`
            }
        }).then(res =>{
            if(res?.data?.code === 200){
                const addresses = res?.data?.data?.addresses
                // addresses?.length > 0 && addresses.sort((a, b) => b.isDefault - a.isDefault);
                dispatch(FetchAdressSuccess(addresses))
                // const data = addresses?.filter(val => val?.isDefault === true)
                // if(data?.length>0) {
                //     dispatch(selectedAddress(data[0]))
                // }else if(addresses?.length > 0) {
                //     dispatch(selectedAddress(addresses[0]))
                // }
                dispatch(selectedAddress(addresses?.[0]))
            }
        }).catch(err =>{
            dispatch(FetchAdressFailure(err?.response?.message))
        })
    }
}

export const deleteAddress = (payload,data) =>{
    return(dispatch)=>{
        dispatch(FetchAdressReq())
        axios.post(`${REACT_APP_baseUrl}/user/delete_user_address`,payload,{
            headers:{
                Authorization : `Bearer ${localStorage.getItem("userToken")}`
            }
        }).then(res =>{
            if(res?.data?.code === 200){
                dispatch(DeleteAdrress(data))
                dispatch(getAddress())
            }
        }).catch(err =>{
            dispatch(FetchAdressFailure(err?.response?.message))
        })
    }
}

export const updateAddress = (payload) =>{
    return(dispatch)=>{
        dispatch(FetchAdressReq())
        axios.post(`${REACT_APP_baseUrl}/user/update_user_address`,payload,{
            headers:{
                Authorization : `Bearer ${localStorage.getItem("userToken")}`
            }
        }).then(res =>{
            if(res?.data?.code === 200){
                dispatch(EditAdrress(payload))
                const id = store?.getState()?.addressReducer?.selectedAddress?.id
                if(id === payload?.id) dispatch(selectedAddress(payload))
            }
        }).catch(err =>{
            dispatch(FetchAdressFailure(err?.response?.message))
        })
    }
}

export const defaultAddress = (payload) =>{
    return(dispatch)=>{
        dispatch(FetchAdressReq())
        axios.get(`${REACT_APP_baseUrl}/user/set_default_user_address`,{
            headers:{
                Authorization : `Bearer ${localStorage.getItem("userToken")}`
            },
            params : payload
        }).then(res =>{
            if(res?.data?.code === 200){
                dispatch(getAddress())
                // dispatch(DeleteAdrress(data))
            }
        }).catch(err =>{
            dispatch(FetchAdressFailure(err?.response?.message))
        })
    }
}