import React, { Component } from 'react';
// import LoginModal from './Modals/LoginModal';
import MainLoginModel from './Modals/MainLoginModel';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchCountyCode,fetchPhoneFormat } from '../../Redux/CountryCode/CountryCodeActions';
import { fetchCountiriesTopUp } from '../../Redux/mobileTopUp/mobileActions';
import ExperienceConfirmationModal from './Modals/ExperienceConfirmationModal';
class BuyNowCard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showPopup:false,
            confirmOrder:false,
            byNowButton:true,
            orders:{},
            pyload:{},
            quantity:1,
            isLoaded: false,

            countrycode : "SAU",
            formFeilds:{
                country:{id:"country",title:"Country",type:"dropdown",value:"Saudi Arabia",required:true,tabIndex:"1",readOnly:false,ariaLabel:"select your country",errorMessage:"",options:this.props.countryo?.map(val=>val.country_name)},
                mobileNumber:{id:"mobileNumber",title:"Mobile Number",type:"tel",value:"",required:true,tabIndex:"3",readOnly:false,ariaLabel:"enter your mobile number",errorMessage:""},
            },
            operatorlist:[],
            recipient_name:'',
            recipient_email:'',
            recipient_number:undefined,
            paymentType:'points' ,
            selectCountry:""
         }
    }
    
    componentDidMount(){
        this.props.fetchCountyCode()
        this.props.selectedCountry.map((val)=>{
          
                this.changeCountry(val.country_name)
                this.setState({selectCountry:val.country_name})
            
        })
    }
   
    componentDidUpdate(){
        if(!this.props.mobileCountryLoading && this.state.formFeilds.country.options.length===0){
            let updatedFormFeilds = {...this.state.formFeilds}
            updatedFormFeilds.country.options = this.props.countryo?.map(val=>val.country_name)
            if(this.state.formFeilds.country.options.length>0){
                this.setState({
                    formFeilds : updatedFormFeilds
                })
            }
        }
    }
    // error
    selectValue=(val,feild)=>{
        let updatedFormFeilds = {...this.state.formFeilds}
        updatedFormFeilds[feild].value = val
        updatedFormFeilds[feild].errorMessage = ""
        this.setState({
            formFeilds:updatedFormFeilds
        })
        if(feild === "country"){
            this.setState({
                countrycode : this.props.countryo.find(value => (value.country_name === val || value.country_name_arabic === val)).country_code
            }) 
       
        }
       
    }

    

    
setSelectedRate = ( val) => {
    this.setState({
        selectedRate: parseInt(val)
    })
}

setByNowButton(val){
    this.setState(old=>({
        byNowButton:!old.byNowButton
    }))
}

BuyNowWord=(val)=>{
    if(this.props.error){
        this.setState({
            showPopup: val
        })
    }
    else{
        this.sendData()
    }
}

sendData(){
    document.getElementsByTagName('form')[0].checkValidity()
    if(document.getElementsByTagName('form')[0].reportValidity()){
        this.props.parentCallback({
            name:this.state.recipient_name,
            email:this.state.recipient_email,
            number:this.props.PhoneFormat.country_code+this.state.recipient_number,
            paymentType:this.state.paymentType
        })
    }
   
}

checkCreditButton(){
    if(!this.state.byNowButton && this.props.price){
        return false
    }
    else{
        return true
    }
}

changeCountry = (value) => {
    const payload = {
            "countries": {
                "country_name": value
            }
    }
    this.setState({recipient_number:''})
    this.props.fetchPhoneFormat(payload)
}
    render() { 
        
        
        const {t,countryCode } = this.props
        const { confirmOrder,orders } = this.state
        
        const desktopView=(
            <div  className="d-md-block d-none flex-shrink-0" style={{width:'17rem',minWidth:'17rem'}}>
            <div className="card mb-4 rounded-0 w-100">
                <div className="card-body text-black-50">
                {/* <LoginModal show={this.state.showPopup} close={this.BuyNowWord}/> */}
                <MainLoginModel show={this.state.showPopup} loginFun={()=>this.setState({showPopup:!this.state.showPopup})} lang={this.props.lang}/>
                     {
                         confirmOrder && <ExperienceConfirmationModal orders={orders} pyload={this.state.pyload} show={this.state.showPopup}  card={this.props}/>
                     }
                    {
                        <>
                        <form onSubmit={(e) => e.preventDefault()}>
                        <div className='mb-2 py-1'>
                            
                            <div  className="">
                                <div className='pb-1'>{t("Recipient's Name")} </div>
                            <input  
                                                
                                                type="text"
                                                className='p-2 w-100 border rounded-0 text-dark'
                                                placeholder={`${t("Enter Recipient's Name")}`}
                                                name="type"
                                                
                                                required
                                                value={this.state.recipient_name}
                                                onChange={(e)=> this.setState({recipient_name:e.target.value})}
                                                />
                            </div>
                            <div className='pt-3'>
                                <div className=''>{t("Recipient's email")}</div>
                                
                                        <div className="py-2">
                                            <input  
                                                type="email"
                                                className='p-2 w-100 border rounded-0 text-dark'
                                                placeholder={`${t("Enter Recipient's Email")}`}
                                                name="type"
                                                required
                                                value={this.state.recipient_email}
                                                onChange={(e)=> this.setState({recipient_email:e.target.value})}
                                                pattern="^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$"
                                                />
                                        </div>
                            </div>
                            <div className="my-2">
                        <label>{t("Select a Country")}</label>
                        <select className="arrows dis border w-100"  
                        
                        onChange={(e) => {this.changeCountry(e.target.value)}}
                        >
                            
                            {this.props.countryCode && countryCode.map((country, index) => {
                               return <option value={country.country_name} selected={country.country_name === this.state.selectCountry} key={country.id} 
                               > {country.country_name} </option>
                            })}
                        </select>
                        
                    </div>
                    <label>{t("Recipient's number")}</label>
                            <div className="d-flex p-0 w-100 flex-wrap">
                            
                                          {
                                               this.props.lang==='en' ?
                                               <>  <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                               <option
                                               >{this.props.PhoneFormat && this.props.PhoneFormat.country_code} </option>
                                           </select>
                                           
                                           <input 
                                               className="col-9 py-2 rounded-0 border text-dark"
                                               type="number" 
                                               onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)} 
                                               required
                                               onChange={(e)=>this.setState({recipient_number:e.target.value})}
                                                onWheelCapture={e => {e.target.blur()}}
                                               // pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                               value={this.state.recipient_number}
                                               name="number"/>
                                           <div>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div>
                                               </>
                                               :
                                               <>
                                                
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="number" 
                                                onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)} 
                                                required
                                                onChange={(e)=>this.setState({recipient_number:e.target.value})}
                                                onWheelCapture={e => {e.target.blur()}}
                                                // pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                value={this.state.recipient_number}
                                                name="number"/>
                                                 <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                                <option
                                                >{this.props.PhoneFormat && this.props.PhoneFormat.country_code} </option>
                                            </select>
                                            
                                            <div>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div>
                                               </>
                                          }
                                        </div>
                                        </div>
                                        <p className='text-danger'>
                                            
                                            {`${t(`Your information will be shared with the ${this.props.catType ? this.props.catType : 'experience'} provider`)}. ${t("Please ensure to enter the required details correctly")}.`}
                                            </p>
                                        <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setByNowButton()}} />
                                <label className="giftLabel" >{t("I have read and accepted the Terms & Conditions")}</label>
                        </div>
                    <br/>
                    {/* Commenting the Buy with Points */}
                    <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'points'},()=>this.BuyNowWord(this.props.error))}} disabled={this.state.byNowButton}>{t("Buy with Points")}</button>

                    <button type="button" data-testid='exp-credit-card-btn' className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'creditcard'},()=>this.BuyNowWord(this.props.error))}} disabled={this.state.byNowButton}>{t("Buy with Credit Card")}</button>
                
                        </form>
                        </>
                    }
                    
                            
                        
            </div>
                        
            </div>
        </div>)
        const mobileView = (
            <div className='d-md-none d-block mb-5'>
                  <div className="card mb-4 rounded-0 w-100">
                  <div className="card-body text-black-50">
                  {/* <LoginModal show={this.state.showPopup} close={this.BuyNowWord}/> */}
                  <MainLoginModel show={this.state.showPopup} loginFun={()=>this.setState({showPopup:!this.state.showPopup})} lang={this.props.lang}/>
                     {
                         confirmOrder && <ExperienceConfirmationModal show={this.state.showPopup} orders={orders} pyload={this.state.pyload}   card={this.props}/>
                     }
                    {
                        <>
                        <form onSubmit={(e) => e.preventDefault()}>
                        <div className='my-2 py-1'>
                            {t("Recipient's Name")} 
                            <div className="d-flex py-2">
                            <input  
                                                type="text"
                                                className='p-2 border rounded-0 text-dark'
                                                placeholder={`${t("Enter Recipient's Name")}`}
                                                name="type"
                                                required
                                                value={this.state.recipient_name}
                                                onChange={(e)=> this.setState({recipient_name:e.target.value})}
                                                />
                            </div>
                            <div className='py-3'>
                                <div className=''>{t("Recipient's email")}</div>
                                
                                        <div className="py-2">
                                            <input  
                                                type="email"
                                                className='p-2 border rounded-0 text-dark'
                                                placeholder={`${t("Enter Recipient's Email")}`}
                                                name="type"
                                                required
                                                value={this.state.recipient_email}
                                                onChange={(e)=> this.setState({recipient_email:e.target.value})}
                                                pattern="^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$"
                                                />
                                        </div>
                            </div>
                            <div className=" pb-3">
                        <label>{t("Select a Country")}</label>
                        <select className="arrows dis border w-100"  
                        // value={this.props.countryId} 
                        onChange={(e) => {this.changeCountry(e.target.value)}}
                        >
                            {this.props.countryCode && countryCode.map((country, index) => {
                               return <option value={country.country_name} selected={country.country_name === this.state.selectCountry} key={country.id} 
                               > {country.country_name} </option>
                            })}
                        </select>
                    </div>
                    <div className="my-2">
                    <label>{t("Recipient's number")}</label>
                            {
                                 this.props.lang==='en'?
                                 <div className="d-flex p-0 w-100 flex-wrap">
                                            <select className="btn border bg-light rounded-0 col-3 caret px-1"  style={{textAlignLast:"center"}}>
                                                <option>{this.props.PhoneFormat && this.props.PhoneFormat.country_code} </option>
                                            </select>
                                           
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="number" 
                                                onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)} 
                                                required
                                                onChange={(e)=>this.setState({recipient_number:e.target.value})}
                                                // pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                value={this.state.recipient_number}
                                                name="number"/>
                                            <div className='px-3 pt-2'>{t("Format")}: <span className='ml-4'>+{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX </span> </div>
                                        </div>
                                        :
                                        <div className="d-flex p-0 w-100 flex-wrap">
                                           
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="number" 
                                                onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)} 
                                                required
                                                onChange={(e)=>this.setState({recipient_number:e.target.value})}
                                                // pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                value={this.state.recipient_number}
                                                name="number"/>
                                                 <select className="btn border bg-light rounded-0 col-3 caret px-1"  style={{textAlignLast:"center"}}>
                                                <option>{this.props.PhoneFormat && this.props.PhoneFormat.country_code} </option>
                                            </select>
                                           
                                            <div className='px-3 pt-2'>{t("Format")}: <span className='ml-4'>+{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX </span> </div>
                                        </div>
                            }
                                 </div>
                                        </div>
                                        <p className='text-danger'>
                                            
                                        {`${t(`Your information will be shared with the ${this.props.catType ? this.props.catType : 'experience'} provider`)}. ${t("Please ensure to enter the required details correctly")}.`}
                                            </p>
                                        <div className="d-flex gap-1 pt-3">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setByNowButton()}} />
                                <label className="giftLabel" style={{paddingLeft: "1rem",paddingRight:"1rem"}}>{t("I have read and accepted the Terms & Conditions")}</label>
                        </div>
                    <br/>
                    <button type={!this.state.showPopup ? 'submit' : 'button'} className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'points'},()=>{this.BuyNowWord(this.props.error)})}} disabled={this.state.byNowButton}>{t("Buy with Points")}</button>
                    <button type={!this.state.showPopup ? 'submit' : 'button'} className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'creditcard'},()=>{this.BuyNowWord(this.props.error)})}} disabled={this.checkCreditButton()}>{t("Buy with Credit Card")}</button>
                
                        </form>
                        </>
                    }
                    
                            
                        
            </div>
                  </div>
            </div>
        )
        return ( 
            <>
                {desktopView}
                {mobileView}
            </>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        countryCode:state.countryCode.countryCode,
        loading:state.countryCode.countryCodeloading,
        pointBalance:state.point.pointBalance,
        PhoneFormat:state.PhoneFormat.PhoneFormat,
        error:state.auth.error,
        authToken:state.auth.authToken,
        countryList:state.countries.countries,
        mobileCountryLoading:state.mobileCountry.loading,
        countrySelected:state.countries.countryId,
        countryo:state.mobileCountry.mobileCountries,
        selectedCountry:state.countries?.countries?.filter(e => e.id === parseInt(state.countries?.countryId))
        
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchCountyCode : () => dispatch(fetchCountyCode()),
        fetchPhoneFormat : (payload) => dispatch(fetchPhoneFormat(payload)),
        fetchCountiriesTopUp: () => dispatch(fetchCountiriesTopUp()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BuyNowCard));
