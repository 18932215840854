import React from "react";
import "./ModelOverlayComp.css";
import {ReactComponent as CancelIcon} from '../../../assests/img/closeIcon.svg'

const ModelOverlayComp = ({ showModal, closeHandle, size, component }) => {
  return (
    <div className="overlayContainer">
      {component}
      <span className="close_sidenav" onClick={closeHandle}>
        <CancelIcon width={30} height={30}/>
      </span>
    </div>
  );
};

export default ModelOverlayComp;
