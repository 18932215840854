import {FETCH_ADDRESS_REQ,FETCH_ADDRESS_SUCCESS,FETCH_ADDRESS_FAILURE,ADD_ADDRESS,EDIT_ADDRESS,DELETE_ADDRESS, SELECTED_ADDRESS, CLEAR_ADDRESS} from "./AddressTypes"

const initialState = {
    loading:false,
    error:"",
    AddressDetails: [],
    selectedAddress:null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ADDRESS_REQ:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ADDRESS_SUCCESS:
        return {
          ...state,
          loading: false,
          AddressDetails: action.payload,
        };
      case FETCH_ADDRESS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
    case ADD_ADDRESS:
        return {
          ...state,
          loading: false,
          AddressDetails: [...state.AddressDetails, action.payload]
        };
      case EDIT_ADDRESS:
        const index = state.AddressDetails.findIndex(item => item?.id === action?.payload.id)
        const UpdatedAddressDetails = [...state.AddressDetails]
        UpdatedAddressDetails[index] = action?.payload
        return {
          ...state,
          loading: false,
          AddressDetails: UpdatedAddressDetails
        };
      case DELETE_ADDRESS:
        return {
            ...state,
            loading: false,
            AddressDetails: action.payload
        };
      case SELECTED_ADDRESS:
        return{
          ...state,
          selectedAddress: action.payload
        }
        case CLEAR_ADDRESS:
          return{
            ...state,
            AddressDetails: [],
            selectedAddress:null
          }  
      default:
        return state;
    }
  };
  
  export default reducer;