import React, { Component } from "react";
import { connect } from "react-redux";
// import BannerSection from "./BannerSection";
// import FeaturedCards from "./FeaturedCards";
import DiscountOffersSection from "./DIscounts&OfferesSection/DiscountOffersSection";

import { withTranslation } from "react-i18next";
import MobileCategorySection from "./MobileCategorySection";
import { Link } from "react-router-dom";
// import { currancyToPoints } from "../../utils/pointsConversion";
import { fetchEMagazines } from "../../Redux";
import Experience from "./Experience";
import HassadGifts from "./HassdGifts";
import SectionBanner from "./SectionBanner";
// import SoftwareGames from "./SoftwareGamesSection";
import SoftwareSection from "./SoftwareSection";
import GamesSection from "./GamesSection";
import SecondSectionBanner from './SecondSectionBanner';
import { ConfirmAccount } from "Redux/ConfirmAcc/confirmAccAction";
import ModalWrapper from "components/shared/Modals/ModalWrapper";
import { ReactComponent as PasswordChanged } from "../../assests/img/Login/password-changed-successfully.svg";
import { ReactComponent as TokenExp } from "../../assests/img/Login/token-expired.svg";
import { ReactComponent as TokenInvalid } from "../../assests/img/Login/token-invalid.svg";
import { ShowHeader } from "Redux/auth/authActions";


class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eMagazines: [],
      isLoaded: false,
      arr: [],
      showModal:false
    };
  }
  fetchEMagazinesHandler = () => {
    const payload = {
      brands: {
        program_id: 1,
        currency: this.props.countrySelected,
      },
    };
    this.props.fetchEMagazines(payload);
  };
  componentDidMount() {
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }),
      800
    );
    this.props.ShowHeader(true)
    if (this.props.location.pathname.includes("auth/login")) {
      this.setState({ showModal: true });
    }
    const queryParams = window.location.search;
    const token = queryParams.split('confirm_account=')[1];
    if(token){
      this.props.ConfirmAccount(token)
    }else if(this.props.location.pathname.startsWith("auth/login")){
      this.props.history.replace("/")
    }  
  }
  fetchEMagazinesHandler = () => {
    const payload = {
      brands: {
        program_id: 1,
        currency: this.props.countrySelected,
      },
    };
    this.props.fetchEMagazines(payload);
  };
  // componentDidUpdate=(prevProps,prevState)=>{

  //     if((prevProps.currancyCode !== this.props.currancyCode) ){
  //         this.fetchEMagazinesHandler()
  //     }
  // }

  Close = () =>{
    this.setState({showModal:false})
    this.props.history.replace("/")
  }

  render() {
    const { t, modulesData } = this.props;
    // const emagsdesktop = this.props.eMagazines?.length > 0 && (
    //   <>
    //     <div className="d-md-none d-none ">
    //       <section className="px-md-5 px-3 py-md-4 py-1 ">
    //         <div className="headings fw-md-bold px-5 fw-bolder fs-md-3 fs-5">
    //           {t("E-Magazines")}
    //         </div>
    //         {this.props.eMagazines && (
    //           <Link
    //             className={`text-decoration-none color-skyblue fw-bold px-5  ${
    //               this.props.lang === "en" ? "float-right" : "float-left"
    //             } d-inline-block p-0 pb-2`}
    //             onClick={() => {
    //               window.scrollTo(0, 0);
    //             }}
    //             to="/e-magazines"
    //           >
    //             {`${this.props.lang === "en" ? "Show All" : "ترتيب حسب"}`}{" "}
    //           </Link>
    //         )}
    //         <div className="text-black-50 fw-bold px-5 ">
    //           {t("Dive into the world of unlimited reading")}
    //         </div>
    //       </section>
    //       <section className="px-5">
    //         <div className="py-md-4 py-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-3 px-5 mx-auto">
    //           {this.props.eMagazines?.map((mag, index) =>
    //             index < 4 ? (
    //               <div className="text-center border-0" key={mag.id}>
    //                 <Link
    //                   to={{
    //                     pathname: `/e-magazines/${mag.id}`,
    //                     state: { ...mag },
    //                   }}
    //                 >
    //                   <img
    //                     className="img-fluid w-100 card-hover"
    //                     src={mag.images?.color?.medium_rectangle}
    //                     alt={mag.name}
    //                   />
    //                 </Link>
    //                 <div className="fs-5 fw-bold py-md-3 py-2">
    //                   <div className="product_title fw-bold">
    //                     {this.props.lang === "en" ? mag.name : mag.name_arabic}
    //                   </div>
    //                   <div className="product_value">
    //                     {mag.rates[0]}{" "}
    //                     <small>{t("SAR")}</small>
    //                   </div>
    //                 </div>
    //               </div>
    //             ) : (
    //               false
    //             )
    //           )}
    //         </div>
    //       </section>
    //     </div>
    //   </>
    // );
    // const emagsMobile = this.props.eMagazines?.length > 0 && (
    //   <>
    //     <div className="d-none d-md-none  ">
    //       <section className="px-md-5 px-3 mx-auto py-md-4">
    //         {this.props.eMagazines && (
    //           <Link
    //             l
    //             className={`text-decoration-none color-skyblue fw-bold mt-2  ${
    //               this.props.lang === "en" ? "float-right" : "float-left"
    //             } d-inline-block p-0 pb-2`}
    //             onClick={() => {
    //               window.scrollTo(0, 0);
    //             }}
    //             to="/e-magazines"
    //           >
    //             {`${this.props.lang === "en" ? "Show All" : "ترتيب حسب"}`}{" "}
    //           </Link>
    //         )}
    //       </section>
    //       <section className="px-md-5 px-3 mx-auto py-md-4 py-1">
    //         <div className="headings fw-md-bold fw-bolder fs-md-3 fs-5">
    //           {t("E-Magazines")}
    //         </div>

    //         <div className="text-black-50 fw-bold ">
    //           {t("Dive into the world of unlimited reading")}
    //         </div>
    //         <div className="py-md-4 py-3 d-grid grid-cols-md-4 grid-cols-2  gap-md-5 gap-3 justify-content-center">
    //           {this.props.eMagazines?.map((mag, index) =>
    //             index < 4 ? (
    //               <div className="text-center border-0 mx-2" key={mag.id}>
    //                 <Link
    //                   to={{
    //                     pathname: `/e-magazines/${mag.id}`,
    //                     state: { ...mag },
    //                   }}
    //                 >
    //                   <img
    //                     className="img-fluid w-100 card-hover"
    //                     src={mag.images?.color?.large_rectangle}
    //                     alt={mag.name}
    //                   />
    //                 </Link>
    //                 <div className="fs-5 fw-bold py-md-3 py-2">
    //                   <div className="product_title fw-bold">
    //                     {this.props.lang === "en" ? mag.name : mag.name_arabic}
    //                   </div>
    //                   <div className="product_value">
    //                     {mag.rates[0]}{" "}
    //                     <small>{t("SAR")}</small>
    //                   </div>
    //                 </div>
    //               </div>
    //             ) : (
    //               false
    //             )
    //           )}
    //         </div>
    //       </section>
    //     </div>
    //   </>
    // );
    return (
      <div >
        {false ? (
          <section>
            <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <main className="bg-md-color-light bg-color-gray h-auto overflow-auto">
            {/* <div className="px-md-5 px-0">
              <MobileCategorySection lang={this.props.lang} />
            </div> */}
          </main>
        )}

        <div className="px-md-5 px-0 mb-2">
          {/* <------- Offers Section  Section ----------------> */}
          {modulesData["RewardsBy Offers"] && (
            <DiscountOffersSection lang={this.props.lang} />
          )}
          
          {/* <------- Hassad Gifts Section ----------------> */}
          {modulesData["RewardsBy Gifts"] && (
            <HassadGifts lang={this.props.lang} />
          )}
        </div>
        {false ? (
          <section>
            <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <>
            <SectionBanner lang={this.props.lang} />
            {/* <-------  Emagazine Section ----------------> */}
            {/* {!modulesData["E-Magazines"] && (
              <>
                {emagsMobile}
                <main className="bg-md-color-light bg-color-gray h-auto overflow-auto">
                  {emagsdesktop}
                </main>
              </>
            )} */}
                <SecondSectionBanner lang={this.props.lang}/>

          </>
        )}
        {/* <------------- Experiences Section --------------> */}
        {modulesData["Experiences"] && <Experience lang={this.props.lang} />}

        {/* <------- Games Section  ----------------> */}
        {/* {!modulesData["Games"] && <GamesSection lang={this.props.lang} />} */}

        {/* <------- Softwares section  ----------------> */}
        {/* {!modulesData["Software"] && <SoftwareSection lang={this.props.lang} />} */}

        {
          !this.props.ConfirmAccLoading && this.props.ConfirmAccStatus ?
          <ModalWrapper show={this.state.showModal}>
            <div className="bg-white rounded acc-confirm p-4 text-center">
              <div className="acc-confirm-img">
                <PasswordChanged/>
              </div>
              <h4 className="muted-color fw-bold mt-3 mb-4">{t("Account successfully verified")}</h4>
              <button className="btn btn-sky w-100" onClick={this.Close}>{t("OK")}</button>
            </div>
          </ModalWrapper> : 
          !this.props.ConfirmAccLoading && this.props.ConfirmAccError === "Invalid token" ?
          <ModalWrapper show={this.state.showModal}>
            <div className="bg-white rounded acc-confirm p-4 text-center">
              <div className="acc-confirm-img">
                <TokenExp/>
              </div>
              <h4 className="muted-color fw-bold mt-3 mb-4">{t("Invalid Token")}</h4>
              <button className="btn btn-sky w-100" onClick={this.Close}>{t("OK")}</button>
            </div>
        </ModalWrapper> : 
        !this.props.ConfirmAccLoading && this.props.ConfirmAccError === "Something went wrong" ?
        <ModalWrapper show={this.state.showModal}>
          <div className="bg-white rounded acc-confirm p-4 text-center">
            <div className="acc-confirm-img">
              <TokenInvalid/>
            </div>
            <h4 className="muted-color fw-bold mt-3 mb-4">{t("Something went wrong")}</h4>
            <button className="btn btn-sky w-100" onClick={this.Close}>{t("OK")}</button>
          </div>
      </ModalWrapper> : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countriesloading: state.countries.loading,
    // eMagazines:state.eMagazines.eMagazines,
    eMagazines: state.brand.brandData?.filter((e) => e.category_id === 52)[0]
      ?.brands,
    // data:state.brand.brandData,
    loading: state.brand.loading,
    countrySelected: state.countries.countryId,
    currancyCode: state.countries.convertionRate.currency_code,
    modulesData: state.categories.moduleInfo,
    ConfirmAccLoading : state.ConfirmAccount.loading,
    ConfirmAccStatus : state.ConfirmAccount.status,
    ConfirmAccError : state.ConfirmAccount.err,
    valid: state.auth.valid,
    tokenLoading: state.auth.tokenLoading

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchEMagazines: (payload) => dispatch(fetchEMagazines(payload)),
    ConfirmAccount : (token) => dispatch(ConfirmAccount(token)),
    ShowHeader: (data) => dispatch(ShowHeader(data)),

  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(HomePage));
