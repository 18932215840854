import axios from "axios"
import {TENENT_REQUEST,TENENT_SUCCESS,TENENT_FAILURE} from "./TenetTypes"
import { fetchCountriesSuccess } from "Redux/country/countriesAction"

export const getSlugReq = () =>{
    return{
        type:TENENT_REQUEST
    }
}

export const fetSlugSucss = (data) =>{
    return{
        type:TENENT_SUCCESS,
        payload:data
    }
}

export const getSlug = (slug) => {
    let URL = window.location?.origin
    return (dispatch)=>{
        dispatch(getSlugReq())
        axios.get(`${"https://162-review-develop-3zknud.dev.meritincentives.me"}/storefront/v1/tenants?slug=${slug}`).then(response  => {
                dispatch(fetSlugSucss(response.data))
                dispatch(fetchCountriesSuccess(response.data))
            })
            .catch(error => {
                const errorMsg = error
                dispatch({type:TENENT_FAILURE,payload:errorMsg})
            })
    }
}

