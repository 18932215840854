import axios from "axios"
import { FETCH_BRAND_FAILURE, FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS, 
    GET_BRAND_FAILURE, GET_BRAND_REQUEST, GET_BRAND_SUCCESS } from "./brandTypes"
import { REACT_APP_api_V2, REACT_APP_baseUrl_V2_V3 } from "utils/app-constants"



const API_URL = `${REACT_APP_baseUrl_V2_V3}`
export const fetchBrandRequest = () => {
    return {
        type:FETCH_BRAND_REQUEST,
    }
}

export const fetchBrandSuccess = data => {
    return {
        type:FETCH_BRAND_SUCCESS,
        payload:data
    }
}

export const fetchBrandFailure = error =>{
    return {
        type:FETCH_BRAND_FAILURE,
        payload:error
    }
}

export const fetchBrand = (id,currencyCode,countryCode,countrySelected,isMultiLang,isPhysical) => {
    let payload
    isPhysical = isPhysical || JSON.parse(localStorage.getItem("isPhysical"))
    if(REACT_APP_api_V2 === "true" || isMultiLang){
        if(isPhysical){
            payload = {
                    "id": id,
                    "currency": currencyCode,
                    "country": countryCode
                }
            }
        else{
            payload = {
                "id": id,
                "currency": currencyCode,
            }
        }
    }else{
        payload = {
            "brands": {
                "id": id,
                "currency": countrySelected
            }
        }

    }
    const URL = isPhysical ? "merchandise_brand_details" : "get_brand_details"
    return (dispatch)=>{
        dispatch({type: FETCH_BRAND_REQUEST})
        dispatch(fetchBrandRequest);
        axios({
            url:`${API_URL}/brands/${URL}`,
            method:isPhysical ? "POST" : "GET",
            data : isPhysical && payload,
            params : !isPhysical && payload
        },
            )
            .then(response  => {
                if(response.data.code === 200){

                    const brand = response.data?.data?.brand
                    dispatch(fetchBrandSuccess(brand))
                }else if(isPhysical && response?.data?.name){
                    const brand = response.data
                    dispatch(fetchBrandSuccess(brand))
                }
                else{

                    const errorMsg = response.data?.message
                    dispatch(fetchBrandFailure(errorMsg))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchBrandFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const getBrandRequest = () => {
    return {
        type:GET_BRAND_REQUEST,
    }
}

export const getBrandSuccess = data => {
    return {
        type:GET_BRAND_SUCCESS,
        payload:data
    }
}

export const getBrandFailure = error =>{
    return {
        type:GET_BRAND_FAILURE,
        payload:error
    }
}

export const getBrandData = (payload,lang) => {
    const merchantPaylod = {
        "program_id": 1,
        "currency": payload["brands"]["currency"],
        "country": payload["brands"]["country"]
    }
    const GiftcardPayload = {
        "currency": payload["brands"]["currency"]
    }
    return (dispatch)=>{
        dispatch({type: GET_BRAND_REQUEST})
        dispatch(getBrandRequest);
        const ListGiftCards = axios.get(`${API_URL}/brands/get_brands`,{
                params:GiftcardPayload
            })
            const ListMerchandise = axios.post(`${API_URL}/brands/list_merchandise_brands`,
            merchantPaylod
            )
        Promise.all([ListGiftCards,ListMerchandise]).then(([
            ListGiftCards, 
            ListMerchandise
        ])=>{
            let data = null
            const brandData = []
            let merchantData = null
            if(ListGiftCards.data.code === 200){
                brandData.push(ListGiftCards.data.data.categories)
            }
            if(ListMerchandise?.data?.categories?.length > 0){
                merchantData = (ListMerchandise?.data?.categories)
                
            }
            if(brandData?.length > 0) {
                merchantData?.length > 0 && merchantData?.map((val)=>{
                    return(
                        brandData?.[0]?.push(val)
                    )
                })
                data = brandData
            }else if(merchantData?.length > 0){
                data = merchantData
            }
            dispatch(getBrandSuccess(data?.[0] || []))
        }).catch(err =>{
            dispatch(getBrandFailure(err?.response?.message || "Something went wrong!! Please try again!!"))
        })
    }
}