import axios from "axios";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import store from "../store";
import {
  FETCH_ORDER_LIST_FAILURE,
  FETCH_ORDER_LIST_REQUEST,
  FETCH_ORDER_LIST_SUCCESS,
  FETCH_RECEIVEDGIFTS_SUCCESS,
} from "./MyWalletTypes";
import {
  REACT_APP_baseUrl,
  REACT_APP_baseUrl_V2_V3,
  REACT_APP_baseUrl_V4,
} from "utils/app-constants";
import moment from "moment";

export const fetchOrderListRequest = () => {
  return {
    type: FETCH_ORDER_LIST_REQUEST,
  };
};

export const fetchOrderListSuccess = (data) => {
  return {
    type: FETCH_ORDER_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchOrderListFailure = (error) => {
  return {
    type: FETCH_ORDER_LIST_FAILURE,
    payload: error,
  };
};

export const fetchOrderList = (payload, countrySelected, lang) => {
  const expPayload = {
    experience_orders: {
      currency: countrySelected,
    },
  };
  let token = localStorage.getItem("userToken");
  let URL = REACT_APP_baseUrl_V4;
  return (dispatch) => {
    dispatch({ type: FETCH_ORDER_LIST_REQUEST });
    dispatch(fetchOrderListRequest);
    const eCardEMagOrdersResponse = axios.get(`${URL}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const merchantOrdersResponse = axios.get(
      `${URL}/orders/get_merchant_order_list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // const offersOrdersResponse = axios.post(`${REACT_APP_baseUrl}/offer_orders/get_orders`,
    //                             payload,{
    //                                 headers:{
    //                                     "Authorization":`Bearer ${store.getState().auth.authToken}`
    //                                 },
    //                                 params:{
    //                                     "secret_key":secretKeyGenerator(payload)
    //                                 }
    //                             })

    // const experiencesOrdersResponse = axios.post(`${REACT_APP_baseUrl}/experience_orders/get_orders`,
    // expPayload,{
    //                                 headers:{
    //                                     "Authorization":`Bearer ${store.getState().auth.authToken}`
    //                                 },
    //                                 params:{
    //                                     "secret_key":secretKeyGenerator(expPayload)
    //                                 }
    //                             })
    // const upcomingflightsResponse = axios.get(`${REACT_APP_baseUrl}/travel_shops/get_upcomming_flights`,
    // {
    //     headers:{
    //         "Authorization":`Bearer ${store.getState().auth.authToken}`
    //     },
    //     params:{
    //         "order":"desc",
    //         "type":"future"
    //     }})

    // const pastflightsResponse = axios.get(`${REACT_APP_baseUrl}/travel_shops/get_upcomming_flights`,
    // {
    //     headers:{
    //         "Authorization":`Bearer ${store.getState().auth.authToken}`
    //     },
    //     params:{
    //         "order":"desc",
    //         "type":"past"
    //     }})

    // axios.get(`${REACT_APP_baseUrl}/giftcards/received_gifts`,{
    //     headers:{
    //         "Authorization":`Bearer ${store.getState().auth.authToken}`
    //     }
    // }).then(response => {
    //     if(response.data.code === 200){
    //         dispatch({
    //             type:FETCH_RECEIVEDGIFTS_SUCCESS,
    //             payload:{
    //                 giftReceived:response.data.data.giftcards,
    //                 offerReceived:response.data.data.vouchers
    //             }
    //         })
    //     }
    // })
    // const upcomingFlightStatus = (status) => {
    //     if(['success'].includes(status)){return 'success'};
    //     if(['in_progress','hold'].includes(status)){return 'in progress'}
    //     return 'canceled'
    // }

    Promise.all([eCardEMagOrdersResponse, merchantOrdersResponse])
      .then(
        ([
          eCardEMagOrders,
          merchantOrders,
          // offersOrders,
          // experiencesOrder,
          // upcomingFlights,
          // pastflights
        ]) => {
          let data = null;
          const GiftCardsOrdersData = []
          const MerchantOrdersData = []
          let ecardEmag = eCardEMagOrders?.data?.data?.filter(
            (val) => val?.status !== "PENDING"
          );
          const merchantOrder = merchantOrders?.data?.data?.filter(val => val?.status !== "PENDING")
          
          ecardEmag?.map((Val) => {
            return Val?.items?.map((val2) => {
              val2["orderID"] = Val?.id;
              return GiftCardsOrdersData?.push(val2);
            });
          });
          merchantOrder?.map((Val) => {
            return Val?.items?.map((val2) => {
              val2["orderID"] = Val?.id;
              return MerchantOrdersData?.push(val2);
            });
          });

          if(GiftCardsOrdersData?.length > 0){
            MerchantOrdersData?.length > 0 && 
            MerchantOrdersData?.map((val)=>{
                return(
                    GiftCardsOrdersData?.push(val)
                )
            })
            data = GiftCardsOrdersData
          }else if(MerchantOrdersData?.length > 0){
            data = MerchantOrdersData
        }

        let filterData = []

        filterData = data?.length > 0 && data.filter(
          (val) => (val?.status === "REVIEW" || val?.status === "AWAITING_CONFIRMATION" || val?.status === "DELIVERED" || val?.status === "CANCELED" || val?.status === "FULFILLED" || val?.status === "SHIPPED" || val?.status === "APPROVED"));

        filterData?.length > 0 && filterData?.sort((item1, item2) => {
            const name1 = moment(item1.createdAt).valueOf();
            const name2 = moment(item2.createdAt).valueOf();
            if (name1 > name2) {
              return -1;
            }
            if (name1 < name2) {
              return 1;
            }
            return 0;
          });

          // ecardEmag =  [...new Map(ecardEmag?.map((m) => [m.orderid, m]))?.values()];

          // const offerOrder = offersOrders.data.data.orders.filter(val => val.order_status !== "PENDING")
          // const experienceOrder = experiencesOrder.data.data.orders.filter(val => val.status !== "PENDING")
          // const mobileTopUpOrder = eCardEMagOrders.data.data.orders.filter(val => val.order_type === "MOBILE_TOPUP" && val.order_status !== "PENDING")
          // const merchantOrder = merchantOrders.data.data.orders.filter(val => val.order_status !== "PENDING")
          // const softwareGamesOrder =  eCardEMagOrders.data.data.orders.filter(val => val.order_status !== "PENDING" && (val.order_type === 'SOFTWARE_AND_GAME'))
          // const comingFligtsData=upcomingFlights.data.data.upcomming_flights?.results;
          // comingFligtsData?.forEach(e => e.booking_status = upcomingFlightStatus(e.booking_status));
          // const pastflightsData = pastflights.data.data.upcomming_flights.results

          // Commenting for next release
          // const softwareOrder = eCardEMagOrders.data.data.orders.filter(val => val.order_status !== "PENDING" && val.reward_type === 'software')
          // const gameOrder = eCardEMagOrders.data.data.orders.filter(val => val.order_status !== "PENDING" && val.reward_type === 'game')

          dispatch(
            fetchOrderListSuccess({
              eCardEMagOrders: filterData || [],
              // mobileTopUpOrders :mobileTopUpOrder,
              // offersOrders:offerOrder,
              // experienceOrders :experienceOrder,
              // merchantOrders:merchantOrder,
              // softwareGamesOrder:softwareGamesOrder,
              // comingFlights:comingFligtsData || [],
              // pastFlights:pastflightsData || [],

              // Commenting for next release

              // softwareOrders: [],
              // gameOrders: []
            })
          );
        }
      )
      .catch((error) => {
        const errorMsg = error;
        console.log(errorMsg);
        dispatch(fetchOrderListFailure("failed"));
      });
  };
};
