import React,{Component} from 'react'
import { connect } from 'react-redux';
import { fetchFeatureCards } from '../../Redux/featureCards/featureCardsActions';
import { withTranslation } from 'react-i18next';
import '../../assests/css/App.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel'
import countryCurrency from "../../utils/countrycodes/countryCurrency.json"
import { REACT_APP_api_V2 } from 'utils/app-constants';

class FeaturedCards extends Component{

    constructor(props){
        super(props)
        this.state={
            showCards:false,
            shouldBeActive: true,
            limit:4,
            arr:[],
            featureCards:undefined,
            device:'forMobile'
        }
        
    }

    fetchFeaturedCardsHandler=()=>{
        let currency = countryCurrency?.filter(val => val?.countryName === this.props.selectedCountry[0].name)
        let payload
        if(REACT_APP_api_V2 === "true"){
            payload = {
                "brands":
                    {
                        "program_id":1, 
                        "currency": currency[0]?.currencyCode,
                        "country": this.props.country
                    }, 
            }
        }else{
            payload = {
            "brands":
                {
                    "program_id":1, 
                    "currency":this.props.countrySelected,
                }, 
            }
        }

        this.props.fetchFeatureCards(payload,this.props.lang);
    }

    componentDidMount(){
  
        this.fetchFeaturedCardsHandler()
        if(window.innerWidth>700){
            this.setState({device:'forLaptop'})
        }
    }
    
    componentDidUpdate=(prevProps)=>{
 
        if((prevProps.currancyCode && (prevProps.currancyCode !== this.props.currancyCode)) ){
                this.fetchFeaturedCardsHandler()
        }
        if(this.props.featureCards?.length>0 && (prevProps.loading !== this.props.loading)){

            if(this.state.device === 'forMobile'){
                this.cardAdder(2)
            }
            else{
                this.cardAdder(4)
            }
        }    
    }

    showAllCards(){
        const {showCards} = this.state
        this.setState({
            showCards:!showCards
        })
    }
    
    cardAdder(limit){
        if(this.state.device === 'forLaptop'){
            let firstSlide = document.getElementById('firstSlide')
                firstSlide && firstSlide.click()
        }
        else{
            let firstSlideMob = document.getElementById('firstSlideMob')
           firstSlideMob && firstSlideMob.click()
        }

        let cards = [];
        // 
        if(this.props.featureCards?.length > limit){
            for(let i = 0;i<this.props.featureCards?.length;i+=limit){
                let arr = [];
                for(let j = 0;j<limit;j++){
                    this.props.featureCards[i+j] && arr.push(this.props.featureCards[i+j])
                }
                cards.push(arr)
            }
        }
        else{
            cards.push(this.props.featureCards)
        }
       this.setState({featureCards:cards})
    }
    render(){
        let val;
        let limit;
        let arr = []
        const {t}= this.props
        if(this.props.featureCards && arr.length === 0){val = this.props.featureCards?.length}
        window.innerWidth >=700 ? limit = 4 : limit = 2;
        for(let i = 0;i<Math.ceil(val/limit);i++){
            arr.push(i)
        }
        // let touchstartX = 0;
        // let touchendX = 0;


        // function handleGesture() {
        // if (touchendX < touchstartX) {document.getElementsByName('featureCardNext')[0].click()} ;
        // if (touchendX > touchstartX) {document.getElementsByName('featureCardPrev')[0].click()} ;
        // }

        const desktopView=(
            <>
            { this.props.loading ?
                <>
                 <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                </>
                :
                <>
               { this.props.featureCards?.length>0 && <div className="d-md-block d-none container-fluid p-0 pb-5">
            <div className="d-flex">
                    <div className="w-100">
                        <div className="mx-5">
                            <div>
                               <h3 className='headings fw-bold'>{`${this.props.lang === 'en' ? "Our Favourite Brands" : "الماركات المفضلة"}`}</h3>     
                             {/* {
                                 this.props.featureCards && <Link className={`text-decoration-none color-skyblue fw-bolder  ${this.props.lang === 'en' ? "float-right": "float-left"} d-inline-block p-0 pb-2`}
                                 onClick={()=>{window.scrollTo(0, 0)}}
                                 to='/giftcards'>{`${this.props.lang === 'en' ? "Show All" :  "ترتيب حسب"}`}</Link>
                             } */}
                                <h6 className='text-black-50'>{`${this.props.lang === 'en' ? "Send gift cards to anyone anywhere in the world" : " أرسل بطاقات هدايا لأي شخص في أي مكان في العالم"}`}</h6>
                            </div>
                        </div>
                        <br/>
                        {   !(this.state.showCards && !this.props.loading) ? 
                        <Carousel controls={this.props.featureCards?.length > 4} className='mx-5 customcarousel'>

                        {
                               this.state.featureCards && this.state.featureCards.map((card,index) =>  (               
                                       <Carousel.Item interval={3000} key={index}>
                                                     {
                                                        card[0] && <Link to={`/selectedgiftcard/${card[0]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover" style={{width:'45%'}} src={card[0]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                   </Link>
                                                    }
                                                    {
                                                        card[1] && <Link to={`/selectedgiftcard/${card[1]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[1]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                </Link>
                                                    }
                                                    {
                                                        card[2] && <Link to={`/selectedgiftcard/${card[2]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[2]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                </Link>
                                                    } 
                                                    {
                                                        card[3] && <Link to={`/selectedgiftcard/${card[3]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[3]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                </Link>
                                                    }                                       
                                </Carousel.Item>
                                    ))
 
}
</Carousel>
: 
false 
                        }
                        {/* {   !(this.state.showCards && !this.props.loading) ?
                            <div id="featureCardIndicator" className="carousel slide text-center" data-ride='carousel' data-interval='4000'>
                            <ol className="carousel-indicators mb-3">
                                {       
                                    this.props.featureCards && arr?.map((arr,index)=>(
                                        <li id={index === 0 ? "firstSlide" : "" } data-target="#featureCardIndicator" data-slide-to={index} className={index === 0 ? "active" : "" }></li>
                                    ))
                                }             
                          </ol>


                          <div role="listbox" className="carousel-inner">
                          {
                                            this.state.featureCards && this.state.featureCards.map((card,index) => (
                                                <div className={`carousel-item ${index ===0 && 'active'}`}>
                                                    {
                                                        card[0] && <Link to={`/selectedgiftcard/${card[0]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover w-20" style={{width:'45%'}} src={card[0]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                   </Link>
                                                    }
                                                    {
                                                        card[1] && <Link to={`/selectedgiftcard/${card[1]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover w-20"style={{width:'45%'}} src={card[1]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                </Link>
                                                    }
                                                    {
                                                        card[2] && <Link to={`/selectedgiftcard/${card[2]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover w-20"style={{width:'45%'}} src={card[2]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                </Link>
                                                    } 
                                                    {
                                                        card[3] && <Link to={`/selectedgiftcard/${card[3]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                        <img className="p-2 eFeatured card-hover w-20"style={{width:'45%'}} src={card[3]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                </Link>
                                                    }  
                                                </div>
                                            ))
                                        }
                       
                            </div>    
                            <a className="carousel-control-prev"  href="#featureCardIndicator" role="button" data-slide="prev" id='nextSlide'>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next"  href="#featureCardIndicator" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div> :false
                         } */}
                    </div>
                </div>
        </div>
    }
                </>
            }
            </>
           
        )
        const mobileView=(
           <>
           {
               this.props.loading ?
               <>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
               </>
               :
               <>
               {
                    this.props.featureCards?.length>0 && <div className="d-block d-md-none container-fluid px-0 py-3 bg-light mb-2">
                    <div className="d-flex">
                            <div className="w-100">
                                <div className="px-4 mx-auto">
                                    <div>
                                       <h5 className='headings m-0 d-inline-block'>{t("Our Favourite Brands")}</h5>
                                       {/* {
                                         this.props.featureCards && <Link className={`text-decoration-none   ${this.props.lang === 'en' ? "float-right": "float-left"} d-inline-block p-0 pb-2`}
                                         onClick={()=>{window.scrollTo(0, 0)}}
                                         to='/giftcards'>{`${this.props.lang === 'en' ? "Show All" :  "ترتيب حسب"}`}</Link>
                                     } */}
                                       
                                        <small className='text-black-50 m-0 fw-bold d-block'>{t("Send gift cards to anyone anywhere in the world")}</small>
                                    </div>
                                </div>
                                
                                <Carousel className='text-center' controls={false} indicators={false}>
                               
                              {  this.state.featureCards && this.state.featureCards.map((card,index) => (
                                             <Carousel.Item interval={3000} key={index}>
                                {
                                                                card[0] && <Link to={`/selectedgiftcard/${card[0]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                                <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[0]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                        </Link>
                                                            }
                                                           {
                                                                card[1] && <Link to={`/selectedgiftcard/${card[1]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                                <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[1]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                        </Link> 
                                                            }                                     
                                        </Carousel.Item>
                              ))
                          }
                          </Carousel>
                                {/* <span className="p-2 w-2">send gift cards to anyone anywhere in the world</span> */}   
                                {/* onTouchStart={(e)=>{touchstartX = e.changedTouches[0].screenX;}} onTouchEnd={(e)=>{touchendX = e.changedTouches[0].screenX;
        handleGesture();}}                       */}
                          {/* <div  data-interval="4000" id="carouselExampleControls" class="carousel slide text-center" data-ride="carousel"> */}
                                    {/* <div className="carousel-inner mx-n1 p-2">
                                                {
                                                    this.state.featureCards && this.state.featureCards.map((card,index) => (
                                                        <div className={`carousel-item ${index ===0 && 'active'}`}>
                                                            {
                                                                card[0] && <Link to={`/selectedgiftcard/${card[0]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                                <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[0]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                        </Link>
                                                            }
                                                            {
                                                                card[1] && <Link to={`/selectedgiftcard/${card[1]?.id}`} onClick={()=>{window.scrollTo(0, 0)}}>
                                                                <img className="p-2 eFeatured card-hover"style={{width:'45%'}} src={card[1]?.images?.color?.medium_square} alt="First slide" srcSet=""/>
                                                        </Link> 
                                                            }
                                                        </div>
                                                    ))
                                                }
                                                
                                        
                                    </div>    */}
                                    {/* <ol  className="indicator-size carousel-indicators m-5 pb-1 ">
                                        {       
                                            this.props.featureCards && arr.map((arr,index)=>(
                                                index < 4 && <li id={index === 0 ? "firstSlideMob" : "" } data-target="#carouselExampleControls" data-slide-to={`${index}`} className={index === 0 ? "active" : "" }></li>
                                            )) 
                                        }    
                                  </ol>    */}
                                  {/* <a style={{visibility:'hidden'}} className="carousel-control-prev" name='featureCardPrev' href="#carouselExampleControls" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a style={{visibility:'hidden'}} className="carousel-control-next" name='featureCardNext' href="#carouselExampleControls" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a> */}
                                {/* </div> */}
                                
                            </div>
                        </div>
                </div>
               }
               </>
           }
           </>
        )
        return(
            <>
                {desktopView}
                {mobileView}
            </>
        )
    }
}
const mapStateToProps = state => {
 
    return {
        featureCards:state.featureCards.featureCards,
        loading:state.featureCards.loading,
        pointBalance:state.point.pointBalance,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        country: state.countries?.countryCode,
        selectedCountry : state.countries?.countries?.countries?.filter(val => state.countries?.countryCode === val.code),
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchFeatureCards : (payload,lang) => dispatch(fetchFeatureCards(payload,lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FeaturedCards));
