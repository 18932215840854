import { ADD_TO_CART, REMOVE_ITEM, SUB_QUANTITY, ADD_QUANTITY, DESTROY_CART } from './cartTypes'


const initState = {
    addedItems: JSON.parse(localStorage.getItem("cart")) || [],
}

const cartReducer = (state = initState, action) => {
    //INSIDE HOME COMPONENT
    if (action.type === ADD_TO_CART) {
        //check if the action id exists in the addedItems
        let existed_item = state.addedItems.find(item => action.payload.id === item.id)

        if (existed_item) {
            existed_item["quantity"] += action.payload?.quantity
            state.addedItems.quantity += action.payload?.quantity
            localStorage.setItem("cart", JSON.stringify(state.addedItems))
            return {
                ...state,
            }

        }
        else {
            localStorage.setItem("cart", JSON.stringify(state.addedItems))
            return {
                ...state,
                addedItems: [...state.addedItems, action.payload],
            }

        }
    }
    if (action.type === REMOVE_ITEM) {
        let new_items = state.addedItems.filter(item => action.id !== item.id)

        return {
            ...state,
            addedItems: new_items,
        }
    }
    //INSIDE CART COMPONENT
    if (action.type === ADD_QUANTITY) {
        let addedItem = state.addedItems.find(item => item.id === action.id)
        addedItem.quantity += 1
        localStorage.setItem("cart", JSON.stringify(state.addedItems))
        return {
            ...state,
        }
    }
    if (action.type === SUB_QUANTITY) {
        let addedItem = state.addedItems.find(item => item.id === action.id)
        //if the qt == 0 then it should be removed
        if (addedItem.quantity === 1) {
            let new_items = state.addedItems.filter(item => item.id !== action.id)
            return {
                ...state,
                addedItems: new_items,
            }
        }
        else {
            addedItem.quantity -= 1
            localStorage.setItem("cart", JSON.stringify(state.addedItems))
            return {
                ...state,
            }
        }

    }
    if (action.type === DESTROY_CART) {
        return {
            addedItems: []
        }
    }
    else {
        return state
    }

}

export default cartReducer
