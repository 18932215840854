import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import emailIcon from "../../assests/img/email.svg";
import phoneIcon from "../../assests/img/phone.svg";
import backButton from "../../assests/img/carret-left-blue.svg";
import { ISSUE_TYPE } from "utils/ConstantData";
import { ShowHeader } from "Redux/auth/authActions";
import axios from "axios";
import { REACT_APP_baseUrl, REACT_APP_TCS_STOREFRONT } from "utils/app-constants";
import ModalWrapper from "components/shared/Modals/ModalWrapper";
import Success from "../../assests/img/Success.svg";
import cookie from 'react-cookies';
import disposableEmails from '../../utils/DisposableEmails.json'
import { fetSlugSucss } from "Redux/multiTenet/TenetAction";

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dropdownHandle = this.dropdownHandle.bind(this);
    this.validate = this.validate.bind(this)
    this.state = {
      name: this.props?.userDetails?.firstName || "",
      email: this.props?.userDetails?.email || "",
      orderId: this.props?.userDetails?.customer_id || "",
      referenceId: "",
      message: "",
      error: {
        issueType:"",
        name: "",
        email: "",
        orderId: "",
        referenceId: "",
        message: "",
      },
      issueType: "",
      loading: false,
      loadPage:false,
      show: false
    };
  }
  validate() {
    const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
    if(!this.state.issueType){
      this.setState({ error: { issueType: this.props.t('Issue type is required') } })
      return false;
    }else if (!this.state.name) {
      this.setState({ error: { name: this.props.t('Name field is required') } })
      return false;
    } else if (!this.state.email) {
      this.setState({ error: { email: this.props.t("Email field is required") } })
      return false;
    } else if (emailRegex.test(this.state.email) === false) {
      this.setState({ error: { email: this.props.t("Enter valid email") } });
      return false;
    }else if (disposableEmails[this.state.email.split('@')[1]]) {
      this.setState({ error: { email: this.props.t("This email is not allowed") } });
      return false;
    }else if (this.props.slug?.slug === 'kantar-bav' && !this.state.orderId) {
      this.setState({ error: { orderId: this.props.t("User Id field is required") } });
      return false;
    }else if(!this.state.message){
      this.setState({error:{message:this.props.t('Message field is required')}})
      return false;
    }
    return true
  }
  handleChange(event) {
    let name = event.target.name
    this.setState({ [name]: event.target.value });
    if (name === "email" || name === 'orderId') {
      this.setState({ error: { } });
    }
  }
  dropdownHandle(item) {
    this.setState({ issueType: item })
  }

  contactUs = () => {
    const { name, email, issueType, orderId, referenceId, message } = this.state
    let contact_us = {
      "name": name,
      "email": email,
      "issue_type": issueType,
    }
    if (orderId !== "") contact_us["order_id"] = orderId
    if (referenceId !== "") contact_us["reference_id"] = referenceId
    if (message !== "") contact_us["message"] = message

    this.setState({loading:true})
    axios.post(`${REACT_APP_baseUrl}/contact_us`, {contact_us} ,{
    }).then(response => {
      if (response.data.code === 200) {
        this.setState({ loading: false, show: true })
      }
    }).catch(err => {
      this.setState({
        error: "The enter code is not valid!",
        loading: false
      })
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    let val = this.validate();
    if (val) {
      this.contactUs()
    }
  }

  handleOk = () => {
    this.setState({ show: false })
    this.props?.history?.goBack()
    this.props.ShowHeader(true)
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      behavior: "smooth"
    })
    this.props.ShowHeader(true)
    // Auto populate Promo code from cookies if there are any
    let promoCode =  cookie.load("RedemptionCode")
    if(promoCode){
      this.setState({referenceId:promoCode})
    }
    this.props.slug?.journey === "SSO" && this.getSlug()
  }

  getSlug = () =>{
    this.setState({loadPage:true})
    axios.get(`${REACT_APP_TCS_STOREFRONT}/storefront/v1/tenants?slug=${this.props.slug?.slug}`,{
      headers:{
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Expires: '0',
    }
    }).then(res=>{
      if(res.status === 200){
        this.props.fetSlugSucss(res.data)
        this.setState({loadPage:false})
      }else{
        this.setState({loadPage:false})
      }
    }).catch(err =>{
      this.setState({loadPage:false})
    })
  }

  render() {
    const Loader = () => {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    };

    const Model = () => {
      return (
        <ModalWrapper show={this.state.show}>
          <div
            className="p-4 bg-white d-flex flex-column justify-content-center align-items-center mx-3 text-center py-md-5 py-4"
            style={{ width: '30rem' }}>
            <img
              className="p-md-0 p-3"
              height="100"
              width="100"
              src={Success} alt="smiley" />
            <span className='redeem-text mt-3'>{t("Message Sent")}</span>
            <div className='text-muted align-self-center w-50 mt-3 mx-4 m-0 py-md-3 py-0'>
              {t("Thank you for contacting us. We will be in touch soon!")}
            </div>
            <button className="proceed-yes-btn align-self-center mt-3"
              onClick={this.handleOk}>
              <span className="fw-bold">{t("OK")}</span></button>
          </div>
        </ModalWrapper>
      )
    }

    const { t } = this.props
    const desktopView = (
      <div className="d-md-block d-none">
        <div className="d-flex flex-row w-100 justify-content-end overlap-container">
          <div className="d-flex flex-column justify-content-center  sqaure-contact overlap-card-left">
            <div className="d-flex flex-column justify-content-center">
            <div className="mx-2">
                <h5 className="heading-white-con">{t("Contact Us")}</h5>
            </div>
              <div>
              {
              this.props.contactUs?.email !== "" &&
              <>
                <span className="p-2 mx-1 rounded">
                  <img src={emailIcon} alt="email icon not found" />
                </span>
                <span >
                  <a href={`mailto:${this.props.contactUs?.email}?subject = Feedback&body = Message`} className="text-light">
                    {this.props.contactUs?.email}
                  </a>
                </span>
                </>
              }
              </div>
              <div>
              {
              this.props.contactUs?.phoneNumber !== "" &&
              <>
                <span className="p-2 mx-1 rounded">
                  <img src={phoneIcon} alt="email icon not found" />
                </span>
                <span><a href={`tel:=${this.props.contactUs?.phoneNumber}`} className="text-light">{this.props.contactUs?.phoneNumber}</a></span>
                </>
              }
              </div>
          
            </div>
          </div>
          <div className="d-flex flex-column  gap-4 border rounded p-3 bg-light-grey" style={{width:"60%"}}>
            <div  style={{alignSelf:'center',width:'50%'}}>
            <span className="d-flex justify-content-center">
              <h5 className="heading-dark">{t("Your Feedback")}</h5>
            </span>
            <div>
              <div className="text-muted">{t("Issue Type")}<span className="text-danger">*</span></div>
              <div className={`input-group ${!this.state.issueType && this.state.error.issueType ? "" :"mb-3"}`}>
                <select className="custom-select" id="inputGroupSelect01" onChange={(e) => this.dropdownHandle(e.target.value)}>
                <option value="" disabled selected hidden>{t("Please Select")}</option>
                  {ISSUE_TYPE.map((item, i) => (
                    <option key={i} value={item.value}>{t(`${item.name}`)}</option>
                  ))

                  }
                </select>
              </div>
              {!this.state.issueType && this.state.error.issueType ? (
                  <label className="text-error">{this.state.error.issueType}</label>
                ) : null}
            </div>
            <div>
              <div className="text-muted">{t("Name")}<span className="text-error">*</span></div>
              <div>
                <div className="d-flex ">
                  <input
                    name="name"
                    // type={type}
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder={t("Enter your name")}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  disabled={this.props?.userDetails?.firstName ? true : false}
                  />
                </div>
                {!this.state.name && this.state.error.name ? (
                  <label className="text-error">{this.state.error.name}</label>
                ) : null}
              </div>
            </div>
            <div className="mt-3">
              <div className="text-muted">{t("Email")}<span className="text-error">*</span></div>
              <div>
                <div className="d-flex ">
                  <input
                    name="email"
                    // type={type}
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder={t("Enter your email")}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  disabled={this.props?.userDetails?.email ? true : false}
                  />
                </div>
                {this.state.error.email ? (
                  <label className="text-error">{this.state.error.email}</label>
                ) : null}
              </div>
            </div>
            {/* <div className="mt-3">
            {this.props.slug?.slug === 'kantar-bav' ? 
              <div className="text-muted">{t("User ID - Enter ID located at the bottom left corner of your survey")} <span className="text-error">*</span></div> : 
              <div className="text-muted">{t("User ID - as per the invite letter received")} <span className="text-muted">{t("(Optional)")}</span></div>
            }
              <div>
                <div className="d-flex ">
                  <input
                    name="orderId"
                    // type={type}
                    value={this.state.orderId}
                    onChange={this.handleChange}
                    placeholder={`${t("User ID")}/${t("Refrence ID")}`}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  disabled={this.props?.userDetails?.customer_id ? true : false}
                  />
                </div>
                {(this.props.slug?.slug === 'kantar-bav' && this.state?.error?.orderId) ? (
                  <label className="text-error">{this.state?.error?.orderId}</label>
                ) : null}
              </div>
            </div> */}
            {/* <div className="mt-3">
              <div className="text-muted">{t("Promo Code")}<span className="text-muted">{t("(Optional)")}</span></div>
              <div>
                <div className="d-flex ">
                  <input
                    name="referenceId"
                    // type={type}
                    value={this.state.referenceId}
                    onChange={this.handleChange}
                    placeholder={t("Promo Code")}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  // disabled={disabled}
                  />
                </div>
              </div>
            </div> */}
            <div className="mt-3">
              <div className="text-muted">{t("Message")} (`{t("Required")}`)<span className="text-error">*</span></div>
              <div>
                <div className="d-flex ">
                  <textarea
                    placeholder={t("Please describe the issue in detail as it is important to know what happened")}
                    value={this.state.message}
                    onChange={this.handleChange}
                    name="message"
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
                {!this.state.message && this.state.error.message ? (
                  <label className="text-error">{this.state.error.message}</label>
                ) : null}
              </div>
            </div>
            <div className="text-center mt-3">
              <button className="btn w-50 primary-btn text-light rounded text-center" onClick={this.handleSubmit}>
                <span>{this.state.loading ? <Loader /> : t("Send")}</span>
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
    const mobileView = (
      <>
        <div className="d-block d-md-none" style={{ marginBottom: 120 }}>
        <div className='d-flex flex-row mb-2'>
            {this.props.location.pathname !== "/" && (
              <div
                className="fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props?.history?.goBack()
                  // this.props.ShowHeader(true)
                }}
              >
                <section
                  style={{
                    transform: `${this.props.lang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`,
                    }}
                  >
                    <img src={backButton} alt="" style={{marginTop:'5px'}}/> <span className='body-typo' style={{color:'#005E9A'}}>{t("Back")}</span>
                  </section>
                </section>
              </div>
            )}  
            </div>
          <div className="d-flex flex-column py-3 gap-2 sqaure-contact h-auto">
            <div >
              <span>
              <h5 className="heading-white-con">{t("Contact Us")}</h5>
              </span>
            </div>
            {
              this.props.contactUs?.email !== "" &&
              <div>
                <span className="p-2 mx-1 rounded">
                  <img src={emailIcon} alt="email icon not found" />
                </span>
                <span>
                  <a href={`mailto:${this.props.contactUs?.email}?subject = Feedback&body = Message`} className="text-light">
                    {this.props.contactUs?.email}
                  </a>
                </span>
              </div>
            }
            {
              this.props.contactUs?.phoneNumber !== "" &&
                <div>
                  <span className="p-2 mx-1 rounded">
                    <img src={phoneIcon} alt="email icon not found" />
                  </span>
                  <span>
                    <span>
                      <a href={`tel:=${this.props.contactUs?.phoneNumber}`} className="text-light">
                        ${this.props.contactUs?.phoneNumber}
                      </a>
                    </span>
                  </span>
                </div>
            }
          </div>
          <div className="d-flex flex-column w-100 gap-4 border p-3 mt-3 bg-light-grey border rounded">
            <div className="pt-2">
            <span className="d-flex justify-content-center">
              <h5 className="heading-dark">{t("Your Feedback")}</h5>
            </span>
              <div className="text-muted">{t("Issue Type")}<span className="text-error">*</span></div>
              <div>
                <div className="d-flex my-2">


                  <div className="input-group ">

                    <select className="custom-select" id="inputGroupSelect01" onChange={(e) => this.dropdownHandle(e.target.value)}>
                    <option value="" disabled selected hidden>{t("Please Select")}</option>
                      {ISSUE_TYPE.map((item, i) => (
                        <option key={i} value={item.value}>{t(`${item.name}`)}</option>
                      ))
                      }
                    </select>
                  </div>

                </div>
                {!this.state.issueType && this.state.error.issueType ? (
                  <label className="text-error">{this.state.error.issueType}</label>
                ) : null}
              </div>
            </div>
            <div>
              <div className="text-muted">{t("Name")}<span className="text-error">*</span></div>
              <div>
                <div className="d-flex my-2">
                  <input
                    name="name"
                    // type={type}
                    value={this.state.name}
                    onChange={this.handleChange}
                    placeholder={t("Enter your name")}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  disabled={this.props?.userDetails?.firstName ? true : false}
                  />
                </div>
                {!this.state.name && this.state.error.name ? (
                  <label className="text-error">{this.state.error.name}</label>
                ) : null}
              </div>
            </div>
            <div>
              <div className="text-muted">{t("Email")}<span className="text-error">*</span></div>
              <div>
                <div className="d-flex my-2">
                  <input
                    name="email"
                    // type={type}
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder={t("Enter your email")}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  disabled={this.props?.userDetails?.email ? true : false}
                  />
                </div>
                {this.state.error.email ? (
                  <label className="text-error">{this.state.error.email}</label>
                ) : null}
              </div>
            </div>
            {/* <div>
            {this.props.slug?.slug === 'kantar-bav' ? 
              <div className="text-muted">{t("User ID - Enter ID located at the bottom left corner of your survey")} <span className="text-error">*</span></div> : 
              <div className="text-muted">{t("User ID - as per the invite letter received")} <span className="text-muted">{t("(Optional)")}</span></div>
            }
              <div>
                <div className="d-flex my-2">
                  <input
                    name="orderId" 
                    // type={type}
                    value={this.state.orderId}
                    onChange={this.handleChange}
                    placeholder={`${t("User ID")}/${t("Refrence ID")}`}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  disabled={this.props?.userDetails?.customer_id ? true : false}
                  />
                </div>
                {(this.props.slug?.slug === 'kantar-bav' && this.state?.error?.orderId) ? (
                  <label className="text-error">{this.state?.error?.orderId}</label>
                ) : null}
              </div>
            </div> */}
            {/* <div>
              <div className="text-muted">{t("Promo Code")}<span className="text-muted">{t("(Optional)")}</span></div>
              <div>
                <div className="d-flex my-2">
                  <input
                    name="referenceId"
                    // type={type}
                    value={this.state.referenceId}
                    onChange={this.handleChange}
                    placeholder={t("Promo Code")}
                    className="border border-2 outline-none rounded-card-7 px-2 py-2 w-100 "
                  // title={title}
                  // maxLength={maxLength}
                  // disabled={disabled}
                  />
                </div>
              </div>
            </div> */}
            <div>
              <div className="text-muted">{t("Message")} (`{t("Required")}`)<span className="text-error">*</span></div>
              <div>
                <div className="d-flex my-2">
                  <textarea
                    placeholder={t("Please describe the issue in detail as it is important to know what happened")}
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  >

                  </textarea>
                </div>
                {!this.state.message && this.state.error.message ? (
                  <label className="text-error">{this.state.error.message}</label>
                ) : null}
              </div>
            </div>
            <div className="text-center">
              <button className="btn w-100 primary-btn text-light border rounded text-center" onClick={this.handleSubmit}>
                <span>{this.state.loading ? <Loader /> : t("Send")}</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <>
      {
        this.state.loadPage ? <Loader/> :  
        <section className="px-md-5 px-3 py-md-4 py-1">
          <Model />
          {desktopView}
          {mobileView}
        </section>
      }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.brand.loading,
    slug : state.tenetReducer?.slug,
    contactUs : state.tenetReducer?.slug?.contactUs,
    countryCode : state.countries?.countryCode,
    userDetails : state?.countries?.countries
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ShowHeader: (data) => dispatch(ShowHeader(data)),
    fetSlugSucss: (data) => dispatch(fetSlugSucss(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactUs));
