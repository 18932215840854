import React, { Component } from 'react'
import editPng from '../../assests/img/Edit.png'
import deletePng from '../../assests/img/Delete.png'
import AddNewAddress from './Modals/AddNewAddress'
import backButton from "../../assests/img/carret-left-blue.svg";
import { withTranslation } from 'react-i18next';
import { addAddress, AddAdrress, defaultAddress, deleteAddress, DeleteAdrress, EditAdrress, selectedAddress, updateAddress } from 'Redux/DeliveryAddress/AddressAction';
import { connect } from 'react-redux';
import Loader from './Loader';
import LoginModal from './Modals/LoginModal';


class SelectDeliveryAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAddressModal: false,
            editOrAdd: '',
            editItem: {}
        }
    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    handleRemove(id) {
        const payload = {
            "id":id
        }
        let data = this.props.AddressDetails.filter(elem => elem.id !== id)
        this.props.deleteAddress(payload,data)
        // this.props.DeleteAdrress(data)
    }
    handleEdit(item) {
        const filteredItem = this.props.AddressDetails.find(elem => elem?.id === item?.id)
        this.setState({showAddressModal: true, editOrAdd:'edit', editItem: filteredItem})
    }
    hideAddressModal() {
        this.setState({
            showAddressModal: this.props.loading
        })
    }

    handleAddress = () =>{
        if(this.props.selectedAddress !== null){
            this.props.shipAddress(true)
        }
    }
    render() {
        const {t}=this.props
        const desktopView =
            <div className='d-md-block d-none w-100'>
                <div className='d-flex w-100'>
                    <div className={`${window.location?.pathname.includes("address/selectedAddress") ? "w-100" : "w-75"}`}>
                        <div className='d-flex flex-column'>
                            <span className='drc-title fs-1 fw-bold'>{t("Select Delivery Address")}</span>
                            <span className=''>{t("Select or Add an address")}</span>
                        </div>
                        <div className='delivery-address my-3'>
                            {
                                this.props.AddressDetails.map((item, index) => {
                                    return (
                                        <div className='my-3' key={index}>
                                            <div className='d-flex justify-content-between border-rad-top p-4'>
                                                {
                                                        index === 0 ?
                                                        <span className='fs-4 fw-bold drc-title'>{t(`Current Address`)}</span>
                                                        : <span className='fs-4 fw-bold drc-title'>{`${t("Address")} ${index + 1}`}</span>
                                                }
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='fw-normal mr-3'>{t("Use this Mailing Address")}</span>
                                                    <input type="radio" name='address' className='p-3 cursor-pointer' checked={this.props.SelectedAddress?.id === item?.id}  onChange={() => this.props.selectedAddress(item)} style={{ width: "20px", height: "30px" }} />
                                                </div>
                                            </div>
                                            <div className='p-4 border-rad-bottom'>
                                                <table className='w-100'>
                                                    <tr className='d-flex mb-3'>
                                                        <td className='w-25'>
                                                            <div className='d-flex flex-column align-items-between w-100'>
                                                                <span className='text-muted'>{t("Full Name")}:</span>
                                                                <span className='fw-bold'>{item?.fullName}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className='d-flex mb-3'>
                                                    <td className='mr-5'>
                                                            <div className='d-flex flex-column align-items-between w-100'>
                                                                <span className='text-muted'>{t("Street Address")}:</span>
                                                                <span className='fw-bold'>{item?.line1}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex flex-column align-items-between w-100'>
                                                                <span className='text-muted'>{t("Apartment No")}:</span>
                                                                <span className='fw-bold'>{item?.line2}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className='d-flex mb-3'>
                                                       
                                                        <td className='mr-5'>
                                                            <div className='d-flex flex-column align-items-between w-100'>
                                                                <span className='text-muted'>{t("City")}:</span>
                                                                <span className='fw-bold'>{item?.city}</span>
                                                            </div>
                                                        </td>
                                                        <td className='mr-5'>
                                                            <div className='d-flex flex-column align-items-between w-100'>
                                                                <span className='text-muted'>{t("State")}:</span>
                                                                <span className='fw-bold'>{item?.state}</span>
                                                            </div>
                                                        </td>
                                                        <td className='mr-5'>
                                                            <div className='d-flex flex-column align-items-between w-100'>
                                                                <span className='text-muted'>{t("Zip Code")}:</span>
                                                                <span className='fw-bold'>{item?.zip}</span>
                                                            </div>
                                                        </td>
                                                        {
                                                            item?.phone &&
                                                            <td className='w-25'>
                                                                <div className='d-flex flex-column align-items-between w-100'>
                                                                    <span className='text-muted'>{t("Phone")}:</span>
                                                                    <span className='fw-bold'>{item?.phone}</span>
                                                                </div>
                                                            </td>
                                                        }
                                                    </tr>
                                                </table>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <div className='d- cursor-pointer ml-3' onClick={() => this.handleEdit(item)}>
                                                        <img src={editPng} alt="edit" />
                                                        <span className='ml-1'>{t("Edit Address")}</span>
                                                    </div>
                                                    {
                                                        index !== 0 &&
                                                        <div className='d- cursor-pointer ml-3' onClick={() => this.handleRemove(item?.id)}>
                                                            <img src={deletePng} alt="remove" />
                                                            <span className='ml-1'>{t("Remove")}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.props?.AddressDetails?.length < 3 &&
                                <button id="new-addresses-btn" className='fw-normal' onClick={() => this.setState({ showAddressModal: true, editOrAdd:'add'})}>{t("Add new address")}</button>
                            }
                            {
                                window.location?.pathname.includes("address/selectedAddress") && this.props?.AddressDetails?.length > 0 &&
                                <button id="new-addresses-btn" className='fw-normal ml-3' onClick={() => this.props?.history?.goBack()}>{t("Proceed")}</button>
                            }
                        </div>
                        {
                            this.state.showAddressModal && <AddNewAddress show={this.state.showAddressModal} hideAddressModal={() => this.hideAddressModal()} addNewAddress={this.props.AddAdrress} addAddress={this.props.addAddress} updateAddress={this.props.EditAdrress} UpdateAddress={this.props.updateAddress} defaultAddress={this.props.defaultAddress} editOrAdd={this.state.editOrAdd} editItem={this.state.editItem} SelectedAddress={this.props.SelectedAddress} selectedAddress={this.props.selectedAddress}/>
                        }
                    </div>
                    {
                        !window.location?.pathname.includes("address/selectedAddress") && this.props?.AddressDetails?.length > 0 &&
                        <div className='ml-3 border-rad w-25 delivery-order-summary h-50'>
                            <div className='p-3'>
                                <span className='drc-title fw-bold fs-4'>{t("Order Summary")}:</span>
                                <div className='d-flex justify-content-start mt-2'>
                                    <img
                                        style={{ width: "30%" }}
                                        className="p-0"
                                        src={this.props.card?.images?.color?.small_rectangle || this.props.card?.images?.color?.medium_rectangle}
                                        alt={this.props.card?.name}
                                    />
                                    <span className='ml-3 fw-bold'>{this.props.card?.name}</span>
                                </div>
                                {/* <div className='my-2 d-flex justify-content-between align-items-center'>
                                    <span>Subtotal:</span>
                                    <span className='drc-title fw-bold'>{this.props.currencySymbol} {this.props.orders?.denomination * this.props.orders?.quantity}</span>
                                </div> */}
                            </div>
                            <div className='p-3'>
                                <div className='border-rad d-flex justify-content-between bg-white px-3 rounded py-1 mb-2 align-items-center'>
                                    <span>{t("Total")}:</span>
                                    <span className='drc-title fs-3 fw-bold'>{this.props.currencySymbol}{this.props.orders?.denomination * this.props.orders?.quantity}</span>
                                </div>
                                <button className='w-100 new-address-btn' onClick={() => this.handleAddress()}>{t("Next")}</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        const mobileView =
            <div className='d-block d-md-none w-100'>
                <div>
                <div className='d-flex flex-row'>
                   <div
                className="fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props?.history?.goBack()
                //   this.props.ShowHeader(true)
                }}
              >
                <section
                  style={{
                    transform: `${this.props.lang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`,
                    }}
                  >
                    <img src={backButton} alt="" style={{marginTop:'5px'}}/> <span className='body-typo' style={{color:'#005E9A'}}>{t("Back")}</span>
                  </section>
                </section>
                   </div>
                   </div>
                    <div className='w-100'>
                        <div className='d-flex flex-column'>
                            <span className='drc-title fs-4 fw-bold'>{t("Select Delivery Address")}</span>
                            <span className=''>{t("Select or Add an address")}</span>
                        </div>
                        <div className='delivery-address d-flex flex-column my-3'>
                            {
                                this.props.AddressDetails.map((item, index) => {
                                    return (
                                        <div className='my-3' key={index}>
                                            <div className='d-flex align-items-center justify-content-between border-rad-top p-4'>
                                                <div className='d-flex flex-column'>
                                                    {
                                                        index === 0 ?
                                                        <span className='fs-4 fw-bold drc-title'>{t(`Current Address`)}</span>
                                                        :   <span className='fs-4 fw-bold drc-title'>{`${t("Address")} ${index + 1}`}</span>
                                                    }
                                                    <span className='fw-normal mr-3'>{t("Use this Mailing Address")}</span>
                                                </div>
                                                <input type="radio" name='address-mb' className='p-3 cursor-pointer' checked={this.props.SelectedAddress?.id === item?.id} onChange={() => this.props.selectedAddress(item)} style={{ width: "20px", height: "30px" }} />
                                            </div>
                                            <div className='p-4 border-rad-bottom'>
                                                <div className='d-flex flex-column align-items-between mb-2'>
                                                    <span className='text-muted'>{t("Full Name")}:</span>
                                                    <span className='fw-bold'>{item?.fullName}</span>
                                                </div>
                                                <div className='d-flex flex-column align-items-between mb-2'>
                                                    <span className='text-muted'>{t("Street Address")}:</span>
                                                    <span className='fw-bold'>{item?.line1}</span>
                                                </div>
                                                <div className='d-flex flex-column align-items-between mb-2'>
                                                    <span className='text-muted'>{t("Apartment No")}:</span>
                                                    <span className='fw-bold'>{item?.line2}</span>
                                                </div>
                                                <div className='d-flex flex-column align-items-between mb-2'>
                                                    <span className='text-muted'>{t("City")}:</span>
                                                    <span className='fw-bold'>{item?.city}</span>
                                                </div>
                                                <div className='d-flex flex-column align-items-between mb-2'>
                                                    <span className='text-muted'>{t("State")}:</span>
                                                    <span className='fw-bold'>{item?.state}</span>
                                                </div>
                                                <div className='d-flex flex-column align-items-between mb-2'>
                                                    <span className='text-muted'>{t("Zip Code")}:</span>
                                                    <span className='fw-bold'>{item?.zip}</span>
                                                </div>
                                                {
                                                    item?.phone &&
                                                    <div className='d-flex flex-column align-items-between mb-2'>
                                                        <span className='text-muted'>{t("Phone")}:</span>
                                                        <span className='fw-bold'>{item?.phone}</span>
                                                    </div>
                                                }
                                                <div className={`d-flex justify-content-between mt-4`}>
                                                    <div className='cursor-pointer' onClick={() => this.handleEdit(item)}>
                                                        <img src={editPng} alt="edit" />
                                                        <span className='ml-1'>{t("Edit Address")}</span>
                                                    </div>
                                                    {index !== 0 && 
                                                        <div className='cursor-pointer' onClick={() => this.handleRemove(item?.id)}>
                                                            <img src={deletePng} alt="remove" />
                                                            <span className='ml-1'>{t("Remove")}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.props?.AddressDetails?.length < 3 &&
                                <button className='new-address-btn-mb fw-normal mx-2' onClick={() => this.setState({ showAddressModal: true, editOrAdd:'add' })}>{t("Add new address")}</button>
                            }
                            {
                                window.location?.pathname.includes("address/selectedAddress") && this.props?.AddressDetails?.length > 0 &&
                                <button className='new-address-btn-mb fw-normal mx-2' style={{marginBottom:"50%"}} onClick={() => this.props?.history?.goBack()}>{t("Proceed")}</button>
                            }
                        </div>
                        {
                            this.state.showAddressModal && <AddNewAddress show={this.state.showAddressModal} hideAddressModal={() => this.hideAddressModal()} addNewAddress={this.props.AddAdrress} addAddress={this.props.addAddress} updateAddress={this.props.EditAdrress} UpdateAddress={this.props.updateAddress} defaultAddress={this.props.defaultAddress} editOrAdd={this.state.editOrAdd} editItem={this.state.editItem} SelectedAddress={this.props.SelectedAddress} selectedAddress={this.props.selectedAddress}/>
                        }
                        {
                            !window?.location?.pathname.includes("address/selectedAddress") && this.props?.AddressDetails?.length > 0 &&
                                <div className='w-100 border-rad h-50 mb-5'>
                                    <div className='p-3'>
                                        <div className='d-flex justify-content-start mt-2'>
                                            <img
                                                style={{ width: "30%" }}
                                                className="p-0"
                                                src={this.props.card?.images?.color?.small_rectangle || this.props.card?.images?.color?.medium_rectangle}
                                                alt={this.props.card?.name}
                                            />
                                            <span className='ml-3 fw-bold'>{this.props.card?.name}</span>
                                        </div>
                                    </div>
                                    <div className='p-3'>
                                        <div className='border-rad my-2 d-flex justify-content-between bg-white px-3 py-1 align-items-center'>
                                            <span>Total:</span>
                                            <span className='drc-title fs-3 fw-bold'>{this.props.currencySymbol}{this.props.orders?.denomination * this.props.orders?.quantity}</span>
                                        </div>
                                        <button className='w-100 new-address-btn' onClick={() => this.handleAddress()}>{t("Next")}</button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        return (
            <>
                {
                    !this.props.valid ? <LoginModal show={true} close={()=>this.props?.history?.goBack()}/> : this.props.loading ? <Loader/> :
                    <div className={`mb-5 w-100 ${window.location?.pathname.includes("address/selectedAddress") && "container"}`}>
                        {desktopView}
                        {mobileView}
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        AddressDetails : state.addressReducer?.AddressDetails,
        SelectedAddress : state?.addressReducer?.selectedAddress ,
        loading : state?.addressReducer?.loading,
        valid : state?.auth?.valid
    }
}


const mapDispatchToProps = dispatch => {
    return {
        AddAdrress: (payload) => dispatch(AddAdrress(payload)),
        EditAdrress: (payload) => dispatch(EditAdrress(payload)),
        DeleteAdrress: (payload) => dispatch(DeleteAdrress(payload)),
        selectedAddress: (payload) => dispatch(selectedAddress(payload)),
        addAddress: (payload,isDefault) => dispatch(addAddress(payload,isDefault)),
        deleteAddress: (payload,data) => dispatch(deleteAddress(payload,data)),
        updateAddress: (payload) => dispatch(updateAddress(payload)),
        defaultAddress: (payload) => dispatch(defaultAddress(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(((withTranslation()(SelectDeliveryAddress))));
