// import axios from 'axios';
import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


class AboutAndTC extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selected:'about'
         }
    }
    setSelected=(val)=>{
        this.setState({
            selected:val
        })
    }

// Detect Hyperlink form Trems
    detectURLs(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.split(urlRegex)
          .map(part => {
            if (part?.match(urlRegex)) {
              return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
            }
          else{
                return part;
          }
          });
      } 
    render() { 
        const softwareGamesDetails = this.props.softwareGamesDetails
        // console.log('terms', this.props.termsAndConditions)
        const {t} = this.props
        return ( 
            <div className='pb-5'>
                <div className="d-grid grid-cols-2 text-center">
                    <div className={`py-2 ${this.state.selected==='about' ? 'btn-sky text-light' :'border-color text-muted'}`}
                        onClick={()=>{this.setSelected('about')}}>
                        {t("About")}
                    </div>
                    <div className={`py-2 ${this.state.selected==='t&c' ? 'btn-sky text-light' :'border-color text-muted'}`}
                        onClick={()=>{this.setSelected('t&c')}}>
                        {t("T&Cs")}
                    </div>
                </div>
                {
                    this.state.selected==='about' &&
                    <div className='py-4'>
                        <p>
                            {this.props.lang === 'en' ? softwareGamesDetails?.description?.split("\n")?.map((e,index)=> <div>{this.detectURLs(e)}</div> ): softwareGamesDetails?.description_arabic?.split("\n")?.map((e,index)=> <div>{this.detectURLs(e)}</div> )}
                        </p>
                        
                        <Accordion defaultActiveKey="0" >
                        
                           
                           
                            
                           
                        </Accordion>
                    </div>
                }
                {
                    this.state.selected==='t&c' &&
                    <div className='py-4'>
                        {/* <div className='fw-bold color-blue fs-5'>{t("Included in the price")}</div> */}
                        <div className='py-4'>
                        {
                            this.props.lang==='en'?
                            this.props.termsAndConditions &&  this.props.termsAndConditions[0]?.terms_text?.split("\n")
                            .map(terms=>
                                <div>
                                    {this.detectURLs(terms)}
                                </div>
                                )
                            :
                            this.props.termsAndConditions && this.props.termsAndConditions[1]?.terms_text?.split('\n')
                            .map(terms=>
                                <div>
                                    {this.detectURLs(terms)}
                                </div>
                                )
                        }
                        </div>
                        {/* <div className='text-danger fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</div> */}
                    </div>
                }
            </div>
         );
    }
}
const mapStateToProps = state => {
    return {
        softwareGamesDetails:state.softwareGames.softwareGameseDetails,
        termsAndConditions:state.softwareGames.softwareGamesTerms,
    }
}



export default connect(mapStateToProps)(withTranslation()(AboutAndTC));
