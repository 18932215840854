/* eslint-disable no-unused-vars */
import axios from "axios"
import { FETCH_AUTH_TOKEN_FAILURE, FETCH_TOKEN_REQUEST, FETCH_AUTH_TOKEN_INVALID, FETCH_AUTH_TOKEN_REQUEST, FETCH_AUTH_TOKEN_SUCCESS, VALID_TOKEN, DELETE_TOKEN, REMOVE_ERROR_MESSAGE, SIGN_UP_MESSAGE, SIGN_UP_MESSAGE_CLEAR, SHOW_HEADER } from "./authTypes"
import { load, remove } from 'react-cookies';
import store from "../store"
import { REACT_APP_baseUrl } from 'utils/app-constants';
import { fetchPointBalance } from "Redux/point/pointActions";
import { RESET_CLEAR } from "Redux/PasswordReset/ResetTypes";
import { FETCH_POINT_BALANCE_CLEAR } from "Redux/point/pointTypes";
import { fetchCountriesSuccess, setCountryCode, setlang } from "Redux/country/countriesAction";

export const fetchAuthTokenRequest = () => {
    // if(localStorage.getItem('userToken')){
    //     localStorage.removeItem('userToken')
    // }
    return {
        type: FETCH_AUTH_TOKEN_REQUEST,
    }
}
export const fetchTokenRequest = () => {
    // if(localStorage.getItem('userToken')){
    //     localStorage.removeItem('userToken')
    // }
    return {
        type: FETCH_TOKEN_REQUEST,
    }
}

export const fetchAuthTokenSuccess = data => {
    return {
        type: FETCH_AUTH_TOKEN_SUCCESS,
        payload: data
    }
}
export const fetchAuthTokenInvalid = data => {
    return {
        type: FETCH_AUTH_TOKEN_INVALID,
        payload: data
    }
}

export const fetchAuthTokenFailure = error => {
    return {
        type: FETCH_AUTH_TOKEN_FAILURE,
        payload: error
    }
}

export const removeErrorMessage = payload => {
    return {
        type: REMOVE_ERROR_MESSAGE,
        payload: ""
    }
}

export const SignUpMessage = (data) => {
    return {
        type: SIGN_UP_MESSAGE,
        payload: data
    }
}

export const ShowHeader = (data) => {
    return {
        type: SHOW_HEADER,
        payload : data
    }
}

export const SignUpMessageClear = () => {
    return {
        type: SIGN_UP_MESSAGE_CLEAR
    }
}
export const authValid = (token) => {
    return {
        type: VALID_TOKEN,
        payload: {
            authtoken: token
        }
    }
}

const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
}

export const fetchAuthToken = () => {
    return (dispatch)=>{
        dispatch(fetchAuthTokenRequest());
        const queryParams = window.location?.search;
        let token = queryParams?.split('token=')[1];
        // let token =  load('user_token');


        let localToken = localStorage.getItem("userToken")

        if(localStorage.getItem("userToken") && !token){
            dispatch(fetchAuthTokenSuccess(localToken))
        }
        else if (token){
            localStorage.setItem("userToken", token);
            dispatch(fetchAuthTokenSuccess(token));
            // remove('user_token');
        }
        else{
            dispatch(fetchAuthTokenFailure(true))
        }
    }
}

// export const fetchAuthToken = (signin, cb, signUpFlag) => {
//     return (dispatch) => {
//         dispatch({ type: FETCH_AUTH_TOKEN_REQUEST })

//         let APIURL = signUpFlag ? 'accounts/registrations/signup' : 'accounts/sessions/signin';
//         let payload = signUpFlag ? signin : { signin }
//         axios.post(`${REACT_APP_baseUrl}/${APIURL}`, payload)

//             .then(response => {
//                 // if (response.data?.data?.user){
//                 if (response.data?.data?.user.access_token) {
//                     dispatch(fetchAuthTokenSuccess(response.data?.data?.user.access_token))
//                     localStorage.setItem("userToken", response.data?.data?.user.access_token);
//                     cookie.save("firstname", response.data?.data?.user.first_name)
//                     dispatch(fetchPointBalance(response.data?.data?.user.access_token));
//                     if (typeof cb === "function") {
//                         cb()
//                     }
//                     if (signUpFlag) {
//                         dispatch(SignUpMessage(payload.signup.email))
//                     }
//                 }
//                 else {
//                     let errorMsg = response.data?.message || "Session Timeout"
//                     dispatch(fetchAuthTokenInvalid(errorMsg))
//                     setTimeout(() => dispatch(removeErrorMessage('')), 2000)
//                 }

//             })
//             .catch((error) => {
//                 dispatch(fetchAuthTokenFailure(true))
//             })

//     }
// }


export const checkTokenValidity = () => {
    let token = localStorage.getItem("userToken");
    let payload = {}
    return async (dispatch) => {
        dispatch({ type: FETCH_TOKEN_REQUEST })
        await axios.post(`${REACT_APP_baseUrl}/accounts/sessions/valid`,
            payload,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })
            .then(response => {

                if (response.data.data.valid) {

                    return dispatch({
                        type: VALID_TOKEN,
                        payload: {
                            authtoken: token
                        }

                    })
                }
                else {
                    localStorage.removeItem("userToken")
                    dispatch(fetchAuthTokenFailure("Session Timeout"))
                    dispatch({
                        type: FETCH_POINT_BALANCE_CLEAR
                    })
                }
            })
            .catch((err) => {
                return dispatch(fetchAuthTokenFailure("Session Timeout"))
            })

    }
}

export const logout = (cb) => {

    let token = localStorage.getItem("userToken");
    // axios.post(`${REACT_APP_baseUrl}/accounts/sessions/valid`,
    return (dispatch) => {
        axios.delete(`${REACT_APP_baseUrl}/accounts/sessions/signout`,
            {
                headers: {
                    "X-Access-Token": token
                }
            })
            .then(response => {
                if (response.data.code === 200) {
                    localStorage.removeItem("userToken");
                    // cookie.remove("firstname")
                    dispatch({
                        type: DELETE_TOKEN,
                    })
                    dispatch({
                        type: RESET_CLEAR
                    })
                    dispatch({
                        type: FETCH_POINT_BALANCE_CLEAR
                    })
                    if (typeof cb === 'function') {
                        cb()
                    }
                }
            })
    }
}

export const fetchCustomerDetails = () =>{
    let token = localStorage.getItem("userToken");
    let URL = "user/get_customer_details"
    return(dispatch) =>{
        axios.get(
            `${REACT_APP_baseUrl}/${URL}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ).then((response)=>{
            if(response.data?.code === 200){
                dispatch(fetchCountriesSuccess(response.data?.data))
                dispatch(setCountryCode(response.data?.data?.countries[0]?.code))
                dispatch(setlang(response.data?.data?.countries[0]?.languages[0]))
            }
        }).catch((err)=>{

        })
    }
}