import React, { Component } from 'react'
import ModalWrapper from './ModalWrapper'
import { withTranslation } from 'react-i18next';
import StateCities from "../../../utils/states-cities/StateCities.json"

class AddDeliveryAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newAddress: { fullName: '', line1: '', line2 : '',  city: '', state: '', zip: ''},
            error: {},
            checked:this.props.editItem?.isDefault || false
        }
    }
    componentDidMount(){
        if (this.props.editOrAdd === 'edit') { 
            this.setState({
                newAddress: this.props.editItem,
            })
        }
    }
    validate(){
        if (!this.state.newAddress?.fullName) {
            this.setState({ error: { fullName: 'Name is required'} })
            return false
        }
        else if(!this.state.newAddress?.line1){
            this.setState({ error: { line1: 'Address is required'} })   
            return false
        }
        else if(!this.state.newAddress?.city){
            this.setState({ error: { city: 'City is required'} })          
            return false
        }
        else if(!this.state.newAddress?.state){
            this.setState({ error: { state: 'State is required'} })          
            return false
        }
        else if(!this.state.newAddress?.zip){
            this.setState({ error: { zip: 'Zip Code is required'} })          
            return false
        }
        else if(this.state.newAddress?.zip?.length>5 || this.state.newAddress?.zip?.length<5){
            this.setState({ error: { zip: 'Please enter a valid Zip Code'} })          
            return false
        }
        // else if(!this.state.newAddress?.phone){
        //     this.setState({ error: { phone: 'Phone is required'} })          
        //     return false
        // }
        return true
    }
    handleChange(e) {
        const name = e.target.name
        this.setState((prevState) => ({
            newAddress: {
                ...prevState.newAddress,
                [name]: e.target.value,
            },
            error: {
                ...prevState.error,
                [name]: ''
            }
        }))
    }
    handleSave() {
        let val = this.validate()
        if (val) {   
            this.props.addAddress(this.state.newAddress,false)
            // this.props.selectedAddress(this.state.newAddress)
            // this.props.addNewAddress(this.state.newAddress)
            this.props.hideAddressModal()
        }
    }
    handleUpdate() {
        // const payload = {
        //     "id": this.state.newAddress?.id
        // }
        let val = this.validate()
        if (val) {  
            // if(this.state.newAddress?.id === this.props.SelectedAddress?.id){
            //     this.props.selectedAddress(this.state.newAddress)
            // }
            // if(this.state.checked){
            //     this.props.selectedAddress(this.state.newAddress)
            //     this.props.defaultAddress(payload)
            // }
            // this.props.updateAddress(this.state.newAddress)
            this.props.UpdateAddress(this.state.newAddress)
            this.props.hideAddressModal()
        }
    }
    render() {
        const {t}=this.props
        const desktopView =
            <section className='p-5 d-md-block d-none add-new-address-wrapper overflow-auto'>
                <span className='drc-title fw-bolder fs-4'>{this.props.editOrAdd === 'add' ? t("Add New Delivery Address") 
                :t("Edit Delivery Address")}</span>
                <div className='d-flex w-100 mt-3'>
                    <form className='w-100'>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Full Name")} *</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.fullName} name='fullName' type='text' placeholder={t('Enter Your Full Name')} />
                            {this.state.error?.fullName && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.fullName)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Address")} *</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.line1} 
                            name='line1' type='text' placeholder={t('Enter Your Street Address')} />
                            {this.state.error?.line1 && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.line1)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Apartment No")}</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.line2} 
                            name='line2' type='text' placeholder={t('Enter Your Apartment No (Optional)')} />
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("City")} *</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.city} name='city' type='text' placeholder={t('Enter Your City')} />
                            {this.state.error?.city && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.city)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("State")} *</span>
                            <select name="state"  onChange={e => this.handleChange(e)} className='add-address-inp'>
                            <option value="none" className='placeholder' selected disabled hidden>{t('Select Your State')}</option>
                                {
                                    StateCities?.map((val)=>{
                                        return(
                                            <>
                                                <option value={val?.state} selected={this.state.newAddress?.state?.toLocaleLowerCase() === val?.state?.toLocaleLowerCase()}>{val?.state}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                            {this.state.error?.state && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.state)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Zip Code")} *</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.zip} name='zip' type='number' placeholder={t('Enter Your Zip Code')} />
                            {this.state.error?.zip && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.zip)}</span>}
                        </div>
                        {/* <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Phone (Optional)")}</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.phone} name='phone' type='number' placeholder={t('Enter Your Phone')} />
                            {this.state.error?.phone && <span className='text-danger mt-1 ml-1'>{this.state.error?.phone}</span>}
                        </div> */}
                    </form>
                    {/* <div className='w-50 bg-white h-50 p-4 mt-4'>
                        <span className=''>Select a label for effective delivery:</span>
                        <div className='d-flex align-items-center justify-content-start my-2'>
                            <button className='new-address-tab mr-2'>Home</button>
                            <button className='new-address-tab-2 mr-2'>Office</button>
                        </div>
                        <div className='d-flex flex-column'>
                            <span className='drc-title fw-bold mt-3 fs-5'>{("Default Address (Optional)")}</span>
                            <span className='my-1'>{t("Check the box to mark this as Your default shipping address")}</span>
                            <div className='d-flex mt-2'>
                                <input type="checkbox" className='mr-2' checked={this.state.checked} onClick={() => this.setState({checked:!this.state.checked})}/>
                                <span>{t("Default shipping Address")}</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='d-flex justify-content-between'>
                    <button className='new-address-btn-2 mr-4' onClick={this.props.hideAddressModal}>{t("Cancel")}</button>
                    {this.props.editOrAdd === 'add' ?
                        <button className='new-address-btn' onClick={() => this.handleSave()}>{t("Save")}</button>
                    :
                        <button className='new-address-btn' onClick={() => this.handleUpdate()}>{t("Update")}</button>
                    }
                </div>
            </section>
        const mobileView =
            <section className='p-4 d-block d-md-none add-new-address-wrapper-mb add-scrollbar'>
                <span className='drc-title fw-bolder fs-4'>{this.props.editOrAdd === 'add' ? t("Add New Delivery Address") 
                :t("Edit Delivery Address")}</span>
                <div className='d-flex flex-column w-100 mt-3'>
                    <form className='w-100'>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Full Name")} *</span>
                            <input className='add-address-inp w-100' onChange={e => this.handleChange(e)} value={this.state.newAddress?.fullName} name='fullName' type='text' placeholder={t('Enter Your Full Name')} />
                            {this.state.error?.fullName && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.fullName)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Address")} *</span>
                            <input className='add-address-inp w-100' onChange={e => this.handleChange(e)} value={this.state.newAddress?.line1} name='line1' type='text' placeholder={t('Enter Your Street Address')} />
                            {this.state.error?.line1 && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.line1)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Apartment No")}</span>
                            <input className='add-address-inp w-100' onChange={e => this.handleChange(e)} value={this.state.newAddress?.line2} 
                            name='line2' type='text' placeholder={t('Enter Your Apartment No (Optional)')} />
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("City")} *</span>
                            <input className='add-address-inp' onChange={e => this.handleChange(e)} value={this.state.newAddress?.city} name='city' type='text' placeholder={t('Enter Your City')} />
                            {this.state.error?.city && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.city)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("State")} *</span>
                            <select name="state"  onChange={e => this.handleChange(e)} className='add-address-inp w-100'>
                            <option value="none" selected disabled hidden>{t('Select Your State')}</option>
                                {
                                    StateCities?.map((val)=>{
                                        return(
                                            <>
                                                <option value={val?.state} selected={this.state.newAddress?.state?.toLocaleLowerCase() === val?.state?.toLocaleLowerCase()}>{val?.state}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                            {this.state.error?.state && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.state)}</span>}
                        </div>
                        <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Zip Code")} *</span>
                            <input className='add-address-inp w-100' onChange={e => this.handleChange(e)} value={this.state.newAddress?.zip} name='zip' type='number' placeholder={t('Enter Your Zip Code')} />
                            {this.state.error?.zip && <span className='text-danger mt-1 ml-1'>{t(this.state.error?.zip)}</span>}
                        </div>
                        {/* <div className='d-flex flex-column mb-3'>
                            <span className='text-muted mb-1'>{t("Phone (Optional)")}</span>
                            <input className='add-address-inp w-100' onChange={e => this.handleChange(e)} value={this.state.newAddress?.phone} name='phone' type='number' placeholder={t('Enter Your Phone')} />
                            {this.state.error?.phone && <span className='text-danger mt-1 ml-1'>{this.state.error?.phone}</span>}
                        </div> */}
                    </form>
                    {/* <div className='w-100 bg-white h-50 p-4 mt-4'>
                        <span className=''>Select a label for effective delivery:</span>
                        <div className='d-flex align-items-center justify-content-between my-2'>
                            <button className='new-address-tab w-50 mr-2'>Home</button>
                            <button className='new-address-tab-2 w-50 mr-2'>Office</button>
                        </div>
                        <div className='d-flex flex-column'>
                            <span className='drc-title fw-bold mt-3 fs-5'>{t("Default Address (Optional)")}</span>
                            <span className='my-1'>{t("Check the box to mark this as Your default shipping address")}</span>
                            <div className='d-flex mt-2'>
                                <input type="checkbox" className='mr-2' checked={this.state.checked} onClick={() => this.setState({checked:!this.state.checked})}/>
                                <span>{t("Default shipping Address")}</span>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className='w-100 d-flex flex-column justify-content-between'>
                {this.props.editOrAdd === 'add' ? 
                    <button className='new-address-btn w-100' onClick={() => this.handleSave()}>{t("Save")}</button>
                    :
                    <button className='new-address-btn w-100' onClick={() => this.handleUpdate()}>{t("Update")}</button>
                }
                    <button className='new-address-btn-2 w-100 mr-4' onClick={this.props.hideAddressModal}>{t("Cancel")}</button>
                </div>
            </section>
        return (
            <ModalWrapper show={this.props.show}>
                {desktopView}
                {mobileView}
            </ModalWrapper>
        )
    }
}
export default withTranslation()(AddDeliveryAddress)