import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

 class Policy extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            show,
            showModal,
            title,
            contentInArabic,
            contentInEnglish,
            contentEnglish,
            contentArabic,
            list,
            t,
            lang
          } = this.props;

          let details;
          let content;
          this.props.lang === 'en' ? details = contentEnglish : details = contentArabic
          this.props.lang === 'en' ? content = contentInEnglish : content = contentInArabic
        return (
            <>
            {/* mobile modal  */}
            <Modal
            show= {show}
            backdrop="static"
            keyboard={true}
            aria-labelledby="contained-modal-title-vcenter"
            className="d-block d-md-none policymodal"
            scrollable={true}
        >
        <Modal.Header >
            <p className='mb-auto color-green col-11  fs-md-bolder fs-medium'>{title}</p>
            <div className='justify-content-end fs-medium color-red' onClick={showModal}>x</div>
        </Modal.Header>
        <Modal.Title className="text-center">
        </Modal.Title>
        <Modal.Body className='m-0 p-0'>
            <p className="pl-1 pr-5 pb-5">
               {list && <ol>{details?.map((e,index) => <li className='list-style-auto'>{e}</li>)}</ol>}
               {!list && content}
            </p>
        </Modal.Body>
        </Modal>

        {/* desktop Modal  */}
        <Modal
         show= {show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="d-md-block d-none justfy-content-center mx-auto border"
    //   style={{marginLeft:"25%"}}
    >
      <Modal.Header >
      <p className='mb-auto color-green px-2  fs-md-bolder fs-medium fw-bold'>{t(title)}</p>
        <div className='justify-content-end fs-medium color-red cursor-pointer' onClick={showModal}>x</div>
      </Modal.Header>
      <Modal.Body className='m-0 p-0'>
            <p className="px-4 pb-5 overflow-auto custom-scrollbar" style={{maxHeight:'400px'}}>
               {list && <ol>{details?.map((e,index) => <li className='list-style-auto'>{e}</li>)}</ol>}
               {!list && content}
            </p>
        </Modal.Body>
    </Modal>

        </>
        )
    }
}
export default (withTranslation() (Policy));
