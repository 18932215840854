import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import { withTranslation } from 'react-i18next';

class LoginModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true
         }
    }
    setModalView=()=>{
        this.setState({
            show:false
        })
    }
    render() { 
        const {t}= this.props
        return ( 
            <ModalWrapper show={ this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'40rem'}}> 
                    <span className='text-dark'>
                        {t("To redeem your rewards, Please login to Digital Reflection Center")} <a href="https://digitalreflectioncenter.com/rewards.aspx" className="loginModal fw-bold">{t("Website")} </a>
                    </span>
                    <br/>
                    <button 
                        className="btn primary-btn rounded-0 text-light mt-3 mx-2"
                        onClick={()=>{this.props.close(false)}}>
                        <span className="fw-bold ">{t("Close")}</span>
                    </button>
                </div>
            </ModalWrapper>
         );
    }
}

export default (withTranslation()(LoginModal))   ;
