import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


class PassengerInputField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            compenent:null
         }
    }
    getInputCompenent(data){
        const {t}= this.props
        switch (data.type) {
            case "email":
                return(
                    <>
                        <input 
                            className="p-2 input-field rounded-0 border outline-color-green"
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            placeholder={t(data.ariaLabel)}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            type={data.type} 
                            value={data.value}
                            maxLength={data.maxLength}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                            autoComplete="off"
                            style={{position:'relative'}}
                            />
                            {
                                data.isClearable && <div onClick={()=>this.props.clearSpecificField(data.id)} class={`cursor-pointer ${this.props.lang === 'ar' ? 'clear-button-ar-div' : 'clear-button-div'}`}>

                                <span className='clear-button'></span>
                             </div>
                            }
                            </>
                        
                )
            case "text": 
                return(
                    <>
                        <input 
                            className="p-2 input-field rounded-0 border outline-color-green"
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            placeholder={t(data.ariaLabel)}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            type={data.type} 
                            value={data.value}
                            maxLength={data.maxLength}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                            autoComplete="off"
                            style={{position:'relative'}}
                            />
                            {
                                data.isClearable && <div onClick={()=>this.props.clearSpecificField(data.id)} class={`cursor-pointer ${this.props.lang === 'ar' ? 'clear-input-ar-div' : 'clear-input-div'}`}>

                                <span className='clear-button'></span>
                             </div>
                            }
                            </>
                        
                )
              
                    case "textarea": 
                        return(
                                <textarea 
                                    className="py-2 input-field rounded-0 border outline-color-green"
                                    style={{minHeight:"100px"}}
                                    id={data.id}
                                    title={data.title}
                                    aria-label={data.ariaLabel}
                                    tabIndex={data.tabIndex}
                                    readOnly={data.readOnly}
                                    type={data.type} 
                                    value={data.value}
                                    onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                                    autoComplete="off"
                                    />
                                
                        )
            case "tel":
                return(
                    <div className='d-flex'>
                    <select className="btn border bg-light rounded-0 caret px-1" style={{textAlignLast:"center", minWidth:"4rem"}}>
                    <option>966</option>
                    </select>
                    <input 
                    className=" input-field p-2 border rounded-0 outline-color-green w-100"
                    id={data.id}
                    title={data.title}
                    aria-label={data.ariaLabel}
                    tabIndex={data.tabIndex}
                    readOnly={data.readOnly}
                    type={data.type} 
                    placeholder={t(data.ariaLabel)}
                    value={data.value}
                    maxLength= {data.maxLength}
                    onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}/>
                    {
                        data.isClearable && <div onClick={()=>this.props.clearSpecificField(data.id)} class={`cursor-pointer ${this.props.lang === 'ar' ? 'clear-button-ar-div' : 'clear-button-div'}`}>
                        <span className='clear-button'></span>
                     </div>
                    }
                    </div>
                   
                )
            case "dropdown":
                return(
                  <div className="select">
                        
                        <select className={`${data.fullWidth ? 'p-2 w-100 ' : 'py-2 px-3'} ${this.props.lang==='en'? "travelArrows" : " travelArrows-arabic"}  text-muted rounded-0 border outline-color-green caret-down dropdown_`}
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            value={data.value}
                            disabled={data.disable}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                            >
                        {
                            data.options.map(option=>(
                                <option 
                                    className="d-block w-100"
                                    value={option}
                                    key={option}>
                                    {t(option)}
                                </option>
                            ))
                        }
                    </select>
                  </div>
                )
            case "date":
                return(
                    <input 
                        className="p-2 input-field rounded-0 border outline-color-green"
                        id={data.id}
                        data-date-inline-picker="true"
                        type="text" 
                        onFocus={(e) => e.target.type = 'date'}
                        onBlur={(e)=> e.target.type = 'text'}
                        title={data.title}
                        aria-label={data.ariaLabel}
                        placeholder={t(data.ariaLabel)}
                        tabIndex={data.tabIndex}
                        readOnly={data.readOnly}
                        value={data.value}
                        max={new Date(data.maxDate)?.toLocaleDateString('en-ca')}
                        onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                        />
                    
            ) 

            case 'radio':
               return (
                   <div className='d-flex align-content-center text-center'>
                       {
                           data.options.map(item => 
                            <div className="d-flex align-content-center justify-content-center text-center mx-2">
                                 <div className='mx-2'>
                                 <input checked={data.value == item} onChange={(e)=>{this.props.onChange(e.target.value,data.id)}} type='radio' id={item} value={item} name={data.ariaLabel}/>
                                 </div>
                                <p className='text-muted'>{item}</p>
                            </div>
                            )
                       }
                   </div>
               )
            default:
            break;
        }
    }
    render() { 
        const data=this.props.data
        const {t}=this.props
        return ( 
           
            <div className={`py-2 ${data.id === 'address' ? 'col-12' : 'col-12 col-md-6'}`}>
                <div  className='d-flex flex-column w-100'>
                <label 
                    className="text-muted fw-bold color-red"
                    htmlFor={data.id}>
                        {t(data.title)}
                        <span className='color-red'>{data.required === true && `*`}</span>
                </label>
                    {this.getInputCompenent(data)}
                    {
                        ['first_name','last_name'].map(e => e === data.id && <small className='text-muted py-2'>{t("Enter the passenger's name exactly as it appears on their passport or identity card")}</small>)
                    }
                    {(data.errorMessage && data.value==="")&&
                        <h6 className="text-danger w-100 py-2 text-center m-0" style={{backgroundColor:"rgb(255, 230, 230)"}}>
                            {t(data.errorMessage)}
                        </h6>
                    }
                </div>
               
            </div>
            
         );
    }
}
 
export default (withTranslation() (PassengerInputField));
