import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import {ReactComponent as Que} from '../../../assests/img/que.svg'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import store from 'Redux/store';
import { DELETE_TOKEN } from 'Redux/auth/authTypes';
import { CLEAR_ADDRESS } from 'Redux/DeliveryAddress/AddressTypes';

export class LogOutModel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:false,
            type:'all'
         }
    }
    setModalView=(param)=>{
        if(param==="yes"){
            store?.dispatch({type : DELETE_TOKEN})
            store?.dispatch({type : CLEAR_ADDRESS})
            this.props.closeHandle(false)   
            localStorage.removeItem("userToken")
            window?.location.replace("https://digitalreflectioncenter.com/rewards.aspx")
        }else{
            this.props.closeHandle(false)   
        }
    }
  render() {
    const {t}= this.props
    return (
        <>
        <ModalWrapper show={this.props.showLogout}>
        <div 
            className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
            style={{width:'25rem'}}>
            <Que/>
            <h3 className='model-text-dark my-2 py-md-3 py-0'>
                {t("Do you really want to logout?")}
            </h3> 
            {/* <div className='text-muted my-2 mx-auto w-75'>
               {t("You can find your order in the ‘My Wallet’ section.")}
            </div> */}
            {/* <div className='redeem-text2'>{t('Your new available balance is ')} {this.props.currencySymbol}{this.props.point}</div> */}
            <div className='d-md-block d-none'>
            <div className='d-flex justify-content-center align-items-center'>
                <Link 
                to={"#"}
                className="btn-logout-drc decoration-none  w-50 p-2 rounded"
                onClick={()=>{this.setModalView("yes")}}>
                {t("Yes")}
            </Link>
            <Link 
                 to={"#"}
                className="btn btn-block primary-btn decoration-none rounded mx-2 p-2 w-50"
                onClick={()=>{this.setModalView("no")}}>
                {t("No")}
            </Link>
            </div>
            </div>
            <div className='d-md-none d-block'>
            <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                <Link 
                to={"#"}
                className="btn-logout-drc decoration-none  w-100 p-2 rounded"
                onClick={()=>{this.setModalView("yes")}}>
                {t("Yes")}
            </Link>
            <Link 
                to={"#"}
                className="btn btn-block primary-btn decoration-none rounded mx-2 p-2 w-100"
                onClick={()=>{this.setModalView("no")}}>
                {t("No")}
            </Link>
            </div>
            </div>
            
            {/* <button 
                className="btn  btn-secondary mt-3 mx-2"
                onClick={()=>{this.setModalView()}}>
                <span className="fw-bold primary-color">{t("Close")}</span>
            </button> */}
        </div> 
    </ModalWrapper>
    </>
    )
  }
}

export default withRouter(withTranslation()(LogOutModel))