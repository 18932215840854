import './CategorySection.css';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next/';
import { connect } from 'react-redux';
import { currancyToPoints } from '../../utils/pointsConversion';

function ChooseSection(props) {
  return <div className="cat-section cat-choose">
    {props.catItems.map(catItem => (
      <div key={catItem.name}
        className="cat-item"
      >
        <img className="cat-img" src={catItem.img} alt="img" />
        <label className="cat-label"> {catItem.name}</label>
      </div>
    ))
    }
  </div>

    ;
}


function FeaturedSection(props) {

  return <div className="cat-section cat-featured d-grid grid-cols-md-4 grid-cols-2 gap-md-4 text-center">
    {props.catItems?.map(catItem => (
      <div className={`mx-2 cat-item `} key={catItem.id}>
        <Link
          to={{
            pathname: `/selectedgiftcard/${catItem.id}`,

          }}>
          <img className="cat-img card-hover" src={catItem.images?.color?.medium_rectangle} alt={catItem.name} />
        </Link>
        <label className="text-blackColor">
          {catItem.name}
        </label>
      </div>))}
  </div>
}

function OffersSection(props) {

  return <div className="cat-section cat-featured d-grid grid-cols-md-4 grid-cols-2 gap-md-4 text-center pb-4">
    {props.catItems?.map(catItem => (
      <div className="cat-item " key={catItem.id}>
        <Link
          to={{
            pathname: `/offer/${catItem.id}`,
          }}>
          <img className="cat-img card-hover" src={catItem.image?.small_rectangle} alt={catItem.title} />
        </Link>
        <label className="cat-label">
          {props.lang === 'en' ? catItem.title : catItem.title_arabic}
        </label>
      </div>

    ))
    }
  </div>

    ;
}

function ExperienceSection(props) {

  return <div className="cat-section cat-featured d-grid grid-cols-md-4 grid-cols-2 gap-md-4 text-center">
    {props.catItems?.map(catItem => (
      <div className="cat-item " key={catItem.id}>
        <Link
          to={{
            pathname: `/experience/${catItem.id}`,
          }}>
          <img className="card-hover experiences-img" src={catItem.images?.small_rectangle} alt={catItem.title} />
          {/* <img className="cat-img card-hover" src={catItem.images?.small_rectangle} alt={catItem.title} /> */}
        </Link>
        <div className='text-center color-blue'>
          {props.lang === 'en' ? catItem.name : catItem.name_arabic}
        </div>
        {/* <label className="cat-label">
        {props.lang === 'en' ? catItem.name : catItem.name_arabic }
         </label> */}
      </div>
    ))
    }
  </div>
}
const numberWithCommas = (x) => {
  let y = x.toFixed(0)
  return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function MerchandiseSection(props) {

  return <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 pt-3 pb-3 pb-md-2">
    {props.catItems?.map(card => (
      <div className='' key={card.id}>
        <Link className='offerCardHover' to={`/gift/${card.id}`}>
          <div style={{ cursor: 'pointer' }} className="border d-flex flex-column pb-1 h-100">
            <div className="text-center">
              <img
                className="img-fluid w-80"
                src={card.image && card.image.small_square} alt={card.title} />
            </div>
            <div className='px-2'>
              <h5 className="color-blue m-0 d-inline-block"
                style={{ minHeight: "75px", textOverflow: 'ellipsis', fontSize: '1.1rem' }}>{props.lang === 'en' ? card.product_name : card.product_name_arabic}</h5>
              <div style={{ minHeight: "35px" }} className="text-black-50">{props.lang === 'en' ? card.brand_name : card.brand_name_arabic}</div>
            </div>
            <div style={{ flexGrow: '1', alignItems: 'flex-end' }} className="d-flex px-2">
              <div>
                <h4 className="d-inline">{numberWithCommas(currancyToPoints(card.total_cost && card.total_cost))} </h4>
                <span className='py-2'>{props.lang === 'en' ? "Points" : "نقطة"}</span>
              </div>
            </div>

          </div>
        </Link>
      </div>
    ))
    }
  </div>
}

function SoftwareSection(props) {

  return <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 pt-3 pb-3 pb-md-2">
    {props.catItems?.map(card => (
      <div className='' key={card.id}>
        <Link className='offerCardHover' to={`/softwares/${card.id}`}>
          <div style={{ cursor: 'pointer' }} className="border d-flex flex-column pb-1 h-100">
            <div className="text-center">
              <img
                className="img-fluid w-80"
                src={card.images && card.images.small_square} alt={card.name} />
            </div>
            <div className='px-2'>
              <h5 className="color-blue m-0 d-inline-block"
                style={{ minHeight: "75px", textOverflow: 'ellipsis', fontSize: '1.1rem' }}>{props.lang === 'en' ? card.name : card.name_arabic}</h5>
            </div>
            <div style={{ flexGrow: '1', alignItems: 'flex-end' }} className="d-flex px-2">
              <div>
                <h4 className="d-inline">{numberWithCommas(card.points_in_sar && card.points_in_sar)} </h4>
                <span className='py-2'>{props.lang === 'en' ? "Points" : "نقطة"}</span>
              </div>
            </div>

          </div>
        </Link>
      </div>
    ))
    }
  </div>
}

function GamesSection(props) {

  return <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 pt-3 pb-3 pb-md-2">
    {props.catItems?.map(card => (
      <div className='' key={card.id}>
        <Link className='offerCardHover' to={`/games/${card.id}`}>
          <div style={{ cursor: 'pointer' }} className="border d-flex flex-column pb-1 h-100">
            <div className="text-center">
              <img
                className="img-fluid w-80"
                src={card.images && card.images.small_square} alt={card.name} />
            </div>
            <div className='px-2'>
              <h5 className="color-blue m-0 d-inline-block"
                style={{ minHeight: "75px", textOverflow: 'ellipsis', fontSize: '1.1rem' }}>{props.lang === 'en' ? card.name : card.name_arabic}</h5>
            </div>
            <div style={{ flexGrow: '1', alignItems: 'flex-end' }} className="d-flex px-2">
              <div>
                <h4 className="d-inline">{numberWithCommas(card.points_in_sar && card.points_in_sar)} </h4>
                <span className='py-2'>{props.lang === 'en' ? "Points" : "نقطة"}</span>
              </div>
            </div>

          </div>
        </Link>
      </div>
    ))
    }
  </div>
}


function ShopbrandSection(props) {
  return <div className="cat-section cat-shopbrand">
    {props.catItems.map(catItem => (
      <div key={catItem.img}
        className="cat-item"
      >
        <img className="cat-img" src={catItem.img} alt="img" />

      </div>
    ))
    }
  </div>

    ;
}

function CategorySection(props) {
  // const catType = props.catType;

  // if(catType === 'choose') {
  //   return <ChooseSection {...props}></ChooseSection>
  // } else if(catType === 'featured') {
  //   return <FeaturedSection {...props}></FeaturedSection>
  // }

  return <>
    <div className="cat-title-section">
      <div className="title-section">
        <h5
        className="color-skyblue ml-0 pt-3 pb-0 mb-0"
        >{props.catTitle}</h5>

      </div>
      {/* {props.showAllLink && <div className="show-all-link mt-2">
        <Link className='text-decoration-none  fw-bold' to={props.goToPage}>{props.showAllLinkText}</Link>
      </div>
      } */}
    </div>
    <CategoryDisply {...props}></CategoryDisply>

  </>

    ;
}

function CategoryDisply(props) {
  const catType = props.catType;

  if (catType === 'choose') {
    return <ChooseSection {...props}></ChooseSection>
  } else if (catType === 'featured') {
    return <FeaturedSection {...props}></FeaturedSection>
  }
  // commenting for next release
  else if (catType === 'offers') {
    return <OffersSection {...props}></OffersSection>
  }
  else if (catType === 'experience') {
    return <ExperienceSection {...props}></ExperienceSection>
  }
  else if (catType === 'merchandise') {
    return <MerchandiseSection {...props}></MerchandiseSection>
  }

  else if (catType === 'softwares') {
    return <SoftwareSection {...props} />
  }

  else if (catType === 'games') {
    return <GamesSection {...props} />
  }


  return <ShopbrandSection {...props}></ShopbrandSection>

    ;
}
const mapStateToProps = state => {
  return {
    loading: state.giftCards.loading,
    valid: state.auth.valid
  }
}

export default connect(mapStateToProps)((withTranslation()(CategorySection)));
