import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, SET_COUNTRY, SET_CONVERSION_RATE, COUNTRY_CODE, SET_LANG, CURRENCY_CODE } from "./countryTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';
import store from "Redux/store";

export const fetchCountriesRequest = () => {
    return {
        type:FETCH_COUNTRIES_REQUEST,
    }
}

export const fetchCountriesSuccess = data => {
    return {
        type:FETCH_COUNTRIES_SUCCESS,
        payload:data
    }
}

export const fetchCountriesFailure = error =>{
    return {
        type:FETCH_COUNTRIES_FAILURE,
        payload:error
    }
}

export const setCountry = data =>{
    return {
        type:SET_COUNTRY,
        payload:data
    }
}
export const setCountryHandler = id =>{
    return (dispatch)=>{
        localStorage.setItem("countryID", id);
        dispatch(setCountry(id))
        dispatch(fetchConversionRate(id))
    }
}

export const setCountryCode = code =>{
    return (dispatch)=>{
        dispatch({
            type : COUNTRY_CODE,
            payload : code
        })
    }
}

export const setCurrencyCode = code =>{
    return (dispatch)=>{
        dispatch({
            type : CURRENCY_CODE,
            payload : code
        })
    }
}

export const setlang = lang =>{
    let Journey = store.getState()?.tenetReducer?.slug?.slug
    let Language = localStorage.getItem("lang")
    return (dispatch)=>{
        dispatch({
            type : SET_LANG,
            payload : window.location.origin === "https://public-voice-eu.develop-kantar-frontend.meritincentives.me" && Journey === "kantar-public-voice-eu" ? Language : lang
        })
    }
}
export const setConversionRate = data =>{
    return {
        type:SET_CONVERSION_RATE,
        payload:data
    }
}
export const fetchConversionRate = (id) =>{
    const payload={
        "giftcard_units": {
            "currency": id
        }
    }
    return (dispatch)=>{
        axios.post(`${REACT_APP_baseUrl}/giftcard_units/get_conversion_rate`,
        payload,{
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                let conversionRate = response.data.data;
                dispatch(setConversionRate(conversionRate))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
            })
    }
}

const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0',
        }
    }
  }

export const fetchCountries = () => {
    return (dispatch)=>{
        dispatch(fetchCountriesRequest);
        axios.post(`${REACT_APP_baseUrl}/giftcard_units/get_currencies`,
            { },cacheHeaders())
            .then(response  => {
                let countries = response.data.data.giftcard_units;
                dispatch(fetchCountriesSuccess(countries))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchCountriesFailure("failed"))
            })
    }
}

export const fetchCountryID = () => {
   let localCountryID =  localStorage.getItem("countryID");
    return async(dispatch)=>{
        let localToken = localStorage.getItem("userToken")
        let payload = {
            "session": 
                {
                "user_token":localToken
                }
        }
        localToken && await axios.post(`${REACT_APP_baseUrl}/accounts/sessions/get_details_by_token`,payload)
        .then((response)=>{
            if(response.data.code === 200){
                let countryID = response.data.data?.user?.currency_id
                countryID && localStorage.setItem("countryID", countryID);
                countryID && dispatch(setCountry(countryID))
            }
            else if(response.data.code === 400 && localCountryID){
                dispatch(setCountry(localCountryID))  
            }
            else{
                dispatch(setCountry(2))  
            }
        })
        .catch(err => {
            dispatch(setCountry(2))
        })
    }
}