import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import HomePage from './homePage/HomePage';
// import GiftcardList from './GiftCardPage/GiftcardList';
// import PartnersList from './HassadPartnersPage/PartnersList';
import '../assests/css/App.css';
// import HassadPartnersSection from './HassadPartnersPage/HassadPartnersSection';
import CategoryPage from './CategoryPage/CategoryPage';
import TopHeader from './shared/TopHeader';
import MyWallet from './MyWallet/myWallet';
// import EMagazinesPage from './E-Magazines/E-MagazinesPage';
// import EMagazinePage from './E-Magazines/E-MagazinePage';
// import PointExchange from './PointExchange/PointExchangePage';
// import PointExchangeCardPage from './PointExchange/PointExchangeCardPage';
// import PointsExchangeFormReviewPage from './PointExchange/PointExchangeFormReviewPage';
import Footer from './shared/Footer';
import GiftCardsPage from './GiftCards/GiftcardsPage';
import GiftCardPage from './GiftCards/GiftCardPage';
import Experiences from './Experiences/ExperiencesPage';
import ExperienceBookingPage from './Experiences/ExperiencesBookingPage';
// import ShippingAddressPage from './PaymentFlow/ShippingAddressPage'
import cookie from 'react-cookies';
import OffersPage from './Offers/OffersPage';
import OffersCategory from './Offers/OffersCategory';
import CategorySection from './shared/CategorySection';
import SearchHeader from './shared/SearchHeader';
import MobileTopUp from './MobileTopUp/MobileTopUp.jsx';
// import PaymentPage from './PaymentFlow/PaymentPage';
// import OrderSummaryPage from './PaymentFlow/OrderSummaryPage';
import init from '../utils/init';
import NotFoundCard from './shared/NotFoundCard';
// import HassadGiftsPage from './HassadGifts/HassadGiftsPage';
import GiftPage from './HassadGifts/GiftPage';
import GiftsPage from './HassadGifts/GiftsPage';
// import GiftsCategory from  './HassadGifts/GiftsCategory';
import ViewDetails from './MyWallet/ViewDetails';
import OfferPage from './Offers/OfferPage';
import BannerSection from './homePage/BannerSection';
import Tambola from './homePage/Tambola';
import FeaturedCards from './homePage/FeaturedCards';
// import PointsSummaryPage from './PaymentFlow/PointsSummaryPage';
// import OfferSummaryPage from './PaymentFlow/OfferSummaryPage';
// import MobileTopUpSummaryPage from './PaymentFlow/MobileTopUpSummaryPage';
// import TermsAndConditions from './shared/TermsAndConditions';
// import ExperienceSummaryPage from './PaymentFlow/ExperienceSummaryPage';
// import OffersPaymentPage from './PaymentFlow/OffersPaymentPage';
// import ExperiencePaymentPage from './PaymentFlow/ExperiencePaymentPage';
// import MerchandiseSummaryPage from './PaymentFlow/MerchandiseSummaryPage';
// import MerchandisePaymentPage from './PaymentFlow/MerchandisePaymentPage';
// import MobileTopUpPaymentPage from './PaymentFlow/MobileTopUpPaymentPage';
// import MobileCategory from './CategoryPage/MobileCategory';
import i18next from 'i18next';
// un used import files , requiered in fututure 
import SoftwareGames from './Softwares&Games/Software&GamesPage';
// import SoftwareGamesBookingPage from './Softwares&Games/Softwares&GamesBookingPage';
// import SoftwareGamesPaymentPage from './PaymentFlow/SoftwareGamespaymentPage';
// import SoftwareAndGamesSummaryPage from './PaymentFlow/SoftwareGamesSummaryPage'
// import ViewDetailsSofGam from './MyWallet/ViewDetailsSofGam';
// import SoftwarePage from './Software/SoftwarePage';
// import GamesPage from './Games/GamesPage';
import SoftwaresBookingPage from './Software/SoftwaresBookingPage';
import GamesBookingPage from "./Games/GamesBookingPage"
import { connect } from 'react-redux';
import axios from 'axios';
import { MODULE_INFO } from '../Redux/categories/categoriesTypes';
import store from '../Redux/store';
// import { StripePaymentPage } from '../components/PaymentFlow/stripePaymentPage.jsx'
import { fetchAuthTokenFailure, fetchAuthTokenSuccess, } from '../Redux/auth/authActions';
import { fetchCountriesSuccess, fetchCountryID,setCountryCode, setCurrencyCode, setlang } from '../Redux/country/countriesAction';
// import ProductsPage from './CategoryPage/ProductsPage';
// import Giftflow from './shared/giftflow/Giftflow';
import { DebugPartyTown, REACT_APP_baseUrl,REACT_APP_TCS_STOREFRONT } from 'utils/app-constants';
// import ForgetPassword from './ForgetPassword/ForgetPassword';
// import PrivacyPolicy from './shared/PrivacyPolicy';
import GiftsCategory from './HassadGifts/GiftsCategory';
// import SignUp from './SignUp/SignUp';
import { checkTokenValidity } from 'Redux/auth/authActions';
// import Myprofile from './Myprofile/Myprofile';
// import PointsHistory from './Myprofile/PointsHistory';
import TravelShop from './TravelShop/TravelShop';
import PassengerDetails from './TravelShop/PassengerDetails';
import ContactDetail from './TravelShop/ContactDetail';
import DetailsDisplay from './TravelShop/DetailsDisplay';
// import BookingDetails from './TravelShop/BookingDetails';
import TravelConfirmationModal from './shared/Modals/TravelConfirmationModal';
import Search from './TravelShop/SearchFlights';
import TravelShopResults from './TravelShop/TravelShopResults';
import FlightsDetails from './TravelShop/FlightsDetails';
import GiftCardRedemption from './Redemption/GiftCardRedemption';
import WelcomePopup from './Redemption/WelcomePopup';
import ContactUs from './ContactUs/ContactUs';
import Multitenets from "../utils/MultiTenent/Multi-tenets.json"
import { fetSlugSucss, getSlugReq } from 'Redux/multiTenet/TenetAction';
import { TENENT_FAILURE } from 'Redux/multiTenet/TenetTypes';
import { withRouter } from "react-router";
import PageNotfound from './shared/PageNotfound';
import ValidateToken from './Redemption/validateToken';
import countryCurrency from "../utils/countrycodes/countryCurrency.json"
import getSymbolFromCurrency from 'currency-symbol-map'
import FAQ from './FAQ/FAQ';
import ViewCart from './Cart/ViewCart';
import SelectDeliveryAddress from './shared/SelectDeliveryAddress';
// import MaintenancePage from './shared/MaintenancePage';
import { Partytown } from '@builder.io/partytown/react';

class MyRouter extends Component {
    state = {
        lang: 'en',
        loading: true,
        redemptionCode:"",
        currencySymbol: null,
        code:"",
        portal:"",
        colorPalette: {}
    }
    setLang = (newlang) => {
        this.setState({ lang: newlang }, () => {
            if (newlang === 'English') {
                // document.dir = 'ltr'
                document.lang = 'en'
                // document.body.style.textAlign = "left"
                // document.body.style.fontFamily = `"Montserrat", sans-serif;`;
            }
            else {
                // document.dir = 'rtl'
                document.lang = 'es'
                // document.body.style.textAlign = "right"
                // document.body.style.fontFamily = "arabicFont"
            }
        })
    }

    moduleInfo = async (lang,code) => {
        const uninterceptedAxiosInstance = axios.create();
        await uninterceptedAxiosInstance.get(`${REACT_APP_baseUrl}/platform_module_images`,{
            headers:{
                'Tenant': this.props.slugID?.slug,
                "x-merit-locale": `${lang}-${code}`,  
            }
            })
            .then(response => {
                if (response.data.code === 200) {
                    let categories = response.data.data?.module_images?.sort((a, b) => a.sort_order - b.sort_order);
                    let moduleInfo = {};
                    categories.forEach((e) => moduleInfo[e.module_name] = e.active)
                    store.dispatch({
                        type: MODULE_INFO,
                        payload: {
                            moduleInfo: moduleInfo
                        }
                    })
                }
            })
            .catch(err => {
            })
        await store.dispatch(fetchCountryID())
        await store.dispatch(checkTokenValidity())
        this.setState({ loading: false })
    }

    getSlug = (slug) => {
        const uninterceptedAxiosInstance = axios.create();
        store.dispatch(getSlugReq())
        uninterceptedAxiosInstance.get(`${REACT_APP_TCS_STOREFRONT}/storefront/v1/tenants?slug=${slug}`,{
            headers:{
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Expires: '0',
            }
        }).then(response  => {
                 if(response.data?.journey === "SSO" || response.data?.journey === "AUTO_LOGIN_LINK"){
                    const queryParams = window.location?.search;
                    let key = queryParams?.split('secret=')[1];
                    this.validateCustomer(key,response,uninterceptedAxiosInstance,slug)                    
                }
                else{
                    this.SlugDetails(response)  
                }
            })
            .catch(error => {
                const errorMsg = error
                store.dispatch({type:TENENT_FAILURE,payload:errorMsg})
            })
}

validateCustomer = (val,response,uninterceptedAxiosInstance,slug) => {
        if(window.location.pathname?.includes("magic-link")){
            let payload = {code:val}
            uninterceptedAxiosInstance({
                url:`${REACT_APP_baseUrl}/accounts/sessions/validate_magic_link_code`,
                method: "post",
                data : payload,
                headers: {
                    'Tenant': slug,
                    "x-merit-locale": `${response.data?.countries[0]?.languages[0]}-${response.data.countries[0]?.code}`,
                },
            }).then((res)=>{
                if(res.status === 200) {
                    const token = res?.data?.access_token
                    localStorage.setItem("userToken", token)
                    this.getUserDetails(response,uninterceptedAxiosInstance,slug)
                }
            }).catch((err)=>{
                localStorage.removeItem("userToken")
                this.getUserDetails(response,uninterceptedAxiosInstance,slug)
            })
        }else{
            this.getUserDetails(response,uninterceptedAxiosInstance,slug)
        }
}

getUserDetails = (response,uninterceptedAxiosInstance,slug) =>{
    if(response.data?.journey === "SSO" || response.data?.journey === "AUTO_LOGIN_LINK"){
            const queryParams = window.location?.search;
            let token = queryParams?.split('token=')[1];

            let localToken = localStorage.getItem("userToken")

            if((localStorage.getItem("userToken") && !token) || token){
                if(token) localStorage.setItem("userToken", token);
                store.dispatch(fetchAuthTokenSuccess(localToken))
                uninterceptedAxiosInstance.get(`${REACT_APP_baseUrl}/user/get_customer_details`,
                    {
                    headers: {
                        Authorization: `Bearer ${token || localToken}`,
                        'Tenant': slug,
                        "x-merit-locale": `${response.data?.countries[0]?.languages[0]}-${response.data.countries[0]?.code}`,
                    },
                    }
                ).then((res)=>{
                    if(res.data?.code === 200){
                        this.triggerError(res.data?.data?.customer_id)
                        this.setState({
                            code:res.data?.data?.countries[0]?.code,
                            lang:res.data?.data?.countries[0]?.languages[0]
                        },()=>{
                            let currency = countryCurrency?.filter(val => val?.countryName === res.data?.data?.countries[0].name)
                            store.dispatch(fetSlugSucss(response.data))
                            this.setLanguage(this.state.lang)
                            store.dispatch(fetchCountriesSuccess(res.data?.data))
                            store.dispatch(setCountryCode(res.data?.data?.countries[0]?.code))
                            store.dispatch(setCurrencyCode(currency[0]?.currencyCode))
                        })
                    }else{
                        this.SlugDetails(response)
                    }
                }).catch((err)=>{
                    this.SlugDetails(response)
                })
            }
            else{
                this.SlugDetails(response)
                store.dispatch(fetchAuthTokenFailure(true))
            }
    }else{
        this.SlugDetails(response)                       
    }
}

    triggerError = (id) =>{
        window.addEventListener('error', function(event) {
            // Send the error to New Relic with the userId attribute
            if (typeof newrelic == 'object') {
                // eslint-disable-next-line no-undef
                newrelic.noticeError(event.error, { userId: `${id}` });
              }
        });
    }

    SlugDetails = (response) =>{
        this.setState({
            code:response.data.countries[0]?.code,
            lang:response.data?.countries[0]?.languages[0]
        },()=>{
            let currency = countryCurrency?.filter(val => val?.countryName === response.data.countries[0].name)
           this.setLanguage(this.state.lang)
           store.dispatch(fetSlugSucss(response.data))
           store.dispatch(fetchCountriesSuccess(response.data))
           store.dispatch(setCountryCode(response.data.countries[0]?.code))
           store.dispatch(setCurrencyCode(currency[0]?.currencyCode))
        })
    }

    setLanguage = (lang) =>{
        let language = localStorage.getItem("lang")
        if(window.location?.origin === "https://public-voice-eu.develop-kantar-frontend.meritincentives.me"){
            i18next.changeLanguage(language)
            store.dispatch(setlang(language))
        }else{
            i18next.changeLanguage(lang)
            localStorage.setItem('lang', lang);
            store.dispatch(setlang(lang))
        }
    }
    getTenantTheme = (slug) => {
        axios.get(`${REACT_APP_TCS_STOREFRONT}/storefront/v1/tenants/themes?slug=${slug}`)
            .then((response) => {
                this.setState({ colorPalette: response.data.colorPalette },()=>{
                    if(Object.keys(this.state.colorPalette).length > 0){
                        document.documentElement.style.setProperty('--primaryColorTheme', this.state.colorPalette.primaryColor);
                        document.documentElement.style.setProperty('--secondaryColorTheme', this.state.colorPalette.secondaryColor);
                  document.documentElement.style.setProperty('--primaryBtnTheme', this.state.colorPalette.primaryButtonColor);
                  document.documentElement.style.setProperty('--primaryTextTheme', this.state.colorPalette.primaryTextColor);
                    }
                })
            })
            .catch((error) => {
               
            })
    }
    componentDidMount() {
        let domain = window.location?.origin;        
        let slug = Object.values(Multitenets)?.filter(val => val.domain === domain)
        // let lang = cookie.load('language');
        // if (lang === null || lang === undefined) {
        //     lang = 'ar';
        // }
        // else if (lang === 'ar') {
        //     i18next.changeLanguage('ar')
        // }

        // this.setLang(lang)
        // init()
        if(slug[0]?.slug){
            this.getSlug(slug[0]?.slug)
            // this.getTenantTheme(slug[0]?.slug)
            this.setState({portal:slug[0]?.portal})
        }else if(slug[0]?.slug===undefined){
            this.props.history.push('/notFound')
            this.setState({loading:false})
        }
        const redemptionCode = cookie.load("RedemptionCode")
        this.setState({redemptionCode:redemptionCode})
    }

    componentDidUpdate(prevprops){
        if(prevprops.slugLoading !== this.props.slugLoading && this.props.slugID?.id){
            this.moduleInfo(this.state.lang,this.state.code)
        }
        if(prevprops.valid !== this.props.valid && this.props.slugID?.id){
            const redemptionCode = cookie.load("RedemptionCode")
            this.setState({redemptionCode:redemptionCode})
            if(this.props.slugID?.journey === "PROMO_CODE_REDEMPTION"){
                this.triggerError(redemptionCode)
            }
        }
        if(prevprops.selectedCountry !== this.props.selectedCountry){
            let currency = countryCurrency?.filter(val => val?.countryName === this.props.selectedCountry[0].name)
            this.setState({
                currencySymbol: getSymbolFromCurrency(currency[0]?.currencyCode) ? getSymbolFromCurrency(currency[0]?.currencyCode) : '£'
            })
        }
    }

    render() {
        const { modulesData } = this.props
        return (
            this.state.loading ?

                <div className="d-flex justify-content-center pt-2 pb-2">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> :

                <div className='main-div'>
                    <Partytown debug={DebugPartyTown} forward={['dataLayer.push']} />
                    <Router>
                        <Route exact path='/sso-login' render={props => <ValidateToken setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/magic-link' render={props => <ValidateToken setLang={this.setLang} lang={this.state.lang} {...props} />} />

                        {/* Redemtion Code Pop up */}
                        <Route exact path='/redemptioncode' render={props => <GiftCardRedemption setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route path="*" render={props => <SearchHeader {...props} lang={this.state.lang} setLang={this.setLang} redemptionCode={this.state.redemptionCode} currencySymbol={this.state.currencySymbol} setLanguage={this.setLanguage}/>} />

                        <Route path="*" render={props => <TopHeader {...props} lang={this.state.lang} setLang={this.setLang} currencySymbol={this.state.currencySymbol} setLanguage={this.setLanguage}/>}/>
                       
                        {/* <Route path="*" render={props => !props.location?.search?.includes('?lang=') && <CategorySection {...props} lang={this.state.lang} setLang={this.setLang}/>} /> */}
                        <Route path="/" render={props => !props.location?.search?.includes('?lang=') && <WelcomePopup {...props} lang={this.state.lang} setLang={this.setLang} portal={this.state.portal} currencySymbol={this.state.currencySymbol}/>} />
                        {/* <Route exact path='/' render={props => <Tambola setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        {/* <Route exact path='/' render={props => <BannerSection setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        {/* <Route exact path='/' render={props => <FeaturedCards setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        <Route exact path='/' render={props => <HomePage setLang={this.setLang} lang={this.state.lang} {...props} redemptionCode={this.state.redemptionCode} />} />
                        <Route exact path='/' render={props => <GiftCardsPage setLang={this.setLang} lang={this.state.lang} {...props} currencySymbol={this.state.currencySymbol}/>} />
                        {/* <Route exact path='/auth/login' render={props => <HomePage setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        <Route exact path='/view-cart' render={props => <ViewCart setLang={this.setLang} lang={this.state.lang} {...props} currencySymbol={this.state.currencySymbol}/>} />
                        <Route exact path='/address/selectedAddress' render={props => <SelectDeliveryAddress setLang={this.setLang} lang={this.state.lang} {...props} currencySymbol={this.state.currencySymbol}/>} />
                        <Route exact path='/404' render={props => <NotFoundCard lang={this.state.lang} {...props} />} />
                        <Route exact path='/notFound' render={props => <PageNotfound lang={this.state.lang} {...props} />} />
                        <Route exact path="/category/:categoryName" render={props => <CategoryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        {/* <Route exact path="/category/:categoryName/:subCategory" render={props => <ProductsPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/selectedpartnercard' render={props => <PartnersList setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/categories' render={props => <MobileCategory setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/hassadpartners' render={props => <HassadPartnersSection setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        <Route exact path='/contactus' render={props =>  <ContactUs setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/faq' render={props =>  <FAQ setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        {/* Giftflow */}
                        {/* <Route exact path='/giftflow' render={props =>  <Giftflow location={props.location} setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}

                        <Route exact path='/redeem-card:token' render={props => <HomePage setLang={this.setLang} lang={this.state.lang} redem={true} {...props} />} />
                       
                   
                      
                        {/* <------------- Giftcards Section --------------> */}

                        {
                            modulesData["Gift Cards"] && <>
                                {/* <Route exact path='/giftcards' render={props => <GiftCardsPage setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                                <Route exact path="/selectedgiftcard/:id" render={props => <GiftCardPage setLang={this.setLang} lang={this.state.lang} {...props} redemptionCode={this.state.redemptionCode} currencySymbol={this.state.currencySymbol} />} />
                            </>
                        }


                        {/* <------------- Emagazine Section --------------> */}
                        {/* {modulesData["E-Magazines"] && <>
                    <Route exact path='/e-magazines' render={props =>  <EMagazinesPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/e-magazines/:id' render={props =>  <EMagazinePage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    </>
                    } */}


                        {/* <------------- Offers Section --------------> */}
                        {modulesData["RewardsBy Offers"] && <>
                            <Route exact path='/offers' render={props => <OffersPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            <Route exact path='/offers/:category' render={props => <OffersCategory setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            <Route exact path='/offer/:id' render={props => <OfferPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        </>
                        }

                        {/* <------------- Points Exchange Section --------------> */}
                        {/* {modulesData["Points Exchange"] && <>
                    <Route exact path='/points-exchange' render={props =>  <PointExchange setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/points-exchange/confirm-details/:id' render={props =>  <PointsExchangeFormReviewPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/points-exchange/:id' render={props =>  <PointExchangeCardPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    </>
                    } */}


                        {/* <------------- Experiences Section --------------> */}

                        {modulesData["Experiences"] && <>
                            <Route exact path='/experiences' render={props => <Experiences setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            <Route exact path='/experience/:id' render={props => <ExperienceBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        </>
                        }

                        {/* <------------- Mobile Topup Section --------------> */}
                        {modulesData["Mobile TopUp"] &&
                            <Route exact path='/mobileTopUp' render={props => <MobileTopUp setLang={this.setLang} lang={this.state.lang} {...props} />} />}


                        {/* <------------- Hassad Gifts Section --------------> */}
                        {modulesData["RewardsBy Gifts"] && <>
                            <Route exact path='/gifts' render={props => <GiftsPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            {/* <Route exact path='/gifts/:category' render={props => <ProductsPage {...props}/>} /> */}
                            <Route exact path='/gifts/:category' render={props => <GiftsCategory setLang={this.setLang} lang={this.state.lang} {...props} />} />

                            <Route exact path='/gift/:id' render={props => <GiftPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        </>
                        }

                        {/* <------------- Softwares Section --------------> */}
                        {/* {modulesData["Software"] && <>
                    <Route exact path='/softwares' render={props =>  <SoftwarePage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/softwares/:id' render={props =>  <SoftwaresBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    </>
                    } */}

                        {/* <------------- Games Section --------------> */}
                        {/* {modulesData["Games"] && <>
                    <Route exact path='/games/:id' render={props =>  <GamesBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    <Route exact path='/games' render={props =>  <GamesPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                    </>
                    } */}

                        {/* <------------- Software and Games Section --------------> */}
                        {modulesData["Software&Games"] && <>
                            <Route exact path='/softwares&games' render={props => <SoftwareGames setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            <Route exact path='/softwares/:id' render={props => <SoftwaresBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            <Route exact path='/games/:id' render={props => <GamesBookingPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        </>
                        }

                        {/* <------------- Flights Section --------------> */}
                        {modulesData["Travel shop"] &&
                            <>
                                <Route exact path='/flights' render={props => <TravelShop setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/flights-results' render={props => <TravelShopResults setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/flights-details' render={props => <FlightsDetails setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/contacts' render={props => <ContactDetail setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/passenger-details/:number' render={props => <PassengerDetails setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/travel-details' render={props => <DetailsDisplay setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/order-confirmation-travel' render={props => <TravelConfirmationModal setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/searchFlight' render={props => <Search setLang={this.setLang} lang={this.state.lang} {...props} />} />
                                <Route exact path='/travel-confirmation-order' render={props => <TravelConfirmationModal setLang={this.setLang} lang={this.state.lang} {...props} />} />
                            </>
                        }



                        {/* <------------- Mywallet Section --------------> */}

                        <Route exact path='/mywallet' render={props => <MyWallet setLang={this.setLang} lang={this.state.lang} {...props} redemptionCode={this.state.redemptionCode} />} />
                        <Route exact path='/mywallet/:categoryName' render={props => <MyWallet setLang={this.setLang} lang={this.state.lang} {...props} redemptionCode={this.state.redemptionCode} currencySymbol={this.state.currencySymbol}/>} />
                        <Route exact path='/giftcard/:token/:lang' render={props => <ViewDetails setLang={this.setLang} slug={this.props.slugID} lang={this.state.lang} {...props} />} />
                        {/* <Route exact path ='/softwareGames/:id/:lang' render={props => <ViewDetailsSofGam setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}

                        {/* <------------- Credit Card Section --------------> */}

                        {/* <Route exact path='/payment/card-details' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/card-details-offers' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/card-details-topup' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/card-details-experience' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/card-details-merchandise' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/card-details-software-games' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/card-details-travelshop' render={props => <StripePaymentPage setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}

                        {/* <------------- Order Summary Section --------------> */}

                        {/* <Route exact path='/payment/orders-summary/:id' render={props => <OrderSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path="/payment/mobile-topup-summary/:id" render={props => <MobileTopUpSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/offers-orders-summary/:id' render={props => <OfferSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/experience-orders-summary/:id' render={props => <ExperienceSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/merchandise-orders-summary/:id' render={props => <MerchandiseSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        {/* <Route exact path='/payment/points-orders-summary/:id' render={props =>  <PointsSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        {/* <Route exact path='/payment/softwareAndGames-orders-summary/:id' render={props => <SoftwareAndGamesSummaryPage setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/payment/travel-orders-summary/:id' render={props => <BookingDetails setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}


                        {/* <------------- My Profile Section --------------> */}
                        {/* <Route path="/auth/my-profile" render={props => <Myprofile setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route path="/points-history" render={props => <PointsHistory setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}


                        {/* Forget Password */}
                        {/* <Route path="/auth/forgotPassword/:token" render={props => <ForgetPassword setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        {/* <Route path="/auth/signup" render={props =><SignUp  setLang={this.setLang} lang={this.state.lang} {...props}/>}/> */}

                        {/* <------------- Terms, Privacy Policy, Footer --------------> */}
                        {/* <Route exact path='/terms-and-conditions' render={props => <TermsAndConditions setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        <Route exact path='/privacy-policy' render={props => <PrivacyPolicy setLang={this.setLang} lang={this.state.lang} {...props} />} /> */}
                        <Route path="*" render={props => <Footer setLang={this.setLang} lang={this.state.lang} {...props} />} />
                        {/* added maintenance page */}
                         {/* <Route exact path='*' render={props => <MaintenancePage lang={this.state.lang} {...props} />} />
                        </> */}
                    </Router>

                </div>


        )
    }
}

const mapStateToProps = (state) => {
    return {
        modulesData: state.categories.moduleInfo,
        valid: state.auth.valid,
        slugID : state.tenetReducer?.slug,
        slugLoading : state.tenetReducer?.loading,
        selectedCountry : state.countries?.countries?.countries?.filter(val => state.countries?.countryCode === val.code),
    };
};

export default connect(mapStateToProps)(withRouter(MyRouter));
