import React, { Component } from 'react';
// import TermsConditionModal from './Modals/TermsConditionModal';
// import LoginModal from './Modals/LoginModal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { fetchCountyCode } from '../../Redux/CountryCode/CountryCodeActions';
import MainLoginModel from './Modals/MainLoginModel';

class BuyNowCard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isGift:false,
            deliveryType:'email',
            email:'',
            phno:'',
            message:'',
            quantity:0,
            byNowButton:true,
            showPopup:false,
            paymentType:'points' 
         }
    }
    changeDeliveryType=(type)=>{
        if(type==="SMS"){
            this.props.fetchCountyCode()
        }
        this.setState({
            deliveryType:type
        })
    }
    setGift=(val)=>{
        this.setState({
            isGift:val
        })
    }
    setMessage=(val)=>{
        if(val.length<=500){
            this.setState({
                message:val
            })  
        }
        
    }
    
    // changeCountry = (value) => {
    //     const payload = {
    //             "countries": {
    //                 "country_name": value
    //             }
    //     }
    //     this.props.fetchPhoneFormat(payload)
    // }
    componentDidMount (){
        // this.changeCountry("Saudi Arabia")
    }
    BuyNowWord=(val)=>{
        if(this.props.error){
            this.setState({
                showPopup: val
            })
        }
        else{
            this.props.onSubmit(true,{isGift:this.state.isGift,paymentType:this.state.paymentType})
        }
    }
    // error
    setByNowButton(val){
        this.setState(old=>({
            byNowButton:!old.byNowButton
        }))
    }
    checkCreditButton(){
        if(!this.state.byNowButton && this.props.price){
            return false
        }
        else{
            return true
        }
    }
    render() { 
        const {t,isGift,quantity,lang } = this.props
        const desktopView=(
            <div  className="d-md-block d-none flex-shrink-0" style={{width:'17rem',minWidth:'17rem'}}>
            <div className="card mb-4 rounded-0 w-100">
                <div className="card-body text-black-50">
                     {/* <LoginModal show={this.state.showPopup} close={this.BuyNowWord}/> */}
                    <MainLoginModel show={this.state.showPopup} loginFun={()=>this.setState({showPopup:!this.state.showPopup})} lang={lang} />

                    {/* {
                        isGift!==false && 
                        <>
                    <div className='d-flex gap-2'>
                        <input 
                            type="checkbox" 
                            className="" 
                            onClick={(e)=>{this.setGift(e.target.checked)}} />
                            <label className=" " >{t("Buying a Gift")}</label>
                    </div>
                    {
                        this.state.isGift?
                        <div className='my-3 py-1'>
                            {t("Delivery Type")}
                            <div className="d-flex mx-n2 py-2">
                                {
                                    ['email','SMS'].map(type=>(
                                        <h6
                                            className={`btn px-4 mx-2 rounded-0 text-dark text-capitalize ${this.state.deliveryType===type?'selected-value':' border'}`}
                                            onClick={()=>{this.changeDeliveryType(type)}}
                                                >
                                                {t(type)}
                                        </h6>
                                    ))
                                }
                            </div>
                            <div className='py-3'>
                            {
                                    this.state.deliveryType==='SMS' && <div className=" my-3">
                        <label>{t("Select a Country")}</label>
                        <select className="arrows dis border w-100"  
                        // value={this.props.countryId} 
                        onChange={(e) => {this.changeCountry(e.target.value)}}
                        // onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                       
                        >
                            {this.props.countryCode && countryCode.map((country, index) => {
                               return <option key={country.id}
                        selected={index === 190}
                               > {country.country_name} </option>
                            })}
                        </select>
                    </div> }
                                <div className=''>{t("To")}</div>
                                {
                                    this.state.deliveryType==='email'?
                                        <div className="py-2">
                                            
                                            <input  
                                                type="email"
                                                className='p-2 w-100 border rounded-0 text-dark'
                                                placeholder={`${t("Enter Recipient's Email")}`}
                                                name="type"/>
                                        </div>:
                                        <div className="d-flex p-0 w-100 flex-wrap">
                                            <select className="btn border bg-light rounded-0 col-3 caret px-1">
                                                <option>
                                                {this.props.PhoneFormat && this.props.PhoneFormat.country_code}</option>
                                            </select>
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="tel" 
                                                pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                name="number"/>
                                            <div>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div>
                                        </div>
                                }
                            </div>
                            
                            <div className='my-2'>
                                <div>{t("Message")}</div>
                                <textarea 
                                    className="d-block w-100 py-2 my-2 border rounded-0"
                                    onChange={(e)=>{this.setMessage(e.target.value)}}
                                    value={this.state.message}/>
                                <p>{500-this.state.message.length} {t("characters remaining")}</p>
                            </div>
                        </div>
                        :null
                    }
                        </>
                    } */}
                    
                    {
                        quantity!==false &&
                    <div className=" my-3">
                        <label>{t("Quantity")}</label>
                        <select className="dist border w-100" disabled
                        onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                        >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>
                    }           
                        
                        <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setByNowButton()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}</label>
                        </div>
                    <br/>
                    <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'points'},()=>this.BuyNowWord(this.props.error))}} disabled={this.state.byNowButton}>{t("Buy with Points")}</button>
                    <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'creditcard'},()=>this.BuyNowWord(this.props.error))}} disabled={this.checkCreditButton()}>{t("Buy with Credit Card")}</button>
                </div>
            </div>
        </div>)
        const mobileView = (
            <div className='d-md-none d-block'>
                  <div className="card mb-5 rounded-0 w-100">
                <div className="card-body text-black-50">
                {/* <LoginModal show={this.state.showPopup} close={this.BuyNowWord}/> */}
                    <MainLoginModel show={this.state.showPopup} loginFun={()=>this.setState({showPopup:!this.state.showPopup})} lang={this.props.lang}/>

                    {
                        isGift!==false && 
                        <>
                    <div className='d-flex gap-2'>
                        <input 
                            type="checkbox" 
                            className="" 
                            onClick={(e)=>{this.setGift(e.target.checked)}} />
                            <label className="ml-2 mr-2">{t("Buying a Gift")}</label>
                    </div>
                    {/* {
                        this.state.isGift?
                        <div className='my-3 py-1'>
                           {t("Delivery Type")}
                            <div className="d-flex mx-n2 py-2">
                                {
                                    ['email','SMS'].map(type=>(
                                        <h6
                                            className={`btn px-4 mx-2 rounded-0 text-dark text-capitalize ${this.state.deliveryType===type?'selected-value':' border'}`}
                                            onClick={()=>{this.changeDeliveryType(type)}}
                                                >
                                               {t(type)}
                                        </h6>
                                    ))
                                }
                            </div>
                            <div className='py-3'>
                            {
                                    this.state.deliveryType==='SMS' && <div className=" my-3">
                        <label>{t("Select a Country")}</label>
                        <select className="arrows dis border w-100"  
                        // value={this.props.countryId} 
                        onChange={(e) => {this.changeCountry(e.target.value)}}
                        >
                            {this.props.countryCode && countryCode.map((country, index) => {
                               return <option key={country.id} 
                               selected={index === 190}
                               > {country.country_name} </option>
                            })}
                        </select>
                    </div> }
                                <div className=''>{t("To")}</div>
                                {
                                    this.state.deliveryType==='email'?
                                        <div className="py-2">
                                            
                                            <input  
                                                type="email"
                                                className='p-2 w-100 border rounded-0 text-dark'
                                                placeholder={`${t("Enter Recipient's Email")}`}
                                                name="type"/>
                                        </div>:
                                        <div className="d-flex p-0 w-100 flex-wrap">
                                            <select className="btn border bg-light rounded-0 col-3 caret px-1">
                                                <option
                                                
                                               
                                                >{this.props.PhoneFormat && this.props.PhoneFormat.country_code} </option>
                                            </select>
                                            <input 
                                                className="col-9 py-2 rounded-0 border text-dark"
                                                type="tel" 
                                                pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                                name="number"/>
                                            <div>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div>
                                        </div>
                                }
                            </div>
                            
                            <div className='my-2'>
                                <div>{t("Message")}</div>
                                <textarea 
                                    className="d-block w-100 py-2 my-2 border rounded-0"
                                    onChange={(e)=>{this.setMessage(e.target.value)}}
                                    value={this.state.message}/>
                                <p>{500-this.state.message.length} {t("characters remaining")}</p>
                            </div>
                        </div>
                        :null
                    } */}
                        </>
                    }
                    
                    {
                        quantity!==false &&
                    <div className=" my-3 w-25">
                        <label>{t("Quantity")}</label>
                        <select className="dist border" disabled
                        onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                        >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>
                    }           
                        
                        <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setByNowButton()}} />
                                <label className="ml-2 mr-2">{t("I have read and accepted the Terms & Conditions")}</label>
                        </div>
                    <br/>
                    <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'points'},()=>{this.BuyNowWord(this.props.error)})}} disabled={this.state.byNowButton}>{t("Buy with Points")}</button>
                    <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'creditcard'},()=>{this.BuyNowWord(this.props.error)})}} disabled={this.checkCreditButton()}>{t("Buy with Credit  Card")}</button>
                </div>
            </div>

                {/* {
                    isGift!==false && 
                <div className="d-flex py-3 gap-2">
                    <input 
                        type="checkbox" 
                        className="" 
                        onClick={(e)=>{this.setGift(e.target.checked)}} />
                        <label className="" >{t("Buying a Gift")}</label>
                </div>
                }                
                <div className="d-flex gap-2">
                    <input 
                        type="checkbox" 
                        onClick={()=>{this.setByNowButton()}} />
                        <label className="" >{t("I have read and accepted the Terms & Conditions")}</label>
                </div>
                <div className='my-5'>
                </div>
                <div className='position-fixed w-100 px-3 py-2 bg-light' style={{bottom:'3.9rem',left:'0'}}>
                    <button type="button" className="col-12 btn btn-block primary-btn rounded-0 text-center " onClick={this.props.onSubmit}  disabled={this.state.byNowButton}>{t("Buy Now")}</button>
                </div> */}
               
            </div>
        )
        return ( 
            <>
                {desktopView}
                {mobileView}
            </>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        countryCode:state.countryCode.countryCode,
        loading:state.countryCode.countryCodeloading,
        pointBalance:state.point.pointBalance,
        // PhoneFormat:state.PhoneFormat.PhoneFormat,
        error:state.auth.error,
        authToken:state.auth.authToken,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchCountyCode : () => dispatch(fetchCountyCode()),
        // fetchPhoneFormat : (payload) => dispatch(fetchPhoneFormat(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BuyNowCard));
