/* eslint-disable eqeqeq */
import React, { Component } from 'react';
// import TermsConditionModal from './Modals/TermsConditionModal';
// import LoginModal from './Modals/LoginModal';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  fetchCountyCode,
  fetchPhoneFormat,
} from "../../Redux/CountryCode/CountryCodeActions";
import { fetchGiftFlowData } from '../../../src/Redux/GiftFlow/giftFlowActions.js';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
// import MainLoginModel from './Modals/MainLoginModel';
import './giftflow//Buygift.css'
import { Link, Prompt } from "react-router-dom";
import ConfirmPopup from "./giftflow/ConfirmPopup.jsx";
import disposableEmails from '../../utils/DisposableEmails.json'
import LoginModal from './Modals/LoginModal';
import EmailPng from '../../assests/img/Email.png'
import { REACT_APP_ADD_TO_CART } from 'utils/app-constants';
import {ReactComponent as Info} from '../../assests/img/experiences/Info.svg'

class BuyNowCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
            isGift:true,
            deliveryType:'Email',
            email:this.props?.userEmail || "",
            error:{
                email:""
            },
            phno:null,
            quantity:0,
            byNowButton:true,
            showPopup:false,
            message:'Select a Message',
            customizeMessage: '',
            Messages: [
                "Congratulations on your success",
                "Happy new year",
                "Congratulations on your wedding",
                "Ramadan Kareem",
                "Happy Birthday",
                "Happy Eid",
                "Congratulations on your promotion",
                "Congratulations on performing Hajj",
                "Congratulations on the new house",
                "happy mother's day",
                "on the newborn",
                "Get well soon",
                "Welcome Back",
                "Congratulations on your graduation",
                "Happy father's day",
                "Customize message",
              ],
            paymentType:'points',
            confirmShow: false,
            leave: false,
            productPath: ''
            };
         }
  componentDidMount() {
    if(typeof(this.props.state) === "undefined" || typeof(this.props.state)==="object"){
      if(typeof(this.props.state)==="object" && this.props.state?.comingFrom==="giftflow") {

      }
      else{
        this.closeConfirmPopup(true);
      }
    const type = localStorage.getItem('type');
    if (type==='eMagazines') this.setState({productPath: 'e-magazines'})
    else if (type==='giftCards') this.setState({productPath: 'selectedgiftcard'})
    else if (type==='offers') this.setState({productPath: 'offer'})
     }
    }

  componentDidUpdate(){
    if (this.props.message!==""){
      if(this.state.leave===false){
        this.setState({leave: true});
      }
    }
    else{
      if (this.state.leave===true){
          this.setState({leave: false});
      }
    }
  }

    changeDeliveryType=(type)=>{
        if(type==="SMS"){
            this.props.fetchCountyCode()
        }
        this.setState({
            deliveryType:type
        })
    }
    // setGift=(val)=>{
    //     if(val){
    //         this.changeCountry(this.props.selectedCountry || "Saudi Arabia")
    //     }
    //     this.setState({
    //         isGift:val
    //     })
    // }
    setMessage=(val)=>{
            this.setState({
                message:val
            })  
        
    }
    setcustomizeMessage=(val)=>{
        if(val.length<=500){
            this.setState({
                customizeMessage:val
            })  
        }
        
    }
    
    changeCountry = (value) => {
        const payload = {
                "countries": {
                    "country_name": value
                }
        }
        this.props.fetchPhoneFormat(payload)
        this.setState({phno:''})
    }
    
    AddToCart = (val) => {
        if(this.props.error && this.props.cardType === 'digital'){
            this.setState({
                showPopup: val
            })
            return
        }
        const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
        if (!this.state.email && this.props.cardType === 'digital') {
            this.setState({ error: { email: "Email field is required" } })
            return false;
        } else if (emailRegex.test(this.state.email) === false && this.props.cardType === 'digital') {
            this.setState({ error: { email: "Enter valid email" } });
            return false;
        } else if (disposableEmails[this.state.email.split('@')[1]] && this.props.cardType === 'digital') {
            this.setState({ error: { email: "This email is not allowed" } });
            return false;
        }
        else {
            this.props.addCart(true, { paymentType: this.state.paymentType, isGift: this.state.isGift, message: this.state.customizeMessage !== '' ? this.state.customizeMessage : this.state.message === 'Select a Message' ? null : this.state.message, type: this.state.deliveryType, data: this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code + this.state.phno })
        }
    }
    
    BuyNowWord=(val)=>{        
        let inputTags = document.getElementsByClassName('deskForm')
        if(!this.props.valid){
            this.setState({
                showPopup: val
            })
        }
        else{

            const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
            if (!this.state.email && this.props.cardType === 'digital') {
                this.setState({ error: { email: "Email field is required" } })
                return false;
              }else if (emailRegex.test(this.state.email) === false && this.props.cardType === 'digital') {
                this.setState({ error: { email: "Enter valid email" } });
                return false;
             }else if (disposableEmails[this.state.email.split('@')[1]] && this.props.cardType === 'digital') {
                this.setState({ error: { email: "This email is not allowed" } });
                return false;
             }
              else{
                this.props.onSubmit(true,{paymentType:this.state.paymentType,isGift:this.state.isGift,message:  this.state.customizeMessage !== ''  ? this.state.customizeMessage : this.state.message === 'Select a Message' ? null:this.state.message,type: this.state.deliveryType , data : this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code+this.state.phno})
              }
            // if(inputTags[0] && this.state.isGift){
            //     let mobileNumberLength = this.state.phno?.split('')?.length;
            //     let alertTag = document.getElementsByClassName('desktopAlert')[0]
                
            //     if(inputTags[0].checkValidity()){
            //         if((mobileNumberLength <= this.props.PhoneFormat.phone_max_length && mobileNumberLength >=this.props.PhoneFormat.phone_min_length) || this.state.deliveryType === 'Email'){
            //             if(alertTag){
            //                 alertTag.style.display = 'none'
            //             }
            //         this.props.onSubmit(true,{paymentType:this.state.paymentType,isGift:this.state.isGift,message:  this.state.customizeMessage !== ''  ? this.state.customizeMessage : this.state.message === 'Select a Message' ? null:this.state.message,type: this.state.deliveryType , data : this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code+this.state.phno})
            //     }
            //     else{
            //         if(alertTag){
            //             alertTag.style.display = 'block'
            //         }
            //     }
            // }
            //     else{
            //         inputTags[0].reportValidity()
            //     }
            // }
            // else {
            //     this.props.onSubmit(true,{isGift:this.state.isGift,paymentType:this.state.paymentType,message:this.state.customizeMessage !== ''  ? this.state.customizeMessage : this.state.message === 'Select a Message' ? null:this.state.message,type: this.state.deliveryType , data : this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code+this.state.phno})
            // }
        }
    }
     // buy now mobile
     BuyNowWordMobile=(val)=>{
        let inputTags = document.getElementsByClassName('mobileform')
        if(this.props.error){
            this.setState({
                showPopup: val
            })
        }
        else{
            const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
            if (!this.state.email && this.props.cardType === 'digital') {
                this.setState({ error: { email: "Email field is required" } })
                return false;
              }else if (emailRegex.test(this.state.email) === false && this.props.cardType === 'digital') {
                this.setState({ error: { email: "Enter valid email" } });
                return false;
            }else if (disposableEmails[this.state.email.split('@')[1]] && this.props.cardType === 'digital') {
                this.setState({ error: { email: "This email is not allowed" } });
                return false;
             }
              else{
                this.props.onSubmit(true,{paymentType:this.state.paymentType,isGift:this.state.isGift,message:  this.state.customizeMessage !== ''  ? this.state.customizeMessage : this.state.message === 'Select a Message' ? null:this.state.message,type: this.state.deliveryType , data : this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code+this.state.phno})
              }
            // if(inputTags[0] && this.state.isGift){
            //     let mobileNumberLength = this.state.phno?.split('')?.length;
            //     let alertTag = document.getElementsByClassName('mobAlert')[0]
            //     if(inputTags[0].checkValidity()){
            //         if((mobileNumberLength <= this.props.PhoneFormat.phone_max_length && mobileNumberLength >=this.props.PhoneFormat.phone_min_length) || this.state.deliveryType === 'Email'){
            //            if(alertTag){
            //                alertTag.style.display = 'none'
            //            }
            //             this.props.onSubmit(true,{isGift:this.state.isGift,paymentType:this.state.paymentType,message: this.state.customizeMessage !== ''  ? this.state.customizeMessage :this.state.message === 'Select a Message' ? null:this.state.message,type: this.state.deliveryType , data : this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code+this.state.phno})
            //         }
            //         else{
            //             if(alertTag){
            //                 alertTag.style.display = 'block'
            //             }
            //         }
            //     }
            //     else{
            //         inputTags[0].reportValidity()
            //     }
            // }
            // else {
            //     this.props.onSubmit(true,{isGift:this.state.isGift,paymentType:this.state.paymentType,message: this.state.customizeMessage !== '' ? this.state.customizeMessage :  this.state.message === 'Select a Message' ? null:this.state.message,type: this.state.deliveryType , data : this.state.deliveryType === 'Email' ? this.state.email : this.props.PhoneFormat.country_code+this.state.phno})
            // }
        }
    }
    // error
    setByNowButton(val){
        this.setState(old=>({
            byNowButton:!old.byNowButton
        }))
    }
    checkCreditButton(){
        if(!this.state.byNowButton && this.props.price){
            return false
        }
        else{
            return true
        }
    }
    deleteGiftFlowData = (message) => {
        if (message!==""){
          this.setState({confirmShow: true});
        } 
        else{
            if (this.props.valid == false){
                this.setState({
                    showPopup: !this.state.showPopup
                })
            }  
            else{
                this.props.history.replace({ 
                    pathname: "/giftflow",
                    state:{
                        id: this.props.id
                    }
                
                })
            } 
            
            console.log(this.props.error);
        }
      }
    closeConfirmPopup = (val)=>{
        if (val){
          const payload = {
            "message":'',
            "to": '',
            "from": '',
            "email": '',
            "mobile": '',
            "url": '',
            "giftCountryCode": ''
        }
          this.props.fetchGiftFlowData(payload);
        }
        this.setState({confirmShow: false});
      }
    render() { 
         const{Messages}= this.state
        const {t,countryCode,currancyCode,isGift,quantity,selectedCountry,id, message, card, giftCardValue, from, to, email, mobile, url, giftCountryCode, emailPreviewshowHandler } = this.props;
        const desktopView=(
            <div  className="d-md-block d-none flex-shrink-0" style={{width:'17rem',minWidth:'23rem'}}>
            <div className="buy-now-card w-100">
                <div className="card-body text-black-50">
                     <LoginModal show={this.state.showPopup} close={this.BuyNowWord}/>
            {/* <ConfirmPopup show={this.state.confirmShow} Close={this.closeConfirmPopup} /> */}
            {/* <MainLoginModel show={this.state.showPopup} loginFun={()=>this.setState({showPopup:!this.state.showPopup})} lang={this.props.lang}/> */}
            {/* <Prompt
                message={(location) => {
                  return (location.pathname.startsWith("/sele") || location.pathname.startsWith("/giftflow") || location.pathname.startsWith("/payment/card-details"))? true                
                    : (this.state.leave)?`Are you sure you want to go to ${location.pathname}?`
                    : true
                }}
              /> */}

                    {
                        isGift!==false && 
                        <form onSubmit={(e)=> e.preventDefault()} className='deskForm'>
                    <div className='d-flex gap-2'>
                    {/* <Link
                    to={{
                      pathname: (message!=="")?'':'/giftflow',
                      state:{
                        id: id
                      }
                    }}> */}
                        {/* <input
                            type="checkbox"
                            className=""
                            defaultChecked = {(message==="")?false:true}
                            onClick = {()=> this.deleteGiftFlowData(message)}
                          /> */}
                    {/* </Link> */}
                  {/* <label className="ml-2 giftLabel">{t("Buying a Gift")}</label> */}
                </div>
                    {
                       
                        <div className=''>
                        <span className='drc-title fw-bold fs-5'>{t("Delivery Type")}</span>
                           {
                            this.props.cardType === 'physical' ? 
                            <div className='d-flex align-items-start my-3'>
                                <img src={EmailPng} className='object-fit-contain' alt='email' />
                                <span className='text-black ml-2'>{t("Mailed to the U.S. Postal address that you provide")}</span>
                            </div> : 
                            <>
                            <div className="d-flex mx-n2 py-2">
                                {
                                    ['Email'].map(type=>(
                                        <div className='d-flex ml-2 align-items-center'>
                                            <img src={EmailPng} className='' alt={type} />
                                            <span
                                                key={type}
                                                className={`ml-2 rounded-0 border-0 text-dark  ${this.state.deliveryType===type?'':''}`}
                                                onClick={()=>{this.changeDeliveryType(type)}}
                                                    >
                                                    { card?.name?.includes("Special Olympics") ? t("Donation") : t(type)}
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                           
                            <div className='py-2'>
                            {
                                    this.state.deliveryType==='SMS' && <div className=" my-3">
                        <label>{t("Select a Country")}</label>
                        <select className="arrows dis border w-100"  
                        // value={this.props.countryId} 
                        onChange={(e) => {this.changeCountry(e.target.value)}}
                        // onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                       
                        >
                            {this.props.countryCode && countryCode.map((country, index) => {
                                return <option key={country.id}
                        selected={country.country_name === selectedCountry}
                        value={country.country_name}
                               > {this.props.lang === 'en' ? country.country_name: country.country_name_arabic} </option>
                            })}
                        </select>
                    </div> }
                    {
                        !this.props.card?.name?.includes("Special Olympics") && 
                        <>
                            <div className=''>{t("Your Email")}</div>
                            {
                                this.state.deliveryType==='Email'?
                                <>
                                    <div className="mt-2">
                                        
                                    
                                        <input  
                                            type="email"
                                            value={this.state?.email}
                                            readOnly
                                            disabled
                                            onChange={(e) => this.setState({email: e.target.value,error:{email:""}})}
                                            className='p-2 w-100 buy-now-input'
                                            placeholder={ (this.props?.card?.brand_type === "PAYPAL") ? `${t("Enter your PayPal account email")}` : `${t("Enter Recipient's Email")}`}
                                            name="type"/>
                                            {this.state.error.email ? (
                                                <label className="text-error ml-2">{t(this.state.error.email)}</label>
                                            ) : null}
                                    
                                    </div>
                                    {
                                        this.props?.card?.brand_type === "PAYPAL" &&
                                        <div className='d-flex p-2'>
                                            <span><Info/></span>
                                            <span className='drc-title info-text'>{t("This email must be listed in your PayPal account.")}</span>
                                        </div>
                                    }
                                    {/* <div className='py-2'>
                                    <Dropdown className='customDropdown'>
                                        <lebel className='py-3'>{t('Gift Message')}</lebel>
                            <DropdownToggle  className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0 border mt-1 px-0 bg-white no-outline w-100  my-2 `}>
                                    <span  className={`${this.props.lang === 'ar'?"float-right":""} text-dark float-left px-2  '`}  style={{fontSize:'0.85rem'}}>{t(`${this.state.message}`)}</span>
                                    <span className={`${this.props.lang === 'ar'?"float-left ml-5 px-2 arrow-down-arabic":" float-right arrow-down"}  text-dark  '`} ></span>
                                </DropdownToggle>
                            <DropdownMenu className='mr-5'>    
                                {
                            Messages.map(Template_Message=>(
            
                                    <DropdownItem  style={{fontSize:'0.85rem'}} className=" d-flex overflow-hidden" 
                                    onClick={()=>this.setMessage(Template_Message)}                                     
                                    > 
                                    
                                        {t(Template_Message)} 
                                        </DropdownItem>))
                                        }                                
                            </DropdownMenu>
                </Dropdown>
                                    </div> */}
                                    </>
                                    :
                                    <div className="d-flex p-0 w-100 flex-wrap">
                                        {
                                            this.props.lang=== 'en'?
                                            <>
                                            <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                            <option>
                                            {this.props.PhoneFormat && this.props.PhoneFormat.country_code}</option>
                                        </select>
                                        <input 
                                            required
                                            value={this.state.phno}
                                            onChange={(e) =>{this.setState({phno:e.target.value})}}
                                            className="col-9 py-2 rounded-0 border text-dark numberTag"
                                            type="number" 
                                            onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)}
                                            onWheel={(e) => e.target.blur()}
                                            pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                            name="number"/>
                                        
                                        <div className=' text-left'>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div>
                                        <div style={{display:'none',color:"red"}} class=" desktopAlert alert " role="alert">
                            * {t("Enter a valid mobile number")}
                                </div>
                                            </>
                                            :
                                            <>
                                        
                                        <input 
                                            required
                                            value={this.state.phno}
                                            onChange={(e) =>{this.setState({phno:e.target.value})}}
                                            className="col-9 py-2 rounded-0 border text-dark numberTag"
                                            type="number" 
                                            onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)}
                                            onWheel={(e) => e.target.blur()}
                                            pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                            name="number"/>
                                        <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                            <option>
                                            {this.props.PhoneFormat && this.props.PhoneFormat.country_code}</option>
                                        </select>
                                        <div>{t("Format")}: +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</div>
                                        <div style={{display:'none',color:"red"}} class=" desktopAlert alert " role="alert">
                            * {t("Enter a valid mobile number")}
                                </div>
                                            </>
                                        }
                                    </div>
                            }
                        </>
                    }
                            </div>
                            </>
                            }
                            {
                                this.state.message=== 'Customize message' && 
                            <div className='my-2'>
                                <div>{t("Message")}</div>
                                <textarea 
                                    className="d-block w-100 py-2 my-2 border rounded-0"
                                    onChange={(e)=>{this.setcustomizeMessage(e.target.value)}}
                                    value={this.state.customizeMessage}/>
                                <p>{500-this.state.customizeMessage.length} {t("characters remaining")}</p>
                            </div>
                            }
                        </div>
                       
                    }
                        </form>
                    }
                    
                    {/* {
                        quantity!==false &&
                    <div className=" mb-3">
                        <label>{t("Quantity")}</label>
                        <select  className={`dist buy-now-input w-100 ${this.props.lang === "ar" && "ar-select"}`}
                        onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                        >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                   
                    }            */}
                    <div className="d-flex justify-content-between align-items-center p-2 bg-white mb-3">
                        <span className='text-black'>{t("Total")}:</span>
                        <span className='drc-title fw-bolder' style={{fontSize:'24px'}}>{this.props.currencySymbol} {(this.props.giftCardValue * this.props.quantity)}</span>
                    </div>
                        <div className="d-flex gap-2">
                            <input 
                            data-testid='terms-checkbox'
                                type="checkbox"
                                className="flex-shrink-0 mt-1"
                                onClick={()=>{this.setByNowButton()}} />
                                <label className="text-black ml-2" >{t("I have read and accepted the Reward Terms and Conditions")}</label>
                        </div>
                    <br/>
                    {
                        REACT_APP_ADD_TO_CART === "true" && <button type="button" className="new-address-btn w-100 text-center mt-0 btn-disable" onClick={()=>this.AddToCart(this.props.error)} disabled={this.state.byNowButton}>{t("Add to cart")}</button>
                    } 
                    <button type="button" className={`delivery-order-summary-btn w-100 text-center btn-disable mt-3 `}   onClick={()=>{this.setState({paymentType:'points'},()=>this.BuyNowWord(this.props.error))}} disabled={this.state.byNowButton}>{t("Redeem Now")}</button>
                    {/* Commenting for next release */}
                    
                    {/* <button type="button" className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'creditcard'},()=>this.BuyNowWord(this.props.error))}} disabled={this.checkCreditButton()}>{t("Buy with Credit Card")}</button> */}
                </div>
            </div>
        </div>)
        const mobileView = (
            <div className='d-md-none d-block mb-4 pb-2'>
                  <div className="buy-now-card  pb-4 w-100">
                <div className="card-body text-black-50">
                <LoginModal show={this.state.showPopup} close={this.BuyNowWordMobile}/>
                {/* <MainLoginModel show={this.state.showPopup} loginFun={()=>this.setState({showPopup:!this.state.showPopup})} lang={this.props.lang}/> */}

                {/* {(true)?
                <div className="d-flex justify-content-between"> */}
                {/* <button
                 onClick = {()=> this.deleteGiftFlowData(message)}
                 className={(message)?"color-blue py-2 px-4 bg-white buyself":"bg-color-green text-white py-2 px-4 border-0"}
                >
                  {t("Buying for self")}
                </button> */}
                {/* <Link
                to={{
                  pathname: '/giftflow',
                  state:{
                    id: id
                  }
                }} */}
                {/* <p onClick={()=> this.deleteGiftFlowData(message)} className={(message)?"bg-color-green text-white py-4 px-4 border-0":"color-blue py-2 px-4 bg-white buyself"}>
                  {t("Buying as a gift")}</p> */}
                {/* </Link> */}
            {/* </div>:  
              <div className="d-flex">
                  <input 
                    type="checkbox" 
                    className="" 
                    onClick={(e)=>{this.setGift(e.target.checked)}} />
              <label className="ml-2 mr-2">{t("Buying a Gift")}</label>
              </div> */}
          {/* } */}
          {
              (message)
              ?
              <>
                  <div className="giftcardedit mt-5 px-2 py-4">
                        <Link 
                        to={{
                          pathname: '/giftflow',
                          state:{
                            id: id,
                            GiftMessage: message,
                            GiftFrom: from,
                            GiftTo: to,
                            GiftEmail: email,
                            GiftMobile: mobile,
                            GiftCountryCode: giftCountryCode
                          }
                        }}
                        >
                        <button className="color-blue giftcardeditbutton">{t("Edit")}</button>
                        </Link>
                        <div className="w-100 text-center">
                            <img width="100%" height="auto" src={url} alt="" />
                        </div>
                        <p className="py-3 fs-9 border-bottom">
                          {message}
                        </p>
                        <div className="d-flex align-items-center">
                          <div className="w-50">
                              <img width="100%" height="auto"                                 
                              src={(this.state.productPath==="offer")? card?.image?.medium_rectangle:card?.images?.color?.medium_rectangle}
                              alt="" />
                          </div>
                          <div className={`w-50 ${this.props.lang === "ar" ? "pr-3" : "pl-3"}`}>
                              <p className="fw-bold fs-4">{currancyCode} {giftCardValue}</p>
                          </div>
                        </div>
                  </div>
                  <div>
                      <p onClick={emailPreviewshowHandler} className="w-100 text-right fw-bold"> <ins>{t("Click for the email preview")}</ins></p>
                  </div>
              </>
          : 
          null
            }

                    {
                        isGift!==false && 
                        <form className='mobileform' onSubmit={(e)=> e.preventDefault()}>
                    {/* <div className='d-flex gap-2'>
                        <input 
                            type="checkbox" 
                            className="" 
                            onClick={(e)=>{this.setGift(e.target.checked)}} />
                            <label className="ml-2 mr-2">{t("Buying aa Gift")}</label>
                    </div> */}
                    {
                        this.state.isGift===true?
                        <div className='mt-3 pt-1'>
                           <span className='drc-title fw-bold fs-5'>
                            {t("Delivery Type")}
                           </span>
                           {
                            this.props.cardType === 'physical' ? 
                            <div className='d-flex align-items-start my-3'>
                                <img src={EmailPng} className='object-fit-contain' alt='email' />
                                <span className='text-black ml-2'>{t("Mailed to the U.S. Postal address that you provide")}</span>
                            </div> : 
                            <>
                            <div className="d-flex mx-n2 pt-2 pb-0">
                                {
                                    ['Email'].map(type=>(
                                        <div className='d-flex ml-2 align-items-center'>
                                            <img src={EmailPng} className='' alt={type} />
                                            <span
                                                key={type}
                                                className={`ml-2 mb-0 rounded-0 border-0 text-dark  ${this.state.deliveryType === type ? '' : ' border'}`}
                                                onClick={() => { this.changeDeliveryType(type) }}
                                            >
                                                { card?.name?.includes("Special Olympics") ? t("Donation") : t(type)}
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='mt-3 py-1'>
                            {
                                    this.state.deliveryType==='SMS' && <div className=" mb-3 mt-0">
                        <label>{t("Select a Country")}</label>
                        <select className="arrows dis border w-100"  
                        // value={this.props.countryId} 
                        onChange={(e) => {this.changeCountry(e.target.value)}}
                        >
                            {this.props.countryCode && countryCode.map((country, index) => {
                               return <option key={country.id} 
                               selected={country.country_name === selectedCountry}
                               > {country.country_name} </option>
                            })}
                        </select>
                    </div> }
                    {
                        !this.props.card?.name?.includes("Special Olympics") &&
                        <>
                            <div className=''>{t("Your Email")}</div>
                            {
                                this.state.deliveryType==='Email'?
                                <>
                                    <div className="py-2">
                                        
                                        <input  
                                            type="email"
                                            value={this.state?.email}
                                            readOnly
                                            disabled
                                            onChange={(e) => this.setState({email: e.target.value,error:{email:""}})}
                                            className='p-2 w-100 buy-now-input'
                                            placeholder={ (this.props?.card?.brand_type === "PAYPAL") ? `${t("Enter your PayPal account email")}` : `${t("Enter Recipient's Email")}`}
                                            name="type"/>
                                            {this.state.error.email ? (
                                                <label className="text-error ml-2">{t(this.state.error.email)}</label>
                                            ) : null}
                                    </div>
                                    {
                                        this.props?.card?.brand_type === "PAYPAL" &&
                                        <div className='d-flex p-2'>
                                            <span><Info/></span>
                                            <span className='drc-title info-text'>{t("This email must be listed in your PayPal account.")}</span>
                                        </div>
                                    }
                                    {/* <Dropdown className=' overflow-scroll mt-2 customDropdown '>
                                    <lebel>{t('Gift Message')}</lebel>
                            <DropdownToggle  className={`${this.props.lang === 'ar'?"float-left":""} text-light rounded-0 border mt-1 p-2 bg-white no-outline w-100`}>
                                    <span  className={`${this.props.lang === 'ar'?"float-right":""} text-dark float-left '`}>{t(`${this.state.message}`)}</span>
                                    <span className={`${this.props.lang === 'ar'?"float-left ml-5 px-2 arrow-down-arabic":" float-right arrow-down"}  text-dark  '`} ></span>
                                </DropdownToggle>
                            <DropdownMenu  className=' overflow-auto'>     
                                            {
                                        Messages.map(Template_Message=>(
                                    <DropdownItem  className=' overflow-scroll' 
                                    onClick={()=>this.setMessage(Template_Message)}                                     
                                    > 
                                        {t(Template_Message)} 
                                        </DropdownItem>))
                                        }                                
                        </DropdownMenu>
                </Dropdown> */}
                                    </>
                                    :
                                    <div className="d-flex p-0 w-100 flex-wrap pt-2">
                                        <select className="btn border bg-light rounded-0 col-3 caret px-1" style={{textAlignLast:"center"}}>
                                            <option
                                            
                                        
                                            >{this.props.PhoneFormat && this.props.PhoneFormat.country_code} </option>
                                        </select>
                                        <input 
                                            required
                                            className="col-9 py-2 rounded-0 border text-dark inputTag"
                                            onChange={(e) => this.setState({phno:e.target.value})}
                                            type="number"
                                            value={this.state.phno} 
                                            onInput={(e) => e.target.value = e.target.value.slice(0, this.props.PhoneFormat.phone_max_length)}
                                            pattern="[0-9]{2}-[0-9]{3}-[0-9]{4}"
                                            name="number"/>
                                        <div  className=' px-3 pt-2'>{t("Format")}: <span className='ml-4'> +{this.props.PhoneFormat && this.props.PhoneFormat.country_code} X XXX XXXX</span></div>
                                        <div style={{display:'none',color:"red"}} class=" mobAlert alert " role="alert">
                            * {t("Enter a valid mobile number")}
                                </div>
                                    </div>
                            }
                        </>
                    }
                            </div>
                            </>
                           }
                            {
                                     this.state.message=== 'Customize message' && 
                            <div className='my-2'>
                                <div>{t("Message")}</div>
                                <textarea 
                                    className="d-block w-100 py-2 my-2 border rounded-0"
                                    onChange={(e)=>{this.setcustomizeMessage(e.target.value)}}
                                    value={this.state.customizeMessage}/>
                                <p>{500-this.state.customizeMessage.length} {t("characters remaining")}</p>
                            </div>
                            }
                        </div>
                        :null
                    }
                        </form>
                    } 
                    <div className="d-flex justify-content-between align-items-center p-2 bg-white mb-3">
                        <span className='text-black'>{t("Total")}:</span>
                        <span className='drc-title fw-bolder' style={{fontSize:'24px'}}>{this.props.currencySymbol} {this.props.price}</span>
                    </div>
                    {/* {
                        quantity!==false &&
                    <div className=" my-3 mb-4">
                        <label>{t("Quantity")}</label>
                        <select className="dist buy-now-input w-100" 
                        onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                        >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    }            */}
                        
                        <div className="d-flex  gap-2 mt-3 py-1">
                            <input 
                            data-testid='terms-checkbox'
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setByNowButton()}} />
                                <label className=" ml-2 mr-2" style={{marginTop:"-0.05rem"}}>{t("I have read and accepted the Reward Terms and Conditions")}</label>
                        </div>
                    <br/>
                      {/* Commenting Buy with Points */}
                      {
                        REACT_APP_ADD_TO_CART === "true" && <button type="button" className="new-address-btn w-100 text-center mt-0 btn-disable" onClick={()=>this.AddToCart(this.props.error)} disabled={this.state.byNowButton}>{t("Add to cart")}</button>
                    } 
                    <button type='submit' className="delivery-order-summary-btn  w-100 mt-3 text-center btn-disable"   onClick={()=>{this.setState({paymentType:'points'},()=>{this.BuyNowWordMobile(this.props.error)})}} disabled={this.state.byNowButton}>{t("Redeem Now")}</button>

                    {/* Commenting for next release */}

                    {/* <button type='submit' className="btn btn-block primary-btn rounded-0 text-center"   onClick={()=>{this.setState({paymentType:'creditcard'},()=>{this.BuyNowWordMobile(this.props.error)})}} disabled={this.checkCreditButton()}>{t("Buy with Credit Card")}</button> */}
                </div>
            </div>
                {/* {
                    isGift!==false && 
                <div className="d-flex py-3 gap-2">
                    <input 
                        type="checkbox" 
                        className="" 
                        onClick={(e)=>{this.setGift(e.target.checked)}} />
                        <label className="" >{t("Buying a Gift")}</label>
                </div>
                }                
                <div className="d-flex gap-2">
                    <input 
                        type="checkbox" 
                        onClick={()=>{this.setByNowButton()}} />
                        <label className="" >{t("I have read and accepted the Terms & Conditions")}</label>
                </div>
                <div className='my-5'>
                </div>
                <div className='position-fixed w-100 px-3 py-2 bg-light' style={{bottom:'3.9rem',left:'0'}}>
                    <button type="button" className="col-12 btn btn-block btn-primary rounded-0 text-center " onClick={this.props.onSubmit}  disabled={this.state.byNowButton}>{t("Buy Now")}</button>
                </div> */}
               
            </div>
        )
        return ( 
            <>
                {desktopView}
                {mobileView}
            </>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        countryCode:state.countryCode.countryCode,
        loading:state.countryCode.countryCodeloading,
        pointBalance:state.point.pointBalance,
        PhoneFormat:state.PhoneFormat.PhoneFormat,
        valid:state.auth.valid,
        error:state.auth.error,
        authToken:state.auth.authToken,
        // selectedCountry:state.countries?.countries?.filter(e => e?.id===state?.countries?.countryId)[0]?.country_name,
        message: state.giftFormDetails.message,
        from: state.giftFormDetails.from,
        to: state.giftFormDetails.to,
        email: state.giftFormDetails.email,
        mobile: state.giftFormDetails.mobile,
        url: state.giftFormDetails.url,
        giftCountryCode: state.giftFormDetails.giftCountryCode,
        userEmail : state?.countries?.countries?.email
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchCountyCode : () => dispatch(fetchCountyCode()),
        fetchPhoneFormat : (payload) => dispatch(fetchPhoneFormat(payload)),
        fetchGiftFlowData : (payload) => dispatch(fetchGiftFlowData(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BuyNowCard));