import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
class NotFoundCard extends Component {

    componentDidUpdate(prevProps){
        if(prevProps.countrySelected !== this.props.countrySelected){
            let redirectURL = this.props.location?.redirectionURL || '/';
            this.props.history.replace(redirectURL)
        }
    }
    render() {
        const {t,location} = this.props;
        return (
            
            <div className="container"  >
                <div className="my-5 d-flex flex-column justify-content-center text-center p-5">
                <h4 className='text-dark pb-3'>{location?.state?.errormsg ? `${t(location.state?.errormsg)}` : `${t("There are no items currently available")}. ${t("Please check again later")}.`}</h4>
                <div className='text-center mx-auto p-3 primary-btn text-light' style={{width:"fit-content"}}>
                    <Link  className='d-inline text-center nohover text-white text-decoration-none' to='/'>
                        {`${t("Redeem Rewards")}`}
                    </Link>
                </div>
                </div>
                {/* <div style={{height:'calc(100vh - 575px)'}} className="row d-none d-md-block d-lg-block d-xl-none   my-5  text-center p-5">
                <h4 className='text-dark pb-3'>{location?.state ? `${t(location.state)}` : `${t("There are no items currently available")}. ${t("Please check again later")}.`}</h4>
                <div className='d-inline text-align-center p-3 mb-2 primary-btn text-light'>
                    <Link  className='text-center nohover text-white text-decoration-none' to='/'>
                        {`${t("Go back to homepage")}`}
                    </Link>
                </div>
                </div> */}
            </div>
            
            
        )
    }
}

const mapStateToProps = state => {
    return {
        countrySelected: state.countries.countryCode
    }
}


export default connect(mapStateToProps)(withTranslation()(withRouter(NotFoundCard)))

