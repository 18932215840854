/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShowHeader } from 'Redux/auth/authActions';
import { Link, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const ValidateToken = ({t}) => {
    
    const dispatch = useDispatch()
    const authLoading = useSelector((state)=> state.auth?.loading)
    const authValid = useSelector((state)=> state.auth?.valid)
    const journey = useSelector((state)=> state.tenetReducer?.slug?.journey)
    const history = useHistory()

    useEffect(() => {
        dispatch(ShowHeader(false))
    }, [])

    useEffect(()=>{
        if(!authLoading && authValid){
            if(journey === "SSO"){
                history.replace({ pathname: '/', state:{show: true}})
            }else{
                history.replace("/")
            }
        }
    },[authLoading])
    
  return (
    <div>
        {
            authLoading ?             
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            : !authValid ? <div className='container'>
                <div className="my-5 d-flex flex-column justify-content-center text-center p-5" style={{ height: "80vh" }}>
                    <h2 className='text-uppercase mb-2 fw-bold'>{t("401 Unauthorized")}</h2>
                    <p>{t("Access denied due to invalid credentials")}</p>
                    <Link 
                        to={{pathname:`/`}}
                        className="btn rounded-0 mt-3 mx-auto btn-outline-dark"
                        >
                        {t("Go to homepage")}
                    </Link>
                </div>
            </div> : null
        }
    </div>
    // <div className='d-flex flex-column'>
    //     <input type="text" className='mt-2 mx-3 p-2' value={token} onChange={(e)=>setToken(e.target.value)} />
    //     {
    //         erorr !== "" && <p className='text-danger mx-3'>{erorr}</p>
    //     }
    //     <button className='btn btn-dark w-20 mt-3 mx-3' onClick={validate}>Validate</button>
    // </div>
  )
}

export default withTranslation()(ValidateToken)