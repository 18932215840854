import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  detectURLs(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.split(urlRegex).map((part) => {
      if (part?.match(urlRegex)) {
        return (
          <a href={part} key={part} target="_blank" rel="noreferrer">
            {" "}
            {part}{" "}
          </a>
        );
      } else {
        return part;
      }
    });
  }

  render() {
    const Desktop = () => {
      return (
        <div className="d-none d-md-flex  flex-row">
          <div className="pb-4 px-5 col-11 bg-light-grey p-3 rounded">
            <div>
              <h5 className="model-text-dark"> {t("Description")}</h5>
            </div>
            <div>
              <p className="body-typo">
                {this.props.description &&
                this.props.description?.includes("\n")
                  ? this.props.description
                      ?.split("\n")
                      ?.map((e, index, array) => (
                        // index !== array.length - 1 ?
                        <div key={index}> {this.detectURLs(e)} </div>
                        //  : ''
                      ))
                  : this.props.description &&
                    this.props.description?.length > 0 && (
                      <div>{this.detectURLs(this.props.description)}</div>
                    )}
              </p>
            </div>
          </div>
        </div>
      );
    };

    const Mobile = () => {
      return (
        <div className="d-block d-md-none">
          <div
            className={`sidebar-nav-menu-item mb-2 ${
              this.props.activeCollapse === "Description" ? "item-active" : ""
            }`}
            onClick={() => this.props.handleExpandCollaps("Description")}
            data-id="Description"
          >
            <div className="sidebar-nav-menu-item-head">
              <span className="model-text-dark fs-5">{t("Description")}</span>
            </div>
            <div className="sidebar-nav-menu-item-body px-3">
              <div>
                <p className="body-typo">
                  {this.props.description &&
                  this.props.description?.includes("\n")
                    ? this.props.description
                        ?.split("\n")
                        ?.map((e, index, array) => (
                          // index !== array.length - 1 ?
                          <div key={index}> {this.detectURLs(e)} </div>
                          //  : ''
                        ))
                    : this.props.description &&
                      this.props.description?.length > 0 && (
                        <div>{this.detectURLs(this.props.description)}</div>
                      )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    };
    const { t } = this.props;
    return (
      <>
        <Desktop />
        <Mobile />
      </>
    );
  }
}

export default withTranslation()(Description);
