import React, { Component } from 'react';
import shoesImg from '../../assests/img/Shoes.png'
import iphoneImg from '../../assests/img/Iphone.png'
import dollsImg from '../../assests/img/Dolls.png'
import bagImg from '../../assests/img/Bag.png'
import ProductOffersCard from '../shared/ProductOffersCard';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Breadcrumbs from '../shared/Breadcrumbs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sortOfferCards } from '../../Redux/offers/offerActions';
import { fetchOffers } from '../../Redux/offers/offerActions';
import { fetchBrand } from '../../Redux/brand/brandActions';
import { withTranslation } from 'react-i18next';

class OffersCategory extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            categoryName:false,
            filteredCards:false,
            categories:[],
            data:this.props.offers,
            currentCategory:window.location.pathname.split('/')[2].replace(/%20/ig,' '),
            Bcategories:['Brasino and Bisro','Casual Dinning','Ethinic','Family Style','Fast Food','Fine Dining'],
            brands:['Al Baik','Al Tazaj','Hardes','Harfy','Kudu'],
            establisdhments:['Casual Dining','Food Court'],
            discountProductCards:[
                {product_name:'Pink nubuck sneakers, seasonal shoes for walking and sports',img:shoesImg,brand_name:'Ellese',original_rate:'245',discounted_rate:'213',offer:'15% off',points_rate:'426',expiry_date:'Expires 25 Oct,2020'},
                {product_name:'Apple iPhone XR (64GB,Blue)',img:iphoneImg,brand_name:'Apple',original_rate:'908',discounted_rate:'825',offer:'10% off',points_rate:'1650',expiry_date:'Expires 27 Oct,2020'},
                {product_name:'Kids toy-rattle, blue monkey Rattle for Baby infant toddler',img:dollsImg,brand_name:'Toy Land',original_rate:'22',discounted_rate:'50',offer:'Buy 1 Get 1 Free',points_rate:'660',expiry_date:'Expires 08 Nov,2020'},
                {product_name:'Ben Sherman Nottingham Lightweight Hardside 4-Wheeles',img:bagImg,brand_name:'Mosafer',original_rate:'908',discounted_rate:'825',offer:'With Orders above SAR 100',points_rate:'660',expiry_date:'Expires 10 Nov,2020'},
            ],
            showNav:false,
         }
    }
    componentDidMount(){
        this.fetchOrdersList()
        this.fetchBrandHandler()
        
    }

componentDidUpdate(){
//    if(this.props.countrySelected!== 2){
//        this.props.history.replace("/404")
//    }
   
   if(this.props.categoryName !== this.props.match.params.category && this.props.offers?.length >0){
    this.props.sortOfferCards(this.props.category,"Date",this.props.match.params.category,true);
    this.setState({filteredCards:true,categoryName:this.props.match.params.category});
   }
}
    
    fetchOrdersList(){
        const payload = {
            "offer_campaign": {
                "currency": this.props.countrySelected
            }
        } 
        this.props.fetchOffers(payload)
    }
    fetchBrandHandler = (id) => {
        const payload = {
            "brands": {
                "id":id,
                "currency": this.props.countrySelected
            }
        }
        this.props.fetchBrand(payload)
    }
    toggleNav=(val)=>{
        this.setState(old=>(
            {...old,showNav:val}
        ))
    }
    render() {
        const {t}=this.props 
        const {currentCategory} = this.state
        const breadcrumbs =[
            {name:'Home',url:'/',active:true},
            {name:'Offers',url:'/offers',active:true},
            {name:currentCategory,url:'',acitve:false}
        ] 
        let data = this.props.filteredCards;
       
        if(this.state.filteredCards){
            data = this.props.filteredCards
            
        }
       const desktopview=(
        <div className="d-md-block d-none">
                
                
        <Breadcrumbs crumbs={breadcrumbs}/>
        <div className="d-flex p-5 gap-5">
            <div className='col-2 p-0'>
                <div className='border-bottom fw-bold '>
                <span className='fs-5 headings fw-bold'>{t("Categories")}</span>
                    <div className='text-muted d-flex flex-column gap-2 py-3 position-relative px-4'>
                        <span className='fs-2 fw-bold align-top position-absolute color-skyblue' style={{top:'0',left:'0'}}>
                            <Link to={{pathname:'/offers'}}  onClick={()=>{
                                        this.props.sortOfferCards(this.props.category,"Date","All",true);this.setState({filteredCards:true,categoryName:false});
                                    }}>‹</Link>
                        </span>
                        {
                          
                        <p  className='color-skyblue'
                                             
                        > {t(this.props.categoryName)}</p>
                      
                    }
                        {/* {
                            Bcategories.map(category => <div>{t(category)}</div>) 
                        } */}
                    </div>
                </div>
                 {/* brand categories */}
                {/* <div className='border-bottom fw-bold '>
                    <span className='fs-5 color-blue fw-bold'>{t("Brands")}</span>
                    <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                        {
                            brands.map(brand => (
                                <div className='d-flex align-items-center gap-2'>
                                    <input className='d-inline' type="checkbox" />
                                    <span className=''>{t(brand)}</span>
                                </div>
                            )) 
                        }
                    </div>
                </div> */}
                {/* <div className='border-bottom fw-bold py-4'>
                    <span className='fs-5 color-blue fw-bold'>{`${t("Points")}(SAR)`}</span>
                    <div className='text-muted d-flex gap-2 py-3 align-items-center justify-content-between'>
                        <input type="number" className='p-2 border' style={{width:'5rem'}}/>
                            {t("to")}
                        <input type="number" className='p-2 border' style={{width:'5rem'}}/>
                        <span className="color-skyblue">{t("Go")}</span>
                    </div>
                </div> */}
                {/* <div className='border-bottom fw-bold '>
                    <span className='fs-5 color-blue fw-bold'>{t("Establishment Type")}</span>
                    <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                        {
                            establisdhments.map(establisdhment => (
                                <div className='d-flex align-items-center gap-2'>
                                    <input className='d-inline' type="checkbox" />
                                    <span className=''>{establisdhment}</span>
                                </div>
                            )) 
                        }
                    </div>
                </div> */}
            </div>
            <div>
                <div style={{minWidth:'70vw'}} className='d-flex justify-content-between'>
                    <div className='pb-4'>
                        <span className='fs-3 headings fw-bold'>{t("Kantar Offers")}</span>
                        <span className='fs-5  sub-headings'> - {t(this.props.categoryName)}</span>
                    </div>
                    <div >
                        <span>{t("Sort By")}</span>
                        <Dropdown className='d-inline' >
                            <DropdownToggle style={{width:'8rem'}} className=' rounded-0 mx-3 py-2 px-3 bg-white text-dark'>
                                <span className='color-blue fw-bold float-left'>{t(this.props.sortBy)}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                            <DropdownItem onClick={()=>{this.props.sortOfferCards(this.props.filteredCards? this.props.filteredCards : this.props.offers,"Date",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Date")}</DropdownItem>
                            <DropdownItem onClick={()=>{this.props.sortOfferCards(this.props.filteredCards? this.props.filteredCards : this.props.offers,"Points",this.props.categoryName,false);this.setState({filteredCards:true})}}>{t("Points")}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 py-3">
                    {
                      data &&  data.map( (card,id)=>(
                        
                            <ProductOffersCard  card={card} key={id} lang={this.props.lang}/>
                        ))
                    }
               </div>
            </div>
        </div>
    </div>
       )
       const mobileview=(
        <div className="d-block d-md-none container-fluid px-0 py-3 mb-2"  style={{zIndex:'1500',bottom:'4rem'}}>
        <div className="d-flex">
            <div className="w-100">

            <div className='pb-4 px-4'>
                     <span className='fs-3 headings fw-bold'>{t("Kantar Offers")}</span>
                     <span className='fs-5 sub-headings'> - {t(this.props.categoryName)}</span>          
             </div>
             

            <div className="py-md-4 pt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2 px-3 content-box">
                
            {
                      data &&  data.map( (card,id)=>(
                        
                            <ProductOffersCard card={card} key={id} lang={this.props.lang}/>
                        ))
                    }
        </div>
            </div>
        </div>
    </div>

       )
    //    const filterModal=(
    //        //Brands, Establisment for mobile view
    //     <div className="d-md-none d-block position-fixed bg-light w-100 " style={{zIndex:'1500',bottom:'4rem'}}>
    //     { this.state.showNav &&
    //      <div className="bg-light overflow-auto py-2" style={{zIndex:'1500',maxHeight:'calc(100vh - 6rem)'}}>
    //          <div className="py-5  d-flex justify-content-between" >
    //              <div className="col-2 fs-5 cursor-pointer color-skyblue text-right" onClick={()=>{this.toggleNav(false)}} style={{transform:'rotate(180deg)'}}>      
    //                 <section >➜</section>              
    //             </div>   
    //             <div className=" fs-6 cursor-pointer color-skyblue text-right justify-content-end px-4" onClick={()=>{this.toggleNav(false)}} >      
    //                 <section >{t("Reset")}</section>              
    //             </div>          
    //          </div>
    //                   <div className='text-muted d-flex flex-column gap-3 border-bottom   px-3 mt-0 pt-0'>
    //                   <span className='fs-5 color-blue fw-bold px-3'>{t("Categories")}</span>
    //                   <div className='text-muted d-flex flex-column gap-2 py-3 position-relative px-4 pl-5'>
    //                     <span className='fs-2 fw-bold align-top position-absolute color-skyblue' style={{top:'0',left:'0'}}>
    //                         <Link className="pl-4 color-skyblue" to={{pathname:'/offers'}}>‹</Link>
    //                     </span>
    //                     {
                          
    //                     <p  className='color-skyblue'
                                             
    //                     > {t(this.props.categoryName)}</p>
                      
    //                 }
    //                     {
    //                         Bcategories.map(category => <h6>{t(category)}</h6>) 
    //                     }
    //                 </div>
    //                     </div>
    //                     <div className='border-bottom fw-bold  px-3 py-4 '>
    //                     <span className='fs-5 color-blue fw-bold px-3'>{t("Brands")}</span>
    //                     <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
    //                         {
    //                             brands.map(brand => (
    //                                 <div className='d-flex align-items-center gap-2 pl-4'>
    //                                     <input className='d-inline' type="checkbox" />
    //                                     <span className=''>{brand}</span>
    //                                 </div>
    //                             )) 
    //                         }
    //                     </div>
    //                  </div>
    //                  <div className='border-bottom fw-bold py-4 px-3'>
    //                     <span className='fs-5 color-blue fw-bold px-3'>{t("Price")}(SAR)</span>
    //                     <div className='text-muted d-flex gap-2 py-3 align-items-center px-3 '>
    //                         <input type="number" className='p-2 border' style={{width:'5rem'}}/>
    //                             {t("to")}
    //                         <input type="number" className='p-2 border' style={{width:'5rem'}}/>
    //                         <span className="color-skyblue">{t("Go")}</span>
    //                     </div>
    //                 </div>
    //                 <div className='border-bottom fw-bold px-3 py-4 '>
    //                     <span className='fs-5 color-blue fw-bold px-3'>{t("Establishment Type")}</span>
    //                     <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
    //                         {
    //                             establisdhments.map(establisdhment => (
    //                                 <div className='d-flex align-items-center gap-2 px-3'>
    //                                     <input className='d-inline' type="checkbox" />
    //                                     <span className=''>{establisdhment}</span>
    //                                 </div>
    //                             )) 
    //                         }
    //                     </div>
    //                 </div>
                       
    //                     <div className="px-5 py-3">
    //                     <button type="button" className="btn btn-block btn-primary rounded-0 text-center"onClick={()=>{this.toggleNav(false)}}  >{t("Apply Filters")}</button>
    //                     </div>
    //         </div>
    //     }
        
    //     </div>
    // )
        return ( 
           <>
           {(this.props.loading || this.props.loading2) ? 
                <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </section> :
           <div>
                  {desktopview}  
                {mobileview}
           </div>
    } 
           </>
      
         );
    }
}
const mapStateToProps = state => {
    return {
        offers:state.offers.offersList,
        category:state.offers.category,
        loading:state.offers.loading,
        sortBy:state.offers.sortBy,
        filteredCards: state.offers.filteredCards,
        offerCard: state.brand.brand,
        countrySelected:state.countries.countryId,
        categoryName:state.offers.categoryName,
        loading2:state.countries.loading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchOffers : (payload) => dispatch(fetchOffers(payload)),
        fetchBrand : (payload) => dispatch(fetchBrand(payload)),
        sortOfferCards:(data,sortBy,categoryName,onlyCategory,onlyPointsFilter) => dispatch(sortOfferCards(data,sortBy,categoryName,onlyCategory,onlyPointsFilter))
    }
}

export default connect (mapStateToProps, mapDispatchToProps)(withTranslation() (OffersCategory));