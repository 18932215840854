import React,{Component} from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { currancyToPoints } from '../../utils/pointsConversion';
// import { fetchExperienceCards } from '../../Redux/experience/experienceAction';

class Experience extends Component{
    constructor(props){
        super(props)
        this.state={
            
        }
        
    }

    
    componentDidUpdate(prevProps){
        if(typeof this.state.data === 'undefined' && !this.props.loading){
            this.setState({data:this.props.experienceCards})
        }
        // if((prevProps.currancyCode && (prevProps.currancyCode !== this.props.currancyCode)) ){
        //     this.fetchExperienceCards(this.props.countrySelected)
        // }  
    }
    
    // numberWithCommas = (x) => {
    //     let num = +x;
    //     let y = num.toFixed(0)
    //     return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
    numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  getFirstKeyInPrice = (experience)=>{
    const {t} = this.props;
    if(experience.price_per_users && Object.keys(experience.price_per_users)){
        return <span>{t("From")} <b>{this.numberWithCommas((Object.values(experience.price_per_users)[0]))}</b> {Object.keys(experience.price_per_users)[0] === "1" ? t('Price Per Adult') : `${t('Price for')} ${Object.keys(experience.price_per_users)[0]} ${t('Person')} `} </span>
    }
}
    render() { 
        const {t} = this.props
        const desktopView  =(
            
            <div  className="d-none d-lg-flex row gx-5 px-5 pt-0 w-100 m-0">
       
            <section className="px-md-5 px-3 py-md-4 py-1 w-100">
               
                <div>
                {
                             this.props.experienceCards && <Link className={`text-decoration-none color-skyblue fw-bold  ${this.props.lang === 'en' ? "float-right": "float-left"} d-inline-block p-0 pb-2`}
                             onClick={()=>{window.scrollTo(0, 0)}}
                             to='/experiences'>{`${this.props.lang === 'en' ? "Show All" :  "ترتيب حسب"}`}</Link>
                         }
                <h5 className="headings fw-md-bold fw-bolder fs-md-3 fs-5">{t("Experiences")}</h5>      
               
                <div className="text-black-50 fw-bold ">{t('Explore Best in Experiences 2022')}</div>
                
                </div>
                <div className={`d-none d-lg-block ${this.props.lang === 'ar'?"":"ml-5"} `} >
              
                </div>
               
                <div className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-4 gap-2">
                
                    {this.props.featureExpCards?.length>0 ? this.props.featureExpCards.map((experience, index) => (
                          index < 4?
                            <div className="text-center border-0"  key={experience.id}>
                                <Link className="active p-0 "
                                    to={{
                                        pathname: `/experience/${experience.id}`,
                                        state: {...experience }
                                    }}>
                                    <img className="card-hover experiences-img" src={experience.images?.large_rectangle} alt={experience.name} />
                                </Link>
                                <div className='text-center py-2 product_title fw-md-bold fw-bold'>
                                    {this.props.lang === 'en' ? experience.name : experience.name_arabic}
                                </div>
                                <div className="product_value">
                                {this.getFirstKeyInPrice(experience)}</div>
                            </div>: false
                        )
                    ):
                    false
                    }
                </div>
            </section>
            </div>

        )
        const mobileView = (
             <section className=" d-block d-md-none">
                
            <section className="px-md-5 px-3 mx-auto py-md-4">  
                  {
                  this.props.experienceCards && <Link l className={`text-decoration-none color-skyblue fw-bold mt-2  ${this.props.lang === 'en' ? "float-right": "float-left"} d-inline-block p-0 pb-2`}
                  onClick={()=>{window.scrollTo(0, 0)}}
                  to='/experiences'>{`${this.props.lang === 'en' ? "Show All" :  "ترتيب حسب"}`} </Link>
              }
               </section>
     <section className="px-md-5 px-3 mx-auto py-md-4 py-1">         
     <div>
     <div className="headings fw-md-bold fw-bold fs-md-3 fs-5">{t("Experiences")}</div>
     <div className="text-black-50 fw-bold ">{t('Explore Best in Experiences 2022')}</div>
     </div>
     <div className={`${this.props.lang === 'ar'?"":"ml-5"} `} >
    
     </div>
 
     
     
     <div className="py-md-4 py-2 d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-3 mb-5 " style={{marginBottom:"1.2rem"}}>
     
         {this.props.featureExpCards?.length>0 && this.props.featureExpCards.map((experience, index) => (
             index < 4?
                 <div className="text-center p-0 d-grid" key={index}>
                     <Link className="active p-0 "
                     
                         to={{
                             pathname: `/experience/${experience.id}`,
                             state: {...experience }
                         }}>
                         <img className="card-hover experiences-img" src={experience.images?.large_rectangle} alt={experience.name} />
                     </Link>
                     <div className='text-center product_title fw-md-bold fw-bold'>
                         {this.props.lang === 'en' ? experience.name : experience.name_arabic}
                     </div>
                     <div style={{alignItems:'flex-end',justifySelf:'center'}} className="d-flex flex-row product_value">
                     {this.getFirstKeyInPrice(experience)}
                                         
                                        </div>
                     
                 </div>: false
             )
         )}
     </div>
     </section>
 </section>
        )
        return ( 
        <>
            {
                this.props.loading ? <section>
                <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 ">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        </section> : this.props.featureExpCards?.length>0 && 
        <>
        
        {desktopView}
        {mobileView}
                
                
        </>
            }
        </>
         );
    }
    
}



const mapStateToProps = state => {
    return {
        experienceCards:state.experiences.experienceCards,
        featureExpCards: state.experiences.featuredExpCards,
        loading:state.experiences.loading,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code
    }
}


const mapDispatchToProps = dispatch => {
    return {
        // fetchExperienceCards: (payload) => dispatch(fetchExperienceCards(payload)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Experience));
