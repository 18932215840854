import React, { Component } from "react";
import Breadcrumbs from "../shared/Breadcrumbs";
// import TAndConly from "../shared/T&Conly";
// import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import secretKeyGenerator from "../../utils/serectKeyGenerator";
import axios from "axios";
// import { currancyToPoints } from "../../utils/pointsConversion";
// import { Link } from "react-router-dom";
import Apple_Wallet from "../../assests/img/Apple_Wallet.png";
import i18next from "i18next";
import thumbLogo from "../../assests/img/header/kantarLogo.svg";
import Barcode from "../../assests/img/DRC-Card-Barcode.svg";
import facbookSvg from "../../assests/img/social-media/facebook.svg";
import twitterSvg from "../../assests/img/social-media/twitter.svg";
import instagramSvg from "../../assests/img/social-media/instagram.svg";
import globeSvg from "../../assests/img/social-media/globe.svg";
import phoneIcon from "../../assests/img/Footer/whatsapp.svg";
import { REACT_APP_baseUrl, REACT_APP_baseUrl_V2_V3 } from 'utils/app-constants';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import backButton from "../../assests/img/carret-left-blue.svg";


class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: {},
      toggleTerms: "terms"
    };
  }

  componentDidMount = () => {
    // let urlLang = this.props.location?.search?.split("=")[1];
    // if (urlLang !== this.props.lang) {
    // i18next.changeLanguage(urlLang);
    // this.props.setLang(urlLang);
    // localStorage.setItem("lang", urlLang);
    // }
    const token = this.props.match.params.token;
    const payload = {
      giftcards: {
        giftcard_token: token,
      },
    };
    let URL = this.props.isMultiLang ? REACT_APP_baseUrl_V2_V3 : REACT_APP_baseUrl
    axios
      .post(`${URL}/giftcards/card_by_token`, payload, {
        // headers:{
        //     "Authorization":`Bearer ${this.props.authToken}`
        // },
        params: {
          secret_key: secretKeyGenerator(payload),
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            details: response.data.data.giftcard,
          });
        }
      })
      .catch((error) => {
        // this.setState({paymentFail:true})
      });
    // this.setState({loading:true})
  };
  numberWithCom = (x) => {
    let num = +x;
    let y = num.toFixed(0);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // Detect Hyperlink form Trems
  detectURLs(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text?.split(urlRegex)?.map(part => {
      if (part?.match(urlRegex)) {
        return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
      }
      else {
        return part;
      }
    });
  }
  handleExpandCollaps = (name) => {
    if (this.state.activeCollapse === name) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ activeCollapse: "" });
    } else {
      //To show collapsiable
      this.setState({ activeCollapse: name });
    }
  };
  render() {
    let socialMediaLinks = [
      { id: 'phone', url: 'http://wa.me/971527682403', imgSrc: phoneIcon },
      { id: 'globe', url: "https://www.flexxpay.com", imgSrc: globeSvg },
      { id: 'facebook', url: "https://facebook.com/flexxpay", imgSrc: facbookSvg },
      { id: 'twitter', url: "https://twitter.com/flexxpay", imgSrc: twitterSvg },
      { id: 'instagram', url: "https://www.instagram.com/flexxpay", imgSrc: instagramSvg },
    ]
    const card = this.state.details;
    const { t } = this.props;
    const BreadcrumbLinks = [
      { name:"Home", url: "/", active: true },
      { name:"My Account", url: "/mywallet/giftCards", active: true },
      { name: `£${this.numberWithCom(card.original_value)} ${card.brand_name}`, url: "" }
    ]
    return (
      <>
        {!card.card_image ? (
          <section>
            <div class="d-flex justify-content-center pt-4 pb-3 mt-4 mb-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </section>
        ) : (
          <section>
            <Breadcrumbs crumbs={BreadcrumbLinks} />

            {/* !this.props.loading && */}
            {/* <header className="viewDetails header_footer  py-1 pb-2">
            <div className="app-logo text-center">
              <img
                src={this.props.slug.logo?this.props.slug.logo:thumbLogo}
                id="custom-tenant-logo"
                class=""
                alt=""
                srcSet=""
              />
              </div>
            </header> */}
            <div className="p-md-3 py-3 d-flex flex-md-row flex-column justify-content-center">
              <div className="p-0 d-md-block  text-center">
              <div className="d-flex flex-row align-items-center d-md-none d-block">
              <div
                className="fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props.history.goBack();
                  // this.props.ShowHeader(true)
                }}
              >
                <section
                  style={{
                    transform: `${this.props.lang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`
                    }}
                  >
                    <img src={backButton} alt="" style={{marginTop:'5px'}}/> <span className='body-typo' style={{color:'#005E9A'}}>{t("Back")}</span>
                  </section>
                </section>
              </div>
              </div>
                {card?.emagazine_flag === 1 ? (
                  <span className="body-typo">
                    {" "}
                    <h5>{t("  ")}</h5>
                  </span>
                ) : (
                  // brand_name
                  <div className="mx-5">
                    {" "}
                    <p className="body-typo">
                      {" "}
                      {this.props.lang === "en"
                        ? `${t("Please find below your")} ${card?.brand_name} ${t("Gift Card with a value of")}`
                        : `${t("Please find below your")} ${card?.brand_name} ${t("Gift Card with a value of")}`}
                      <b className="user-details-title fw-bold">
                        {" "}
                        {this.numberWithCom(card.original_value)}{" "}
                        {this.props.lang === "en"
                          ? card?.currency_name
                          : card?.currency_name}
                      </b>{" "}
                    </p>
                    {/* {this.numberWithCommas(currancyToPoints(giftcard_value * quantity))} */}
                  </div>
                )}

                <div className="pt-3 pb-1">
                  <img
                    style={{ width: "250px", borderRadius: '9px' }}
                    className="img-fluid p-0 mx-auto d-block"
                    src={card?.card_image}
                    alt=""
                  />
                  {card?.emagazine_flag === 0 &&
                    card?.third_party_giftcard_url !== null && (
                      <>
                        <div className="pt-3">

                          <Link
                            to={{ pathname: card?.third_party_giftcard_url }}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none pt-1 fs-6 my-account-brand"
                          >
                            <span style={{ color: '#005E9A' }}>{t("Please click to see your Gift Card details")}</span>
                          </Link>

                        </div>
                      </>
                    )}
                  <div className="py-2">
                    {card?.barcode_image ? (
                      <>
                        <img
                          src={`data:image/png;base64,${card?.barcode_image.content}`}
                          alt="barcode"
                        />
                      </>
                    ) : <><img
                      src={Barcode}
                      alt="barcode"
                      style={{ width: 220, height: 120 }}
                    /></>}
                  </div>
                  {
                    card.emagazine_flag !== 1 &&
                    <div className="body-typo">
                      {card.giftcard_number}
                    </div>
                  }
                  <div style={{ color: "#005E9A", fontSize: "16px" }}
                    className="pb-3 pl-2">
                    <span>{t("Expiry Date")} :</span>
                    <span> 2023-08-04</span>
                  </div>
                  <div>
                    <img
                      style={{ width: "180px", height: '55px' }}
                      className="img-fluid p-0 pl-2 mx-auto d-block cursor-pointer"
                      src={Apple_Wallet}
                      alt=" "
                    />
                  </div>

                  {card?.emagazine_flag === 1 && (
                    <button className="border-0 bg-color-skyblue p-2">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="nohover text-white text-decoration-none"
                        href={card?.third_party_giftcard_url}
                      >
                        {t("Please click to view your E-Magazine")}
                      </a>
                    </button>
                  )}
                </div>
                {card?.emagazine_flag === 0 &&
                  card?.third_party_giftcard_url !== null &&
                  card?.giftcard_pin !== null && (
                    <div
                      style={{ color: "#747c91", fontSize: "16px" }}
                      className=""
                    >
                      {t("Pin/Challenge Token")} : {card?.giftcard_pin}
                    </div>
                  )}
                {card?.third_party_giftcard_url == null && (
                  <>
                    <div
                      style={{ color: "#747c91", fontSize: "18px" }}
                      className="pb-3"
                    >
                      {card?.giftcard_number}
                    </div>
                    {card?.giftcard_pin !== null && (
                      <div
                        style={{ color: "#747c91", fontSize: "16px" }}
                        className="pb-3"
                      >
                        {t("Pin/Challenge Token")} : {card?.giftcard_pin}
                      </div>
                    )}
                    {card?.expiration_date == null ? (
                      false
                    ) : (
                      <div
                        style={{ color: "#747c91", fontSize: "16px" }}
                        className="pb-3 pl-2"
                      >
                        {t("Expiry Date")} : {card.expiration_date}
                      </div>
                    )}
                    {card?.emagazine_flag !== 1 &&
                      card?.third_party_giftcard_url === null && (
                        <a href={card?.pkpass_link}>
                          <img
                            style={{ width: "180px" }}
                            className="img-fluid p-0 pl-2 mx-auto d-block cursor-pointer"
                            src={Apple_Wallet}
                            alt=" "
                          />
                        </a>
                      )}
                  </>
                )}
              </div>
            </div>
            {/* <div className="p-4 pb-4 text-wrap d-md-block d-none">
              <div className="d-flex flex-column bg-light-grey border rounded text-wrap">
                <h4 className="fs-4 heading-dark px-3"> {t("Terms")}</h4>
                {
                  <div className="px-3 py-3 " style={{ maxWidth: "700px" }}>
                    {card?.terms && !this.props.isMultiLang &&
                      Object.values(card?.terms)[0]?.map((terms) =>
                        terms?.terms_text
                          ?.split("\n")
                          ?.map((term) => <div>{this.detectURLs(term)}</div>)
                      )}
                    {
                      this.props.isMultiLang && card?.terms && card?.terms?.includes("\n") ? card?.terms?.split('\n')?.map((e, index, array) => (
                        // index !== array.length - 1 ? 
                        <div className="body-typo" key={index}> {`${this.detectURLs(e)}`} </div>
                        // : ''
                      )) :
                        card?.terms && card?.terms?.length > 0 &&
                        <div>{this.detectURLs(card?.terms)}</div>
                    }
                  </div>
                }
              </div>
            </div> */}

            <div className="p-4 pb-4 text-wrap d-md-nonw d-block">
              <div
                className={`sidebar-nav-menu-item ${this.state.activeCollapse === "terms"
                    ? "item-active"
                    : ""
                  }`}
                onClick={() => this.handleExpandCollaps("terms")}
                data-id="terms"
              >
                <div className="sidebar-nav-menu-item-head">
                  <span className="sidebar-nav-menu-item-head-title fw-bolder fs-4" >
                    {t("Terms")}
                  </span>
                </div>
                <div className="sidebar-nav-menu-item-body">
                  <div>
                    <div className="px-3 py-3 " style={{ maxWidth: "700px" }}>
                      {card?.terms && !this.props.isMultiLang &&
                        Object.values(card?.terms)[0]?.map((terms) =>
                          terms?.terms_text
                            ?.split("\n")
                            ?.map((term) => <div>{this.detectURLs(term)}</div>)
                        )}
                      {
                        this.props.isMultiLang && card?.terms && card?.terms?.includes("\n") ? card?.terms?.split('\n')?.map((e, index, array) => (
                          // index !== array.length - 1 ? 
                          <div className="body-typo" key={index}> {`${this.detectURLs(e)}`} </div>
                          // : ''
                        )) :
                          card?.terms && card?.terms?.length > 0 &&
                          <div>{this.detectURLs(card?.terms)}</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <footer className="text-center header_footer">
              {/* <div className="d-flex justify-content-center text-center p-4">
                {socialMediaLinks.map((link) => (
                  <div className="px-2" key={link.id}>
                    <a href={link.url} target="_blank" rel="noreferrer">
                      <img
                        className="img-fluid"
                        style={{ height: "2.0rem" }}
                        src={link.imgSrc}
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div> */}
              {/* <div className="p-4 
              text-white">
                {`${t("© 2022 Kantar ™ All Rights Reserved.")}`}
              </div> */}
            </footer>
          </section>
        )}
        {/* } */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slug: state.tenetReducer?.slug,
    isMultiLang : state.tenetReducer?.slug?.featureFlags?.isMultilingual
  };
};
export default connect(mapStateToProps)(withRouter(withTranslation()(ViewDetails)))