/* eslint-disable no-unused-vars */
import React, { Component } from "react";
// import thumbLogo from '../../../assests/img/header/thumbLogo.png'
import cardImg from "../../../assests/img/card.svg";
import thumbLogo from "../../../assests/img/header/kantarLogo.svg";
import charityLogo from "../../../assests/img/charityLifeLogo.svg";
import ModalWrapper from "./ModalWrapper";
import { Redirect, withRouter } from "react-router-dom";
import {
  fetchOrderDetailsSuccess,
  postOrderCreation,
  removeOrder,
} from "../../../Redux/order/orderActions";
import { connect } from "react-redux";
// import {currancyToPoints, pointsToCurrancy} from '../../../utils/pointsConversion';
import axios from "axios";
import secretKeyGenerator from "../../../utils/serectKeyGenerator";
import FailMessageModal from "./FailMessageModal";
import SuccessfullMessageModal from "./SuccessfulMessageModal"
import ProcessingModal from "./ProcessingModal"
import { withTranslation } from "react-i18next";
import Mada from "../../../assests/img/Mada.png";
import MasterCard from "../../../assests/img/MasterCard.png";
import Visa from "../../../assests/img/Visa.png";
import removeIcon from "../../../assests/img/DRC-Delete.svg";
import { fetchCurrencyConversion } from "../../../Redux/conversion/conversionAction";
import { REACT_APP_baseUrl_V2 } from "utils/app-constants";
import cookie from "react-cookies"
import backButton from "../../../assests/img/carret-left-blue.svg";
import editPng from '../../../assests/img/Edit.png'
import { QUANTITY } from "utils/ConstantData";
import WarningModal from "./WarningModal";




class OrderConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: {
        total: this.props.orders.amount,
        payable_amount: this.props.orders.amount,
      },
      redirectToPayment: false,
      checkoutBtn: false,
      paymentFail: false,
      pending:false,
      errorMsg: " ",
      loading: true,
      convertedCurrency: this.props.orders.amount,
      selectedCurrency: "AED",
      code: [],
      pointsCurrancy: null,
      convertedPointsBalance: null,
      email: "",
      charityFlag: true,
      showCharity:false,
      promoCode:"",
      insufficientBalance:false,
      orderNumber:""
    };
  }

  processOrder = (id) => {
    let payload = {}
    payload = {
      "id": id
    }

    let token = localStorage.getItem('userToken')
    axios.post(`${REACT_APP_baseUrl_V2}/orders/process_order`,
      payload,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }).then(response => {
        if (response.status === 200 && response.data?.code) {
          this.setState({
            pending: false,
            paymentFail: true,
            errorMsg: response.data?.message
          })
        } else {
          const orderStatus = response.data?.data?.status
          if (orderStatus === "CANCELED") {
            this.setState({
              pending: false,
              paymentFail: true,
              loading: false,
              errorMsg: response.data?.message
            })
          }
          else if (orderStatus === "FULLFILLED" || orderStatus === "CONFIRMED" || orderStatus === "APPROVED" || orderStatus === "REVIEW") {
            this.setState({
              pending: false,
              loading: false,
              errorMsg: response.data?.message,
            })
          }
        }
      })
      .catch(error => {
        this.setState({
          pending: false,
          paymentFail: true,
          errorMsg: "Something went wrong!! Please try again!!"
        })
      })
  }

  postOrderHandler() {
    let amount = this.props.GiftCardQuantity * this.props.GiftCardValue
    let amount_points = amount
    this.setState({checkoutBtn:true})
    const { orders, pointBalance } = this.props;
    orders["quantity"] = Number(this.props.GiftCardQuantity)
    let token = localStorage.getItem('userToken')
    if(amount_points > pointBalance){
      this.setState({
        insufficientBalance:true,
        checkoutBtn:false
      })
      return
    } 
    if(orders?.isPhysical && this.props.Address === null){
      alert("Please add delivery address")
      return
    }
    if (Number(pointBalance)>0) {
      let Items = []
      delete orders["recipient"]
      if(orders["isPhysical"]) orders["address_id"] = this.props.Address?.id
      else orders["address_id"] = ""
      delete orders["isPhysical"]
      Items.push(orders)
      const payload = { items: Items };
      this.setState({
        pending:true
    })
        axios
          .post(`${REACT_APP_baseUrl_V2}/orders/create_order_v2`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then((response) => {
            if (response.status === 200 && response.data?.code) {
              this.setState({
                paymentFail: true,
                pending : false,
                errorMsg: response.data?.message,
                checkoutBtn : false
              });
            } else {
              const orderDetails = response?.data?.data;
              this.setState({orderNumber : orderDetails?.id})
              const orderStatus = response.data?.data?.status
              if (orderStatus === "CANCELED") {
                this.setState({
                  pending: false,
                  paymentFail: true,
                  loading: false,
                  errorMsg: response.data?.message
                })
              }
              else if (orderStatus === "FULLFILLED" || orderStatus === "CONFIRMED" || orderStatus === "APPROVED" || orderStatus === "REVIEW") {
                this.setState({
                  pending: false,
                  loading: false,
                  errorMsg: response.data?.message,
                })
              }
            }
          })
          .catch((error) => {
            if(error.response?.data?.message?.includes("Previous order for the same product is in progress") || error.response?.data?.code ==="ERR-0026") return
            this.setState({
              paymentFail: true,
              pending : false,
              errorMsg: "Something went wrong!! Please try again!!",
              checkoutBtn : false
            });
          });
    } else {
      this.setState({
        paymentFail: true,
        errorMsg: "Points Redemption Failed",
        checkoutBtn : false
      });
    }
  }
  setCheckoutBtn(val) {
    this.setState((old) => ({
      checkoutBtn: !old.checkoutBtn,
    }));
  }
  removeOrderhandler = () => {
    this.props.remove(false);
    this.props.removeOrder();
    this.props.shipAddress(false)
  };
  // numberWithCommas = (x) => {
  //     let num = +x;
  //     let y = num.toFixed(0)
  //     return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  numberWithCom = (x) => {
    let num = +x;
    let y = num.toFixed(2);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // currencyConversion = (event) => {
  //   let payload = {
  //     giftcard_units: {
  //       currency: event.target.value,
  //     },
  //   };
  //   this.props.fetchCurrencyConversion(payload);
  //   this.setState({ selectedCurrency: event.target.value });
  // };
  setCharityFlagHandle() {
    this.setState((old) => ({
      charityFlag: !old.charityFlag,
    }));
  }

  componentDidMount(){
    let promoCode =  cookie.load("RedemptionCode")
    this.setState({promoCode:promoCode})
  }
    // componentDidMount() {
  //   let payload;

  //   if (
  //     Object.values(this.props.acceptedCurrencies).some(
  //       (e) => e.unit_name_short === this.props.currancyCode
  //     )
  //   ) {
  //     payload = {
  //       giftcard_units: {
  //         currency: this.props.currancyCode,
  //       },
  //     };
  //     this.setState({ selectedCurrency: this.props.currancyCode });
  //   } else {
  //     payload = {
  //       giftcard_units: {
  //         currency: "AED",
  //       },
  //     };
  //   }

  //   // this.props.fetchCurrencyConversion(payload);
  //   // added email
  //   console.log("did", this?.props?.orders?.contact_email);

  //   this.setState({ email: this?.props?.orders?.contact_email });

  //   // if(Object.values(this.props.acceptedCurrencies).some(e => e.unit_name_short === this.props.currancyCode)){
  //   //     this.setState({convertedCurrency:this.props.orders?.giftcard_value})
  //   // }else{
  //   //     this.setState({convertCur:true,convertCur2:false})
  //   // }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.currencyConversonLoading !== this.props.currencyConversonLoading
  //   ) {
  //     let total = this.state.orderDetails.total;
  //     let missingPoints = (
  //       currancyToPoints(total) - this.props.pointBalance
  //     ).toFixed(2);
  //     let convertedPointsBalance = (
  //       this.props.pointBalance /
  //       this.props.convertedCurrency?.currency_exchange_rate
  //     ).toFixed(2);
  //     if (missingPoints > 0 && !this.props.cardPayment) {
  //       total = pointsToCurrancy(missingPoints);
  //     }
  //     if (this.state.selectedCurrency === this.props.currancyCode) {
  //       // For Available Currencies
  //       const payableAmount = total;
  //       if (this.props.minimum_payable_amount > payableAmount) {
  //         this.setState({
  //           pointsCurrancy: this.props.minimum_payable_amount,
  //           convertedPointsBalance: convertedPointsBalance,
  //         });
  //       } else {
  //         this.setState({
  //           pointsCurrancy: payableAmount,
  //           convertedPointsBalance: convertedPointsBalance,
  //         });
  //       }
  //     } else {
  //       // For other currencies which is not avaible
  //       const payableAmount = otherPointsToCurrancy(total);
  //       const markUp = payableAmount * 0.05 + Number(payableAmount);
  //       if (this.props.minimum_payable_amount > markUp) {
  //         this.setState({
  //           pointsCurrancy: this.props.minimum_payable_amount,
  //           convertedPointsBalance: convertedPointsBalance,
  //         });
  //       } else {
  //         this.setState({
  //           pointsCurrancy: markUp.toFixed(2),
  //           convertedPointsBalance: convertedPointsBalance,
  //         });
  //       }
  //     }
  //   }
  // }
  render() {
    const {
      t,
      card,
      orders,
      currancyCode,
      pointBalance,
      acceptedCurrencies,
      GiftCardMobile,
      GiftCardMobileCode,
      GiftCardEmail,
      GiftCardQuantity,
      GiftCardMessage,
      url,
      cardPayment,
      Address
    } = this.props;

    const {
      checkoutBtn,
      paymentFail,
      errorMsg,
      loading,
      convertedCurrency,
      selectedCurrency,
      pointsCurrancy,
      convertedPointsBalance,
      pending
    } = this.state;
    
    const total = this.state.orderDetails.total;
    // const missingPoints = (currancyToPoints(total) - pointBalance).toFixed(2);
    const desktopOrderSummaryCard = (
      <div className="d-md-block d-none">
        {/* <FailMessageModal
          show={paymentFail}
          lang={this.props.lang}
          Message={errorMsg}
        /> */}
        <SuccessfullMessageModal show={!pending && !paymentFail && !loading} lang={this.props.lang} promoCode={this.state.promoCode} currencySymbol={this.props.currencySymbol} orderNumber={this.state.orderNumber}/>
        <FailMessageModal show = {!pending && paymentFail} lang={this.props.lang} Message={errorMsg}/>
        <ProcessingModal show={pending} lang={this.props.lang}/>
        {
        this.state.insufficientBalance && <WarningModal show={this.state.insufficientBalance} closeModal={() => {this.setState({insufficientBalance:false})}} currencySymbol={this.props.currencySymbol}/>
        }
        <div className="Order-summery-card mb-4" style={{ width: "18rem" }}>
          <div className="card-body">
            <div className="order-summery-heading">
              {t("Order Summary")}:
            </div>
            {/* {(cardPayment || missingPoints > 0) && (
              <div className="d-flex flex-row justify-content-between">
                {this.props.cardPayment || missingPoints > 0 ? (
                  <>
                    <label
                      htmlFor="accCurrency"
                      className="text-black-50 fw-bold col-8 m-0 p-0"
                    >
                      {t("Select Payment Currency")}:
                    </label>
                    <select
                      id="accCurrency"
                      onChange={this.currencyConversion}
                      className="dist border col-4 p-0 m-0 text-center form-control arrow-indicator no-outline"
                    >
                      {acceptedCurrencies?.map((e) => (
                        <option
                          name={e.unit_name_short}
                          selected={
                            e.unit_name_short === this.props.currancyCode
                          }
                          value={e.unit_name_short}
                        >
                          {e.unit_name_short}
                        </option>
                      ))}
                    </select>
                  </>
                ) : null}
              </div>
            )} */}

            <div className="d-flex flex-row justify-content-between py-2">
              {/* <span className="order-summery-body-text">{t("Subtotal")}:</span> */}
              {/* {this.props.currencyConversonLoading && (
                <div class="spinner">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
              )} */}
              
              {/* <span>{this.props.currencySymbol} {this.props.orders?.denomination* this.props.orders?.quantity}</span> */}
              
              {/* {this.props.lang === "en" && cardPayment ? (
                <span className=" product_value fs-large">
                  {" "}
                  {this.numberWithCommas(total)}{" "}
                  <small className="fw-normal ">{t(currancyCode)}</small>
                </span>
              ) : this.props.lang === "ar" && cardPayment ? (
                <span className="product_value fs-large">
                  &nbsp;{t(currancyCode)} {this.numberWithCommas(total)}{" "}
                </span>
              ) : this.props.lang === "ar" && !cardPayment ? (
                <span className="product_value fs-large">
                <small className="fw-normal ">{"\u00A3"}</small>
                  {this.numberWithCommas(currancyToPoints(total))}{" "}
                </span>
              ) : this.props.lang === "en" && !cardPayment ? (
                <span className=" product_value fs-large">
                  {" "}
                  <small className="fw-normal ">{"\u00A3"}</small>
                  {this.numberWithCommas(currancyToPoints(total))}{" "}
                </span>
              ) : null} */}
            </div>
            {
              this.state.showCharity && <>
                  <div className="bg-color-grey p-2 d-flex flex-row justify-content-between  align-items-center">
                    <span>
                      <img
                        src={charityLogo}
                        alt="logo"
                        className="img-fluid mr-md-auto"
                      />
                    </span>
                    <span className="mt-3">
                      <p className="small">
                        Want to donate
                        <br />{this.props.currencySymbol}  5 for charity
                      </p>
                    </span>
                    <span className="text-right">
                      <input
                        type="checkbox"
                        className="flex-shrink-0"
                        onClick={() => {
                          this.setCharityFlagHandle();
                        }}
                      />
                    </span>
                  </div>
                  {!this.state.charityFlag ? (
                    <div className=" d-flex flex-row justify-content-between align-items-center mt-3">
                      <span className="text-black-50 fw-bold">
                        Charity Life Donation
                      </span>
                      <span className="text-black-50 fw-bold">{this.props.currencySymbol} 5</span>
                    </div>
                  ) : null}
              </>
            }

            {/* disabled Points Fucntionalities */}
            {/* <div className="d-flex flex-row justify-content-between py-2">
                            <span className="text-black-50 fw-bold">{t("Available")}:</span>
                            {this.props.lang==='en'?
                            <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(pointBalance)}<small>{'\u00A3'} </small></h5>
                            :
                            <h5 className="text-danger fw-bold m-0 fs-regular"><small> {t("Points")}</small> {this.numberWithCommas(pointBalance)}{'\u00A3'}</h5>
                        }
                        </div> */}
            {/*                         
                         {
                            missingPoints>0 && !this.props.cardPayment &&
                            <>
                            
                            <div className="d-flex flex-row justify-content-between py-2">
                                <span className="text-black-50 fw-bold">{t("Missing Points")}:</span>
                                {
                                   this.props.lang==='en'?
                                   <h5 className="text-danger fw-bold m-0 fs-regular">{this.numberWithCommas(missingPoints)} <small>{t("Points")}</small></h5> 
                                    :
                                    <h5 className="text-danger fw-bold m-0 fs-regular"><small>{t("Points")}</small> {this.numberWithCommas(missingPoints)} </h5>
                               }
                            </div>
                            </>
                            
                        }  */}
            {/* /* Showing Only SAR and Credit Card Image */}
            {/* {(this.props.cardPayment || missingPoints > 0) && (
              <div className="d-flex flex-row py-3">
                <div className="p-0 col-2">
                  <img
                    src={cardImg}
                    alt=""
                    className="img-fluid verticle-center"
                  />
                </div>
                <div className="px-1 col-10">
                  <h5 className="primary-color  fw-bold m-0">
                    {this.numberWithCommas(pointsCurrancy)}{" "}
                    {t(selectedCurrency)}
                  </h5>
                  <small className="text-muted fw-bold">
                    {t("to be paid by Credit Card/MADA")}
                  </small>
                  <br />
                  <img src={Visa} alt="visa" />
                  <img src={MasterCard} alt="maser-card" />
                  <img src={Mada} alt="mada" />
                </div>
              </div>
            )} */}

            {/* <hr className="hr-line" /> */}
            <div className="d-flex flex-row justify-content-between align-itmes-center  bg-white p-2 rounded">
              <h5 className="order-summery-body-text">{t("Total")}:</h5>
              <span className="order-summery-heading">{this.props.currencySymbol} {this.props.price}</span>
              {/* {this.props.lang === "en" &&
              (cardPayment || missingPoints > 0) ? (
                <span className=" product_value fs-large">
                  {this.numberWithCommas(pointsCurrancy)}{" "}
                  <small>{t(selectedCurrency)}</small>
                </span>
              ) : this.props.lang === "ar" &&
                (cardPayment || missingPoints > 0) ? (
                <span className="product_value fs-large">
                  &nbsp;{t(selectedCurrency)}{" "}
                  {this.numberWithCommas(pointsCurrancy)}{" "}
                </span>
              ) : this.props.lang === "ar" && !cardPayment ? (
                <span className="product_value fs-large">
                  &nbsp;{"\u00A3"}{" "}
                  {this.numberWithCommas(currancyToPoints(convertedCurrency))}{" "}
                </span>
              ) : this.props.lang === "en" && !cardPayment ? (
                <span className=" product_value fs-large">
                  {" "}
                  {this.numberWithCommas(
                    currancyToPoints(
                      !this.state.charityFlag
                        ? convertedCurrency + 5
                        : convertedCurrency
                    )
                  )}{" "}
                  <small className="fw-normal ">{"\u00A3"}</small>
                </span>
              ) : null} */}
            </div>
            {/* <div className="d-flex gap-2">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" >{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
            <br />  
            <button className="delivery-order-summary-btn w-100 text-center btn-disable" disabled={checkoutBtn}
              onClick={(e) => { e?.detail === 1 && this.postOrderHandler()}}>{t("Redeem Now")}</button>
            {/* <button
              className="btn btn-block primary-btn text-light rounded-0 text-center"
              onClick={() => {
                this.postOrderHandler();
              }}
              disabled={this.props.currencyConversonLoading}
            >
              {t("Checkout Now")}
              {loading ? (
                <span>
                  {" "}
                  <div class="spinner-border spinner-border-sm " role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <span className="px-2">{t("Checking")} . . .</span>
                </span>
              ) : (
                <span>
                  {this.props.cardPayment || missingPoints > 0
                    ? t("Buy with Credit Card")
                    : t("Redeem Now")}
                </span>
              )}
            </button> */}
          </div>
        </div>
      </div>
    );
    const mobileOrderSummaryCard = (
      <div className="d-md-none d-block flex-shrink-0">
        {/* <FailMessageModal
          show={paymentFail}
          lang={this.props.lang}
          Message={errorMsg}
        /> */}
        <SuccessfullMessageModal show={!pending && !paymentFail && !loading} lang={this.props.lang} promoCode={this.state.promoCode} currencySymbol={this.props.currencySymbol}/>
        <FailMessageModal show = {!pending && paymentFail} lang={this.props.lang} Message={errorMsg}/>
        <ProcessingModal show={pending} lang={this.props.lang}/>
        {
        this.state.insufficientBalance && <WarningModal show={this.state.insufficientBalance} closeModal={() => {this.setState({insufficientBalance:false})}} currencySymbol={this.props.currencySymbol}/>
        }
        {/* <div className="fs-5 fw-bold text-muted py-2">{t("Order Summary")}</div> */}
        {/* <div className="border px-3"> */}
          {/* {(this.props.cardPayment || missingPoints > 0) && (
            <div className="d-flex flex-row justify-content-between align-items-center py-3 border-bottom">
              <label
                htmlFor="accCurrency"
                className="text-black-50 fw-bold col-8 m-0 p-0"
              >
                {t("Select Payment Currency")}:
              </label>
              <select
                id="accCurrency"
                onChange={this.currencyConversion}
                className="dist border col-4 p-0 m-0 text-center form-control arrow-indicator no-outline"
                name=""
              >
                {acceptedCurrencies?.map((e) => (
                  <option
                    selected={e.unit_name_short === this.props.currancyCode}
                    value={e.unit_name_short}
                  >
                    {e.unit_name_short}
                  </option>
                ))}
              </select>
            </div>
          )} */}
          <div className="d-flex flex-row justify-content-between py-3 border-bottom">
            {/* <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
            
            <span>{this.props.currencySymbol} {this.props.orders?.denomination* this.props.orders?.quantity}</span> */}
            {/* {this.props.currencyConversonLoading && (
              <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            )} */}
            {/* {this.props.lang === "en" ? (
              <div className="text-dark fw-bold m-0 fs-5 fw-bold  fs-regular">
                {this.numberWithCommas(currancyToPoints(convertedCurrency))}
                <small>{"\u00A3"}</small>
              </div>
            ) : (
              <div className="text-dark fw-bold m-0 fs-5 fw-bold  fs-regular">
                <small> {"\u00A3"}</small>&nbsp;
                {this.numberWithCommas(currancyToPoints(convertedCurrency))}
              </div>
            )} */}
          {/* </div> */}

          {/* PointsFunctionalities Disabled */}
          {/* <div className="d-flex flex-row justify-content-between py-2">
            <span className="text-black-50 fw-bold">{t("Available")}:</span>
            {this.props.lang === "en" ? (
              <h5 className="text-danger fw-bold m-0 fs-regular">
                {this.numberWithCommas(pointBalance)}
                <small> {t("Points")}</small>
              </h5>
            ) : (
              <h5 className="text-danger fw-bold m-0 fs-regular">
                <small> {t("Points")}</small>{" "}
                {this.numberWithCommas(pointBalance)}
              </h5>
            )}
          </div> */}
          {/* {missingPoints > 0 && !this.props.cardPayment && (
            <>
              <div className="d-flex flex-row justify-content-between  py-3 border-bottom">
                <span className="text-black-50 fw-bold">
                  {t("Missing Points")}:
                </span>
                {this.props.lang === "en" ? (
                  <div className="text-danger fw-bold m-0 fs-5 fw-bold  fs-regular">
                    {this.numberWithCommas(missingPoints)}
                    <small>{t(currancyCode)}</small>
                  </div>
                ) : (
                  <div className="text-danger fw-bold m-0 fs-5 fw-bold  fs-regular">
                    <small>{t(currancyCode)}</small>&nbsp;
                    {this.numberWithCommas(missingPoints)}
                  </div>
                )}
              </div>
            </>
          )} */}
          {
            this.state.showCharity && 
            <>
              <div className="bg-color-grey p-2 d-flex flex-row justify-content-between  align-items-center">
                  <span>
                    <img
                      src={charityLogo}
                      alt="logo"
                      className="img-fluid mr-md-auto"
                    />
                  </span>
                  <span className="mt-3">
                    <p className="small">
                      Want to donate
                      <br /> 5 {this.props.currencySymbol} for charity
                    </p>
                  </span>
                  <span className="text-right">
                    <input
                      type="checkbox"
                      className="flex-shrink-0"
                      onClick={() => {
                        this.setCharityFlagHandle();
                      }}
                    />
                  </span>
                </div>
                {!this.state.charityFlag ? (
                  <div className=" d-flex flex-row justify-content-between align-items-center mt-3">
                    <span className="text-black-50 fw-bold">
                      Charity Life Donation
                    </span>
                    <span className="text-black-50 fw-bold">5{this.props.currencySymbol}</span>
                  </div>
                ) : null}
            </>
          }
          <div className="d-flex flex-row justify-content-between py-3">
            {/* <div className="text-dark fw-bold m-0">{t("Total")}</div>
            <span>{this.props.currencySymbol} {this.props.orders?.denomination* this.props.orders?.quantity}</span> */}
            {/* {this.props.lang === "en" && (cardPayment || missingPoints > 0) ? (
              <span className=" product_value fs-large">
                {" "}
                {this.numberWithCommas(pointsCurrancy)}{" "}
                <small>{t(selectedCurrency)}</small>
              </span>
            ) : this.props.lang === "ar" &&
              (cardPayment || missingPoints > 0) ? (
              <span className="product_value fs-large">
                &nbsp;{t(selectedCurrency)}{" "}
                {this.numberWithCommas(pointsCurrancy)}{" "}
              </span>
            ) : this.props.lang === "ar" && !cardPayment ? (
              <span className="product_value fs-large">
                &nbsp;{"\u00A3"}{" "}
                {this.numberWithCommas(currancyToPoints(convertedCurrency))}{" "}
              </span>
            ) : this.props.lang === "en" && !cardPayment ? (
              <span className=" product_value fs-large">
                {" "}
                {this.numberWithCommas(
                  currancyToPoints(!this.state.charityFlag
                        ? convertedCurrency + 5
                        : convertedCurrency)
                )}{" "}
                <small className="fw-normal ">{"\u00A3"}</small>
              </span>
            ) : null} */}
          </div>

          {/* {(this.props.cardPayment || missingPoints > 0) && (
            <div className="d-flex flex-row py-3 border-bottom">
              <div className="p-0 col-1">
                <img
                  src={cardImg}
                  alt=""
                  className="img-fluid verticle-center"
                />
              </div>
              <div className="px-3 col-10">
                <div className=" primary-color fw-bold m-0 fs-5 fw-bold">
                  {this.numberWithCommas(convertedCurrency)}{" "}
                  {t(selectedCurrency)}{" "}
                </div>
                <small className="text-muted fw-bold">
                  {t("to be paid by Credit Card/MADA")}
                </small>
                <br />
                <img src={Visa} alt="visa" />
                <img src={MasterCard} alt="master card" />
                <img src={Mada} alt="mada" />
              </div>
            </div>
          )} */}
        </div>
        <div className=" w-100 p-2 border buy-now-card">
          {/* <div className="d-flex">
                            <input 
                                type="checkbox"
                                className="flex-shrink-0"
                                onClick={()=>{this.setCheckoutBtn()}} />
                                <label className="" style={{paddingLeft: "1rem",paddingRight:"1rem"}}>{t("I have read and accepted the Terms & Conditions")}.</label>
                        </div> */}
          <br />
          <div className="d-flex flex-row p-2 bg-white rounded justify-content-between mb-3">
          <div className="order-summery-body-text">{t("Total")}:</div>
          <span className="order-summery-heading">{this.props.currencySymbol} {this.props.price}</span>
            </div> 
          <button className="delivery-order-summary-btn w-100 text-center mb-3" disabled={checkoutBtn}
              onClick={(e) => { e?.detail === 1 && this.postOrderHandler()}}>{t("Redeem Now")}</button>
          {/* <button
            onClick={() => {
              this.postOrderHandler();
            }}
            disabled={this.props.currencyConversonLoading}
            className="btn btn-block primary-btn w-100 rounded-0 p-2 text-white"
          >
            {t("Checkout Now")}
            {loading ? (
              <span>
                {" "}
                <div class="spinner-border spinner-border-sm " role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <span className="px-2">{t("Checking")} . . .</span>
              </span>
            ) : (
              <span>
                {this.props.cardPayment || missingPoints > 0
                  ? t("Buy with Credit Card")
                  : t("Checkout Now")}
              </span>
            )}
          </button> */}
        </div>
        {/* <div className="d-md-none d-block m-4 p-2" /> */}
      </div>
    );
    return (
      <>
        {
          this.props.show && <>
            <section className="bg-white w-100 h-100">
              <h2 className="drc-title fw-bolder">{t("Review Order Details")}</h2>
              <div className="d-flex flex-md-row flex-column p-md-5 p-3 gap-md-5 bg-white">
                <div className="flex-grow-1">
                  <div className="d-md-flex shoping-details p-4 border-rad-top">
                    <div className="cart-img mr-4">
                      <img
                        src={card.images && card.images?.color?.medium_rectangle}
                        alt={card?.name}
                        className="img-fluid"
                      />
                    </div>
                    <div className="cart-details d-flex justify-content-between">
                      <div className='d-flex flex-column align-items-between w-100'>
                        <div className='d-flex justify-content-between'>
                          <span className='fw-bold fs-4 '>{card?.name}</span>
                          <div
                            className="border-0 cursor-pointer text-decoration-none fw-bold"
                            onClick={() => this.removeOrderhandler()}
                          >
                            <span className="mx-1">
                              <img className="" src={removeIcon} alt="remove icon" />
                            </span>
                            <span className='text-danger'>
                              {t("Remove")}
                            </span>
                          </div>
                        </div>
                        <div className='d-flex  mt-3'>
                          <div className='custom-margin-right'>
                            <span className='mt-3 text-muted'>{t("Delivery Type")}:</span>
                            <p className='drc-title fw-bold mt-1'>{this.props.cardType === "physical" ? t('Mailing Address') : card?.name?.includes("Special Olympics") ? t('Donation') : t('Email')}</p>
                          </div>
                          <div className='custom-margin-right'>
                            <span className='mt-3 text-muted'> {
                                // this.props.cardType==="physical"?
                                // <label>{t("Number of card")}:</label>:
                                // <label>{t("Quantity")}:</label>
                                <label>{t("Quantity")}:</label>
                            }</span>
                            {/* <p className='drc-title fw-bold mt-1'>{this.props.orders?.quantity}</p> */}
                            <div className=" mb-3 w-50">
                            <select id="ddl-quantity" name="quantity-ddl" className={`dist buy-now-input ${this.props.lang === "ar" && "ar-select"}`}
                            onChange={(e)=>{this.props.setQuantity(e.target.value)}}
                            value={this.props.GiftCardQuantity}
                            >
                            {QUANTITY.map((item, i) => (
                            <option key={i} value={item.value} defaultValue={this.props.GiftCardQuantity}>{item.name}</option>
                             ))
                            }
                           </select>
                        </div>
                          </div>
                          <div className='custom-margin-right'>
                            <span className='mt-3 text-muted'>{t("Total Redeemed")}:</span>
                            <p className='drc-title fw-bold mt-1'>{this.props.currencySymbol} {this.props.GiftCardQuantity*this.props.orders?.denomination}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className='d-md-block d-none p-4 shoping-details-2 border-rad-bottom'>
                    {
                      this.props.cardType === "physical" ? 
                      <div>
                    <div className='bg-white p-3 custom-border-rad'>
                      <table className='w-100'> 
                        <tr className='d-flex mb-3 justify-content-between'>
                          <td className='w-25'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("Full Name")}:</span>
                              <span className='fw-bold'>{Address?.fullName}</span>
                            </div>
                          </td>
                          <td>
                            <div className='d- cursor-pointer ml-3'  
                              onClick={() =>  this.props.shipAddress(false)}>
                              <img src={editPng} alt="edit" />
                              <span className='ml-1 drc-title'>{t("Edit Address")}</span>
                            </div>
                          </td>
                        </tr>
                        <tr className="d-flex mb-3">
                        <td className='mr-5'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("Street Address")}:</span>
                              <span className='fw-bold'>{Address?.line1}</span>
                            </div>
                          </td>
                        <td>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("Apartment No")}:</span>
                              <span className='fw-bold'>{Address?.line2}</span>
                            </div>
                          </td>
                        </tr>
                        <tr className='d-flex mb-3'>
                          <td className='mr-5'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("City")}:</span>
                              <span className='fw-bold'>{Address?.city}</span>
                            </div>
                          </td>
                          <td className='mr-5'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("State")}:</span>
                              <span className='fw-bold'>{Address?.state}</span>
                            </div>
                          </td>
                          <td className='mr-5'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("Zip Code")}:</span>
                              <span className='fw-bold'>{Address?.zip}</span>
                            </div>
                          </td>                          
                          {/* <td className='w-25'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>Phone:</span>
                              <span className='fw-bold'>{Address?.phone}</span>
                            </div>
                          </td> */}
                        </tr>
                      </table>
                    </div> 
                    <div className="mt-3 flex-column shoping-details-2 d-flex w-75">
                            {/* <span className="fw-bold">{t(`Thank you for choosing ${card?.name}`)}</span> */}
                            {/* <span className="mt-2">{card.sla}</span> */}
                            {/* <span className="fw-bold">{t(`Your ${card?.name} will be mailed to the address you have select`)}</span> */}
                            <span className="fw-bold"><span>{t(`Your`)}</span> {t(card?.name)} <span>{t(`will be mailed to the address you have selected.`)}</span></span>
                          </div>
                    </div>:
                        <div>
                          {
                    !card?.name?.includes("Special Olympics") &&
                        <div className='bg-white p-3 custom-border-rad'>
                            <table className='w-100'>
                              <tr className='d-flex mb-3'>
                                <td className='w-25'>
                                  <div className='d-flex flex-column align-items-between w-100'>
                                    <span className='text-muted'>{t("Email")}:</span>
                                    <span className='fw-bold text-nowrap'>{this.props.orders?.recipient?.email}</span>
                                  </div>
                                </td>
                              </tr>
                            </table>
                        </div>
                  }
                          <div className="mt-3 flex-column shoping-details-2 d-flex w-75">
                            <span className="fw-bold">{t(`Thank you for choosing`)} {t(card?.name)}.</span>
                            {/* <span className="mt-2">{card.sla}</span> */}
                          </div>
                        </div>
                  }
                  </div>
                  <div className='d-md-none d-block p-4 shoping-details-2 border-rad-bottom'>
                    {
                      this.props.cardType === "physical" ? 
                      <div>
                        <div className='bg-white p-3 custom-border-rad'>
                                <div className='d-flex flex-column align-items-between w-100 mb-2'>
                                  <div className='d-flex justify-content-between' style={{marginBottom:"-0.5rem"}}>
                                  <span className="text-muted">{t("Full Name")}:</span>
                                  <span> 
                                  <div className='d- cursor-pointer'  
                                       onClick={() =>  this.props.shipAddress(false)}>
                                       <img src={editPng} alt="edit" />
                                  </div>
                                  </span>
                                  </div>
                                  <span className='fw-bold'>{Address?.fullName}</span>
                                </div>

                                <div className='d-flex flex-column align-items-between w-100 mb-2'>
                                  <span className='text-muted'>{t("Street Address")}:</span>
                                  <span className='fw-bold'>{Address?.line1}</span>
                                </div>
                                <div className='d-flex flex-column align-items-between w-100 mb-2'>
                                  <span className='text-muted'>{t("Apartment No")}:</span>
                                  <span className='fw-bold'>{Address?.line2}</span>
                                </div>
                              
                                <div className='d-flex flex-column align-items-between w-100 mb-2'>
                                  <span className='text-muted'>{t("City")}:</span>
                                  <span className='fw-bold'>{Address?.city}</span>
                                </div>

                                <div className='d-flex flex-column align-items-between w-100 mb-2'>
                                  <span className='text-muted'>{t("State")}:</span>
                                  <span className='fw-bold'>{Address?.state}</span>
                                </div>
                              
                                <div className='d-flex flex-column align-items-between w-100 mb-2'>
                                  <span className='text-muted'>{t("Zip Code")}:</span>
                                  <span className='fw-bold'>{Address?.zip}</span>
                                </div>
                              
                                {/* <div className='d-flex flex-column align-items-between w-100'>
                                  <span className='text-muted'>Phone:</span>
                                  <span className='fw-bold'>{Address?.phone}</span>
                                </div> */}
                            
                        </div>
                        <div className="mt-3 flex-column shoping-details-2 d-flex w-75">
                            {/* <span className="fw-bold">{t(`Thank you for choosing ${card?.name}`)}</span> */}
                            {/* <span className="mt-2">{card.sla}</span> */}
                            {/* <span className="fw-bold">{t(`Your ${card?.name} will be mailed to the address you have select`)}</span> */}
                            <span className="fw-bold"><span>{t(`Your`)}</span> {t(card?.name)} <span>{t(`will be mailed to the address you have selected.`)}</span></span>
                          </div> 
                        </div>
                        : 
                        <div>
                          {
                    !card?.name?.includes("Special Olympics") &&
                          <div className='bg-white p-3 custom-border-rad'>
                            <div className='d-flex flex-column align-items-between w-100'>
                              <span className='text-muted'>{t("Email")}:</span>
                              <span className='fw-bold'>{this.props.orders?.recipient?.email}</span>
                            </div>
                          </div>
                  }
                        <div className="d-md-none mt-3 flex-column shoping-details-2 d-block w-75">
                            {/* <span className="fw-bold">{t(`You will receive your ${card?.name} directly to the Mailing address you have provided`)}</span>   <br /> */}
                            <span><span>{t(`Your`)}</span> {t(card?.name)} <span>{t(`will be mailed to the address you have selected.`)}</span></span><br/>
                            {/* {card.sla} */}
                          </div>
                        </div>
                    }
                  </div>
                </div>
                <div>
                  {desktopOrderSummaryCard}
                    {mobileOrderSummaryCard}
                </div>
              </div>
              <div className="my-4 d-md-block d-none pl-5">
            </div>
              {/* <div className="d-flex flex-md-row flex-column p-md-5 p-3 gap-md-5 bg-white">
                <div className="flex-grow-1 d-flex align-self-start border-md-1 border-none p-md-3 p-0">
                  <div className="col-3 p-md-2 p-0" style={{ minWidth: "8rem" }}>
                    <img
                      className="img-fluid"
                      src={card.images && card.images?.color?.medium_rectangle}
                      alt="giftCard"
                    />
                  </div>
                  <div className=" px-3 flex-grow-1 ">
                    <div className=" product_title fs-md-3 fs-5 ">
                      {card.name}
                    </div>

                    <div className="pb-2 fw-bold">
                  
                      <div className="pt-2 fw-bold">
                        <img src="" alt="" />
      
                          <span className="sunHeadings fw-bold">
                            {" "}{this.props.currencySymbol}
                            {this.props.orders?.denomination} *{" "}
                            {this.props.orders?.quantity} {t("Quantity")}{" "}
                          </span>
                      </div>
                      <hr />

                        <div className="d-flex w-100">
                          <div className="d-flex flex-row justify-content-start align-items-center gap-3">
                            <span>{t('Delivery Type')}: </span>
                            <span className="fw-bold">{t('Email')}</span>
                            <span>
                              <div className="border text-muted d-md-block d-none p-1">
                                {this.props.orders?.recipient?.email}
                              </div>
                              <div className="py-2 d-md-none d-block">
                                    <input
                                      type="text"
                                      readOnly
                                      value={this.props.orders?.recipient?.email}
                                      className="p-2 w-100 border rounded-0 text-dark"
                                    /> 
                              </div>
                            </span>
                          </div>
                        </div>
                    </div>

                   
                    <div className="d-flex align-items-start justify-content-md-between justify-content-end">
                      <span className="d-md-flex flex-column d-none w-75">
                        <span className="fw-bold">{t('You will receive your eGift card directly to the email address you have provided,')}</span>   
                        {card.sla}
                      </span>
                      <button
                        className="border-0 bg-white text-decoration-none color-skyblue fw-bold"
                        onClick={() => this.removeOrderhandler()}
                      >
                        <span className="mx-1">
                          <img className="" src={removeIcon} alt="remove icon" />
                        </span>
                        {t("Remove")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-md-none d-block mt-3">
                  <span className="fw-bold">{t('You will receive your eGift card directly to the email address you have provided,')}</span> 
                  <br />  
                  {card.sla}
                </div>
                {desktopOrderSummaryCard}
                {mobileOrderSummaryCard}
              </div> */}
            </section>
          </>
        }
        
          </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orderDetails: state.order.orderDetails,
    orderPosted: !state.order.loading,
    pointBalance: state.point.pointBalance,
    errorFlag: state.order.errorFlag,
    authToken: state.auth.authToken,
    currancyCode: state.countries.convertionRate.currency_code,
    convertedCurrency: state.conversion.convertedValue,
    acceptedCurrencies: state.conversion.paymentCurrencies,
    currencyConversonLoading: state.conversion.loading,
    minimum_payable_amount:
      state.conversion?.convertedValue?.minimum_payable_amount,
    valid: state.auth.valid,
    slug : state.tenetReducer?.slug?.slug,
    countryCode : state.countries?.countryCode,
    logo:state.tenetReducer?.slug,
    Address : state?.addressReducer?.selectedAddress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postOrderCreation: (payload) => dispatch(postOrderCreation(payload)),
    removeOrder: () => dispatch(removeOrder()),
    fetchOrderDetailsSuccess: (data) =>
      dispatch(fetchOrderDetailsSuccess(data)),
    fetchCurrencyConversion: (payload) =>
      dispatch(fetchCurrencyConversion(payload)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(OrderConfirmationModal))
);
