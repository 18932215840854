import store from "../Redux/store"

export const currancyToPoints = (currancy) =>{
    const {convertionRate:{currency_exchange_rate,point_conversion_rate}} = store.getState().countries
    let points = (currancy * currency_exchange_rate)/point_conversion_rate
    if(isNaN(points))
        return 0
    else
        return points.toFixed(2)
}
export const pointsToCurrancy = (points) => {
    const {convertionRate:{currency_exchange_rate,point_conversion_rate}} = store.getState().countries
    let rate = (points * point_conversion_rate)/currency_exchange_rate
    if(isNaN(rate))
        return 0
    else
        return rate.toFixed(2)
}
// It is for BuyNow Card to get Specific Countries conversion rates
export const pointsToCurrancyCreditCountries = (points) => {
    const {convertedValue:{currency_exchange_rate,point_conversion_rate}} = store.getState().conversion
    let rate = (points * point_conversion_rate)/currency_exchange_rate
    if(isNaN(rate))
        return 0
    else
        return rate.toFixed(2)
}

// Converting Points into payable amount for currencies not available in BuyNow card
export const otherPointsToCurrancy = (points) => {
    const convertedPoints = currancyToPoints(points)
    const {convertedValue:{currency_exchange_rate}} = store.getState().conversion
    let rate = convertedPoints / currency_exchange_rate
    if(isNaN(rate))
        return 0
    else
        return rate.toFixed(2)
}

export const numberWithCommas = (x) => {
    if(typeof x === 'number'){
        let y = x.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else return 0;
}


export const cacheHeaders = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }
    }
  }
