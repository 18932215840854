import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SearchIcon from "../../assests/img/Union.png";
import getSymbolFromCurrency from "currency-symbol-map";
import cardDetailsLink from "../../assests/img/Frame392.png";
import arrow from "../../assests/img/Arrow.png";
import arrowUp from "../../assests/img/ArrowUp.svg";
import arrowDown from "../../assests/img/ArrowDown.svg";

class ProductTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOrders: [],
      random: [],
      searchInp: "",
      sortOrder: {
        date: "desc",
        description: "",
      },
    };
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }),
      800
    );
  }
  componentDidMount() {
    this.setState({
      filterOrders: this.props?.rows,
    });
    this.sortOrders("Date", "desc");
  }
  sortOrders(name, type) {
    if (name === "Description") {
      this.setState({
        sortOrder: {
          ...this.state.sortOrder,
          description: type,
          date: "",
        },
      });
      if (type === "asc") {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = item1.brand.name.toUpperCase();
          const name2 = item2.brand.name.toUpperCase();
          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });
      } else {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = item1.brand.name.toUpperCase();
          const name2 = item2.brand.name.toUpperCase();
          if (name1 > name2) {
            return -1;
          }
          if (name1 < name2) {
            return 1;
          }
          return 0;
        });
      }
    } else {
      this.setState({
        sortOrder: {
          ...this.state.sortOrder,
          date: type,
          description: "",
        },
      });
      if (type === "asc") {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = moment(item1.createdAt).valueOf();
          const name2 = moment(item2.createdAt).valueOf();
          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
          return 0;
        });
      } else {
        this.state?.filterOrders?.sort((item1, item2) => {
          const name1 = moment(item1.createdAt).valueOf();
          const name2 = moment(item2.createdAt).valueOf();
          if (name1 > name2) {
            return -1;
          }
          if (name1 < name2) {
            return 1;
          }
          return 0;
        });
      }
    }
  }
  render() {
    const { t } = this.props;
    let lang = this.props.i18n.language;
    const mapOrders = this.state?.filterOrders
      ?.filter((item) => {
        return this.state?.searchInp.toLowerCase() === ""
          ? item
          : (item?.brand?.name)
              .toLowerCase()
              .includes(this.state?.searchInp.toLowerCase());
      })
      ?.map((val, ind) => {
        return (
          <>
            <div className="card border-0">
              <div
                className={`my-acc-headings ${
                  ind % 2 !== 0 ? "my-wallet-card-bg" : "bg-white"
                }`}
                id="headingOne"
              >
                <h2 className="mb-0 d-flex align-items-center">
                  <button
                    className="btn btn-block btn-accordion text-left border-0"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#${val?.id}`}
                    aria-expanded="false"
                    aria-controls={val?.id}
                  >
                    <div className="row">
                      <div>
                        <span>
                          <img className="arrow-img" src={arrow} alt="product-img" />
                        </span>
                        <span className="mx-1">
                          {moment(val?.createdAt * 1000).format("MM/DD/YYYY")}
                        </span>
                        <span className="mr-4">
                          {moment(val.createdAt * 1000).format("hh:mm:ss A")}
                        </span>
                      </div>
                      <span className="col">{val?.brand?.name}</span>
                      <div className="col">
                        <Link to={"#"}>
                          <span className="drc-title text-underline">
                            {val?.orderID}
                          </span>
                        </Link>
                      </div>
                      <span className="col">
                        {" "}
                        {val?.brand?.variety?.denomination * val?.quantity}
                      </span>
                      <span className="col m-auto" id="status">
                        {t((val?.status_label).toLowerCase())}
                      </span>
                    </div>
                  </button>
                </h2>
              </div>

              <div
                id={val?.id}
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div
                  className={`card-body border-top ${
                    ind % 2 !== 0 ? "my-wallet-card-bg" : "bg-white"
                  }`}
                >
                  <div className="d-md-block d-none">
                    <table className="table table-borderless">
                      <tbody>
                        <div
                          className={`p-3 my-account-item ${
                            ind % 2 === 0 ? "my-wallet-card-bg" : "bg-white"
                          }`}
                        >
                          <tr
                            className="fw-bold text-black-50 d-flex"
                            //   key={row.id}
                          >
                            <td className="px-1 py-1">
                              <div
                                className=" bg-color-gray d-flex align-items-center justify-content-center"
                                style={{ width: "13rem" }}
                              >
                                <img
                                  className="d-block img-fluid"
                                  style={{
                                    maxHeight: "11rem",
                                  }}
                                  src={val?.brand?.variety?.imageURL}
                                  alt="img"
                                />
                              </div>
                            </td>
                            <td width="100%">
                              <table width="100%" className="">
                                <tr className="">
                                  <td width="100%" className="py-0">
                                    <div className="">
                                      <h5 className="m-0 fw-bold product_title">
                                        {val?.brand?.name}
                                      </h5>
                                      {/* <div className="pt-1 fs-6 fw-normal drc-title">
                                        {" "}
                                        {t("by")} {val?.brand?.name}
                                      </div> */}
                                    </div>
                                  </td>
                                  {/* <td>
                                                                                    <button className="status-btn">
                                                                                        {t(val?.status)}
                                                                                    </button>
                                                                                </td> */}
                                </tr>
                                <tr>
                                  <td className="px-1">
                                    <table width="100%">
                                      <tr className="">
                                        {/* <td>
                                                                                                <div className="d-flex flex-column">
                                                                                                    <span className="fw-normal">
                                                                                                        {`${t("Order ID")}:`}{" "}
                                                                                                    </span>
                                                                                                    <span className="color-skyblue">
                                                                                                        {val?.id}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td> */}
                                        <td>
                                          <div className="d-flex flex-column">
                                            <span className="fw-normal">
                                              {` ${val?.brand?.name?.toLowerCase().includes("paypal") ? t("Number of Credits") : (val?.brand?.name.includes("Special Olympics") ? t('Number of Donations') : t("Number of cards"))}:`}{" "}
                                            </span>
                                            <span className="color-skyblue">
                                              {val?.quantity}
                                            </span>
                                          </div>
                                        </td>
                                        {/* <td>
                                                                                                {this.props?.featureFlags?.displayPoints &&
                                                                                                    <div className="d-flex flex-column">
                                                                                                        <span className="fw-normal">
                                                                                                            {`${t("Points Redeemed")}:`}{" "}
                                                                                                        </span>
                                                                                                        <span className="color-skyblue">
                                                                                                            {(val?.use_points)}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                }
                                                                                            </td> */}
                                        {/* <td>
                        <div className="d-flex flex-column">
                            <span className="fw-normal">
                            {`${t("Points Redeemed")}:`}{" "}
                            </span>
                            <span className="color-skyblue">
                            20 Points
                            </span>
                        </div>
                        </td> */}
                                        {/* </tr>
                                                                                        <tr> */}
                                        {/* <td>
                                                                                                <div className="d-flex flex-column">
                                                                                                    <span className="fw-normal">
                                                                                                        {`${t("Order Date")}:`}{" "}
                                                                                                    </span>
                                                                                                    <span className="color-skyblue">
                                                                                                        {this.props.slug?.slug ===
                                                                                                            "kantar-bav"
                                                                                                            ? moment(val?.brand?.createdAt).format(
                                                                                                                "MM/DD/YYYY"
                                                                                                            )
                                                                                                            : moment(val?.brand?.createdAt).format(
                                                                                                                "DD/MM/YYYY"
                                                                                                            )}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td> */}
                                        {/* <td>
                                                                                                <div className="d-flex flex-column ">
                                                                                                    <span className="fw-normal">
                                                                                                        {t("Product Value")}:{" "}
                                                                                                    </span>
                                                                                                    <span className="color-skyblue fw-bold">
                                                                                                        {val?.brand?.variety?.denomination}{" "}
                                                                                                        {val?.brand?.variety?.currency}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td> */}
                                        <td>
                                         {!val?.brand?.name.includes("Special Olympics") && <div className="d-flex flex-column ">
                                            <span className="fw-normal">
                                              {val?.address === null
                                                ? t("Email")
                                                : t("Mailing Address")}
                                            </span>
                                            <span className="color-skyblue fw-bold">
                                              {val?.address === null ? (
                                                val?.recipient?.email
                                              ) : (
                                                <div className="d-flex flex-column">
                                                  <span>
                                                    {val?.address?.fullName}
                                                  </span>
                                                  <span>
                                                    {val?.address?.line1}{val?.address?.line2 && `, ${val?.address?.line2}`}
                                                  </span>
                                                  <span>
                                                    {val?.address?.city},&nbsp;{val?.address?.state}&ensp;{val?.address?.zip}
                                                  </span>
                                                </div>
                                              )}
                                            </span>
                                          </div>}
                                        </td>
                                        {/* <td>
                                          {val?.status === "FULFILLED" && (
                                            <div className="d-flex py-2 flex-column">
                                              <span className="fw-normal">
                                                {`${t("Card Details")}:`}{" "}
                                              </span>
                                              <div className="my-account-brand d-flex">
                                                {val?.giftCardTokens?.map(
                                                  (token, ind2) => {
                                                    return (
                                                      <Link
                                                        style={{
                                                          textDecoration:
                                                            "none",
                                                        }}
                                                        to={`/giftcard/${token}/details?lang=${lang}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="wallet-link text-nowrap mr-4"
                                                      >
                                                        <div className="d-flex align-items-center justify-content-between my-account-brand">
                                                          <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                                                            <h6
                                                              className="m-0 fw-bold "
                                                              style={{
                                                                fontSize:
                                                                  "11px",
                                                              }}
                                                            >
                                                              {t("View")}{" "}
                                                              {ind2 + 1}
                                                            </h6>
                                                          </button>
                                                        </div>
                                                      </Link>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          )} 
                                          {(val?.status === "DELIVERED" ||
                                            val?.status === "SHIPPED") && (
                                            <div className="d-flex py-2 flex-column">
                                              <span className="fw-normal">
                                                {`${t("Track Order")}:`}{" "}
                                              </span>
                                              <div className="my-account-brand d-flex">
                                                <Link
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                  to={{pathname:val?.shipment_tracking_url}}
                                                  target="_blank" rel="noopener noreferrer"
                                                  className="wallet-link text-nowrap mr-4"
                                                >
                                                  <div className="d-flex align-items-center justify-content-between my-account-brand">
                                                    <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                                                      <h6
                                                        className="m-0 fw-bold "
                                                        style={{
                                                          fontSize: "11px",
                                                        }}
                                                      >
                                                        {t("View")}
                                                      </h6>
                                                    </button>
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>
                                          )}
                                        </td> */}
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                {/* {
                                  (val?.status === "DELIVERED" ||
                                  val?.status === "SHIPPED") && 
                                    <tr className="px-1">
                                      <td className="p-0">
                                        <div>
                                          <span className="fw-normal">{t("Tracking Id")}:{" "}</span>
                                        </div>
                                        <div>
                                          <span className="fw-bold color-skyblue">{val?.shipment_tracking_id}</span>
                                        </div>
                                      </td>
                                    </tr>
                                } */}
                              </table>
                            </td>
                          </tr>
                        </div>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    const linkCards = (type, row) => {
      if (type === "GiftCards") {
        return (
          <>
            {/* <Link style={{textDecoration:"none"}}
                                to={{
                                   pathname:`/giftcard/${row.giftcard_tokens[0]}/details?lang=${lang}`,
                                   state: {...row }
                               }}
                                target="_blank"
                                rel="noreferrer"
                                >
                                      <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>0{row?.quantity}</p>
                                        <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"11px"}}>{t("View")}</h6>
                                </button> </td> 
                    </Link>
                    */}

            {row.giftcard_tokens.map((token, index) => {
              return (
                <>
                  {
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname: `/giftcard/${token}/details?lang=${lang}`,
                        state: { ...row },
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <td className="px-1 py-4 align-bottom  ">
                        <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                          <p className="m-0" style={{ fontSize: "15px" }}>
                            0{index + 1}
                          </p>
                          <h6
                            className="m-0 fw-bold color-skyblue"
                            style={{ fontSize: "11px" }}
                          >
                            {t("View")}
                          </h6>
                        </button>{" "}
                      </td>
                    </Link>
                  }
                </>
              );
            })}
            {/* {
                               row?.isgift === false?
                               <Link
                               to={{
                                  pathname:`/giftcard/${row.giftcard_token}/details?lang=${lang}`,
                                  state: {...row }
                              }}
                            
                              ><td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                              <p className='m-0'  style={{fontSize:"15px"}}>0{index+1}</p>
                          <h6 className='m-0 fw-bold primary-color'  style={{fontSize:"12px"}}>{t("View")}</h6>
                  </button> </td></Link>
                              :
                              false
                           } */}
          </>
        );
      } else if (type === "Offers") {
        if (row.vouchers === null) {
          return;
        } else
          return (
            <>
              {row.is_gift === false &&
                row.vouchers.map((element, index) => {
                  return (
                    <a
                      style={{ textDecoration: "none" }}
                      href={element.offer_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <td className="px-1 py-4 align-bottom  ">
                        <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                          <p className="m-0" style={{ fontSize: "15px" }}>
                            0{index + 1}
                          </p>
                          <h6
                            className="m-0 fw-bold color-skyblue"
                            style={{ fontSize: "11px" }}
                          >
                            {t("View")}
                          </h6>
                        </button>{" "}
                      </td>
                    </a>
                  );
                })}
            </>
            //             // <>
            //             //   {
            //             //      row.vouchers.map((element)=>{
            //             //     return(
            //             //      <a style={{textDecoration:"none"}}
            //             //      href= {element.offer_url}
            //             //      target="_blank"
            //             //      rel="noreferrer"
            //             //      >
            //             //         <td className="px-0 py-4 align-bottom"><u className="color-skyblue text-decoration-underline">{t("view details")}</u></td>
            //             //      </a>
            //             //     )
            //             //       })
            //             // }
            //             // </>

            //          //   <Link
            //          //   to={{pathname:row.offer_url}}
            //          //   target="_blank" rel="noopener noreferrer"
            //          //   >
            //          //   <td className="px-0 py-4 align-bottom"><u className="color-skyblue text-decoration-underline">{t("view details")}</u></td>
            //          //   </Link>
          );
      } else if (type === "Merchandise") {
        return (
          <Link
            to={{ pathname: row.shipment_tracking_url }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <td className="px-1 py-4 align-bottom  ">
              <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                <p className="m-0" style={{ fontSize: "15px" }}>
                  01
                </p>
                <h6
                  className="m-0 fw-bold mb-1 primary-color"
                  style={{ fontSize: "12px" }}
                >
                  {t("View")}
                </h6>
              </button>{" "}
            </td>
          </Link>
        );
      } else if (type === "Experience") {
        return (
          row.experience_url && (
            <Link
              to={{ pathname: row.experience_url }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <td className="px-1 py-4 align-bottom  ">
                <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                  <p className="m-0" style={{ fontSize: "15px" }}>
                    01
                  </p>
                  <h6
                    className="m-0 fw-bold mb-1 primary-color"
                    style={{ fontSize: "12px" }}
                  >
                    {t("View")}
                  </h6>
                </button>{" "}
              </td>
            </Link>
          )
        );
      } else if (type === "Software&Games") {
        return (
          <Link
            style={{ textDecoration: "none" }}
            to={{
              pathname: `/softwareGames/${row.id}/details?lang=${lang}`,
              state: { data: row },
            }}
            target="_blank"
            rel="noreferrer"
          >
            <td className="px-1 py-4 align-bottom  ">
              <button className="bg-light-gray border-0 px-3  fw-bold view-button">
                <p className="m-0" style={{ fontSize: "15px" }}>
                  01
                </p>
                <h6
                  className="m-0 fw-bold color-skyblue"
                  style={{ fontSize: "11px" }}
                >
                  {t("View")}
                </h6>
              </button>{" "}
            </td>
          </Link>
        );
      }
    };
    return (
      <>
        {/* {
              this.state.tableLoading ?
              <section>
              <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                  <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                  </div>
              </div>
          </section>
              : */}
        <div>
          <div className="user-details p-3 mb-5">
            <div className="mb-3 user-details-title fs-5 fw-bolder">
              {t("User Details")}
            </div>
            <table>
              <thead className="">
                <tr>
                  <th className="pr-5 pb-1 text-muted fw-normal">
                    {t("Name")}
                  </th>
                  <th className="pr-5 pb-1 text-muted fw-normal">
                    {t("Email")}
                  </th>
                  {/* <th className='pr-5 pb-1 text-muted fw-normal'>{t("Phone")}</th> */}
                </tr>
              </thead>
              <tbody className="">
                <tr>
                  <td className="pr-5 fw-bold">
                    {this.props.userDetail?.firstName || t("N/A")}
                  </td>
                  <td className="pr-5 fw-bold">
                    {this.props.userDetail?.email}
                  </td>
                  {/* <td className='pr-5 fw-bold'>{t("8775741789")}</td> */}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex flex-column">
              <span className="fs-1 fw-bolder user-details-title">
                {t("My Account")}
              </span>
              <span className="">{`${this.props?.rows?.length} ${
                this.props?.rows?.length > 1 ? t("Orders") : t("Order")
              }`}</span>
            </div>
            <div className="p-2 rounded my-account-search">
              <input
                className="my-account-search-inp"
                onChange={(e) => this.setState({ searchInp: e.target.value })}
                type="text"
                placeholder={t("Search All")}
              />
              <img src={SearchIcon} alt="search icon" />
            </div>
          </div>
        </div>
        <div className="py-4 d-md-block d-none">
          <table className="table table-borderless">
            {/* <thead className="border-bottom">
                      <tr>
                          {this.props.headers.map(header=>(
                              <th 
                                  className="text-muted fw-bold px-0"
                                  scope="col"
                                  key={header}>
                                  {t(header)}
                              </th>
                          ))}
                      </tr>
                  </thead> */}
            <tbody>
              <div className="my-acc-headings">
                <h2 className="mb-0 d-flex align-items-center">
                  <button
                    className="btn btn-block text-left cursor-none border-0"
                    type="button"
                  >
                    <div className="row text-muted">
                      <div className="col d-flex align-items-center">
                        <img src={arrow} style={{ opacity: "0" }} alt="img" />
                        <span className="mr-2">{t("Date&Time")}</span>
                        <div className="d-flex flex-column">
                          <img
                            className={`cursor-pointer ${
                              this.state.sortOrder?.date &&
                              this.state.sortOrder?.date !== "desc"
                                ? ""
                                : "arrow-muted"
                            }`}
                            style={{ width: "1.2rem" }}
                            onClick={() => this.sortOrders("Date&Time", "asc")}
                            src={arrowUp}
                            alt=""
                          />
                          <img
                            className={`cursor-pointer ${
                              this.state.sortOrder?.date &&
                              this.state.sortOrder?.date === "desc"
                                ? ""
                                : "arrow-muted"
                            }`}
                            style={{ width: "1.2rem" }}
                            onClick={() => this.sortOrders("Date&Time", "desc")}
                            src={arrowDown}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col d-flex align-items-center">
                        <span className="mr-2">{t("Description")}</span>
                        <div className="d-flex flex-column">
                          <img
                            className={`cursor-pointer ${
                              this.state.sortOrder?.description &&
                              this.state.sortOrder?.description !== "desc"
                                ? ""
                                : "arrow-muted"
                            }`}
                            style={{ width: "1.2rem" }}
                            onClick={() =>
                              this.sortOrders("Description", "asc")
                            }
                            src={arrowUp}
                            alt=""
                          />
                          <img
                            className={`cursor-pointer ${
                              this.state.sortOrder?.description &&
                              this.state.sortOrder?.description === "desc"
                                ? ""
                                : "arrow-muted"
                            }`}
                            style={{ width: "1.2rem" }}
                            onClick={() =>
                              this.sortOrders("Description", "desc")
                            }
                            src={arrowDown}
                            alt="product-img"
                          />
                        </div>
                      </div>
                      <span className="col d-flex align-items-center">
                        {t("Order")}
                      </span>
                      <span className="col d-flex align-items-center">{`${t(`Total ${this.props.currencySymbol}`)} 
                      ${t("Redeemed")}`}</span>
                      <span className="col d-flex align-items-center">
                        {t("Status")}
                      </span>
                    </div>
                  </button>
                </h2>
              </div>
              <div className="accordion" id="accordionExample">
                {mapOrders?.length ? (
                  mapOrders
                ) : (
                  <div className=" text-center text-muted fs-5 mt-3">
                    {t("No results found.")}
                  </div>
                )}
              </div>
            </tbody>
          </table>
        </div>
        {/* } */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    slug: state.tenetReducer?.slug,
    userDetail: state?.countries?.countries,
  };
};

export default connect(mapStateToProps)(withTranslation()(ProductTable));
