import React, { Component } from "react";
import { Button } from "react-bootstrap";
import flightsUp from '../../assests/img/TravelShop/plane-up.svg'
import flightsDown from '../../assests/img/TravelShop/plane-down.svg'
import moment from 'moment'
import { withTranslation } from "react-i18next";
import { numberWithCommas } from '../../utils/pointsConversion'

class TravelShopFlightsBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compenent: null
    }
  }
  addTimes(segments) {

  }
  render() {

    const { data, segments, point,t } = this.props;
    let missingPoint = 0
    if (point && point + 1) {
      missingPoint = data?.fare_local?.price_in_points - point
    }
    if (data) {
      const flightData = []
      segments.map((segmentsData, index) => {
        let startToEnd = {}
        const filteredFlight = data.segments.filter(segment => index + 1 === segment.segment_indicator)
        // data.segments.map((segment, i) => {
        //   if (segmentsData.origin === segment.origin.code) {
        //     startToEnd = { ...startToEnd, takeOff: segment, stop: i }
        //   }
        //   if (segmentsData.destination === segment.destination.code) {
        //     startToEnd = { ...startToEnd, land: segment, stop: i - startToEnd.stop }
        //   }
        // })
        startToEnd['takeOff'] = filteredFlight[0]
        if (filteredFlight.length > 1) {
          startToEnd['land'] = filteredFlight[filteredFlight.length - 1]
        } else {
          startToEnd['land'] = filteredFlight[0]
        }
        // const duration = this.addTimes(filteredFlight)

        let duration = 0;
        filteredFlight?.forEach(segment => {
          duration = duration + moment.duration(segment?.accumulated_duration || '00:00:00').as('milliseconds')
        });
        let time = moment.utc(duration).format("HH:mm")?.split(":");
        duration = `${time[0]}${t('h')} ${time[1]}${t('m')}`

        startToEnd['stop'] = filteredFlight.length - 1
        startToEnd['duration'] = duration
        flightData.push(startToEnd)
      })

      const stops = flightData.reduce((result, data) => result + (data?.stop || 0), 0);
      return <>
        <div className={`flights-box d-flex flex-wrap border bg-white mt-4 shadow-sm ${data.className}`}>
          <div className="flights-info">
            <div className="airline-routes-info mb-4 d-none d-md-block">
              {
                data.best && <span className="best">{data.best}</span>
              }
              {
                data.fees && <span className="fees">{data.fees}</span>
              }
              {
                stops ? <span className="stops">{stops} {t("Stops")}</span> : null
              }
            </div>
            {
              flightData.map((flightDetail, i) => (
                flightDetail ?
                  <div className="flights">
                    <div className="plane mt-auto mb-1 d-none d-md-block">
                      <img loading='lazy' decoding='async' src={i ? flightsDown : flightsUp} alt="" />
                    </div>
                    <div className="flight-row justify-content-between">
                      <div className="flight-name">
                        <img loading='lazy' decoding='async' src={flightDetail?.takeOff?.airline_image} alt="" />
                        <h5>{flightDetail?.takeOff?.airline_name}</h5>
                      </div>
                      <div className="airline-time text-md-right">
                        <p>{moment(flightDetail?.takeOff?.departure_time).format('hh:mm a')}</p>
                        <p>{flightDetail?.takeOff?.origin?.code}</p>
                      </div>
                      <div className="timeline">
                        <p className="d-none d-md-block">{flightDetail?.duration}</p>
                        <div className="c-timeline"></div>
                        <span className="d-none d-md-block">{flightDetail?.stop ? `${flightDetail.stop} ${t("stop")}` : t("Nonstop")}</span>
                        <p className="d-block d-md-none">{flightDetail?.duration} ({flightDetail?.stop ? `${flightDetail.stop} ${t("stop")}` : t("Nonstop")})</p>
                      </div>
                      <div className="airline-time">
                        <p>{moment(flightDetail?.land?.arrival_time).format('hh:mm a')}</p>
                        <p>{t(flightDetail?.land?.destination?.code)} </p>
                      </div>
                      {/* <div className="airline-time d-none d-md-block"> */}
                      {/* <p>{flightDetail?.duration}</p> */}
                      {/* <p>{moment.utc(moment.duration(moment(flightDetail?.land?.arrival_time).diff(moment(flightDetail?.takeOff?.departure_time))).as('milliseconds')).format('HH:mm')}</p> */}
                      {/* </div> */}
                    </div>
                  </div> : null
              ))
            }
          </div>
          <div className="flights-option d-md-flex flex-md-column">
            <div className="text-md-right">
              <p className="text-gray d-none d-md-block">{t(flightData?.[0]?.land?.cabin_class)}</p>
              <h4>{numberWithCommas(data?.fare_local?.price_in_points)} <span>{t("Points")}</span></h4>
            </div>
            <p className="d-none d-md-block">{flightData[0]?.takeOff?.airline_name}</p>
            <div className="points mt-md-3 text-md-right order-md-4">
              <p className="d-none d-md-block">{numberWithCommas(data?.fare_local?.price_in_points)} {t("Points")} <span className="btnTxt">({numberWithCommas(data?.fare_local?.total_fare_sar)} {data?.fare_local?.currency})</span></p>
              {missingPoint > 0 ? <p>{t("Missing Points")} <span className="text-green">{numberWithCommas(missingPoint)}</span></p> : null}
            </div>
            <Button block className="rounded-sm mt-md-2 order-md-3 py-2 btn-sky border-white" onClick={() => this.props.handleViewDetail(data.resultID)}>{t("View Deal")}</Button>
          </div>
        </div>
      </>
    }
    return <div></div>;
  }
}

export default withTranslation()(TravelShopFlightsBox);
