import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


class TravelInputField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            compenent:null
         }
    }
    getInputCompenent(data){
        const {t} = this.props
        switch (data.type) {
            case "text":
            case "email":  
                return(
                        <input 
                            className="py-2 input-field w-100 rounded-0 border outline-color-green"
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            placeholder={t(data.ariaLabel)}
                            type={data.type} 
                            value={data.value}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                            autoComplete="off"
                            />
                )

            case "tel":
                return(
                   
                        <div className="d-flex col-12 p-0">
                            <select 
                                className="btn border bg-light rounded-0 caret flex-shrink-0" 
                                name="countryCode" 
                                value="saudi"
                                onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}>
                                <option value="saudi">966</option>
                            </select>
                            <input 
                                className={`flex-grow-1 py-2 w-50 rounded-0 border outline-color-green ${this.props.lang === 'ar' ? 'ml-4' : 'mr-4'}`}
                                id={data.id}
                                title={data.title}
                                aria-label={data.ariaLabel}
                                tabIndex={data.tabIndex}
                                readOnly={data.readOnly}
                                type={data.type} 
                                value={data.value}
                                placeholder={t(data.ariaLabel)}
                                maxLength={data.maxLength}
                                onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}/>
                        </div>
                   
                )
            case "dropdown":
                return(
                        <select className="travelArrows dis border py-2 d-block outline-color-green" 
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            value={data.value}
                            disabled={data.disabled}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}>
                        {
                            data.options.map(option=>(
                                <option 
                                    className="d-block w-100"
                                    value={option}
                                    key={option}>
                                    {t(option)}
                                </option>
                            ))
                        }
                    </select>
                 
                )
                
            default:
            break;
        }
    }
    render() { 
        const data=this.props.data
        const {t}=this.props
        return ( 
            <div className={` py-2 ${data.sideAlign ? 'w-50 d-inline-block' : 'd-flex flex-column w-100'}`}>
                <label 
                    className="fw-bold text-muted d-block"
                    htmlFor={data.id}
                    >{`${t(data.title)}`}
                    <span className='color-red'>{data.required === true && `*`}</span>
                    </label>
                    {this.getInputCompenent(data)}
                    {(data.errorMessage && data.value==="")&&
                        <h6 className="text-danger py-2 text-center m-0" style={{backgroundColor:"rgb(255, 230, 230)"}}>
                            {`${t(data.errorMessage)}`}
                        </h6>
                    }
            </div>
         );
    }
}
 
export default (withTranslation() (TravelInputField));