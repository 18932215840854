import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProductTable from '../ProductTable';
import SearchBar from '../SearchBar';

class GiftsSection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchFilter:"",
            dropdownFilter:"ALL",
            isLoaded:true
         }
    }
    headers=["Product","Description","Status","","Quantity"]
    setDropdownFilter = (val) =>{this.setState({dropdownFilter:val})}
    setFilter=(val)=>{this.setState({searchFilter:val})}
    searchFilter=()=>{
       return  this.props.giftCardOrders
                .filter(em=>
                    (em.brand_name.match(new RegExp(`${this.state.searchFilter.replace(/[^\w\s]/gi, "")}`,"ig"))
                    &&
                    (this.state.dropdownFilter==="ALL"?
                        true:
                        this.state.dropdownFilter===em.order_status)
                    ))
    }
    render() { 
        setTimeout(() => {this.setState({isLoaded: false})}, 100);
        const {dropdownFilter} = this.state;
        return ( 
           <>
            {
               this.state.isLoaded ?
               <section>
                     <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
               </section>
               :
            <section>
                {/* <SearchBar 
                    sectionName="Gift Cards"
                    onChange={this.setFilter} 
                    dropdownFilter={dropdownFilter} 
                    setDropdownFilter={this.setDropdownFilter}
                    dropdownItems = {["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]}
                /> */}
                <div>
                    <>
                        <ProductTable type='GiftCards' headers={this.headers} rows={this.props.giftCardOrders} currencySymbol={this.props.currencySymbol}/>
                    </>                            
                </div>
            </section>
            }
           </>
         );
    }
}
const mapStateToProps = state => {
    return {
        giftCardOrders:state.myWallet.eCardEMagOrders
    }
}

export default connect(mapStateToProps)(GiftsSection);