import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


class MerchandiseInputField extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            compenent:null
         }
    }
    getInputCompenent(data){
        switch (data.type) {
            case "email":
            case "text": 
            case "number":
                return(
                        <input 
                            className="py-2 input-field rounded-0 border outline-color-skyblue"
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            type={data.type} 
                            value={data.value}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                            autoComplete="off"
                            />
                        
                )
              
                    case "textarea": 
                        return(
                                <textarea 
                                    className="py-2 input-field rounded-0 border outline-color-skyblue"
                                    style={{minHeight:"100px"}}
                                    id={data.id}
                                    title={data.title}
                                    aria-label={data.ariaLabel}
                                    tabIndex={data.tabIndex}
                                    readOnly={data.readOnly}
                                    type={data.type} 
                                    value={data.value}
                                    onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                                    autoComplete="off"
                                    />
                                
                        )
            case "tel":
                return(
                   
                        <div className="d-flex col-6 p-0">
                           {
                               this.props.lang ==='en'?
                               <>
                                <select 
                                className="btn border bg-light rounded-0 caret flex-shrink-0 " 
                                name="countryCode" 
                                value={this.props.PhoneFormat.country_code}
                                disabled
                                onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}>
                                <option value={this.props.PhoneFormat.country_code}>{this.props.PhoneFormat.country_code}</option>
                            </select>
                            <input 
                                
                                className=" input-field py-2 border rounded-0 outline-color-skyblue"
                                id={data.id}
                                title={data.title}
                                aria-label={data.ariaLabel}
                                tabIndex={data.tabIndex}
                                readOnly={data.readOnly}
                                type="number" 
                                value={data.value}
                                // maxLength= "9"
                                onChange={(e)=>{this.props.onChange(e.target.value.slice(0,this.props.PhoneFormat?.phone_max_length),data.id)}}/>
                               </>
                               :
                               <>
                            <input 
                            
                                className="col-9 col-lg-10 py-2 rounded-0 border"
                                id={data.id}
                                title={data.title}
                                aria-label={data.ariaLabel}
                                tabIndex={data.tabIndex}
                                readOnly={data.readOnly}
                                type="number"
                                value={data.value}
                                // maxLength= '9'
                                onChange={(e)=>{this.props.onChange(e.target.value.slice(0,this.props.PhoneFormat?.phone_max_length),data.id)}}/>
                                  <select 
                                className="btn border bg-light rounded-0 caret flex-shrink-0" 
                                name="countryCode" 
                                disabled
                                value={this.props.PhoneFormat.country_code}
                                onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}>
                                <option value={this.props.PhoneFormat.country_code}>{this.props.PhoneFormat.country_code}</option>
                            </select>
                               </>
                           }
                        </div>
                   
                )
            case "dropdown":
                const {t}= this.props
                return(
                  <div className="select">
                        <select className=" py-2 px-3 rounded-0 border outline-color-skyblue caret-down dropdown_" 
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            value={data.value}
                            disabled
                            >
                        {
                            data.options.map(option=>(
                                <option 
                                    className="d-block w-100"
                                    value={option}
                                    key={option}>
                                    {t(option)}
                                </option>
                            ))
                        }
                    </select>
                  </div>
                )
                
            default:
            break;
        }
    }
    render() { 
        const data=this.props.data
        const {t}=this.props
        return ( 
           
            <div className="  py-2">
                <div  className='d-flex flex-column w-75'>
                <label 
                    // className="fw-bold"
                    htmlFor={data.id}
                    >{`${t(data.title)}`}
                    </label>
                    {this.getInputCompenent(data)}
                    {(data.errorMessage && data.value==="")&&
                        <h6 className="text-danger w-50 py-2 text-center m-0" style={{backgroundColor:"rgb(255, 230, 230)"}}>
                            {t(data.errorMessage)}
                        </h6>
                    }
                </div>
               
            </div>
            
         );
    }
}
 
export default (withTranslation() (MerchandiseInputField));