import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import {ReactComponent as Success} from '../../../assests/img/Success.svg'
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchPointBalance } from '../../../Redux/point/pointActions';
import { destroyCart } from 'Redux/cart/cartAction';

class SuccessfullMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true,
            type:'all'
         }
    }
    setModalView=()=>{
        this.setState({
            show:false
        })
    }
    componentDidMount(){
        if(localStorage.getItem('type')){
            this.setState({type:localStorage.getItem('type')})
        }
    }
    // Disabling Points Balance API
    componentDidUpdate(){
        if(this.props.show){
            let tkn = localStorage.getItem("userToken")
            this.props.fetchPointBalance(tkn,this.props?.promoCode,this.props.lang)
        }
    }
    render() { 
        const {t}= this.props
        
        return ( 
            <ModalWrapper show={this.state.show && this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4 rounded" 
                    style={{width:'35rem'}}>
                    <div className='fs-2 fw-bold redeem-text mb-3'>{t("Order Confirmation")}</div>
                    <Success/>
                    <h3 className='fs-5 fw-bold my-2 py-md-3 py-0'>
                        {t("Order Placed Successfully")}
                    </h3> 
                    <div className='bg-white mb-4 px-3 py-2 rounded w-50 d-flex justify-content-center mx-auto'>
                        <span className='redeem-text fs-6 fw-bold'>{this.props.orderNumber}</span>
                    </div>
                    <div className='body-typo my-2 mx-auto w-75'>
                       {t("You can find this reward in the My Account section. It will have the order number referenced above.")}
                    </div>
                    <hr className='order-border'/>
                    <div className='redeem-text2' >{t('Your new available balance is ')} <span className='redeem-text'>{this.props.currencySymbol} {this.props.point}</span></div>
                    <div className='d-md-block d-none'>
                    <div className='d-flex justify-content-center align-items-center'>
                    <Link 
                        to={{pathname:`/`}}
                        className="btn  btn-secondary rounded mt-3 mx-2"
                        onClick={()=>{this.setModalView(); this.props.destroyCart()}}>
                        {t("Go to home page")}
                    </Link>
                    <Link 
                        to={{pathname:`/mywallet/${this.state.type}`}}
                        className="btn primary-btn rounded mx-2 mt-3 text-light border-color-skyblue"
                        onClick={()=>{this.setModalView(); this.props.destroyCart()}}>
                        {t("Go to My Account")}
                    </Link>
                    </div>
                    </div>
                    <div  className='d-md-none d-block'>
                    <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                    <Link 
                        to={{pathname:`/`}}
                        className="btn  btn-secondary rounded mt-3 mx-2  w-100"
                        onClick={()=>{this.setModalView(); this.props.destroyCart()}}>
                        {t("Go to home page")}
                    </Link>
                    <Link 
                        to={{pathname:`/mywallet/${this.state.type}`}}
                        className="btn primary-btn rounded mx-2 mt-3 text-light border-color-skyblue  w-100"
                        onClick={()=>{this.setModalView(); this.props.destroyCart()}}>
                        {t("Go to My Account")}
                    </Link>
                    </div>
                    </div>
                  
                    {/* <button 
                        className="btn  btn-secondary mt-3 mx-2"
                        onClick={()=>{this.setModalView()}}>
                        <span className="fw-bold primary-color">{t("Close")}</span>
                    </button> */}
                </div>
            </ModalWrapper>
         );
    }
}
const mapStateToProps = state => {
    return {
        point:state.point?.pointBalance
    }
}
const mapDispatchToProps = dispatch => {
    return {    
        fetchPointBalance : (token,code,lang) => dispatch(fetchPointBalance(token,code,lang)),
        destroyCart:()=>dispatch(destroyCart())
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SuccessfullMessageModal));