import axios from "axios";
import {
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS,
} from "./ForgetPassTypes";
import { REACT_APP_baseUrl } from "utils/app-constants";

const ForgetPassRequest = () => {
  return {
    type: FORGET_PASSWORD_REQUEST,
  };
};

const ForgetPassFailure = (err) => {
  return {
    type: FORGET_PASSWORD_FAILURE,
  };
};

const ForgetPasssucc = (data) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const ForgetPassEmail = (email) => {
  return (dispatch) => {
    dispatch(ForgetPassRequest());
    axios
      .post(
        `${REACT_APP_baseUrl}/accounts/passwords/new`,
        {    
            email: email,
        }
      )
      .then((res) => {
        dispatch({ type: FORGET_PASSWORD_SUCCESS });
      })
      .catch((err) => {
        dispatch(ForgetPassFailure(err));
      });
  };
};

export const ForgetPassSuccess = (pass,confirmPass,token) => {
  const ForgotPayload = {
    change_password: {
      password: pass,
      password_confirmation: confirmPass
  },
    token: token
  }
  return (dispatch) => {
    dispatch(ForgetPassRequest());
    axios.put(`${REACT_APP_baseUrl}/accounts/passwords/update`, 
      ForgotPayload,
      )
      .then((res) => {
        dispatch(ForgetPasssucc(res.data.message));
      })
      .catch((err) => {
        dispatch(ForgetPassFailure(err));
      });
  };
};

// export const ForgetPassEmail = (email) => {
//   return (dispatch) => {
//     dispatch(ForgetPassRequest());
//     axios
//       .post(`https://6231c48459070d92733a1396.mockapi.io/sendEmail`, {
//         email: email,
//       })
//       .then((res) => {
//         console.log(res);
//         dispatch({ type: FORGET_PASSWORD_SUCCESS });
//       })
//       .catch((err) => {
//         dispatch(ForgetPassFailure(err));
//       });
//   };
// };

// export const ForgetPassSuccess = (pass, token) => {
//   return (dispatch) => {
//     dispatch(ForgetPassRequest());
//     axios
//       .put(
//         `https://6231c48459070d92733a1396.mockapi.io/ForgetPassword/${token}`,
//         { password: pass }
//       )
//       .then((res) => {
//         console.log(res);
//         dispatch(ForgetPasssucc(res.data.password));
//       })
//       .catch((err) => {
//         dispatch(ForgetPassFailure(err));
//       });
//   };
// };
