import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { fetchCountries, setCountryHandler, fetchConversionRate, setCountryCode, setCurrencyCode } from '../../Redux/country/countriesAction';
import { getBrandData } from '../../Redux/brand/brandActions';
import { fetchOffers } from '../../Redux/offers/offerActions';
import { fetchExperienceCards } from '../../Redux/experience/experienceAction'
import { fetchProductsList } from '../../Redux/Merchandise/merchandiseActions';
import { fetchSoftwares } from '../../Redux/Softwares/SoftwareAction'
import { fetchGames } from '../../Redux/Games/GamesAction';
// import { fetchCurrencies } from '../../Redux/conversion/conversionAction';
import ResetPassword from "../shared/Modals/ResetPassword"
import Location from "../../assests/img/Login/Location.png"
import { fetchSoftwareGames } from 'Redux/Software&Games/SoftwaresAction';
import countryCurrency from "../../utils/countrycodes/countryCurrency.json"
import { REACT_APP_ADD_TO_CART, REACT_APP_api_V2 } from 'utils/app-constants';
import cartDrc from "../../assests/img/header/Shopping-cart.svg";
import LogOutModel from '../../components/shared/Modals/LogOutModel'
import { getAddress } from 'Redux/DeliveryAddress/AddressAction';


class TopHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.lang,
            countryName: [],
            hideShow:true,
            language:[
                {code:"en",lang:"English"},
                {code:"fr",lang:"français"},
                {code:"de",lang:"Deutsch"},
                {code:"nl",lang:"Dutch"},
                {code:"pt",lang:"Português"},
                {code:"el",lang:"Ελληνικά"},
                {code:"pl",lang:"Polski"},
                {code:"cs",lang:"čeština"},
                {code:"sk",lang:"slovenský"},
                {code:"lu",lang:"lëtzebuergesch"},
                {code:"it",lang:"Italiano"},
                {code:"bg",lang:"български"},
                {code:"hr",lang:"Hrvatski"},
                {code:"uk",lang:"українська"},
                {code:"ru",lang:"Русский"},
                ],
            tenetLang:"",
            loggedUser:"",
            showLogout:false    
        }
    }
    componentDidMount() {
        const { modulesData } = this.props
        // this.props.fetchCountries()
        // this.props.fetchConversionRate(this.props.countryId)
        this.fetchBrands(this.props.countryId)
        localStorage.getItem("userToken") && this.props.getAddress()
        // this.props.fetchCurrencies()
        modulesData["RewardsBy Offers"] && this.fetchOrdersList(this.props.countryId)
        modulesData["Experiences"] && this.fetchExperienceCards(this.props.countryId)
        modulesData["RewardsBy Gifts"] && this.fetchProductHandler(this.props.countryId)
        // modulesData["Games"] && this.fetchGames(this.props.countryId)
        // modulesData["Software"] && this.fetchSoftware(this.props.countryId)
        modulesData["Software&Games"] && this.fetchSoftwareGames(this.props.countryId)
      if(this.props.location.pathname.includes("/contactus")){
        this.setState({hideShow:false})
      }
      if(this.props.lang){ 
        this.getLangCode(this.props.lang?.toLowerCase())
      }
    }
    getLangCode=(lang)=>{
        let langName= this.state.language?.filter((item)=>item?.code===lang)
        this.setState({tenetLang:langName[0]?.lang}) 
    }
    componentDidUpdate = (prevProps, prevState) => {

        if ((prevProps.countryId && (prevProps.countryId !== this.props.countryId)) || prevProps.country !== this.props.country) {
            const { modulesData } = this.props
            this.fetchBrands(this.props.countryId)
            modulesData["RewardsBy Offers"] && this.fetchOrdersList(this.props.countryId)
            modulesData["Experiences"] && this.fetchExperienceCards(this.props.countryId)
            modulesData["RewardsBy Gifts"] && this.fetchProductHandler(this.props.countryId)
            // modulesData["Games"] && this.fetchGames(this.props.countryId)
            // modulesData["Software"] && this.fetchSoftware(this.props.countryId)
            modulesData["Software&Games"] && this.fetchSoftwareGames(this.props.countryId)
        }
    }
    fetchBrands = (countrySelected) => {
        let currency = countryCurrency?.filter(val => val?.countryName ===  (this.props.selectedCountry?.length >0 && this.props.selectedCountry[0].name))
        let payload
        if(REACT_APP_api_V2 === "true" || this.props.isMultiLang){
            payload = {
                "brands":
                {
                    "program_id": 1,
                    "currency": currency[0]?.currencyCode,
                    "country": this.props.country
                },
            }
        }else{
            payload = {
                "brands":
                {
                    "program_id": 1,
                    "currency": countrySelected,
                },
            }    
        }
        this.props.getBrands(payload,this.props.lang);
    }
    fetchOrdersList(countrySelected) {
        const payload = {
            "offer_campaign": {
                "currency": countrySelected
            }
        }
        this.props.fetchOffers(payload)
    }
    fetchExperienceCards(countrySelected) {
        const payload = {
            "experiences": {
                "currency": countrySelected
            }
        }
        this.props.fetchExperienceCards(payload)
    }
    fetchSoftwareGames(countrySelected) {
        const payload = {
            "software_and_game": {
                "currency": countrySelected
            }
        }
        this.props.fetchSoftwareGames(payload)
    }

    fetchSoftware(countrySelected) {
        const payload = {
            "software_and_game": {
                "currency": countrySelected,
                "campaign_type": "SOFTWARE"
            }
        }
        this.props.fetchSoftwares(payload)
    }

    fetchGames(countrySelected) {
        const payload = {
            "software_and_game": {
                "currency": countrySelected,
                "campaign_type": "GAME"
            }
        }
        this.props.fetchGames(payload)
    }

    fetchProductHandler = (countrySelected) => {
        const payload = {
            "merchant_product": {
                "currency": countrySelected
            }
        }

        this.props.fetchProductsList(payload)
    }
    toogleLang = () => {

        if (this.props.lang === 'ar') {
            this.setState({
                show: 'en'
            })
            i18next.changeLanguage('en')
            this.props.setLang('en');
            localStorage.setItem('lang', 'en');
            document.body.style.fontFamily = `"Montserrat", sans-serif`;
        }
        else if (this.props.lang === 'en') {
            this.setState({
                show: 'ar'
            })
            i18next.changeLanguage('ar')
            this.props.setLang('ar');
            localStorage.setItem('lang', 'ar');
            // window.location.reload();
        }
    }

    handleCountryChange = (value) =>{
        let currency = countryCurrency?.filter(val => val?.countryName === value)
        let countryCode = this.props.countries?.filter(val => val?.name === value)
        this.props.setCurrencyCode(currency[0]?.currencyCode)
        this.props.setCountryCode(countryCode[0].code)
        this.props.setLanguage(countryCode[0]?.languages[0])
        // this.props.fetchConversionRate(currency[0]?.currencyCode)
      }

    numberWithCommas = (x) => {
        let num = +x;
        let y = num.toFixed(2);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    loggedUserHandle=(user)=>{
        if(user==="Logout"){
            this.setState({showLogout:true})
        }
      }
      closeHandle=()=>{
        this.setState({showLogout:false})
      }
    render() {
        const { t, countries, countriesloading, countryId } = this.props;
        return (
            <>

                {
                    ((this.props.valid && this.props.hideShow) || (this.props.slugID?.journey !== "PROMO_CODE_REDEMPTION" && this.props.hideShow)) && <>

                        <div className={`custom-border   px-md-5 d-flex justify-content-between position-sticky pr-3 ${this.props.authToken && "height-25"}`} style={{zIndex:'999', top: '-1px', backgroundColor: " #28A1D4" }}>
                        <LogOutModel showLogout={this.state.showLogout} closeHandle={this.closeHandle}/>
                            {/*  <div className='d-md-block d-none my-auto'>
            <div className={`header_footer border-bottom   px-md-4 d-flex justify-content-end position-sticky z-index-top ${this.props.authToken && "height-25"} ${this.props.lang === "en" ? "pr-3" : "pl-3"}`} style={{ top: '0', backgroundColor: " #230871" }}>
                {/*  <div className='d-md-block d-none my-auto'>
                <img src={Location} alt="location"/>
            </div>
           <div className="dropdown">
                    <select 
                        className={`btn border-0 text-light dropdown-toggle ${this.props.lang === "en" ? "text-left" : "text-right"}`} 
                        type="button" style={{width:'13rem'}} 
                        value={countryId}
                        onChange={({target:{value}}) => {this.props.setCountryHandler(value)}}>
                        {!c        ountriesloading &&
                         countries.map(country => {
                            return <option style={{backgroundColor:"#fff",color:"#000"}} 
                                        key={country.id} 
                                        onClick={(e) => this.setCountryHandler(e, country.country_name)} value={country.id}>{this.props.lang==="en" ? country.country_name : country.country_name_arabic} </option>
                                        
                        })}
                    </select>
            </div> */}
            {
              countries?.length > 1 &&   
                <div className="dropdown d-md-none">
                        <select 
                            className={`btn border-0 text-light dropdown-toggle text-left`} 
                            type="button" style={{width:'13rem'}} 
                            // value={countryId}
                            onChange={({target:{value}}) => {this.handleCountryChange(value)}}>
                            {!countriesloading &&
                            countries?.map(country => {
                                return <option style={{backgroundColor:"#fff",color:"#000"}} 
                                            key={country.id} 
                                            onClick={(e) => this.setCountryHandler(e, country.name)} value={country.id}>{t(country?.name)} 
                                            </option>
                                            
                            })}
                        </select>
                </div>
            }
            <div className="txt-medium my-auto p-2 d-flex header-link" >
                    {/* {this.state.tenetLang}
                    <div>|</div> */}
                    <div className='d-md-none d-block'>
                    {this.props?.valid && <span className='fw-normal'>{t("Hi,")} {this.props.userDetail?.firstName || t("N/A")}<span className='ml-2'>|</span></span>}
                    </div>
                    <div className='d-md-block d-none'>
                    <Link to={"/"}
                        className={`text-decoration-none py-2 mr-4 text-center ${(window.location?.pathname === "/" || window.location?.pathname?.includes("selectedgiftcard")) ? "opacity-1" : "opacity-7"}`}
                        >
                        {t("Rewards")}
                    </Link>
                    </div>
                    <div className='d-md-block d-none'>|</div>
                    <div className='d-md-block d-none'>
                    <Link
                        to={"/mywallet/giftCards"}
                        className={`text-decoration-none py-2 mx-4 text-center ${(window.location?.pathname === "/mywallet/giftCards") ? "opacity-1" : "opacity-7"}`}
                        >
                        {t("My Account")}
                    </Link>
                    </div>
                    <div className='d-md-block d-none'>|</div>
                    <div className='d-md-block d-none'>
                    <Link to={"/faq"}
                        className={`text-decoration-none py-2 mx-4 text-center ${(window.location?.pathname === "/faq") ? "opacity-1" : "opacity-7"}`}
                        >
                        {t("FAQ")}
                    </Link>
                    
                    </div>
                </div> 
                {
                    this.props.valid && this.props.point>=0 &&
                    <div className={`d-md-none  text-light my-auto txt-medium text-right border-righ`}>
                        <span className="m-0 p-0">{t("Your balance")}: <span className='fw-bold'>{this.props.currencySymbol} {this.numberWithCommas(this.props.point)}</span></span>
                        <span className='mx-2'>|</span>
                    </div>
                }
                {
                    REACT_APP_ADD_TO_CART === "true" &&
                    <div className='d-md-none d-block d-flex justify-content-center align-items-center'>
                        <Link to={"/view-cart"} count={this.props.cart?.length}>
                            <div className='d-flex position-relative'>
                                <img
                                    src={cartDrc}
                                    alt="cart-icon"
                                    className="img-fluid  cursor-pointer"
                                />
                                {
                                    this.props.items?.length ?
                                        <span className='cart-counter text-center'>{this.props.items?.length}</span>
                                        : null
                                }
                            </div>
                        </Link>
                    </div>
                }
               
                {/* <div className={`text-light border-left my-auto ${this.props.lang === "en" ? "pl-2" : "pr-2"}`} onClick={() => { this.toogleLang() }} style={{ cursor: "pointer" }} >
                    {this.props.lang === "en" ? 'عربى' : 'English'}
                </div> */}
               <div className='d-none d-md-flex txt-medium  align-items-center'>
               <div>
               {/* <div className="col-2 dropdown-toggle text-white p-0">
                <select id="user-select"  name="category" value={this.state.loggedUser} onChange={event => this.loggedUserHandle(event.target.value)}>
                    <option id="0" selected default hidden>Hi, Sue MacDonald</option>
                    <option id="1" >Logout</option>
                </select>
                </div> */}
                 </div>
                 {
                    this.props.valid && 
                    <div className='d-flex'>
                        <div className='d-flex'>
                            <div className='mr-4'>
                                <span>{t('Hi,')} </span>
                                <span>{this.props.userDetail?.firstName || t("N/A")}</span>
                            </div>
                            <div>|</div>
                        </div>
                        <div>
                        {this.props.hideShow?
                    
                        <div className="d-flex flex-row w-100 justify-content-end ">
                        {
                            !this.props.point>=0 &&
                                <div className="text-light d-flex justify-content-center align-items-center ml-4">
                                    <span className="m-0 p-0">{t("Your balance")}: </span>
                                    <div className="text-center"><span className='mr-4 fw-bold'>{this.props.currencySymbol} {this.numberWithCommas(this.props.point)} </span>|</div>
                                </div>
                        }
                        </div>
                        :null} 
                        </div>
                        <div className='d-flex'>
                            <div className='mx-4 cursor-pointer sign-out-clr' onClick={e => this.loggedUserHandle('Logout')}>{t("Sign out")}</div>
                            <div>|</div>
                        </div>
                    </div>
                 }
                 {
                    REACT_APP_ADD_TO_CART === "true" &&
                    <div>
                    <Link to={"/view-cart"} count={this.props.cart?.length}>
                        <div className='d-flex position-relative ml-4'>
                            <img
                                src={cartDrc}
                                alt="cart-icon"
                                className="img-fluid  cursor-pointer"
                            />
                            {
                                this.props.items?.length ? 
                                    <span className='cart-counter text-center'>{this.props.items?.length}</span>
                                    : null
                            }
                        </div>
                    </Link>
                    </div>
                 }
                </div>


                {/*            
            {
                this.props.authToken &&
                    <ResetPassword lang={this.props.lang}/>
            } */}
                        </div>
                    </>
                }
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        countries: state.countries?.countries?.countries,
        country: state.countries?.countryCode,
        selectedCountry : state.countries?.countries?.countries?.filter(val => state.countries?.countryCode === val.code), 
        countriesloading: state.countries.loading,
        convertionRate: state.countries.convertionRate,
        countryId: state.countries.countryId,
        point: state.point.pointBalance,
        authToken: state.auth.authToken,
        modulesData: state.categories.moduleInfo,
        valid: state.auth.valid,
        hideShow : state.auth?.showHeader,
        slugID : state.tenetReducer?.slug,
        slugLoading : state.tenetReducer?.loading,
        items: state.cartReducer?.addedItems,
        isMultiLang : state.tenetReducer?.slug?.featureFlags?.isMultilingual,
        userDetail:state?.countries?.countries
    }
}


const mapDispatchToProps = dispatch => {
    return {
        // fetchCountries: () => dispatch(fetchCountries()),
        setCountryHandler: (val) => dispatch(setCountryHandler(val)),
        fetchConversionRate: (val) => dispatch(fetchConversionRate(val)),
        getBrands: (payload,lang) => dispatch(getBrandData(payload,lang)),
        fetchOffers: (payload) => dispatch(fetchOffers(payload)),
        fetchExperienceCards: (payload) => dispatch(fetchExperienceCards(payload)),
        fetchProductsList: (payload) => dispatch(fetchProductsList(payload)),
        fetchSoftwareGames: (payload) => dispatch(fetchSoftwareGames(payload)),
        fetchSoftwares: (payload) => dispatch(fetchSoftwares(payload)),
        fetchGames: (payload) => dispatch(fetchGames(payload)),
        setCountryCode: (data) => dispatch(setCountryCode(data)),
        setCurrencyCode: (data) => dispatch(setCurrencyCode(data)),
        getAddress: () => dispatch(getAddress())
        // fetchCurrencies: () => dispatch(fetchCurrencies())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopHeader)); 