import React, { Component } from 'react'
import './GiftCardRedemption.css'
import KantarLogo from '../../assests/img/kantarTransparent.svg'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { authValid } from 'Redux/auth/authActions';
import cookie from 'react-cookies';
import {REACT_APP_baseUrl, REACT_APP_RECAPTCHA_SITE_KEY} from 'utils/app-constants';
import axios from 'axios';
import { fetchPointBalanceSuccess } from 'Redux/point/pointActions';
import { Link } from 'react-router-dom';
import { ShowHeader } from "Redux/auth/authActions";
import ReCAPTCHA from "react-google-recaptcha"

// import ProceedRedemption from './ProceedRedemption';

export class GiftCardRedemption extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            value: '',
            error: '',
            token: '',
            amount:'',
            loading: false
        }

        this.captchaRef = React.createRef()
    }
    componentDidMount() {
        let promoCode =  ""
        let code = ""
        promoCode =  cookie.load("RedemptionCode")
        const queryParams = window.location?.search;
        code = queryParams?.split('code=')[1];

        if(((promoCode === code) && this.props.valid) || this.props.slugJourney?.journey !== "PROMO_CODE_REDEMPTION"){
            this.props.history.push("/")
        }else{
            this.props.ShowHeader(false)
            this.setState({
                value: code
            })
        }
    }
    handleInput = (e) => {
        this.setState({
            error: '',
            value: e.target.value
        })
    }
    validatePromoCode = () => {
        this.setState({
            loading:true,
            error: ''
        })
        axios.post(`${REACT_APP_baseUrl}/giftcards/validate_promo_code`, {
            promo_code: this.state.value
        },{
            headers:{
                "recaptcha-response": this.captchaRef.current.getValue()
            }
        }).then(response => {
                if (response?.status === 200) {
                    this.setState({ token: response?.data?.data?.access_token , amount:response?.data?.data?.balance?.amount , loading: false },()=>{
                        if(Number(response?.data?.data?.balance?.amount)>0){
                            this.handleYes()
                        }else{
                            this.setState({step: this.state.step + 1})
                        }
                    })
                    this.captchaRef.current.reset();
                }
            })
            .catch(err => {
                this.captchaRef?.current?.reset();
                if (err?.response?.data?.code === "ERR-0010"){
                    this.setState({
                        error: "The enter code is not valid!",
                        loading:false
                    })
                }else if (err?.response?.data?.code === "ERR-0011"){
                    this.setState({
                        error: "The enter code has expired!",
                        loading:false
                    })
                }else if (err?.response?.data?.code === "ERR-0009"){
                    this.setState({
                        error: "Please confirm you are not a robot",
                        loading:false
                    })
                }else{
                    this.setState({
                        error: "something went wrong please try again!!",
                        loading:false
                    })
                }
            })
    }
    handleRedeemNow = () => {
        if (this.state.value === "" || this.state.value === undefined) {
            this.setState({
                error: "Please enter code!"
            })
        } else {
            const ref = this.captchaRef
            if(ref.current!=null) {
                const captchaToken = ref.current.getValue()
                if (captchaToken === null || captchaToken === '') {
                    this.setState({
                        error: "Please confirm you are not a robot"
                    })
                }else {
                    this.validatePromoCode()
                }
            }
        }

    }
    handleYes = () => {
        localStorage.setItem("userToken", this.state.token);
        cookie.save("RedemptionCode", this.state.value)
        this.props.authValid(this.state.token)
        this.props.fetchPointBalanceSuccess(this.state.amount)
        this.props.ShowHeader(true)
        if(Number(this.state.amount > 0)){
            this.props.history.push({
                pathname: '/',
                state: {
                    amount: this.state.amount,
                    show : true
                }
            })
        }else{
            this.props.history.push({
                pathname: '/mywallet/giftCards',
                state: {
                    amount: this.state.amount,
                    show : false
                }
            })
        }
    }
    componentWillUnmount () {
        this.setState = () => false
        }
    render() {
        const Loader = () => {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        };
        const { t } = this.props
        return (
            <>
                {
                    this.state.step === 1 ?
                        <div className='redemption-page d-flex justify-content-center'>
                            <div className='redemption-card d-flex flex-column justify-content-between'>
                                <span className='d-flex justify-content-center'><img className="kantar-img" src={this.props.logo.logo?this.props.logo.logo:KantarLogo} alt="kantarLogo" /></span>
                                <span className='text-center mt-2 redeem-text'>{t("Redeem Registration Code")}</span>
                                <span className='d-flex justify-content-start enter-code-text my-4'>{t("Please enter your incentive code")}</span>
                                <input className={!this.state.error ? 'redeem-code-input' : 'redeem-input-not-valid'} onChange={this.handleInput} value={this.state.value} type="text" />
                                {<span className='not-valid-msg'>{t(this.state.error)}</span>}
                                <span className='d-flex mt-4 justify-content-center'>
                                    <ReCAPTCHA
                                        onExpired={() => this.captchaRef.current.reset()}
                                        sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                                        ref={this.captchaRef}/>
                                </span>
                                {
                                    this.state.loading ? <Loader/> :
                                <button type="submit" className='redeem-btn mt-4' onClick={this.handleRedeemNow}>{t("Redeem Your Code")}</button>
                                }

                                <span className='help-text text-center mt-4'>
                                   <Link to="/contactus">{t("Need help? Click here")}</Link>
                                </span>
                            </div>
                        </div>
                        :
                        <div className='redemption-page d-flex justify-content-center'>
                            <div className='redemption-card2 d-flex flex-column justify-content-between '>
                                <span className='d-flex justify-content-center'><img className="kantar-img" src={this.props.logo.logo?this.props.logo.logo:KantarLogo} alt="kantarLogo" /></span>
                                <span className='text-center mt-2 redeem-text'>{Number(this.state.amount > 0) ? t("Are you sure you want to proceed ?") : t(`Incentive code is fully redeemed.`)} <br /> {Number(this.state.amount <= 0) ? t("View your Gift card in My wallet section ?") : null}</span>
                                {/* <span className='d-flex justify-content-center enter-code-text mt-5 promo-code'>{this.state.value}</span> */}
                                <div className='text-center d-flex justify-content-center mt-5'>
                                    <button type="submit" className='proceed-no-btn' onClick={() => { this.setState({ step: this.state.step - 1, error: ""}) }}>{t("No")}</button>
                                    <button type="submit" className='proceed-yes-btn' onClick={this.handleYes}>{t("Yes")}</button>
                                </div>
                                <span className='help-text text-center mt-4'>
                                   <Link to="/contactus">{t("Need help? Click here")}</Link>
                                </span>
                            </div>
                        </div>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        valid: state.auth.valid,
        slug : state.tenetReducer?.slug?.slug,
        slugJourney : state.tenetReducer?.slug,
        countryCode : state.countries?.countryCode,
        logo:state.tenetReducer?.slug
    }
}
const mapDispatchToProps = dispatch => {
    return {
        authValid: (token) => dispatch(authValid(token)),
        fetchPointBalanceSuccess: (data) => dispatch(fetchPointBalanceSuccess(data)),
        ShowHeader: (data) => dispatch(ShowHeader(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GiftCardRedemption));
