import { FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_REQUEST, FETCH_COUNTRIES_SUCCESS, SET_CONVERSION_RATE, SET_COUNTRY,COUNTRY_CODE, SET_LANG, CURRENCY_CODE } from "./countryTypes"

const initialState = {
    countries:[],
    loading: true,
    error:'',
    countryId:12,
    convertionRate:{},
    countryCode:"",
    lang:"",
    currencyCode:""
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_COUNTRIES_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                loading:false,
                countries:action.payload
            }
        case FETCH_COUNTRIES_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case SET_COUNTRY:
            return {
                ...state,
                countryId:action.payload
            }
            case COUNTRY_CODE:
                return {
                    ...state,
                    countryCode:action.payload
                }
                case SET_LANG:
                    return {
                        ...state,
                        lang:action.payload
                    }
        case SET_CONVERSION_RATE:
            return {
                ...state,
                convertionRate:action.payload
            }
            case CURRENCY_CODE:
                return {
                    ...state,
                    currencyCode:action.payload
                }    
        default: return state
    }
}

export default reducer;