export const ISSUE_TYPE = [
    { id: 0, name: "Have an issue with PayPal Credit", value: "Have an issue with PayPal Credit" },
    { id: 1, name: "Have an issue with Physical Prepaid Mastercard", value: "Have an issue with Physical Prepaid Mastercard" },
    { id: 2, name: "Have an issue with Virtual Prepaid Mastercard", value: "Have an issue with Virtual Prepaid Mastercard" },
    { id: 3, name: "Other", value: "Other" }
];
export const QUANTITY = [
    {  name: "1", value: 1 },
    {  name: "2", value: 2 },
    {  name: "3", value: 3 },
    {  name: "4", value: 4 },
    {  name: "5", value: 5 }
]; 

export const MASTERCARD_BANK_STATEMENT = "Use your card everywhere Mastercard is accepted. Issued by The Bancorp Bank, N.A., Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Your use of the prepaid card is governed by the Cardholder Agreement, and some fees may apply. This is not a gift card. Please note that prepaid cards are subject to expiration, so pay close attention to the expiration date of the card."