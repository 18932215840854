import { FETCH_DETAILS_FAILURE, FETCH_DETAILS_REQUEST,RECORD_BY_CATEGORIES, ALL_CATEGORIES,FETCH_RECORD_REQUEST, HASSAD_MALL_CATEGORIES, MODULE_INFO } from "./categoriesTypes"



const initialState = {
    loading: true,
    allCategoriesLoading:true,
    error:'',
    recordByCategories:[],
    allCategories:undefined,
    recordLoading:false,
    hassadCategories:undefined,
    moduleInfo:{}
    
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_DETAILS_REQUEST:
            return {
                ...state,
                loading:false,
                error:''
            }
        case FETCH_RECORD_REQUEST:
            
            return{
                ...state,
                loading:true
            }
        case FETCH_DETAILS_FAILURE:
            return {
                ...state,
                loading:false,
                allCategoriesLoading:false,
                error:action.payload,
            }

            case RECORD_BY_CATEGORIES:
                return{
                    ...state,
                    recordByCategories:action.payload.recordByCategories,
                    loading:false,
                }
        case ALL_CATEGORIES:
            return{
                ...state,
                allCategories:action.payload.allCategories,
                allCategoriesLoading:false
            }
        case HASSAD_MALL_CATEGORIES:
            return{
                ...state,
                loading:true,
                hassadCategories:action.payload.categories
            }
        case MODULE_INFO:
            return{
                ...state,
                moduleInfo:action.payload.moduleInfo
            }   
        default: return state
    }
}

export default reducer;