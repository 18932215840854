import React, { Component } from 'react';
// import sad from "../../../assests/img/sadd.svg";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ModalWrapper from 'components/shared/Modals/ModalWrapper';
import thumbLogo from "../../assests/img/header/drc-logo.svg";


class WelcomePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    componentDidUpdate(prevProps) {
        if ((prevProps.location?.pathname?.includes('/redemptioncode') ||  prevProps.location?.pathname?.includes('/magic-link')) && this.props?.location?.pathname === '/' && this.props?.history?.location?.state?.show) {
            this.setState({
                show: true
            })
        }
    }
    render() {
        const { t } = this.props
        return (
            // this.state.show
            <ModalWrapper show={false}>
                <div
                    className="p-4 bg-white d-flex flex-column align-items-center mx-3 text-center py-md-5 py-4"
                    style={{ width: '30rem' }}>
                    <div className='app-logo'>
                    <img
                     id='custom-tenant-logo'
                            src={(this.props?.slug?.logo) ? (this.props?.slug?.logo) : thumbLogo} alt="drc logo" />
                        </div>
                    <span className='redeem-text mt-2'>{t(`Welcome to your ${this.props.portal}`)} <br/><p className='text-muted'>{t("Redeem on your favorite digital Gift card")}</p></span>
                        <div className='text-muted mx-4 m-0 py-md-3 py-0'>
                        {t("You have a ")}<span className='text-dark'> {this.props.currencySymbol} {this.props?.history?.location?.state?.amount || this.props?.points} </span>{t("available balance to Redeem")}
                    </div>
                    <button className="proceed-yes-btn align-self-center mt-3"
                        onClick={() => { this.setState({ show: false }) }}>
                        <span className="fw-bold">{t("OK")}</span></button>
                </div>
            </ModalWrapper>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        slug:state.tenetReducer?.slug,
        points : state?.point?.pointBalance 
    };
};
export default connect(mapStateToProps)(withTranslation()(WelcomePopup));