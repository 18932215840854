
import { ADD_TO_CART, REMOVE_ITEM, SUB_QUANTITY, ADD_QUANTITY, DESTROY_CART } from './cartTypes'

//add cart action
export const addToCart = (data) => {
    return {
        type: ADD_TO_CART,
        payload: data
    }
}
//remove item action
export const removeItem = (id) => {
    return {
        type: REMOVE_ITEM,
        id
    }
}
//subtract qt action
export const subtractQuantity = (id) => {
    return {
        type: SUB_QUANTITY,
        id
    }
}
//add qt action
export const addQuantity = (id) => {
    return {
        type: ADD_QUANTITY,
        id
    }
}

export const destroyCart = () => {
    return {
        type: DESTROY_CART
    }
}