import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import MyRouter from './components/routes';
// import configureStore from './store/configureStore'
// import rootReducer from '../src/components/reducers'
import './assests/css/CustomBootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css';
// Global css file 
import './assests/css/Global.css'
import './index.css';
import reportWebVitals from './reportWebVitals';
import './i18n';
import store from './Redux/store';
import axios from 'axios';


// const store = configureStore(rootReducer)

axios.interceptors.request.use(function (config) {
  config.headers.Tenant = store.getState()?.tenetReducer?.slug?.slug;
  config.headers["x-merit-locale"] = `${store.getState()?.countries?.lang}-${store.getState()?.countries?.countryCode}`;
  return config;
}, null, { synchronous: true });

render(
    <Provider store={store}>
      <Suspense fallback={(<div>...Loading</div>)}>
        <BrowserRouter>
            <MyRouter/>
        </BrowserRouter>
      </Suspense>
  </Provider>,
  document.getElementById('root')
)

// ReactDOM.render(
//   <Suspense fallback={(<div>Loading</div>)}>
//     <BrowserRouter>
//     <MyRouter></MyRouter>
//     </BrowserRouter>
//   </Suspense>,
//   document.getElementById('root')
// );

// document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
