import React, { Component } from "react";
import "../MobileMenu/MobileMenu.css";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DRCLogo from "../../../assests/img/header/drc-logo.svg";
import { ReactComponent as DropDownIcon } from "../../../assests/img/DropArrow.svg";
import { Collapse } from "react-bootstrap";
import LogOutModel from "../Modals/LogOutModel";

import {
  // fetchCountries,
  setCountryHandler,
} from "../../../Redux/country/countriesAction";
import { connect } from "react-redux";

export class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftCardCollapse: false,
      offerCollapse: false,
      audioBookCollapse: false,
      partnerProgramCollapse: false,
      experienceCollapse: false,
      mobileTopCollapse: false,
      gamesCollapse: false,
      itemsCollapse: false,
      showLogout: false,
    };
  }
  logoutHandler=()=>{
    this.setState({showLogout:true})
  }
  closeHandle=()=>{
    this.setState({showLogout:false})
  }
  ToggleLang=(e)=>{
    this.props.toggelLangHandle(e)
  }
  render() {
    const { t, countries, lang } = this.props;
    const menuLinks =[
      {name:t("Rewards"),url:'/'},   
      {name:t("My Account"),url:'/mywallet/giftCards'},
      {name:t("FAQ"),url:'/faq'},
      {name:t("Contact Us"),url:'/contactus'},
  ]
    return (
      <div className="sideNav_container">
        <div className="sideNav_signin">
          <span className="sideNav-Welcome-text">{t("Welcome to")}</span>
          <span>
            <img src={DRCLogo} alt="Digital Reflaction" className="mb-2"/>
          </span>
        </div>
        <div>
          {menuLinks?.map(item => {
            return(
              <div className="sideNav_main_trending_container" key={item?.url}>
                <div className="link_container" onClick={this.props.closeHandle}>
                  <Link to={item?.url} className={`d-flex align-items-center gap-2 py-2 px-4 rounded-3 text-decoration-none ${item?.url === window?.location.pathname ? "opacity-1" : "opacity-7"}`}>
                    <span className="sideNav-link-text">{item?.name}</span>
                  </Link>
                </div>
              </div>
            )
          })}
  {
    this.props.valid && 
          <div className="d-flex flex-row justify-content-between w-100 align-items-center py-2 px-4 rounded-3">
            <div className="cursor">
              <div className="d-flex align-items-center gap-2">
                <span
                  className="sideNav-Logout-text"
                  onClick={this.logoutHandler}
                >
                  {t("Sign out")}
                </span>
              </div>
            </div>
          </div>
  }
          <LogOutModel showLogout={this.state.showLogout} closeHandle={this.closeHandle} />
        </div>
        <div className="language-toggler">
            <div>
              <span className="mx-1 my-1">{t("Language")}</span>
            </div>
            <div className="d-flex flex-row w-100 justify-content-between align-items-center border border-top-0">
              {/* <select
                className="d-flex flex-row justify-content-between w-100"
                id="sidenavCountry"
                onChange={(e)=>{this.ToggleLang(e.target.value)}}
              >
                <option
                  value="English"
                  selected={lang === "en" ? true : false}
                  style={{ backgroundColor: "#fff", color: "#000" }}
                >
                  English
                </option>
                <option
                  value="Spanish"
                  selected={lang === "es" ? true : false}
                  style={{ backgroundColor: "#fff", color: "#000" }}
                >
                  Spanish
                </option>
              </select> */}
              {
                this.props?.languages.length > 0 ?
                <select
                className="d-flex flex-row justify-content-between w-100"
                id="sidenavCountry"
                            value={this.props.lang}
                            onChange={(e)=>{this.ToggleLang(e.target.value)}}
                            >
                            {
                                this.props?.languages.length > 0 &&
                                this.props?.languages.map((val) => (
                                <option key={val} value={val}>
                                {this.props.language.find(item => item.code === val)?.lang}
                                    {/* {val} */}
                                </option>
                                ))}
                            </select>
                            :
                            this.props.language?.find(item => item.code === this.props?.languages[0])?.lang           
}
              <div >
              </div>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.countries.countries,
    countriesloading: state.countries.loading,
    currancyCode: state.countries.convertionRate.currency_code,
    lang: state.countries.lang,
    valid : state?.auth?.valid
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCountryHandler: (val) => dispatch(setCountryHandler(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MobileMenu));
