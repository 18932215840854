
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { removeItem, addQuantity, subtractQuantity, destroyCart } from '../../Redux/cart/cartAction'
import removeIcon from "../../assests/img/ProductDetails/Delete.png";
import { withTranslation } from 'react-i18next';
import add from "../../assests/img/ProductDetails/Plus.svg"
import add2 from "../../assests/img/ProductDetails/Plus2.svg"
import dec from "../../assests/img/ProductDetails/Minus.svg"
import dec2 from "../../assests/img/ProductDetails/Minus2.svg"
import backButton from "../../assests/img/carret-left-blue.svg";
import LoginModal from 'components/shared/Modals/LoginModal';
import WarningModal from 'components/shared/Modals/WarningModal';
import ProcessingModal from "components/shared/Modals/ProcessingModal"
import FailMessageModal from "components/shared/Modals/FailMessageModal"
import SuccessfullMessageModal from "components/shared/Modals/SuccessfulMessageModal"
import axios from 'axios';
import { REACT_APP_baseUrl_V2 } from "../../utils/app-constants"
import cookie from "react-cookies"
import editPng from '../../assests/img/Edit.png'
class Cart extends Component {
  state = {
    total: 0,
    update: false,
    showPopup: false,
    email: "",
    error: {
      email: ""
    },
    insufficientBalance: false,
    paymentFail: false,
    pending: false,
    checkoutBtn: false,
    errorMsg: "",
    promoCode: "",
    loading: true,
    orderNumber:'',
  };

  //to remove the item completely
  handleRemove = (id) => {
    this.setState({ update: !this.state.update });
    this.props.removeItem(id);
  };
  //to add the quantity
  handleAddQuantity = (id, quantity) => {
    if (quantity < 5) {
      this.setState({ update: !this.state.update });
      this.props.addQuantity(id);
    }
  };
  //to substruct from the quantity
  handleSubtractQuantity = (id, quantity) => {
    if(quantity > 1){
      this.setState({ update: !this.state.update });
      this.props.subtractQuantity(id);
    }
  };
  // to calculate cart total
  orderTotal = (array) => {
    let totalPrice = array.reduce(function (accumulator, item) {
      return accumulator + item.quantity * item.denomination;
    }, 0);
    this.setState({ total: totalPrice });
  };

  componentDidMount() {
    this.orderTotal(this.props.items);
    let promoCode = cookie.load("RedemptionCode")
    this.setState({ promoCode: promoCode })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update)
      this.orderTotal(this.props.items);
  }

  processOrder = (id) => {
    let payload = {}
    payload = {
      "id": id
    }

    let token = localStorage.getItem('userToken')
    axios.post(`${REACT_APP_baseUrl_V2}/orders/process_order`,
      payload,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      }).then(response => {
        if (response.status === 200 && response.data?.code) {
          this.setState({
            pending: false,
            paymentFail: true,
            errorMsg: response.data?.message
          })
        } else {
          const orderStatus = response.data?.data?.status
          if (orderStatus === "CANCELED") {
            this.setState({
              pending: false,
              paymentFail: true,
              loading: false,
              errorMsg: response.data?.message
            })
          }
          else if (orderStatus === "FULLFILLED" || orderStatus === "CONFIRMED" || orderStatus === "APPROVED" || orderStatus === "REVIEW") {
            this.setState({
              pending: false,
              loading: false,
              errorMsg: response.data?.message,
            })
          }
        }
      })
      .catch(error => {
        this.setState({
          pending: false,
          paymentFail: true,
          errorMsg: "Something went wrong!! Please try again!!"
        })
      })
  }

  createOrder = () => {
    this.setState({ checkoutBtn: true })
    let Items = []
    const Data = JSON.parse(localStorage.getItem("cart"))
    for (let i = 0; i < Data.length; i++) {
      if(Data[i]?.isPhysical){
        Data[i]["address_id"]= this.props.selectedAddress?.id
      }else{
        Data[i]["address_id"]= ""
      }
      delete Data[i]?.id
      delete Data[i]?.image
      delete Data[i]?.name
      delete Data[i]?.isPhysical
      delete Data[i]?.sla
      delete Data[i]?.recipient
      Items.push(Data[i])
    }
    const payload = {
      "items": Items
    }
    let token = localStorage.getItem('userToken')
    this.setState({ pending: true })
    axios.post(`${REACT_APP_baseUrl_V2}/orders`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => {
        if (response.status === 200 && response.data?.code) {
          this.setState({
            paymentFail: true,
            pending: false,
            errorMsg: response.data?.message,
            checkoutBtn: false
          });
        } else {
          const orderDetails = response?.data?.data;
          this.setState({orderNumber : orderDetails?.id})
          this.processOrder(orderDetails?.id)
        }
      })
      .catch((error) => {
        if (error.response?.data?.message?.includes("Previous order for the same product is in progress") || error.response?.data?.code === "ERR-0026") return
        this.setState({
          paymentFail: true,
          pending: false,
          errorMsg: "Something went wrong!! Please try again!!",
          checkoutBtn: false
        });
      });
  }

  BuyNowWord = (val) => {
    const Data = JSON.parse(localStorage.getItem("cart"))
    let includesPhysicalCard = false
    for (let i = 0; i < Data.length; i++) {
      if(Data[i]?.isPhysical){
        includesPhysicalCard = true
        break
      }
    }
    let pointBalance = this.props.pointBalance ? this.props.pointBalance : 0
    if (!this.props.valid) {
      this.setState({
        showPopup: val
      })
    } else if (this.state.total > pointBalance) {
      this.setState({ insufficientBalance: true })
      return
    }else if(this.props?.selectedAddress === null && includesPhysicalCard){
      alert("Please add delivery address")
    }else {
      this.createOrder()
    }
  }

  render() {
    const { t, selectedAddress } = this.props
    const { paymentFail, loading, errorMsg, pending } = this.state

    let addedItemsDesktop =
      this.props.items?.length > 0 && (
        this.props.items.map((item) => {
          return (
            <div key={`shop_cart_${item.id}`} className="my-4 d-md-block d-none pl-5">
              <div className="d-md-flex shoping-details p-4 border-rad-top">
                <div className="cart-img mr-4">
                  <img
                    src={item?.image}
                    alt={item?.name}
                    className="img-fluid"
                  />
                </div>
                <div className="cart-details d-flex justify-content-between">
                  <div className='d-flex flex-column align-items-between w-100'>
                    <div className='d-flex justify-content-between'>
                      <span className='fw-bold fs-4 '>{t(item?.name)}</span>
                      <div
                        className="border-0 cursor-pointer text-decoration-none fw-bold"
                        onClick={() => this.handleRemove(item.id)}
                      >
                        <span className="mx-1">
                          <img className="" src={removeIcon} alt="remove icon" />
                        </span>
                        <span className='text-danger'>
                          {t("Remove")}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex flex-wrap mt-3'>
                      <div className='custom-margin-right'>
                        <span className='mt-3 text-muted'>{t("Delivery Type")}:</span>
                        <p className='text-center drc-title fw-bold mt-1'>{item?.recipient?.email ? item?.recipient?.email : t('Shipping Address')}</p>
                      </div>
                      <div className='custom-margin-right'>
                        <span className='mt-3 text-muted'>{t("Quantity")}:</span>
                        <div className='d-flex mt-1'>
                          <img src={item?.quantity > 1 ? dec : dec2} alt="dec" onClick={() => { this.handleSubtractQuantity(item.id,item?.quantity) }} className="cursor-pointer" />
                          <p className='m-0 mx-3 fw-bold'>{item?.quantity}</p>
                          <img src={item?.quantity < 5 ? add : add2} alt="add" onClick={() => { this.handleAddQuantity(item.id, item?.quantity) }} className="cursor-pointer" />
                        </div>
                      </div>
                      <div className='custom-margin-right'>
                        <span className='mt-3 text-muted'>{t("Price")}:</span>
                        <p className='text-center drc-title fw-bold mt-1'>{this.props.currencySymbol} {item?.denomination}</p>
                      </div>
                    </div>
                    {
                      !item?.isPhysical && 
                      <>
                          {/* <span className="fw-bold">{t('You will receive your eGift card directly to the email address you have provided,')}</span> */}
                        <span>
                          {item?.sla}
                        </span>
                      </>
                    }
                  </div>
                </div>
              </div>
              {
                selectedAddress === null && item?.isPhysical ? 
                <button className="new-address-btn mt-3 text-center"
              onClick={(e) => this.props.history.push("/address/selectedAddress")}>{t("Add Address")}
            </button>
                : item?.isPhysical &&
                <div className='p-4 shoping-details-2 border-rad-bottom'>
                  <div className='bg-white p-3 custom-border-rad'>
                    <table className='w-100'>
                      <tr className='d-flex mb-3'>
                        <td className='w-25'>
                          <div className='d-flex flex-column align-items-between w-100'>
                            <span className='text-muted'>{t("Full Name")}:</span>
                            <span className='fw-bold'>{selectedAddress?.fullName}</span>
                          </div>
                        </td>
                        <td className='w-25'>
                          <div className='d-flex flex-column align-items-between w-100'>
                            <span className='text-muted'>{t("City")}:</span>
                            <span className='fw-bold'>{selectedAddress?.city}</span>
                          </div>
                        </td>
                        <td className='w-25'>
                          <div className='d-flex flex-column align-items-between w-100'>
                            <span className='text-muted'>{t("Zip Code")}:</span>
                            <span className='fw-bold'>{selectedAddress?.zip}</span>
                          </div>
                        </td>
                        <td className='w-25'>
                          <Link to="address/selectedAddress">
                          <div className='d-flex align-items-between w-100'>
                            <img src={editPng} alt="edit" />
                            <span className='ml-1'>{t("Edit Address")}</span>
                          </div>
                          </Link>
                        </td>
                      </tr>
                      <tr className='d-flex mb-3'>
                        <td className='w-25'>
                          <div className='d-flex flex-column align-items-between w-100'>
                            <span className='text-muted'>{t("Address")}:</span>
                            <span className='fw-bold'>{selectedAddress?.line1}</span>
                          </div>
                        </td>
                        <td className='w-25'>
                          <div className='d-flex flex-column align-items-between w-100'>
                            <span className='text-muted'>{t("State")}:</span>
                            <span className='fw-bold'>{selectedAddress?.state}</span>
                          </div>
                        </td>
                        {selectedAddress?.phone &&
                        <td className='w-25'>
                        <div className='d-flex flex-column align-items-between w-100'>
                          <span className='text-muted'>{t("Phone")}:</span>
                          <span className='fw-bold'>{selectedAddress?.phone}</span>
                        </div>
                      </td>
                    }
                        
                      </tr>
                    </table>
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    <span className="fw-bold">{t('You will receive your physical directly to the shipping address you have provided,')}</span>
                      <span>
                        {item?.sla}
                      </span>
                    </div>
                </div>
              }
            </div>
          );
        })
      )
    let addedItemsMobile =
      this.props.items?.length > 0 && (
        this.props.items.map((item) => {
          return (
            <div key={`shop_cart_${item.id}`} className="my-4 d-md-none d-block w-100 px-3">

              <div className="d-md-flex shoping-details p-3 border-rad-top">
                <div className="cart-img-mb d-flex justify-content-between">
                  <div className='d-flex align-items-start'>
                    <img
                      src={item?.image}
                      alt={item?.name}
                      className="img-fluid mr-2"
                    />
                    <span className='fw-bold fs-6 '>{t(item?.name)}</span>
                  </div>
                  <div
                    className="border-0 cursor-pointer text-decoration-none fw-bold"
                    onClick={() => this.handleRemove(item.id)}
                  >
                    <span className="mx-1">
                      <img className="" src={removeIcon} alt="remove icon" />
                    </span>
                    {/* <span className='text-danger'>
                      {t("Remove")}
                    </span> */}
                  </div>

                </div>
                <div className="cart-details d-flex justify-content-between">
                  <div className='d-flex flex-column align-items-between w-100'>
                    <div className='d-flex justify-content-between'>
                    </div>
                    <div className='d-flex flex-wrap mt-3'>
                      <div className='custom-margin-right'>
                        <span className='mt-3 text-muted'>{t("Delivery Type")}:</span>
                        <p className='text-center drc-title fw-bold mt-1'>{item?.recipient?.email ? item?.recipient?.email : t('Shipping Address')}</p>
                      </div>
                      <div className='custom-margin-right'>
                        <span className='mt-3 text-muted'>{t("Quantity")}:</span>
                        <div className='d-flex mt-1'>
                          <img src={dec} alt="dec" onClick={() => { this.handleSubtractQuantity(item.id) }} className="cursor-pointer" />
                          <p className='m-0 mx-3 fw-bold'>{item?.quantity}</p>
                          {
                            item?.quantity < 5 &&
                            <img src={add} alt="add" onClick={() => { this.handleAddQuantity(item.id, item?.quantity) }} className="cursor-pointer" />
                          }
                        </div>
                      </div>
                      <div className='custom-margin-right'>
                        <span className='mt-3 text-muted'>{t("Price")}:</span>
                        <p className='text-center drc-title fw-bold mt-1'>{this.props.currencySymbol} {item?.denomination}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                selectedAddress === null ? 
                <button className="new-address-btn mt-3 text-center"
                  onClick={(e) => this.props.history.push("/address/selectedAddress")}>{t("Add Address")}
                </button> 
                : 
                <div className='p-4 shoping-details-2 border-rad-bottom'>
                  <div className='bg-white p-3 custom-border-rad'>
                    <div className='d-flex flex-column align-items-between mb-2'>
                      <span className='text-muted'>{t("Full Name")}:</span>
                      <span className='fw-bold'>{selectedAddress?.fullName}</span>
                    </div>
                    <div className='d-flex flex-column align-items-between mb-2'>
                      <span className='text-muted'>{t("Address")}:</span>
                      <span className='fw-bold'>{selectedAddress?.line1}</span>
                    </div>
                    <div className='d-flex flex-column align-items-between mb-2'>
                      <span className='text-muted'>{t("City")}:</span>
                      <span className='fw-bold'>{selectedAddress?.city}</span>
                    </div>
                    <div className='d-flex flex-column align-items-between mb-2'>
                      <span className='text-muted'>{t("State")}:</span>
                      <span className='fw-bold'>{selectedAddress?.state}</span>
                    </div>
                    <div className='d-flex flex-column align-items-between mb-2'>
                      <span className='text-muted'>{("Zip Code")}:</span>
                      <span className='fw-bold'>{selectedAddress?.zip}</span>
                    </div>
                    <div className='d-flex flex-column align-items-between mb-2'>
                    {selectedAddress?.phone &&
                        <>
                        <span className='text-muted'>{t("Phone")}:</span>
                        <span className='fw-bold'>{selectedAddress?.phone}</span>
                      </>
                    }
                    
                      <Link to="address/selectedAddress">
                        <div className='d-flex align-items-between w-100'>
                            <img src={editPng} alt="edit" />
                            <span className='ml-1'>{t("Edit Address")}</span>
                          </div>
                        </Link>
                    </div>
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    {
                      item?.item?.isPhysical ? 
                      <>
                      {/* <span className="fw-bold">{t('You will receive your physical directly to the shipping address you have provided,')}</span> */}
                      <span>
                        {item?.sla}
                      </span>
                      </> : 
                      <>
                        {/* <span className="fw-bold">{t('You will receive your eGift card directly to the email address you have provided,')}</span> */}
                      <span>
                        {item?.sla}
                      </span>
                      </>
                    }
                      
                    </div>

                  {/* <div className='d-flex flex-column mt-3'>
                    <p className='fw-bold'>You will receive your Gift card directly to the email address you have provided,</p>
                    <p className='fw-normal'>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                  </div> */}
                </div>
              }
            </div>
          );
        })
      )

    const desktopOrderSummaryCard = (
      <div className='mr-5 d-md-block d-none'>
        <SuccessfullMessageModal show={!pending && !paymentFail && !loading} lang={this.props.lang} promoCode={this.state.promoCode} currencySymbol={this.props.currencySymbol} orderNumber={this.state.orderNumber}/>
        <FailMessageModal show={!pending && paymentFail} lang={this.props.lang} Message={errorMsg} />
        <ProcessingModal show={pending} lang={this.props.lang} />
        <div className="card mb-4 order-summary-card">
          <div className="card-body">
            <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5 drc-title">
              {t("Order Summary")}:
            </div>


            <div className="d-flex flex-row justify-content-between mt-2 py-2">
              <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
              <span className='fw-bold'>{this.props.currencySymbol} {this.state.total}</span>
            </div>

            <hr className="hr-line" />
            <div className="d-flex flex-row justify-content-between pt-1 pb-3">
              <h5 className="text-black-50 m-0">{t("Total")}:</h5>
              <span className='fw-bold'>{this.props.currencySymbol} {this.state.total}</span>
            </div>
            <br />
            <button className="new-address-btn w-100 text-center" disabled={this.state.checkoutBtn}
              onClick={(e) => { e?.detail === 1 && this.BuyNowWord(this.props.error) }}>{t("Proceed to checkout")}
            </button>
            <button className="delivery-order-summary-btn w-100 mt-3 text-center"
              onClick={(e) => this.props.history.push("/")}>{t("Continue Shopping")}
            </button>
          </div>
        </div>
      </div>
    );
    const mobileOrderSummaryCard = (
      <div style={{ marginBottom: "7rem" }} className='d-md-none d-block w-100'>
        <SuccessfullMessageModal show={!pending && !paymentFail && !loading} lang={this.props.lang} promoCode={this.state.promoCode} currencySymbol={this.props.currencySymbol} orderNumber={this.state.orderNumber}/>
        <FailMessageModal show={!pending && paymentFail} lang={this.props.lang} Message={errorMsg} />
        <ProcessingModal show={pending} lang={this.props.lang} />
        <div className="card mb-4 order-summary-card">
          <div className="card-body">
            {/* <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5 drc-title">
              {t("Order Summary")}:
            </div> */}


            {/* <div className="d-flex flex-row justify-content-between mt-2 py-2">
              <span className="text-black-50 fw-bold">{t("Subtotal")}:</span>
              <span className='fw-bold'>{this.props.currencySymbol} {this.state.total}</span>
            </div> */}

            {/* <hr className="hr-line" /> */}
            <div className="d-flex flex-row align-items-center justify-content-between">
              <h5 className="text-black-50 m-0">{t("Total")}:</h5>
              <span className='fw-bold fs-3 drc-title'>{this.props.currencySymbol} {this.state.total}</span>
            </div>
            <br />
            <button className="new-address-btn w-100 text-center" disabled={this.state.checkoutBtn}
              onClick={(e) => { e?.detail === 1 && this.BuyNowWord(this.props.error) }}>{t("Redeem Now")}
            </button>
            <button className="delivery-order-summary-btn w-100 mt-3 text-center"
              onClick={(e) => this.props.history.push("/")}>{t("Continue Shopping")}
            </button>
          </div>
        </div>
      </div>
    );
    return (
      <div className="cart-container">
        <LoginModal show={this.state.showPopup} close={this.BuyNowWord} />
        {
          this.state.insufficientBalance && <WarningModal show={this.state.insufficientBalance} closeModal={() => { this.setState({ insufficientBalance: false }) }} currencySymbol={this.props.currencySymbol} />
        }
        <div className="cart mt-4 w-100">
          {
            this.props.items?.length > 0 ?
              <>
                <div className='d-md-none d-block px-md-5 px-3'>
                <div className='d-flex flex-row'>
                <div
                className="fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props?.history?.goBack()
                  // this.props.ShowHeader(true)
                }}
              >
                <section
                  style={{
                    transform: `${this.props.lang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`
                    }}
                  >
                    <img src={backButton} alt="" style={{marginTop:'5px'}}/> <span className='body-typo' style={{color:'#005E9A'}}>{t("Back")}</span>
                  </section>
                </section>
              </div>
                  </div>
                  <span className='drc-title fs-2 fw-bold'>{t("Shopping Cart")}</span>
                  {/* <button
                    className="border-0 bg-white text-decoration-none color-skyblue fs-4 mt-3"
                    onClick={() => this.props.destroyCart()}
                  >
                    <span className="mx-1">
                      <img className="" src={removeIcon} alt="remove icon" />
                    </span>
                    {t("Remove All")}
                  </button> */}
                  <p className='m-0'>{this.props.items?.length} {t("items")}</p>
                </div>
                <div className='d-md-flex justify-content-between'>
                  <div className='w-100'>
                    {addedItemsDesktop}
                    {addedItemsMobile}
                  </div>
                  <div className=''>
                    <div className='ml-3'>
                      {desktopOrderSummaryCard}
                    </div>
                    <div className='mx-3'>
                      {mobileOrderSummaryCard}
                    </div>
                  </div>
                </div>
              </> :
              <div className='empty-cart'>
                <span className='fs-4 fw-bold'>{t("Your Cart is Empty")}</span>
                <button className="new-address-btn mt-4 mb-5 px-4 text-center"
                  onClick={(e) => this.props.history.push("/")}>{t("Continue Shopping")}
                </button>
              </div>
          }

        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    items: state.cartReducer?.addedItems,
    valid: state.auth.valid,
    error: state.auth.error,
    pointBalance: state.point?.pointBalance,
    selectedAddress : state?.addressReducer?.selectedAddress
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (id) => { dispatch(removeItem(id)) },
    addQuantity: (id) => { dispatch(addQuantity(id)) },
    subtractQuantity: (id) => { dispatch(subtractQuantity(id)) },
    destroyCart: () => dispatch(destroyCart())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter((withTranslation()(Cart))));