import {TENENT_REQUEST,TENENT_SUCCESS,TENENT_FAILURE} from "./TenetTypes"

const initialState = {
    loading: false,
    slug:null,
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case TENENT_REQUEST:
            return {
                ...state,
                loading:true,
            }
        case TENENT_SUCCESS:
            return {
                ...state,
                loading:false,
                slug:action.payload,
            }
        case TENENT_FAILURE:
            return {
                ...state,
                loading:false,
                slug:action.payload,
            }
        default: return state
    }
}

export default reducer