// import axios from 'axios';
import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


class AboutAndTC extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selected:'about'
         }
    }
    setSelected=(val)=>{
        this.setState({
            selected:val
        })
    }
    // Detect Hyperlink form Trems
    detectURLs(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text?.split(urlRegex)
          .map(part => {
            if (part?.match(urlRegex)) {
              return <a href={part} key={part} target="_blank" rel="noreferrer" > {part} </a>;
            }
          else{
                return part;
          }
          });
      } 

   
    render() { 
        const experienceDetails = this.props.experienceDetails  
        const {t} = this.props
        return ( 
            <div className='pb-5'>
                <div className="d-grid grid-cols-2 text-center">
                    <div className={`py-2 ${this.state.selected==='about' ? 'bg-color text-light' :'border-color text-muted'}`}
                        onClick={()=>{this.setSelected('about')}}>
                        {t("About")}
                    </div>
                    <div className={`py-2 ${this.state.selected==='t&c' ? 'bg-color text-light' :'border-color text-muted'}`}
                        onClick={()=>{this.setSelected('t&c')}}>
                        {t("T&Cs")}
                    </div>
                </div>
                {
                    this.state.selected==='about' &&
                    <div className='py-4'>
                        <p>
                            {this.props.lang === 'en' ? experienceDetails?.about_experience?.split("\n")?.map((e,index)=> <div>{this.detectURLs(e)}</div> ): experienceDetails?.about_experience_arabic?.split("\n")?.map((e,index)=> <div>{this.detectURLs(e)}</div> )}
                        </p>
                        
                        <Accordion defaultActiveKey="0" >
                        <div>
                                <Accordion.Toggle className='bg-color text-light rounded-0 arrow-down' as={Card.Header} eventKey="0">
                                    <span className={`${this.props.lang === 'ar' ? "mr-4" : ""}`} >{t("What are you going to do")}</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                {this.props.lang === 'en' ? experienceDetails?.details?.split('\n')?.map((e,index) => <div  >{e}</div>  ) :
                                
                                 experienceDetails?.details_arabic?.split('\n')?.map((e,index) => <li className="py-1">{this.detectURLs(e)}</li>) }
                                
                                </Card.Body>
                                </Accordion.Collapse>
                            </div>
                            <div>
                                <Accordion.Toggle className='bg-color text-light rounded-0 arrow-down' as={Card.Header} eventKey="1">
                                    <span className={`${this.props.lang === 'ar' ? "mr-4" : ""}`} >{t("What's included")}</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                {this.props.lang === 'en' ?  experienceDetails?.whats_included?.split('\n')?.map((e,index) => <div className="py-0">{this.detectURLs(e)}</div> ) :
                                
                                 experienceDetails?.whats_included_arabic?.split('\n')?.map((e) =><div className="py-0">{this.detectURLs(e)}</div>  ) }
                                
                                </Card.Body>
                                </Accordion.Collapse>
                            </div>
                            <div>
                                <Accordion.Toggle className='bg-color text-light rounded-0 arrow-down' as={Card.Header} eventKey="2">
                                    <span className={`${this.props.lang === 'ar' ? "mr-4" : ""}`} >{t("What's Excluded")}</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                {this.props.lang === 'en' ? experienceDetails?.whats_excluded?.split('\n')?.map((e,index) => <li style={{listStyle:'none'}} className="py-1">{this.detectURLs(e)}</li>  ) :
                                
                                 experienceDetails.whats_excluded_arabic?.split('\n')?.map((e,index) => <li style={{listStyle:'none'}} className="py-1">{this.detectURLs(e)}</li>) }
                                
                                </Card.Body>
                                </Accordion.Collapse>
                            </div>
                            
                            <div>
                                <Accordion.Toggle className='bg-color text-light rounded-0 arrow-down' as={Card.Header} eventKey="3">
                                    <span className={`${this.props.lang === 'ar' ? "mr-4" : ""}`} >{t("Cancellation Policy")}</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                
                                {this.props.lang === 'en' ? experienceDetails?.cancellation_policy !== null ? experienceDetails?.cancellation_policy?.split("\n")?.map((e,index) =><li className="py-1">{this.detectURLs(e)}</li>  ) : false :
                                
                                experienceDetails?.cancellation_policy_arabic !== null ? experienceDetails?.cancellation_policy_arabic?.split("\n")?.map((e,index) =><li className="py-1">{this.detectURLs(e)}</li> ) : false }
                                </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </div>
                }
                {
                    this.state.selected==='t&c' &&
                    <div className='py-4'>
                        {/* <div className='fw-bold color-blue fs-5'>{t("Included in the price")}</div> */}
                        <div className='py-4'>
                        {this.props.termsAndConditions.terms && 
                            this.props.termsAndConditions.terms[(this.props.lang==="ar" && this.props.termsAndConditions?.terms?.find(val=>val?.language?.includes("ARABIC") ) )?1:0]
                            .terms_text?.split("\n")
                            ?.map(terms=>
                            <div className="py-1">
                                {this.detectURLs(terms)}
                            </div>
                         )}
                        </div>
                        {/* <div className='text-danger fw-bold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</div> */}
                    </div>
                }
            </div>
         );
    }
}
const mapStateToProps = state => {
    return {
        experienceDetails:state.experiences.experienceDetails,
        termsAndConditions:state.experiences.experienceTerms,
    }
}



export default connect(mapStateToProps)(withTranslation()(AboutAndTC));
