import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchBanners } from '../../Redux/banners/bannersAction';
import '../../assests/css/App.css';
import { Link } from 'react-router-dom';

class BannerSection extends Component{

constructor(props){
    super(props)
    this.state={
    }
}

componentDidMount(){
    this.props.fetchBanners(this.props.lang);
}

render(){
    let touchstartX = 0;
    let touchendX = 0;
    function handleGesture() {
        if (touchendX < touchstartX && document.getElementsByName('BannerCardNext')?.length !== 0) {document.getElementsByName('BannerCardNext')[0].click()} ;
        if (touchendX > touchstartX && document.getElementsByName('BannerCardPrev')?.length !== 0) {document.getElementsByName('BannerCardPrev')[0]?.click()} ;
        }
    const {banners} = this.props
    const desktopView = (<div className="d-md-block d-none container-fluid pb-md-5 pb-0 mb-2 mt-1">
    <div className="row">
                <div className="col-12 p-0">
                    <div id="carousel-indicator" data-interval='3000' className="carousel slide" data-ride="carousel">
                        <div  >
                            <ol className="carousel-indicators">
                                {
                                    banners?.map((e,index) => index<4 && <li data-target="#carousel-indicator" data-slide-to={index} className={index===0 && `active`}></li>)
                                } 
                                
                            </ol>
                        </div>
                        
                        <div role="listbox" className="carousel-inner px-2">
                        {this.props.banners &&   this.props.banners?.length > 0 && banners?.map((banner,index) =>
                             <div key={index} className={`${index===0 && `active`} carousel-item `}>
                                    {
                                     banner.banner_clickable_flag && banner.banner_clickable_flag ? 
                                        <Link to={banner.redirect_url && banner.redirect_url}>
                                            <img className="d-block w-100" src={`${banner?.banner?.large_rectangle}`} alt='' />
                                        </Link> : <img className="d-block w-100" src={`${banner?.banner?.large_rectangle}`} alt='' />
                                    }
                                
                            </div>) }
                             
                        </div>
                        <div className="d-md-block d-none">
                            {
                                banners?.length > 1 && 
                        <>
                            <a className="carousel-control-prev" href="#carousel-indicator" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carousel-indicator" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
</div>)


const mobileView = (
    <div className="d-block d-md-none container-fluid bg-light pb-md-5 pb-4 mb-2">
    <div  className="row">
                <div  className="col-12 p-0">
                    <div id="carouselMobExampleIndicators" onTouchStart={(e)=>{touchstartX = e.changedTouches[0].screenX;}} onTouchEnd={(e)=>{touchendX = e.changedTouches[0].screenX;
  handleGesture();}} data-interval='3000' className="carousel slide" data-ride="carousel">
                        <div  className='d-sm-inline-block'>
                            <ol className="indicator-size carousel-indicators mb-5 mt-3">
                                {
                                    banners?.map((e,index) =>index<4 && <li data-target="#carouselMobExampleIndicators" data-slide-to={index} className={index===0 && `active`}></li>)
                                } 
                                
                            </ol>
                        </div>
                        
                        <div className="carousel-inner px-2">
                        {this.props.banners && this.props.banners?.length > 0 && banners?.map((banner,index) =>
                              <div key={index} className={`${index===0 && `active`} carousel-item `}>
                                {
                                   banner.banner_clickable_flag && banner.banner_clickable_flag ? 
                                    <Link to={banner.redirect_url && banner.redirect_url}>
                                        <img className="d-block w-100 mb-2"style={{height:"8rem"}}  src={`${banner?.banner?.large_rectangle}`} alt='' />
                                    </Link> : 
                                    <img className="d-block w-100 mb-2" style={{height:"8rem"}} src={`${banner?.banner?.large_rectangle}`} alt='' />
                                }
                            </div>) }
                             
                        </div>
                        <div  className="d-md-block d-none">
                            {
                                banners?.length > 1 && <>
                                <a  className="carousel-control-prev" name='BannerCardPrev' href="#carouselMobExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a  className="carousel-control-next" name='BannerCardNext' href="#carouselMobExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                                </>
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
</div>
)







    return(
        <>
            {this.props.loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section> : <>
                {!this.props.isMultiLang && 
                    <>
                    {desktopView}
                    {mobileView}
                    </>
                }
            </>
        }
        </>
    )
}

}

const mapStateToProps = state => {
    return {
        banners:state.banners.banners?.filter(e => e.section_name ==='SECTION-1'),
        loading:state.giftCards.bannerloading,
        slug:state.tenetReducer?.slug?.slug,
        isMultiLang : state.tenetReducer?.slug?.featureFlags?.isMultilingual
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchBanners: (lang) => dispatch(fetchBanners(lang))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSection);