import isDev from './utils';
import Multitenets from "./MultiTenent/Multi-tenets.json"

let domain = window.location?.origin;        
let slug = Object.values(Multitenets)?.filter(val => val.domain === domain)

export const REACT_APP_ADD_TO_CART = isDev() ? process.env.REACT_APP_ADD_TO_CART : `${window._env_.REACT_APP_ADD_TO_CART}`;

export const REACT_APP_api_V2 = isDev() ? process.env.REACT_APP_API_V2 : `${window._env_.REACT_APP_API_V2}`;

export const REACT_APP_baseUrl = isDev() ? process.env.REACT_APP_ROR_SERVER_API_HOSTNAME : `${window._env_.REACT_APP_ROR_SERVER_API_HOSTNAME}`;

export const REACT_APP_baseUrl_V2 = isDev() ? process.env.REACT_APP_ROR_SERVER_API_HOSTNAME_V2 : `${window._env_.REACT_APP_ROR_SERVER_API_HOSTNAME_V2}`;

export const REACT_APP_baseUrl_V2_V3 = isDev() ? process.env.REACT_APP_ROR_SERVER_API_HOSTNAME_V3 : `${window._env_.REACT_APP_ROR_SERVER_API_HOSTNAME_V3}`


export const REACT_APP_baseUrl_V4 = isDev() ? process.env.REACT_APP_ROR_SERVER_API_HOSTNAME_V4 : `${window._env_.REACT_APP_ROR_SERVER_API_HOSTNAME_V4}`;

export const REACT_APP_stripePublishableKey = isDev() ? process.env.REACT_APP_stripePublishableKey : `${window._env_.REACT_APP_stripePublishableKey}`;

export const REACT_APP_TCS_STOREFRONT =  isDev() ? process.env.REACT_APP_TCS_STOREFRONT : `${window._env_.REACT_APP_TCS_STOREFRONT}`;

export const REACT_APP_RECAPTCHA_SITE_KEY =  isDev() ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : `${window._env_.REACT_APP_RECAPTCHA_SITE_KEY}`;

export const DebugPartyTown = process?.env?.NODE_ENV === "development" ? true : false
