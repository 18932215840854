/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import Breadcrumbs from '../shared/Breadcrumbs';
import BuyNowCard from '../shared/BuyNowCard';
import TAndConly from '../shared/T&Conly';
import OrderConfirmationModal from '../shared/Modals/OrderConfirmationModal';
import { connect } from 'react-redux';
import { fetchBrand } from '../../Redux/brand/brandActions';
// import {currancyToPoints} from '../../utils/pointsConversion';
import { withTranslation } from 'react-i18next';
import {removeOrder} from '../../Redux/order/orderActions'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { checkTokenValidity } from '../../Redux/auth/authActions';
import '../shared/giftflow/Buygift.css';
// import EmailPreview from "../shared/giftflow/EmailPreview";
import { REACT_APP_api_V2, REACT_APP_baseUrl_V2_V3 } from 'utils/app-constants';
import WarningModal from 'components/shared/Modals/WarningModal';
import countryCurrency from "../../utils/countrycodes/countryCurrency.json"
import SelectDeliveryAddress from 'components/shared/SelectDeliveryAddress';
import { addToCart } from 'Redux/cart/cartAction';
import backButton from "../../assests/img/carret-left-blue.svg";
import Description from 'components/shared/Description';
import { MASTERCARD_BANK_STATEMENT } from 'utils/ConstantData';

class GiftCardPage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            cardType: "",
            shipAddress: false,
            giftcard_value:null,
            quantity:1,
            confirmOrder:false,
            orders:{},
            termsConds:[],
            cardPayment:false,
            emailPreviewshow: false,
            showNewGiftFlow: !this.props.convertionRate.enable_buying_as_gift_feature,
            insufficientBalance:false,
            Domination:[],
            activeCollapse: "Description",
            AddressDetails: [{
                id: 1,
                name: 'Sue McDonald',
                city: 'Edison',
                zipcode: '08820',
                address: 'Stephenville Pkwy',
                state: 'NJ',
                phone: 8775741789,
                default: true,
            },
            {
                id: 2,
                name: 'Sue McDonald 2',
                city: 'Edison 2',
                zipcode: '08820 2',
                address: 'Stephenville Pkwy 2',
                state: 'NJ 2',
                phone: 8775741789,
                default: false,
            }],
            selectedAddress:null
         }
    }

    fetchCardDetails = (cardType) =>{
        if(this.props.isMultiLang){
            this.fetchBrandHandler(this.props.match?.params?.id,cardType);
        }else{
            this.fetchBrandHandler(this.props.match?.params?.id);
            this.fetchTermsConds()  ;
        }
    }

    componentDidMount=()=>{
        window.scrollTo(0, 0)
        localStorage.removeItem("type");
        localStorage.setItem("type", "giftCards");
        if(this.props?.history?.location?.state?.physical_giftcard_flag) localStorage.setItem("isPhysical",this.props?.history?.location?.state?.physical_giftcard_flag)
        else localStorage.setItem("isPhysical",false)
        const cardType = this.props?.history?.location?.state?.physical_giftcard_flag || JSON.parse(localStorage.getItem("isPhysical"))
        let deafultSelectedAddress = this.state.AddressDetails.filter(item => item.default === true)
        this.setState({
            selectedAddress: deafultSelectedAddress[0]
        })
        this.fetchCardDetails(cardType)
        this.props.removeOrder();
        this.props.checkTokenValidity() 
        this.setState({Domination:[]})
    }
    shipAddress = (val) => {
        this.setState({
            shipAddress: val
        })
    }
    fetchTermsConds = (payload) => {
        let currency = countryCurrency?.filter(val => val?.countryName === this.props.selectedCountry[0].name)
        if(REACT_APP_api_V2 === "true" || this.props.isMultiLang){
            payload = {
                  "brands": {
                      "id":this.props.match?.params?.id,
                      "currency": currency[0]?.currencyCode,
                      "country": this.props.countryCode
                  }
              }
        }else{
            payload = {
                "brands": {
                    "id":this.props.match?.params?.id,
                    "currency": this.props.countrySelected
                }
            }  
        }
          return (
          
              axios.post(`${REACT_APP_baseUrl_V2_V3}/brands/terms`,
                 payload,
                  {
                      headers: {
                          'Content-Type': 'application/json',
                          'Cache-Control': 'no-cache, no-store, must-revalidate',
                          'Expires': '0',
                      },
                      params:{
                          "secret_key":secretKeyGenerator(payload)
                      }
                  })
                  .then(response  => {
                      // const termsConds = response.data.data.terms
                      this.setState({
                          termsConds: response.data.data?.terms
                            
                      })
                     
                  })
          )
      }
    fetchBrandHandler = (id,cardType) => {
        let currency = countryCurrency?.filter(val => val?.countryName === this.props.selectedCountry[0].name)
        // let payload
        // if(REACT_APP_api_V2 === "true" || this.props.slug?.slug === "kantar-public-voice-eu"){
        //     payload = {
        //         "brands": {
        //             "id": id,
        //             "currency": currency[0]?.currencyCode,
        //             "country": this.props.countryCode
        //         }
        //     }
        // }else{
        //     payload = {
        //         "brands": {
        //             "id": id,
        //             "currency": this.props.countrySelected
        //         }
        //     }
    
        // }
        this.props.fetchBrand(id,currency[0]?.currencyCode,this.props.countryCode,this.props.countrySelected,this.props.isMultiLang,cardType)

    }

    numberWithCommas = (x) => {
        return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    componentDidUpdate=(prevProps,prevState)=>{
        if(!this.props.loading && this.props.prodNotFound && this.props.giftCard && Object.keys(this.props.giftCard).length === 0) {
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/'
            })
        }

        if((prevProps.countryCode !== this.props.countryCode) || (this.props.match.params?.id !== prevProps.match.params?.id)){
            this.fetchCardDetails()    
        }

        if(this.props.slug?.journey !== "PROMO_CODE_REDEMPTION"){
            if((this.state.giftcard_value===null && !this.props.loading && !this.props.prodNotFound && this.props.giftCard?.rates) || (prevProps.giftCard?.name !== this.props.giftCard?.name && !this.props.loading && this.props.giftCard?.rates)){
                const cardType = this.props.giftCard?.physical_giftcard_flag ? "physical" : "digital"
                let terms = this.props.giftCard?.terms ? this.props.giftCard?.terms : this.state.termsConds
                this.setState({giftcard_value:this.props.giftCard?.rates && this.props.giftCard?.rates[0], Domination:this.props.giftCard?.rates, termsConds : terms, cardType})
            }
        }else{
            if((this.state.giftcard_value===null && !this.props.loading && !this.props.prodNotFound && this.props.giftCard?.rates) ||   (prevProps.giftCard?.name !== this.props.giftCard?.name && !this.props.loading && this.props.giftCard?.rates)){
                let Domination = this.props.giftCard?.rates?.filter(val => val <= Number(this.props.pointBalance))
                this.setState({giftcard_value:Domination && Domination?.length>3?Domination[2]:Domination[0], Domination:Domination})
            }

        }

        if((prevProps.currancyCode !== this.props.currancyCode) || (this.props.match.params?.id !== prevProps.match.params?.id) ){
            this.fetchBrandHandler(this.props.match.params?.id) 
            this.fetchTermsConds(this.props.match.params?.id)
            
        }
        if(this.props.slug?.journey === "PROMO_CODE_REDEMPTION"){
            if (!this.props.tokenLoading && !this.props.valid) {
                if(this.props.redemptionCode){
                    this.props.history.replace(`/redemptioncode?code=${this.props.redemptionCode}`)
                }else{
                    this.props.history.replace(`/redemptioncode`)
                }
            }
        }

    }
    setGiftcardValue(val){
        this.setState({
            giftcard_value:val
        })
    }
    setQuantity=(val)=>{
        this.setState({quantity:val})
    }

    addNewAddress = (newAddress) => {
        newAddress = {...newAddress, id : Math.round(Math.random() * 1000)}
        this.setState((prevState) => ({
            AddressDetails: [...prevState.AddressDetails, newAddress]
        }))
    }
    updateAddress = (updatedAddress) => {
        const index = this.state.AddressDetails.findIndex(item => item?.id === updatedAddress?.id)
        const UpdatedAddressDetails = [...this.state.AddressDetails]
        UpdatedAddressDetails[index] = updatedAddress
        this.setState({AddressDetails : UpdatedAddressDetails})
    }

    handleRemove = (data) => {
        this.setState({
            AddressDetails: data
        })
    }

    selectedAddress = (data) =>{
        this.setState({selectedAddress:data})
    }

    AddCart = (val, ...props) => {
        let data = props[0]?.data || null
        let type = props[0]?.type || null
        let quantity = this.state.quantity
        let giftcardValue = this.state.giftcard_value
        const ProductName = this.props.giftCard?.name
        let payload = {
            "name": ProductName,
            "value": giftcardValue,
        }
        if (this.state.cardType === 'digital') {
            payload['email'] = data
        }
        let id = secretKeyGenerator(payload)
        const cart = JSON.parse(localStorage.getItem("cart")) || []
        const findCart = cart?.filter(val => val?.id === id)
        const totalQuantity = findCart[0]?.quantity + Number(quantity)
        if ((findCart && findCart[0]?.quantity >= 5) || (findCart && totalQuantity > 5)) {
            alert("Maximum allowed quantity is 5")
        } else {
            let orders;
            orders = {
                "id": id,
                "brand_id": this.props.giftCard.id,
                "name": ProductName,
                "quantity": Number(quantity),
                "denomination": giftcardValue,
                "country": this.props.countryCode,
                "currency": this.props.giftCard?.unit_name?.toUpperCase(),
                "image": this.props.giftCard.images?.color?.medium_rectangle,
                "isPhysical":this.props?.giftCard?.physical_giftcard_flag,
                "sla":this.props?.giftCard?.sla,
                "isforself": true,
                "order_type":this.props?.giftCard?.physical_giftcard_flag ? "MERCHANT_PRODUCT" : "GIFTCARD"
            }
            this.setState(prevState => ({
                orders: orders
            }), () => type === 'Email' ?
                this.setState(prevState => ({
                    orders: {
                        ...prevState.orders,
                        'recipient': {
                            "email": data
                        }
                    }
                }), () => {
                    this.props.addToCart(this.state.orders)
                    this.props.history.push("/view-cart")
                }) :
                this.setState(prevState => ({
                    orders: {
                        ...prevState.orders,
                        'contact_phone': data
                    }
                }))
            )
        }

    }

    orderSummary=(val,...props)=>{
        let amount = this.state.quantity * this.state.giftcard_value
        let amount_points = amount
        let pointBalance = this.props.pointBalance ? this.props.pointBalance : 0
        if(val){
            if((amount_points > pointBalance) || (this.state?.Domination?.length===0)){
            this.setState({insufficientBalance:true})
            return
        }
        }
        let data = props[0]?.data || null
        let type = props[0]?.type || null
        // let paymentType = props[0]?.paymentType
        let quantity;
        if(!val)
        {
            this.setState({quantity:1})
        }else{
             quantity = this.state.quantity
        }
        let giftcardValue = this.state.giftcard_value
        let orders;
        orders = {
            "brand_id":this.props.giftCard.id,
            "quantity":quantity,
            "denomination":giftcardValue,
            "country": this.props.countryCode,
            "isforself": true,            
            "currency": this.props.giftCard?.unit_name?.toUpperCase(),
            "isPhysical":this.props?.giftCard?.physical_giftcard_flag,
            "order_type":this.props?.giftCard?.physical_giftcard_flag ? "MERCHANT_PRODUCT" : "GIFTCARD"
        }
        this.setState(prevState=>({
            confirmOrder:val,
            orders:orders
        }),() => type === 'Email' ?
            this.setState(prevState => ({
                orders: {
                  ...prevState.orders,
                  'recipient':{
                    "email": data
                  }
                }
              })) :
            this.setState(prevState => ({
                orders: {
                  ...prevState.orders,
                  'contact_phone':data
                }
              }))
            )   
    }
    // emailPreviewshowHandler = () => {
    //     this.setState({ emailPreviewshow: !this.state.emailPreviewshow });
    // }
    handleExpandCollaps = (name) => {
        if (this.state.activeCollapse === name) {
          //If collapsiable is already visible and clicked on same then this will hide it
          this.setState({ activeCollapse: "" });
        } else {
          //To show collapsiable
          this.setState({ activeCollapse: name });
        }
      };
      goBackHandle=()=>{
        this.setState({confirmOrder:false})
        this.shipAddress(false)
      }
    render() { 
        const {t,currancyCode,email, message,url, from,to, mobile, giftCountryCode} = this.props
        const {confirmOrder,orders,giftcard_value,quantity} = this.state
        const card = this.props.giftCard
        const breadcrumbs =[
            // {name:t("Home"),url:'/',active:true},   
            // {name:t("Giftcards"),url:'/',active:true},
            {name:card?.name, url:'', active:confirmOrder?true:false}
        ] 
        const { state } = this.props.location;
        return ( 
            <>
            { this.props.loading && this.props.loading ? <section>
                    <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            </section> :
                <section>

                    {
                        !confirmOrder && 
                            <Breadcrumbs crumbs={breadcrumbs} goBackHandle={this.goBackHandle}/>
                    }
                    
                       {/* !this.props.loading && */}
                        <div className="px-md-5 px-3 pt-md-4 pt-3  d-flex flex-md-row flex-column pb-0 pb-md-0">
                            {
                                ((this.state.cardType === 'digital' && confirmOrder) || this.state.shipAddress) ? <OrderConfirmationModal 
                                cardType={this.state.cardType}
                                cardPayment={this.state.cardPayment} 
                                lang={this.props.lang} 
                                orders={orders} card={card} 
                                show={confirmOrder} 
                                remove={this.orderSummary}
                                shipAddress={this.shipAddress}
                                GiftCardValue={giftcard_value} 
                                GiftCardEmail={email} 
                                GiftCardMobile={mobile} 
                                GiftCardMobileCode={giftCountryCode} 
                                GiftCardQuantity={quantity}
                                GiftCardMessage={message} 
                                url={url}
                                currencySymbol={this.props.currencySymbol}
                                // Address={this.state.selectedAddress}
                                setQuantity={this.setQuantity}
                                price={(giftcard_value * quantity)}
                                /> : 
                                (this.state.cardType === 'physical' && confirmOrder) ? 
                                <SelectDeliveryAddress currencySymbol={this.props.currencySymbol} orders={orders} card={card} shipAddress={this.shipAddress} AddressDetails={this.state.AddressDetails} 
                                addNewAddress={this.addNewAddress} updateAddress={this.updateAddress} handleRemoveAddress={this.handleRemove} selectedAddress={this.state.selectedAddress} Address={this.selectedAddress}/> 
                                : null
                            }

                            {
                                this.state.insufficientBalance && <WarningModal show={this.state.insufficientBalance} closeModal={() => {this.setState({insufficientBalance:false})}} currencySymbol={this.props.currencySymbol}/>
                            }
                       { (!confirmOrder) && <>
                    <div className='d-flex flex-column gap-2 mb-2'>
                        <div className='d-flex flex-row'>
                            <div className="col-3 p-0 d-md-block d-none">
                            <img  
                                className="img-fluid p-0 mx-auto d-block border rounded"   
                                src={card?.images?.color?.medium_rectangle}
                                alt={card?.name}
                            />
                            </div>
                            <div className="flex-grow-1 px-md-5 px-0">
            <div className='d-flex flex-row d-md-none d-block'>
            {this.props.location.pathname !== "/" && (
              <div
                className=" fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props?.history?.goBack()
                //   this.props.ShowHeader(true)
                }}
              >
                <section
                  style={{
                    transform: `${this.props.lang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`,
                    }}
                  >
                    <img src={backButton} alt="" style={{marginTop:'5px'}}/> <span className='body-typo' style={{color:'#005E9A'}}>{t("Back")}</span>
                  </section>
                </section>
              </div>
            )}  
                                </div>
                            <div className="d-flex flex-column justify-content-start align-items-start gap-2">
                               {/* <div>
                               {
                                this.state?.Domination?.length>0 &&
                                <div className="fs-4 fw-bold drc-title" style={{fontSize:"24px"}}>
                                <span>{t(this.props.currencySymbol)} </span>
                                {this.numberWithCommas((giftcard_value * quantity))}
                                   
                                      <small>{t("Points")}</small> 
                                </div>
                               } 
                               </div> */}
                               
                                <div className="fs-4 fw-bold ">
                                {t(card?.name)}
                                </div>
                                {this.state.cardType==="physical" &&
                                <div>
                                    <span className='body-typo drc-title fw-bold'>{t(`Allow 4 weeks for delivery`)}</span>
                                </div> 
                                }
                               
                                {/* <div className='text-black-50 m-0 py-1 fs-md-5 fs-6 fw-bold'>
                                { this.props.lang === 'en' ? card.name:card.name_arabic }
                                </div> */}
                            </div>
                           
                            <div className='d-md-none d-block p-3'>
                                <img 
                                    className="mx-auto d-block"
                                    src={card?.images?.color?.medium_rectangle} 
                                    alt={card?.name} srcSet=""
                                    style={{objectFit:'cover',height:'50vw',maxWidth:'100%'}}/>
                            </div>
                            <hr className="hr-line my-2"/>
                            { card?.rates  === null ? 
                                <div className="text-center"> 
                                    <p className='text-danger fw-bold'>{t("Sold Out")}...... {t("Coming Soon")}</p>
                                </div> : 
                            <div className=" py-md-4 py-2">
                                {
                                    this.state?.Domination?.length>0 &&
                                <div>
                                    <div className='d-md-block d-none pb-2'>{card?.name?.includes("Special Olympics") ? t("Donation Value")  : card?.brand_type === "PAYPAL" ? t("Value") : t("Card Value")}: 
                                    <div className="fs-4 fw-bold drc-title" style={{fontSize:"24px"}}>{this.props.currencySymbol} {giftcard_value}</div>
                                    </div>
                                    <div className='d-md-none d-block pb-2'>
                                    <span className=" fw-bold d-flex flex-row align-items-center justify-content-between">
                                    <span className='body-typo'>{ card?.name?.includes("Special Olympics") ? t("Donation Value") : card?.brand_type === "PAYPAL" ? t("Value") : t("Card Value")}: </span>
                                    <span className='drc-title fs-4 mr-3'>{this.props.currencySymbol} {giftcard_value * this.state.quantity}</span>
                                    </span>
                                    </div>
                                </div>
                                }
                                {/* denominations */}
                                {/* <div className={`${this.state?.Domination?.length>0 && "d-flex flex-wrap "}`}>
                                    {
                                         this.state?.Domination?.length>0 ? this.state.Domination?.map(rate=>(
                                            <button
                                                className={`btn-deno px-4 rounded-1 gridRates no-outline  ${giftcard_value===rate?'secondary-color-border':' border'}`}
                                                key={rate}
                                                onClick={()=>{this.setGiftcardValue(rate)}}>
                                                {rate}
                                            </button> 
                                        )) : 
                                        <>
                                            <p className='mb-0 mt-2'>{t("Please choose another product.")}</p>
                                            <p>{t("The available balance is lower than the product denomination")}</p>
                                        </>
                                    }
                                </div> */}
                        <div className=" mb-3 w-50">
                            {/* {
                                this.state.cardType==="physical"?
                                <label>{t("Number of quantity")}:</label>:
                                <label>{t("Quantity")}:</label>
                            } */}
                            <label>{t("Quantity")}:</label>
                            <select  className={`dist buy-now-input w-100 ${this.props.lang === "ar" && "ar-select"}`}
                            onChange={(e)=>{this.setQuantity(e.target.value)}}
                            >
                            <option selected value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                           </select>
                        </div>
                            </div>
                        }   
                               
                                
                            </div>
                        </div>
                            <div className='d-none d-md-block'>
                                <Description description={this.props?.giftCard?.short_description || this.props?.giftCard?.short_descrption}/>
                            </div>
                            <div className='d-flex flex-row bg-light-grey' style={{width:'92%'}}>
                            <div className='d-md-block d-none pb-4 px-5'>
                                {card.rates=== null ? false :
                               
                               <TAndConly  termsConds={this.state.termsConds} id={this.props.match.params?.id} lang={this.props.lang} slug={this.props.slug?.slug} isMultiLang={this.props.isMultiLang}/>
                                   }
                            </div>
                            </div>
                    </div>
                    <div className='d-block d-md-none'>   
                    <Description description={this.props?.giftCard?.short_description || this.props?.giftCard?.short_descrption} handleExpandCollaps={this.handleExpandCollaps} activeCollapse={this.state.activeCollapse}/>
                                <div>
                                {card.rates=== null ? false :
                               <TAndConly  termsConds={this.state.termsConds} id={this.props.match.params?.id} lang={this.props.lang} slug={this.props.slug?.slug} handleExpandCollaps={this.handleExpandCollaps} activeCollapse={this.state.activeCollapse} isMultiLang={this.props.isMultiLang}/>
                                 }
                                </div>
                            </div>
                            {this.state?.Domination?.length>0 &&
                            <BuyNowCard
                            cardType={this.state.cardType}
                            quantityDisable={true}
                             onSubmit={this.orderSummary}
                             id={this.props.match.params.id}  
                            setQuantity={this.setQuantity} lang={this.props.lang}
                            price={(giftcard_value * quantity)}
                            giftCardValue={giftcard_value}
                            card={card}
                            termsConds={this.state.termsConds}
                            emailPreviewshowHandler={this.emailPreviewshowHandler}
                            emailPreviewshow={this.state.emailPreviewshow}
                            state={state}
                            currancyCode={this.props.currancyCode}
                            history={this.props.history}
                            orders={orders}
                            addCart={this.AddCart}
                            currencySymbol={this.props.currencySymbol}
                            quantity={quantity}
                            />
                            }
                       </>}
                    
                        </div>
                      
                        {/* <EmailPreview
                                show={this.state.emailPreviewshow}
                                showMethod={this.emailPreviewshowHandler}
                                card={card} 
                                termsConds={this.state.termsConds}    
                                lang={this.props.lang}
                                giftCardValue={giftcard_value}  
                                from={from}
                                url={url}    
                                GiftMessage= {message}
                                currancyCode={this.props.currancyCode}
                        /> */}
                        {
                            (message)?<div className='d-md-block d-none px-5 position-relative'>
                                    <Link 
                                        to={{
                                        pathname: '/giftflow',
                                        state:{
                                            id: this.props.match.params.id,
                                            GiftMessage: message,
                                            GiftFrom: from,
                                            GiftTo: to,
                                            GiftEmail: email,
                                            GiftMobile: mobile,
                                            GiftCountryCode: giftCountryCode
                                        }
                                        }}
                                        >
                                            <button className="color-blue giftcardeditbuttondesk">{t("Edit")}</button>
                                    </Link>
                                    <div className="giftcardeditdesk px-5 pt-5">
                                        <div className="row pb-5 border-bottom">
                                            <div className="col-3">
                                            <img width="100%" height="auto" src={url} alt="" />
                                            </div>
                                            <div className="col-4 d-flex align-items-center pl-4">
                                                <p className="giftcardeditmssg">{message}</p>
                                            </div>
                                            <div className="col-2">
                                            </div>
                                            <div className="col-3 text-center">
                                                <img width="80%" height="auto"                                 
                                                    src={card?.images?.color?.medium_rectangle}
                                                    alt="" />
                                                <p className="fw-bold fs-4 ">{currancyCode} {giftcard_value}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                       
                                        <div className="pt-2 hoverpointer">
                                            <p onClick={this.emailPreviewshowHandler} className="w-100 text-right fw-bold"> <ins>{t("Click for the email preview")}</ins></p>
                                        </div>
                                    </div>
                            </div>: null
                        }
                </section> }
                {
                                card?.name?.toLowerCase()?.includes("mastercard") &&
                            <p className='px-md-5 px-3 pt-md-4 pt-3'>{t(MASTERCARD_BANK_STATEMENT)}</p>
                            }
            </>
                );
    }
}

const mapStateToProps = state => {
    return {
        giftCard:state.brand.brand,
        loading:state.brand.loading,
        prodNotFound:state.brand.prodNotFound,
        pointBalance:state.point?.pointBalance,
        countrySelected:state.countries.countryId,
        currancyCode:state.countries.convertionRate.currency_code,
        isMultiLang : state.tenetReducer?.slug?.featureFlags?.isMultilingual,
        convertionRate:state.countries.convertionRate,
        fetchError:state.brand.error,
        email: state.giftFormDetails.email,
        message: state.giftFormDetails.message,
        url: state.giftFormDetails.url,
        from: state.giftFormDetails.from,
        to: state.giftFormDetails.to,
        mobile: state.giftFormDetails.mobile,
        giftCountryCode: state.giftFormDetails.giftCountryCode,
        format: state.giftFormDetails.GiftFormat,
        GiftParticularCardSelect: state.giftFormDetails.GiftParticularCardSelect,
        valid:state.auth.valid,
        tokenLoading:state.auth.tokenLoading,
        slug : state.tenetReducer?.slug,
        countryCode : state.countries?.countryCode,
        selectedCountry : state.countries?.countries?.countries?.filter(val => state.countries?.countryCode === val.code),
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchBrand : (id,currencyCode,countryCode,countrySelected,isMultiLang,cardType) => dispatch(fetchBrand(id,currencyCode,countryCode,countrySelected,isMultiLang,cardType)),
        removeOrder : () => dispatch(removeOrder()),
        checkTokenValidity : () => dispatch(checkTokenValidity()),
        addToCart: (payload) => dispatch(addToCart(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter((withTranslation()(GiftCardPage))));