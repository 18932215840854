import axios from "axios";
import store from "../store"
import {
  FETCH_POINT_BALANCE_FAILURE,
  FETCH_POINT_BALANCE_REQUEST,
  FETCH_POINT_BALANCE_SUCCESS,
} from "./pointTypes";
import { REACT_APP_baseUrl } from "utils/app-constants";

export const fetchPointBalanceRequest = () => {
  return {
    type: FETCH_POINT_BALANCE_REQUEST,
  };
};

export const fetchPointBalanceSuccess = (data) => {
  return {
    type: FETCH_POINT_BALANCE_SUCCESS,
    payload: data,
  };
};

export const fetchPointBalanceFailure = (error) => {
  return {
    type: FETCH_POINT_BALANCE_FAILURE,
    payload: error,
  };
};

export const fetchPointBalance = (token,redeemCode,lang) => {
  return (dispatch) => {
    dispatch(fetchPointBalanceRequest);
    
    let tkn = localStorage.getItem("userToken")
    let Journey = store.getState()?.tenetReducer?.slug?.journey !== "PROMO_CODE_REDEMPTION"
    let URL = Journey ? "user/get_point_balance" : `giftcards/${redeemCode}/check_balance`
    axios.get(
        `${REACT_APP_baseUrl}/${URL}`,
        {
          headers: {
            Authorization: `Bearer ${token || tkn}`,
          },
        }
      )
      .then((response) => {
        const pointBalance = Journey ? response.data?.data?.amount : response.data?.data?.remaining_value;
        dispatch(fetchPointBalanceSuccess(pointBalance));
      })
      .catch((error) => {
        const errorMsg = error;
        console.log(errorMsg);
        dispatch(fetchPointBalanceFailure("failed"));
      });
  };
};
