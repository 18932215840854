import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Prompt, Redirect } from "react-router";
import TravelShopHOC from "../../TravelShop/TravelShopHOC";
import grayTicket from "../../../assests/img/TravelShop/grey-plane.svg";
import  CardImg from '../../../assests/img/card.svg'
import {
  currancyToPoints,
  numberWithCommas,
  otherPointsToCurrancy,
  pointsToCurrancy,
  pointsToCurrancyCreditCountries,
} from "../../../utils/pointsConversion";
import Mada from "../../../assests/img/Mada.png";
import MasterCard from "../../../assests/img/MasterCard.png";
import Visa from "../../../assests/img/Visa.png";
import { applePayHander } from "../../../utils/applePay";
import PlaneTicketBlue from "../../../assests/img/TravelShop/plane-ticket-blue.svg";
import axios from "axios";
import secretKeyGenerator from "../../../utils/serectKeyGenerator";
import FailMessageModal from "./FailMessageModal";
import moment from "moment";
import ConfirmationDetails from "../../TravelShop/ConfirmationDetails";
import { remove } from "react-cookies";
import { removeDataAfterPayment } from "../../../Redux/travelShop/TravelAction";
import Policy from "../../TravelShop/Policy";
import { TermsAndConditionArabic, TermsAndConditionEnglish } from "../../TravelShop/termsDetails";
import { REACT_APP_baseUrl } from "utils/app-constants";
import { fetchCurrencyConversion } from "Redux/conversion/conversionAction";


class TravelConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      orderDetails: {},
      redirectToPayment: false,
      checkoutBtn: true,
      paymentFail: false,
      errorMsg: " ",
      loading: false,
      flightSegments:[],
      TermsModal:false,
      selectedCurrency:this.props.currancyCode || "AED",
      pointsCurrancy:null,
      convertedPointsBalance:null,
      creditCardPaymentPayload:{}
    };
  }

  
  setCheckoutBtn(val) {
    this.setState((old) => ({
      checkoutBtn: !old.checkoutBtn,
    }));
  }
  numberWithCommas = (x) => {
    let num = +x;
    if(typeof num === 'number'){
      let y = num.toFixed(2);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{return 0}
  };
  numberWithCom = (x) => {
    let num = +x;
    let y = num.toFixed(2);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  postOrderHandler(){
    const {orders} = this.props.location?.state;
    const {pointBalance,brandID} = this.props;
    if(pointBalance || this.props.location?.state?.cardPayment){
        const missingPoints = orders.price_in_points - pointBalance
    if(missingPoints>0 || this.props.location?.state?.cardPayment){
        orders["stripe_payment_currency"] = this.state.selectedCurrency;
        orders["brand_id"] = brandID
        orders['amount'] = this.state.pointsCurrancy
        let passenger = orders?.passengers;
        let validDocuments = this.props.flightDetails.valid_documents;
        let contactDetails = this.props.contactDetails
        let type = '1';
        if(validDocuments?.['NationalId']){
          type = '2'
        }
        else if(validDocuments?.['Iqama']){
          type = '3'
        }
        delete orders['passengers']
        if(validDocuments && Object.keys(validDocuments)?.length !== 0){
          Object.keys(passenger)?.forEach((e,index) =>{
            passenger[e]['verification_doc'] = {
              'type':type,
              'number': passenger[e]?.number,
              'issued_country': passenger[e]?.issued_country || "",
              'issued_date' : passenger[e]?.issued_date || "",
              'expiry_date': passenger[e]?.expiry_date
          }
          })
        }
    const payload ={"orders":orders,"passengers":passenger,"contact_detail":contactDetails};
        this.setState({redirectToPayment:true,loading:true,creditCardPaymentPayload:payload})
    }
    else{
        let requestedPayload = orders;
        requestedPayload['amount'] = this.state.pointsCurrancy
        let passenger = orders?.passengers;
        let validDocuments = this.props.flightDetails.valid_documents;
        let type = '1';
        if(validDocuments?.['NationalId']){
          type = '2'
        }
        else if(validDocuments?.['Iqama']){
          type = '3'
        }
        delete requestedPayload['passengers']
        if(validDocuments && Object.keys(validDocuments)?.length !== 0){
          Object.keys(passenger)?.forEach((e,index) =>{
            passenger[e]['verification_doc'] = {
              'type':type,
              'number': passenger[e]?.number,
              'issued_country': passenger[e]?.issued_country || "",
              'issued_date' : passenger[e]?.issued_date || "",
              'expiry_date': passenger[e]?.expiry_date
          }
          })
        }
        delete requestedPayload['passengers']
        requestedPayload['brand_id'] = brandID
        const payload ={"orders":requestedPayload,"passengers":passenger,"contact_detail":this.props.contactDetails}
        axios.post(`${REACT_APP_baseUrl}/orders/create_travel_order`,
        payload,{
            headers:{
                "Authorization":`Bearer ${this.props.authToken}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
              this.props.removeDataAfterPayment();
                if(response.data.code === 200){
                    const orderDetails = response.data.data.order;
                    this.props.history.push(`/payment/travel-orders-summary/${orderDetails.orderid}`)
                }
                else{
                this.setState({
                    paymentFail:true,
                    errorMsg:response.data?.message
                
                })
                }
            })
            .catch(error => {
              this.props.removeDataAfterPayment();
                this.setState({
                    paymentFail:true,
                    errorMsg:"Something went wrong!! Please try again!!"
                })
            })
            this.setState({loading:true})
    }
    }
    else{
        this.setState({
            paymentFail:true,
            errorMsg:"Points Redemption Failed"
        })
    }
    
}

currencyConversion =(event)=>{
  let payload = {
      "giftcard_units": {
          "currency": event.target.value
           }
  }
  
      this.props.fetchCurrencyConversion(payload)
      this.setState({selectedCurrency:event.target.value})
}



componentDidMount(){
  this.myRef.current?.scrollIntoView({behavior: "smooth"})
  if (window.ApplePaySession) {
      if(window.ApplePaySession.canMakePayments()){
        if(document.getElementById('applePayButton') || document.getElementById('applePayButtonMob')){
          document.getElementById('applePayButton').style.display = 'block';
          document.getElementById('applePayButtonMob').style.display = 'block';
      }
    }
}
let payload

if(Object.values(this.props.acceptedCurrencies)?.some(e => e.unit_name_short === this.props.currancyCode)){
    payload = {
        "giftcard_units": {
            "currency": this.props.currancyCode
        }
    }
    this.setState({selectedCurrency:this.props.currancyCode})
}else{
    payload = {
        "giftcard_units": {
            "currency": "AED"
        }
    }           
}

this.props.fetchCurrencyConversion(payload)
}

componentDidUpdate(prevProps,prevState){
  
  if(prevProps.currencyConversonLoading !== this.props.currencyConversonLoading){

      let total = this.props.location?.state?.orders?.price_in_points;
      let missingPoints = (currancyToPoints(total) - this.props.pointBalance).toFixed(2)
          let convertedPointsBalance = (this.props.pointBalance / this.props.convertedCurrency?.currency_exchange_rate).toFixed(2);
          if(missingPoints > 0 && !this.props.location?.state?.cardPayment) {
              total = pointsToCurrancy(missingPoints);
          }


      if(this.state.selectedCurrency === this.props.currancyCode){
          // For Available Currencies
          const payableAmount = pointsToCurrancyCreditCountries(total)
          if(this.props.minimum_payable_amount > payableAmount){
              this.setState({pointsCurrancy:this.props.minimum_payable_amount,convertedPointsBalance:convertedPointsBalance})
          }else{
              this.setState({pointsCurrancy:payableAmount,convertedPointsBalance:convertedPointsBalance})
          }
      }else{
          // For other currencies which is not avaible
          const payableAmount = otherPointsToCurrancy(total)
          const markUp = (payableAmount * 0.05) + Number(payableAmount)
          if(this.props.minimum_payable_amount > markUp){
              this.setState({pointsCurrancy:this.props.minimum_payable_amount,convertedPointsBalance:convertedPointsBalance})
          }else{
              this.setState({pointsCurrancy:markUp.toFixed(2),convertedPointsBalance:convertedPointsBalance})
          }
      }
  }
}
  closeModal=()=> {
    this.setState ({
       TermsModal:!this.state.TermsModal
    })
}
  render() {
    const { t, currancyCode,orderDetails,pointBalance,fareDetails,flightDetails} = this.props;
    const {orders} = this.props.location?.state;
    const { checkoutBtn, loading, paymentFail, errorMsg,selectedCurrency,convertedPointsBalance,pointsCurrancy} = this.state;
    const total = orders?.price_in_points;
    const missingPoints = total - pointBalance;
    const MobileOrderSummary = (
      <>
        {/*Mobile Order Summary start */}
        <div className="d-block d-md-none">
        <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
          <div className="card mb-4 rounded-0" style={{ width: "auto" }}>
            <div className="card-body pb-5">
              <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  color-blue">
                {t("Order Summary")}:
              </div>
              <div className="d-flex justify-content-center px-2 py-3 flex-column gap-1">
                 <h6>{t("Points Breakdown")}</h6>
                 {
                   fareDetails && fareDetails?.map(fareData =>(
                    <>
                    <div className="d-flex justify-content-between">
                    {
                      fareData.passenger_type === 1 && <span className='fw-bold'>{t("Adult")}</span>
                    }
                    {
                      fareData.passenger_type === 2 && <span className='fw-bold'>{t("Child")}</span>
                    }
                    {
                      fareData.passenger_type === 3 && <span className='fw-bold'>{t("Infant")}</span>
                    }
                    <span className='fw-bold'>{numberWithCommas(fareData.price_in_points)} {t("Points")}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='text-muted'>{t("Base Fare")}</span>
                    <span className='text-muted'>{numberWithCommas(fareData.base_fare_in_points)} {t("Points")}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='text-muted'>{t("Tax and Fees")}</span>
                    <span className='text-muted'>{numberWithCommas(fareData.tax_in_points)} {t("Points")}</span>
                  </div>
                    </>
                   ))
                 }
        
                 <div className="d-flex justify-content-between">
                   <span className='fw-bold'>{t("Booking fee is inclusive of VAT")}</span>
                   <span className='fw-bold'>{numberWithCommas(flightDetails?.fare_local?.booking_fee_in_points)} {t("Points")}</span>
                 </div>
                 <hr className='hrLine'/>
               </div>
               {
                        (this.props.location?.state?.cardPayment || missingPoints > 0) && 
                    <div className='d-flex flex-row justify-content-between'>
                    <label htmlFor="accCurrency" className='text-black-50 fw-bold col-8 m-0 p-0'>{t("Select Payment Currency")}:</label>
                    <select id='accCurrency' onChange={this.currencyConversion} className='dist border align-self-center col-4 p-0 m-0 text-center form-control arrow-indicator no-outline' name="">
                        {this.props.acceptedCurrencies?.map(e => <option name={e.unit_name_short} selected={e.unit_name_short === this.props.currancyCode} value={e.unit_name_short}>{e.unit_name_short}</option>)}
                    </select>
                    </div>
                    }
              <div className="d-flex flex-row justify-content-between py-2">
                <span className="text-black-50 fw-bold">{t("Total")}:</span>
                {this.props.lang === "en" ? (
                  <h5 className="text-dark fw-bold m-0 fs-regular">
                    {this.numberWithCommas(total)} <small>{t("Points")}</small>
                  </h5>
                ) : (
                  <h5 className="text-dark fw-bold m-0 fs-regular">
                    <small>{t("Points")}</small> {this.numberWithCommas(total)}
                  </h5>
                )}
              </div>
              <div className="d-flex flex-row justify-content-between py-2">
                <span className="text-black-50 fw-bold">{t("Available")}:</span>
                {this.props.lang === "en" ? (
                  <h5 className="text-danger fw-bold m-0 fs-regular">
                    {this.numberWithCommas(pointBalance)}
                    <small> {t("Points")}</small>
                  </h5>
                ) : (
                  <h5 className="text-danger fw-bold m-0 fs-regular">
                    <small> {t("Points")}</small>{" "}
                    {this.numberWithCommas(pointBalance)}
                  </h5>
                )}
              </div>
              {
                                this.props.currencyConversonLoading &&
                                <div class="spinner">
                                                    <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                                     <div class="bounce3"></div>
                                                            </div>

                            }
              {missingPoints > 0 && !this.props.location?.state?.cardPayment && (
                <>
                  <div className="d-flex flex-row justify-content-between py-2">
                    <span className="text-black-50 fw-bold">
                      {t("Missing Points")}:
                    </span>
                    {this.props.lang === "en" ? (
                      <h5 className="text-danger fw-bold m-0 fs-regular">
                        {this.numberWithCommas(missingPoints)}{" "}
                        <small>{t("Points")}</small>
                      </h5>
                    ) : (
                      <h5 className="text-danger fw-bold m-0 fs-regular">
                        <small>{t("Points")}</small>{" "}
                        {this.numberWithCommas(missingPoints)}{" "}
                      </h5>
                    )}
                  </div>
                  
                  <div className="d-flex flex-row py-3">
                    <div className="p-0 col-2">
                      <img loading='lazy' decoding='async'
                        src={CardImg}
                        alt=""
                        className="img-fluid verticle-center"
                      />
                    </div>
                    <div className="px-1 col-10">
                      <h5 className="color-blue fw-bold m-0">
                        {" "}
                        {this.numberWithCommas(missingPoints)} {t("Points")} (
                        {this.numberWithCom(pointsCurrancy)}{" "}
                        {t(selectedCurrency)})
                      </h5>
                      <small className="text-muted fw-bold">
                        {t("to be paid by Credit Card/MADA")}
                      </small>
                      <br />
                      <img loading='lazy' decoding='async' src={Visa} alt ="visa" />
                      <img loading='lazy' decoding='async' src={MasterCard} alt ="mastercatd" />
                      <img loading='lazy' decoding='async' src={Mada} alt ="mada" />
                    </div>
                  </div>
                </>
              )}
              {/* Showing Only SAR and Credit Card Image */}
              {this.props.location?.state?.cardPayment && (
                <div className="d-flex flex-row py-3">
                  <div className="p-0 col-2">
                    <img loading='lazy' decoding='async'
                      src={CardImg}
                      alt=""
                      className="img-fluid verticle-center"
                    />
                  </div>
                  <div className="px-1 col-10">
                    <h5 className="color-blue fw-bold m-0">
                      {this.numberWithCommas(total)} {t("Points")} (
                      {this.numberWithCom(pointsCurrancy)}{" "}
                      {t(selectedCurrency)})
                    </h5>
                    <small className="text-muted fw-bold">
                      {t("to be paid by Credit Card/MADA")}
                    </small>
                    <br />
                    <img loading='lazy' decoding='async' src={Visa} alt ="visa" />
                      <img loading='lazy' decoding='async' src={MasterCard} alt ="mastercatd" />
                      <img loading='lazy' decoding='async' src={Mada} alt ="mada" />
                  </div>
                </div>
              )}


              <hr className="hr-line" />
              <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                <h5 className="text-dark fw-bold m-0">{t("Total")}</h5>
                {this.props.lang === "en" ? (
                  <h5 className="text-dark fw-bold m-0">
                    {this.numberWithCommas(total)}
                    <small>&nbsp;{t("Points")}</small>
                  </h5>
                ) : (
                  <h5 className="text-dark fw-bold m-0">
                    <small>{t("Points")}</small>&nbsp;
                    {this.numberWithCommas(total)}
                  </h5>
                )}
              </div>
              {/* <div className="d-flex gap-2">
                <input
                  type="checkbox"
                  className="flex-shrink-0"
                  onClick={() => {
                    this.setCheckoutBtn();
                  }}
                />
                <label className="">
                          {t("I have read and accepted the")} <span onClick={this.closeModal} className="color-green cursor-pointer">{t("Terms & Conditions")}</span>.
                </label>
              </div> */}
              <br />
              <button
                className="btn btn-block primary-btn text-light rounded-0 text-center"
                disabled={this.props.currencyConversonLoading}
                onClick={()=>{this.postOrderHandler()}} 
              >
                {/* {t("Checkout Now")} */}
                {loading ? (
                  <span>
                    {" "}
                    <div
                      class="spinner-border spinner-border-sm "
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <span className="px-2">{t("Checking")} . . .</span>
                  </span>
                ) : (
                  <span>
                    {this.props.location?.state?.cardPayment
                      ? t("Buy with Card")
                      : missingPoints > 0
                      ? t("Buy with Card")
                      : t("Checkout Now")}
                  </span>
                )}
              </button>
              {/* //TODO: Remove the false to enable the apply pay  */}
              {this.props.convertionRate?.payment_method_availability
                ?.applepay &&
                (missingPoints > 0 || this.props.location?.state?.cardPayment) && (
                  <button
                    id="applePayButton"
                    className="btn btn-block primary-btn text-light rounded-0 text-center"
                    style={{ display: "none" }}
                    onClick={() => {
                      applePayHander(
                        this.props.location?.state?.cardPayment
                          ? this.state.orderDetails.total
                          : pointsToCurrancy(missingPoints),
                        this.props.location?.state?.orders,
                        this.ApplePayFailed,
                        "GIFTCARD"
                      );
                    }}
                    disabled={this.props.currencyConversonLoading}
                  >
                    {loading ? (
                      <span>
                        {" "}
                        <div
                          class="spinner-border spinner-border-sm "
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <span className="px-2">{t("Checking")} . . .</span>
                      </span>
                    ) : (
                      <span>{t("Buy with Apple Pay")}</span>
                    )}
                  </button>
                )}
            </div>
          </div>
        </div>
        {/* Mobile Order Summary End  */}
      </>
    );
    
    return (
      <div ref={this.myRef}>
        <TravelShopHOC
          childComponent={
            <>
            <Policy
                style={{opacity: ".5 !important"}}
                showModal = {this.closeModal}
                show = {this.state.TermsModal}
                title="Terms & Conditions"
                list={true}
                contentEnglish={TermsAndConditionEnglish}
                contentArabic={TermsAndConditionArabic}
                lang={this.props.lang}
                />
             <FailMessageModal show={paymentFail} lang={this.props.lang} Message={errorMsg}/>
              <section className="bg-white w-100 h-auto">
                <header className="d-flex  px-md-5 px-3 py-2  gap-md-4 gap-3  shadow-sm align-items-center">
                  <div className="fs-large fw-bold">
                    <span className="color-blue">
                      {t("Order Confirmation")}{" "}
                    </span>
                  </div>
                </header>
                <div className="d-flex flex-md-row flex-column p-md-3 p-3 gap-md-5 bg-white">
                  <div className="flex-grow-1 d-flex align-self-start  p-md-3 p-0">
                    <ConfirmationDetails total={total}/>
                  </div>
                  {/*Desktop Order Summary start */}
                  <div className="d-md-block d-none">
                    <div
                      className="card mb-4 rounded-0"
                      style={{ width: "18rem" }}
                    >
                      <div className="card-body pb-5">
                        <div className="fs-4 fw-bold py-md-0 py-3 m-0 mt-md-0 mt-5  color-blue">
                          {t("Order Summary")}:
                        </div>
                        {
                        (this.props.location?.state?.cardPayment || missingPoints > 0) && 
                    <div className='d-flex flex-row justify-content-between'>
                    <label htmlFor="accCurrency" className='text-black-50 fw-bold col-8 m-0 p-0'>{t("Select Payment Currency")}:</label>
                    <select id='accCurrency' onChange={this.currencyConversion} className='dist border align-self-center col-4 p-0 m-0 text-center form-control arrow-indicator no-outline' name="">
                        {this.props.acceptedCurrencies?.map(e => <option name={e.unit_name_short} selected={e.unit_name_short === this.props.currancyCode} value={e.unit_name_short}>{e.unit_name_short}</option>)}
                    </select>
                    </div>
                    }
                        <div className="d-flex flex-row justify-content-between py-2">
                          <span className="text-black-50 fw-bold">
                            {t("Total")}:
                          </span>
                          {this.props.lang === "en" ? (
                            <h5 className="text-dark fw-bold m-0 fs-regular">
                              {this.numberWithCommas(total)}{" "}
                              <small>{t("Points")}</small>
                            </h5>
                          ) : (
                            <h5 className="text-dark fw-bold m-0 fs-regular">
                              <small>{t("Points")}</small>{" "}
                              {this.numberWithCommas(total)}
                            </h5>
                          )}
                        </div>
                        <div className="d-flex flex-row justify-content-between py-2">
                          <span className="text-black-50 fw-bold">
                            {t("Available")}:
                          </span>
                          {this.props.lang === "en" ? (
                            <h5 className="text-danger fw-bold m-0 fs-regular">
                              {this.numberWithCommas(pointBalance)}
                              <small> {t("Points")}</small>
                            </h5>
                          ) : (
                            <h5 className="text-danger fw-bold m-0 fs-regular">
                              <small> {t("Points")}</small>{" "}
                              {this.numberWithCommas(pointBalance)}
                            </h5>
                          )}
                        </div>
                        {
                                this.props.currencyConversonLoading &&
                                <div class="spinner">
                                                    <div class="bounce1"></div>
                                            <div class="bounce2"></div>
                                                     <div class="bounce3"></div>
                                                            </div>

                            }
                        {missingPoints > 0 && !this.props.location?.state?.cardPayment && (
                          <>
                            <div className="d-flex flex-row justify-content-between py-2">
                              <span className="text-black-50 fw-bold">
                                {t("Missing Points")}:
                              </span>
                              {this.props.lang === "en" ? (
                                <h5 className="text-danger fw-bold m-0 fs-regular">
                                  {this.numberWithCommas(missingPoints)}{" "}
                                  <small>{t("Points")}</small>
                                </h5>
                              ) : (
                                <h5 className="text-danger fw-bold m-0 fs-regular">
                                  <small>{t("Points")}</small>{" "}
                                  {this.numberWithCommas(missingPoints)}{" "}
                                </h5>
                              )}
                            </div>
                            <div className="d-flex flex-row py-3">
                              <div className="p-0 col-2">
                                <img loading='lazy' decoding='async'
                                  src={CardImg}
                                  alt=""
                                  className="img-fluid verticle-center"
                                />
                              </div>
                              <div className="px-1 col-10">
                                <h5 className="color-blue fw-bold m-0">
                                  {" "}
                                  {this.numberWithCommas(missingPoints)}{" "}
                                  {t("Points")} (
                                  {this.numberWithCom(pointsCurrancy)}{" "}
                                  {t(selectedCurrency)})
                                </h5>
                                <small className="text-muted fw-bold">
                                  {t(
                                    "to be paid by Credit Card/MADA"
                                  )}
                                </small>
                                <br />
                                <img loading='lazy' decoding='async' src={Visa} alt ="visa" />
                      <img loading='lazy' decoding='async' src={MasterCard} alt ="mastercatd" />
                      <img loading='lazy' decoding='async' src={Mada} alt ="mada" />
                              </div>
                            </div>
                          </>
                        )}
                        {/* Showing Only SAR and Credit Card Image */}
                        {this.props.location?.state?.cardPayment && (
                          <div className="d-flex flex-row py-3">
                            <div className="p-0 col-2">
                              <img loading='lazy' decoding='async'
                                src={CardImg}
                                alt=""
                                className="img-fluid verticle-center"
                              />
                            </div>
                            <div className="px-1 col-10">
                              <h5 className="color-blue fw-bold m-0">
                                {this.numberWithCommas(total)} {t("Points")} (
                                {this.numberWithCom(pointsCurrancy)}{" "}
                                {t(selectedCurrency)})
                              </h5>
                              <small className="text-muted fw-bold">
                                {t("to be paid by Credit Card/MADA")}
                              </small>
                              <br />
                              <img loading='lazy' decoding='async' src={Visa} alt ="visa" />
                      <img loading='lazy' decoding='async' src={MasterCard} alt ="mastercatd" />
                      <img loading='lazy' decoding='async' src={Mada} alt ="mada" />
                            </div>
                          </div>
                        )}

                        <hr className="hr-line" />
                        <div className="d-flex flex-row justify-content-between pt-1 pb-3">
                          <h5 className="text-dark fw-bold m-0">
                            {t("Total")}
                          </h5>
                          {this.props.lang === "en" ? (
                            <h5 className="text-dark fw-bold m-0">
                              {this.numberWithCommas(total)}
                              <small>&nbsp;{t("Points")}</small>
                            </h5>
                          ) : (
                            <h5 className="text-dark fw-bold m-0">
                              <small>{t("Points")}</small>&nbsp;
                              {this.numberWithCommas(total)}
                            </h5>
                          )}
                        </div>
                        {/* <div className="d-flex gap-2">
                          <input
                            type="checkbox"
                            className="flex-shrink-0"
                            onClick={() => {
                              this.setCheckoutBtn();
                            }}
                          />
                          <label className="">
                          {t("I have read and accepted the")} <span onClick={this.closeModal} className="color-green cursor-pointer">{t("Terms & Conditions")}</span>.
                          </label>
                        </div> */}
                        <br />
                        <button
                          className="btn btn-block primary-btn text-light rounded-0 text-center"
                          disabled={this.props.currencyConversonLoading}
                          onClick={()=>{this.postOrderHandler()}} 
                        >
                          {/* {t("Checkout Now")} */}
                          {loading ? (
                            <span>
                              {" "}
                              <div
                                class="spinner-border spinner-border-sm "
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                              <span className="px-2">
                                {t("Checking")} . . .
                              </span>
                            </span>
                          ) : (
                            <span>
                              {this.props.location?.state?.cardPayment
                                ? t("Buy with Card")
                                : missingPoints > 0
                                ? t("Buy with Card")
                                : t("Checkout Now")}
                            </span>
                          )}
                        </button>
                        {/* //TODO: Remove the false to enable the apply pay  */}
                        {this.props.convertionRate?.payment_method_availability
                          ?.applepay &&
                          (missingPoints > 0 || this.props.location?.state?.cardPayment) && (
                            <button
                              id="applePayButton"
                              className="btn btn-block primary-btn text-light rounded-0 text-center"
                              style={{ display: "none" }}
                              onClick={() => {
                                applePayHander(
                                  this.props.location?.state?.cardPayment
                                    ? this.state.orderDetails.total
                                    : pointsToCurrancy(missingPoints),
                                  this.props.location?.state?.orders,
                                  this.ApplePayFailed,
                                  "GIFTCARD"
                                );
                              }}
                              disabled={this.props.currencyConversonLoading}
                            >
                              {loading ? (
                                <span>
                                  {" "}
                                  <div
                                    class="spinner-border spinner-border-sm "
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  <span className="px-2">
                                    {t("Checking")} . . .
                                  </span>
                                </span>
                              ) : (
                                <span>{t("Buy with Apple Pay")}</span>
                              )}
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                  {/* Desktop Order Summary End  */}
                </div>
              </section>
              {MobileOrderSummary}
            </>
          }
        />
        {
                    this.state.redirectToPayment && (
                        (missingPoints>0 || this.props.location?.state?.cardPayment) &&
                        <Redirect
                        push to={{
                        pathname:'/payment/card-details-travelshop',
                        state:{
                            order:this.state.creditCardPaymentPayload,
                            cardPayment:this.props.location?.state?.cardPayment,
                            orderCreationURL:'orders/create_travel_order',
                            summaryPageURL:'/payment/travel-orders-summary',
                            currancyCode:selectedCurrency,
                            convertedCurrency:pointsCurrancy,
                            splitPayment: (missingPoints > 0 && !this.props.location?.state?.cardPayment) ? {value: Number(convertedPointsBalance) + Number(pointsCurrancy)} : false,
                            passOrderID:true
                        }
                }}/>

                    )
                    
                }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderDetails:state.order.orderDetails,
    pointBalance:state.point.pointBalance,
    errorFlag:state.order.errorFlag,
    authToken:state.auth.authToken,
    currancyCode:state.countries.convertionRate.currency_code,
    flightDetails:state.travel.selectedFlightDetail?.search?.data,
    fareDetails:state.travel.selectedFlightDetail?.search?.data?.fare_breakdown_local,
    searchResults: state.travel.searchResults,
    searchQueryData:state.travel.searchQueryData,
    contactDetails:state.travel.contactDetails,
    acceptedCurrencies: state.conversion.paymentCurrencies,
    currencyConversonLoading: state.conversion.loading,
    brandID: state.travel.brandID,
    minimum_payable_amount: state.conversion?.convertedValue?.minimum_payable_amount,
    convertedCurrency: state.conversion.convertedValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeDataAfterPayment: ()=> dispatch(removeDataAfterPayment()),
    fetchCurrencyConversion: (payload) => dispatch(fetchCurrencyConversion(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withTranslation()(TravelConfirmationModal))));
