import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import {ReactComponent as Sadsmiley} from "../../../assests/img/sadsmiley.svg";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class WarningModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show:true
         }
    }

 
    render() { 
        const {t,closeModal}=this.props
        return ( 
            <ModalWrapper show={this.props.show}>
                <div 
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
                    style={{width:'30rem'}}>
                    {/* <img 
                        className="p-md-0 p-3"
                        height="100"
                        src={sadsmiley} alt="" />  */}
                        <Sadsmiley />
                    <h5 className='text-muted m-0 py-md-3 py-0'>
                    {t("Sorry, your selected amount exceeds your point balance.")}
                     {/* <span className='text-dark'>{this.props.currencySymbol}{this.props.point}</span> */}
                    </h5> 
                    {/* <p>{t("")}</p> */}
                    <button className="btn mt-3 mx-2 text-white btnTextBlack  selected-value rounded-0"
                        onClick={()=>{closeModal()}}>
                        <span className="fw-bold">{t("Close")}</span></button>
                </div>
            </ModalWrapper>
         );
    }
}
const mapStateToProps = (state) => {
  return {
    point: state.point.pointBalance,
  };
};


export default connect(mapStateToProps)(withTranslation()(WarningModal));